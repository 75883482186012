import { getPrefName, getPrefNameKana } from "areacode/data/prefList"

export function getCityName(city: CityInfo) {
  return city.name + city.type;
}

export function getPrefCityName(city: CityInfo) {
  return getPrefName(city) + getCityName(city);
}

export function getPrefCountyName(city: CityInfo) {
  return getPrefName(city) + city.county.name + city.county.type;
}

export function getPrefCountyCityName(city: CityInfo) {
  return getPrefName(city) + city.county.name + city.county.type + getCityName(city);
}

export function getCityNameKana(city: CityInfo) {
  return city.kana + city.typeKana;
}

export function getPrefCityNameKana(city: CityInfo) {
  return getPrefNameKana(city) + getCityNameKana(city);
}

export function getPrefCountyCityNameKana(city: CityInfo) {
  return getPrefNameKana(city) + city.county.kana + city.county.typeKana + getCityNameKana(city);
}

export function getPrefCountyCityNameKanaWithSlash(city: CityInfo) {
  if (!city.county.kana) {
    return `${getPrefNameKana(city)} / ${getCityNameKana(city)}`;
  } else if (!getCityNameKana(city)) {
    return `${getPrefNameKana(city)} / ${city.county.kana}${city.county.typeKana}`;
  } else {
    return `${getPrefNameKana(city)} / ${city.county.kana}${city.county.typeKana} / ${getCityNameKana(city)}`;
  }
}

export function getCityListByPref(pref: string) {
  return cityList.filter(function(city) {
    return getPrefName(city) === pref;
  })
}

export interface CityInfo {
  code: string;
  distinct: string;
  pref: string;
  county: {
    name: string;
    type: string;
    kana: string;
    typeKana: string;
    note: string;
  },
  name: string;
  type: string;
  kana: string;
  typeKana: string;
  compartmentCode: string;
  zone: {
    name: string;
    scale: string;
  },
}

export const cityList: CityInfo[] = [
  {
    code: '01100',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01101',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01102',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01103',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01104',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '白石',
    type: '区',
    kana: 'しろいし',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01105',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '豊平',
    type: '区',
    kana: 'とよひら',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01106',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01107',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01108',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '厚別',
    type: '区',
    kana: 'あつべつ',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01109',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '手稲',
    type: '区',
    kana: 'ていね',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01110',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '札幌',
      type: '市',
      kana: 'さっぽろ',
      typeKana: 'し',
      note: '',
    },
    name: '清田',
    type: '区',
    kana: 'きよた',
    typeKana: 'く',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01202',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '函館',
    type: '市',
    kana: 'はこだて',
    typeKana: 'し',
    compartmentCode: '25',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01203',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小樽',
    type: '市',
    kana: 'おたる',
    typeKana: 'し',
    compartmentCode: '15',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01204',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '旭川',
    type: '市',
    kana: 'あさひかわ',
    typeKana: 'し',
    compartmentCode: '85',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01205',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '室蘭',
    type: '市',
    kana: 'むろらん',
    typeKana: 'し',
    compartmentCode: '33',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01206',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '釧路',
    type: '市',
    kana: 'くしろ',
    typeKana: 'し',
    compartmentCode: '50',
    zone: {
      name: '音別町を除く。',
      scale: '',
    },
  },
  {
    code: '01206',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '釧路',
    type: '市',
    kana: 'くしろ',
    typeKana: 'し',
    compartmentCode: '51',
    zone: {
      name: '音別町',
      scale: '',
    },
  },
  {
    code: '01207',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '帯広',
    type: '市',
    kana: 'おびひろ',
    typeKana: 'し',
    compartmentCode: '53',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01208',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北見',
    type: '市',
    kana: 'きたみ',
    typeKana: 'し',
    compartmentCode: '43',
    zone: {
      name: '常呂町',
      scale: '',
    },
  },
  {
    code: '01208',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北見',
    type: '市',
    kana: 'きたみ',
    typeKana: 'し',
    compartmentCode: '59',
    zone: {
      name: '常呂町を除く。',
      scale: '',
    },
  },
  {
    code: '01209',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '夕張',
    type: '市',
    kana: 'ゆうばり',
    typeKana: 'し',
    compartmentCode: '3',
    zone: {
      name: '富野を除く。',
      scale: '',
    },
  },
  {
    code: '01209',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '夕張',
    type: '市',
    kana: 'ゆうばり',
    typeKana: 'し',
    compartmentCode: '4-2',
    zone: {
      name: '富野',
      scale: '',
    },
  },
  {
    code: '01210',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岩見沢',
    type: '市',
    kana: 'いわみざわ',
    typeKana: 'し',
    compartmentCode: '8-2',
    zone: {
      name: '宝水町を除く。',
      scale: '',
    },
  },
  {
    code: '01210',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岩見沢',
    type: '市',
    kana: 'いわみざわ',
    typeKana: 'し',
    compartmentCode: '10',
    zone: {
      name: '宝水町',
      scale: '',
    },
  },
  {
    code: '01211',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '網走',
    type: '市',
    kana: 'あばしり',
    typeKana: 'し',
    compartmentCode: '43',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01212',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '留萌',
    type: '市',
    kana: 'るもい',
    typeKana: 'し',
    compartmentCode: '72',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01213',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '苫小牧',
    type: '市',
    kana: 'とまこまい',
    typeKana: 'し',
    compartmentCode: '34',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01214',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稚内',
    type: '市',
    kana: 'わっかない',
    typeKana: 'し',
    compartmentCode: '66',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01215',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '美唄',
    type: '市',
    kana: 'びばい',
    typeKana: 'し',
    compartmentCode: '8-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01216',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '芦別',
    type: '市',
    kana: 'あしべつ',
    typeKana: 'し',
    compartmentCode: '6',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01217',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '江別',
    type: '市',
    kana: 'えべつ',
    typeKana: 'し',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01218',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '赤平',
    type: '市',
    kana: 'あかびら',
    typeKana: 'し',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01219',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '紋別',
    type: '市',
    kana: 'もんべつ',
    typeKana: 'し',
    compartmentCode: '60-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01220',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '士別',
    type: '市',
    kana: 'しべつ',
    typeKana: 'し',
    compartmentCode: '76-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01221',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '名寄',
    type: '市',
    kana: 'なよろ',
    typeKana: 'し',
    compartmentCode: '81',
    zone: {
      name: '風連町を除く。',
      scale: '',
    },
  },
  {
    code: '01221',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '名寄',
    type: '市',
    kana: 'なよろ',
    typeKana: 'し',
    compartmentCode: '82',
    zone: {
      name: '風連町',
      scale: '',
    },
  },
  {
    code: '01222',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三笠',
    type: '市',
    kana: 'みかさ',
    typeKana: 'し',
    compartmentCode: '10',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01223',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '根室',
    type: '市',
    kana: 'ねむろ',
    typeKana: 'し',
    compartmentCode: '47',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01224',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '千歳',
    type: '市',
    kana: 'ちとせ',
    typeKana: 'し',
    compartmentCode: '2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01225',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '滝川',
    type: '市',
    kana: 'たきかわ',
    typeKana: 'し',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01226',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '砂川',
    type: '市',
    kana: 'すながわ',
    typeKana: 'し',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01227',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '歌志内',
    type: '市',
    kana: 'うたしない',
    typeKana: 'し',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01228',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '深川',
    type: '市',
    kana: 'ふかがわ',
    typeKana: 'し',
    compartmentCode: '73',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01229',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富良野',
    type: '市',
    kana: 'ふらの',
    typeKana: 'し',
    compartmentCode: '86',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01230',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '登別',
    type: '市',
    kana: 'のぼりべつ',
    typeKana: 'し',
    compartmentCode: '33',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01231',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '恵庭',
    type: '市',
    kana: 'えにわ',
    typeKana: 'し',
    compartmentCode: '2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01233',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊達',
    type: '市',
    kana: 'だて',
    typeKana: 'し',
    compartmentCode: '32',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01234',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北広島',
    type: '市',
    kana: 'きたひろしま',
    typeKana: 'し',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01235',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '石狩',
    type: '市',
    kana: 'いしかり',
    typeKana: 'し',
    compartmentCode: '11-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01236',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北斗',
    type: '市',
    kana: 'ほくと',
    typeKana: 'し',
    compartmentCode: '25',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01303',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '石狩',
      type: '郡',
      kana: 'いしかり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '当別',
    type: '町',
    kana: 'とうべつ',
    typeKana: 'ちょう',
    compartmentCode: '12',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01304',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '石狩',
      type: '郡',
      kana: 'いしかり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新篠津',
    type: '村',
    kana: 'しんしのつ',
    typeKana: 'むら',
    compartmentCode: '8-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01331',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '松前',
      type: '郡',
      kana: 'まつまえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松前',
    type: '町',
    kana: 'まつまえ',
    typeKana: 'ちょう',
    compartmentCode: '27',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01332',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '松前',
      type: '郡',
      kana: 'まつまえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '福島',
    type: '町',
    kana: 'ふくしま',
    typeKana: 'ちょう',
    compartmentCode: '27',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01333',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上磯',
      type: '郡',
      kana: 'かみいそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '知内',
    type: '町',
    kana: 'しりうち',
    typeKana: 'ちょう',
    compartmentCode: '26',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01334',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上磯',
      type: '郡',
      kana: 'かみいそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '木古内',
    type: '町',
    kana: 'きこない',
    typeKana: 'ちょう',
    compartmentCode: '26',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01337',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '亀田',
      type: '郡',
      kana: 'かめだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '七飯',
    type: '町',
    kana: 'ななえ',
    typeKana: 'ちょう',
    compartmentCode: '25',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01343',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '茅部',
      type: '郡',
      kana: 'かやべ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鹿部',
    type: '町',
    kana: 'しかべ',
    typeKana: 'ちょう',
    compartmentCode: '20',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01345',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '茅部',
      type: '郡',
      kana: 'かやべ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '森',
    type: '町',
    kana: 'もり',
    typeKana: 'まち',
    compartmentCode: '21',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01346',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '二海',
      type: '郡',
      kana: 'ふたみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八雲',
    type: '町',
    kana: 'やくも',
    typeKana: 'ちょう',
    compartmentCode: '22',
    zone: {
      name: '熊石相沼町、熊石鮎川町、熊石泉岱町、熊石雲石町、熊石大谷町、熊石折戸町、熊石黒岩町、熊石見日町、熊石関内町、熊石平町、熊石畳岩町、熊石館平町、熊石泊川町、熊石鳴神町、熊石西浜町及び熊石根崎町を除く。',
      scale: '',
    },
  },
  {
    code: '01346',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '二海',
      type: '郡',
      kana: 'ふたみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八雲',
    type: '町',
    kana: 'やくも',
    typeKana: 'ちょう',
    compartmentCode: '31',
    zone: {
      name: '熊石相沼町、熊石鮎川町、熊石泉岱町、熊石雲石町、熊石大谷町、熊石折戸町、熊石黒岩町、熊石見日町、熊石関内町、熊石平町、熊石畳岩町、熊石館平町、熊石泊川町、熊石鳴神町、熊石西浜町及び熊石根崎町に限る。',
      scale: '',
    },
  },
  {
    code: '01347',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '山越',
      type: '郡',
      kana: 'やまこし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長万部',
    type: '町',
    kana: 'おしゃまんべ',
    typeKana: 'ちょう',
    compartmentCode: '23',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01361',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '檜山',
      type: '郡',
      kana: 'ひやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '江差',
    type: '町',
    kana: 'えさし',
    typeKana: 'ちょう',
    compartmentCode: '28-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01362',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '檜山',
      type: '郡',
      kana: 'ひやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上ノ国',
    type: '町',
    kana: 'かみのくに',
    typeKana: 'ちょう',
    compartmentCode: '28-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01363',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '檜山',
      type: '郡',
      kana: 'ひやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '厚沢部',
    type: '町',
    kana: 'あっさぶ',
    typeKana: 'ちょう',
    compartmentCode: '28-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01364',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '爾志',
      type: '郡',
      kana: 'にし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '乙部',
    type: '町',
    kana: 'おとべ',
    typeKana: 'ちょう',
    compartmentCode: '28-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01367',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '奥尻',
      type: '郡',
      kana: 'おくしり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '奥尻',
    type: '町',
    kana: 'おくしり',
    typeKana: 'ちょう',
    compartmentCode: '30',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01370',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '瀬棚',
      type: '郡',
      kana: 'せたな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '今金',
    type: '町',
    kana: 'いまかね',
    typeKana: 'ちょう',
    compartmentCode: '24',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01371',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '久遠',
      type: '郡',
      kana: 'くどお',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'せたな',
    type: '町',
    kana: 'せたな',
    typeKana: 'ちょう',
    compartmentCode: '24',
    zone: {
      name: '大成区を除く。',
      scale: '',
    },
  },
  {
    code: '01371',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '久遠',
      type: '郡',
      kana: 'くどお',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'せたな',
    type: '町',
    kana: 'せたな',
    typeKana: 'ちょう',
    compartmentCode: '31',
    zone: {
      name: '大成区',
      scale: '',
    },
  },
  {
    code: '01391',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '島牧',
      type: '郡',
      kana: 'しままき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '島牧',
    type: '村',
    kana: 'しままき',
    typeKana: 'むら',
    compartmentCode: '19',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01392',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '寿都',
      type: '郡',
      kana: 'すっつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '寿都',
    type: '町',
    kana: 'すっつ',
    typeKana: 'ちょう',
    compartmentCode: '19',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01393',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '寿都',
      type: '郡',
      kana: 'すっつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '黒松内',
    type: '町',
    kana: 'くろまつない',
    typeKana: 'ちょう',
    compartmentCode: '19',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01394',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '磯谷',
      type: '郡',
      kana: 'いそや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '蘭越',
    type: '町',
    kana: 'らんこし',
    typeKana: 'ちょう',
    compartmentCode: '18',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01395',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'ニセコ',
    type: '町',
    kana: 'にせこ',
    typeKana: 'ちょう',
    compartmentCode: '18',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01396',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '真狩',
    type: '村',
    kana: 'まっかり',
    typeKana: 'むら',
    compartmentCode: '18',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01397',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '留寿都',
    type: '村',
    kana: 'るすつ',
    typeKana: 'むら',
    compartmentCode: '18',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01398',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '喜茂別',
    type: '町',
    kana: 'きもべつ',
    typeKana: 'ちょう',
    compartmentCode: '18',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01399',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '京極',
    type: '町',
    kana: 'きょうごく',
    typeKana: 'ちょう',
    compartmentCode: '18',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01400',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '倶知安',
    type: '町',
    kana: 'くっちゃん',
    typeKana: 'ちょう',
    compartmentCode: '18',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01401',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '岩内',
      type: '郡',
      kana: 'いわない',
      typeKana: 'ぐん',
      note: '',
    },
    name: '共和',
    type: '町',
    kana: 'きょうわ',
    typeKana: 'ちょう',
    compartmentCode: '17',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01402',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '岩内',
      type: '郡',
      kana: 'いわない',
      typeKana: 'ぐん',
      note: '',
    },
    name: '岩内',
    type: '町',
    kana: 'いわない',
    typeKana: 'ちょう',
    compartmentCode: '17',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01403',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '古宇',
      type: '郡',
      kana: 'ふるう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '泊',
    type: '村',
    kana: 'とまり',
    typeKana: 'むら',
    compartmentCode: '17',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01404',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '古宇',
      type: '郡',
      kana: 'ふるう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神恵内',
    type: '村',
    kana: 'かもえない',
    typeKana: 'むら',
    compartmentCode: '17',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01405',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '積丹',
      type: '郡',
      kana: 'しゃこたん',
      typeKana: 'ぐん',
      note: '',
    },
    name: '積丹',
    type: '町',
    kana: 'しゃこたん',
    typeKana: 'ちょう',
    compartmentCode: '16',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01406',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '古平',
      type: '郡',
      kana: 'ふるびら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '古平',
    type: '町',
    kana: 'ふるびら',
    typeKana: 'ちょう',
    compartmentCode: '16',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01407',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '余市',
      type: '郡',
      kana: 'よいち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '仁木',
    type: '町',
    kana: 'にき',
    typeKana: 'ちょう',
    compartmentCode: '16',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01408',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '余市',
      type: '郡',
      kana: 'よいち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '余市',
    type: '町',
    kana: 'よいち',
    typeKana: 'ちょう',
    compartmentCode: '16',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01409',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '余市',
      type: '郡',
      kana: 'よいち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '赤井川',
    type: '村',
    kana: 'あかいがわ',
    typeKana: 'むら',
    compartmentCode: '16',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01423',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '空知',
      type: '郡',
      kana: 'そらち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南幌',
    type: '町',
    kana: 'なんぽろ',
    typeKana: 'ちょう',
    compartmentCode: '1',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01424',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '空知',
      type: '郡',
      kana: 'そらち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '奈井江',
    type: '町',
    kana: 'ないえ',
    typeKana: 'ちょう',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01425',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '空知',
      type: '郡',
      kana: 'そらち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上砂川',
    type: '町',
    kana: 'かみすながわ',
    typeKana: 'ちょう',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01427',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '夕張',
      type: '郡',
      kana: 'ゆうばり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '由仁',
    type: '町',
    kana: 'ゆに',
    typeKana: 'ちょう',
    compartmentCode: '4-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01428',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '夕張',
      type: '郡',
      kana: 'ゆうばり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長沼',
    type: '町',
    kana: 'ながぬま',
    typeKana: 'ちょう',
    compartmentCode: '4-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01429',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '夕張',
      type: '郡',
      kana: 'ゆうばり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '栗山',
    type: '町',
    kana: 'くりやま',
    typeKana: 'ちょう',
    compartmentCode: '4-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01430',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '樺戸',
      type: '郡',
      kana: 'かばと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '月形',
    type: '町',
    kana: 'つきがた',
    typeKana: 'ちょう',
    compartmentCode: '8-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01431',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '樺戸',
      type: '郡',
      kana: 'かばと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '浦臼',
    type: '町',
    kana: 'うらうす',
    typeKana: 'ちょう',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01432',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '樺戸',
      type: '郡',
      kana: 'かばと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新十津川',
    type: '町',
    kana: 'しんとつかわ',
    typeKana: 'ちょう',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01433',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '雨竜',
      type: '郡',
      kana: 'うりゅう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '妹背牛',
    type: '町',
    kana: 'もせうし',
    typeKana: 'ちょう',
    compartmentCode: '73',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01434',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '雨竜',
      type: '郡',
      kana: 'うりゅう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '秩父別',
    type: '町',
    kana: 'ちっぷべつ',
    typeKana: 'ちょう',
    compartmentCode: '73',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01436',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '雨竜',
      type: '郡',
      kana: 'うりゅう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '雨竜',
    type: '町',
    kana: 'うりゅう',
    typeKana: 'ちょう',
    compartmentCode: '7',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01437',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '雨竜',
      type: '郡',
      kana: 'うりゅう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北竜',
    type: '町',
    kana: 'ほくりゅう',
    typeKana: 'ちょう',
    compartmentCode: '73',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01438',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '雨竜',
      type: '郡',
      kana: 'うりゅう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '沼田',
    type: '町',
    kana: 'ぬまた',
    typeKana: 'ちょう',
    compartmentCode: '73',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01452',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '鷹栖',
    type: '町',
    kana: 'たかす',
    typeKana: 'ちょう',
    compartmentCode: '85',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01453',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '東神楽',
    type: '町',
    kana: 'ひがしかぐら',
    typeKana: 'ちょう',
    compartmentCode: '85',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01454',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '当麻',
    type: '町',
    kana: 'とうま',
    typeKana: 'ちょう',
    compartmentCode: '85',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01455',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '比布',
    type: '町',
    kana: 'ぴっぷ',
    typeKana: 'ちょう',
    compartmentCode: '85',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01456',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '愛別',
    type: '町',
    kana: 'あいべつ',
    typeKana: 'ちょう',
    compartmentCode: '84',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01457',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '上川',
    type: '町',
    kana: 'かみかわ',
    typeKana: 'ちょう',
    compartmentCode: '84',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01458',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '東川',
    type: '町',
    kana: 'ひがしかわ',
    typeKana: 'ちょう',
    compartmentCode: '85',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01459',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '石狩',
    },
    name: '美瑛',
    type: '町',
    kana: 'びえい',
    typeKana: 'ちょう',
    compartmentCode: '85',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01460',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '空知',
      type: '郡',
      kana: 'そらち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上富良野',
    type: '町',
    kana: 'かみふらの',
    typeKana: 'ちょう',
    compartmentCode: '86',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01461',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '空知',
      type: '郡',
      kana: 'そらち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中富良野',
    type: '町',
    kana: 'なかふらの',
    typeKana: 'ちょう',
    compartmentCode: '86',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01462',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '空知',
      type: '郡',
      kana: 'そらち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南富良野',
    type: '町',
    kana: 'みなみふらの',
    typeKana: 'ちょう',
    compartmentCode: '86',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01463',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '勇払',
      type: '郡',
      kana: 'ゆうふつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '占冠',
    type: '村',
    kana: 'しむかっぷ',
    typeKana: 'むら',
    compartmentCode: '86',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01464',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '天塩',
    },
    name: '和寒',
    type: '町',
    kana: 'わっさむ',
    typeKana: 'ちょう',
    compartmentCode: '76-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01465',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '天塩',
    },
    name: '剣淵',
    type: '町',
    kana: 'けんぶち',
    typeKana: 'ちょう',
    compartmentCode: '76-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01468',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '天塩',
    },
    name: '下川',
    type: '町',
    kana: 'しもかわ',
    typeKana: 'ちょう',
    compartmentCode: '82',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01469',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '天塩',
    },
    name: '美深',
    type: '町',
    kana: 'びふか',
    typeKana: 'ちょう',
    compartmentCode: '83',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01470',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '天塩',
    },
    name: '音威子府',
    type: '村',
    kana: 'おといねっぷ',
    typeKana: 'むら',
    compartmentCode: '83',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01471',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '天塩',
    },
    name: '中川',
    type: '町',
    kana: 'なかがわ',
    typeKana: 'ちょう',
    compartmentCode: '83',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01472',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '雨竜',
      type: '郡',
      kana: 'うりゅう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '幌加内',
    type: '町',
    kana: 'ほろかない',
    typeKana: 'ちょう',
    compartmentCode: '76-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01481',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '増毛',
      type: '郡',
      kana: 'ましけ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '増毛',
    type: '町',
    kana: 'ましけ',
    typeKana: 'ちょう',
    compartmentCode: '72',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01482',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '留萌',
      type: '郡',
      kana: 'るもい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小平',
    type: '町',
    kana: 'おびら',
    typeKana: 'ちょう',
    compartmentCode: '72',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01483',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '苫前',
      type: '郡',
      kana: 'とままえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '苫前',
    type: '町',
    kana: 'とままえ',
    typeKana: 'ちょう',
    compartmentCode: '74',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01484',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '苫前',
      type: '郡',
      kana: 'とままえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '羽幌',
    type: '町',
    kana: 'はぼろ',
    typeKana: 'ちょう',
    compartmentCode: '74',
    zone: {
      name: '天売相影、天売富磯、天売弁天、天売前浜、天売和浦、焼尻白浜、焼尻西浦、焼尻東浜及び焼尻緑ヶ丘を除く｡',
      scale: '',
    },
  },
  {
    code: '01484',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '苫前',
      type: '郡',
      kana: 'とままえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '羽幌',
    type: '町',
    kana: 'はぼろ',
    typeKana: 'ちょう',
    compartmentCode: '75',
    zone: {
      name: '天売相影、天売富磯、天売弁天、天売前浜、天売和浦、焼尻白浜、焼尻西浦、焼尻東浜及び焼尻緑ヶ丘に限る。',
      scale: '',
    },
  },
  {
    code: '01485',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '苫前',
      type: '郡',
      kana: 'とままえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '初山別',
    type: '村',
    kana: 'しょさんべつ',
    typeKana: 'むら',
    compartmentCode: '74',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01486',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '天塩',
      type: '郡',
      kana: 'てしお',
      typeKana: 'ぐん',
      note: '',
    },
    name: '遠別',
    type: '町',
    kana: 'えんべつ',
    typeKana: 'ちょう',
    compartmentCode: '67',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01487',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '天塩',
      type: '郡',
      kana: 'てしお',
      typeKana: 'ぐん',
      note: '',
    },
    name: '天塩',
    type: '町',
    kana: 'てしお',
    typeKana: 'ちょう',
    compartmentCode: '67',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01511',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '宗谷',
      type: '郡',
      kana: 'そうや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '猿払',
    type: '村',
    kana: 'さるふつ',
    typeKana: 'むら',
    compartmentCode: '69',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01512',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '枝幸',
      type: '郡',
      kana: 'えさし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '浜頓別',
    type: '町',
    kana: 'はまとんべつ',
    typeKana: 'ちょう',
    compartmentCode: '68',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01513',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '枝幸',
      type: '郡',
      kana: 'えさし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中頓別',
    type: '町',
    kana: 'なかとんべつ',
    typeKana: 'ちょう',
    compartmentCode: '68',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01514',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '枝幸',
      type: '郡',
      kana: 'えさし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '枝幸',
    type: '町',
    kana: 'えさし',
    typeKana: 'ちょう',
    compartmentCode: '70',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01516',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '天塩',
      type: '郡',
      kana: 'てしお',
      typeKana: 'ぐん',
      note: '',
    },
    name: '豊富',
    type: '町',
    kana: 'とよとみ',
    typeKana: 'ちょう',
    compartmentCode: '66',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01517',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '礼文',
      type: '郡',
      kana: 'れぶん',
      typeKana: 'ぐん',
      note: '',
    },
    name: '礼文',
    type: '町',
    kana: 'れぶん',
    typeKana: 'ちょう',
    compartmentCode: '71',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01518',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '利尻',
      type: '郡',
      kana: 'りしり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '利尻',
    type: '町',
    kana: 'りしり',
    typeKana: 'ちょう',
    compartmentCode: '71',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01519',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '利尻',
      type: '郡',
      kana: 'りしり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '利尻富士',
    type: '町',
    kana: 'りしりふじ',
    typeKana: 'ちょう',
    compartmentCode: '71',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01520',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '天塩',
      type: '郡',
      kana: 'てしお',
      typeKana: 'ぐん',
      note: '',
    },
    name: '幌延',
    type: '町',
    kana: 'ほろのべ',
    typeKana: 'ちょう',
    compartmentCode: '67',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01543',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '網走',
      type: '郡',
      kana: 'あばしり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美幌',
    type: '町',
    kana: 'びほろ',
    typeKana: 'ちょう',
    compartmentCode: '45',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01544',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '網走',
      type: '郡',
      kana: 'あばしり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '津別',
    type: '町',
    kana: 'つべつ',
    typeKana: 'ちょう',
    compartmentCode: '45',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01545',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '斜里',
      type: '郡',
      kana: 'しゃり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '斜里',
    type: '町',
    kana: 'しゃり',
    typeKana: 'ちょう',
    compartmentCode: '44',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01546',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '斜里',
      type: '郡',
      kana: 'しゃり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '清里',
    type: '町',
    kana: 'きよさと',
    typeKana: 'ちょう',
    compartmentCode: '44',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01547',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '斜里',
      type: '郡',
      kana: 'しゃり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小清水',
    type: '町',
    kana: 'こしみず',
    typeKana: 'ちょう',
    compartmentCode: '43',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01549',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '常呂',
      type: '郡',
      kana: 'ところ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '訓子府',
    type: '町',
    kana: 'くんねっぷ',
    typeKana: 'ちょう',
    compartmentCode: '59',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01550',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '常呂',
      type: '郡',
      kana: 'ところ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '置戸',
    type: '町',
    kana: 'おけと',
    typeKana: 'ちょう',
    compartmentCode: '59',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01552',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '常呂',
      type: '郡',
      kana: 'ところ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '佐呂間',
    type: '町',
    kana: 'さろま',
    typeKana: 'ちょう',
    compartmentCode: '64',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01555',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '紋別',
      type: '郡',
      kana: 'もんべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '遠軽',
    type: '町',
    kana: 'えんがる',
    typeKana: 'ちょう',
    compartmentCode: '62',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01559',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '紋別',
      type: '郡',
      kana: 'もんべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湧別',
    type: '町',
    kana: 'ゆうべつ',
    typeKana: 'ちょう',
    compartmentCode: '63',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01560',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '紋別',
      type: '郡',
      kana: 'もんべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '滝上',
    type: '町',
    kana: 'たきのうえ',
    typeKana: 'ちょう',
    compartmentCode: '60-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01561',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '紋別',
      type: '郡',
      kana: 'もんべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '興部',
    type: '町',
    kana: 'おこっぺ',
    typeKana: 'ちょう',
    compartmentCode: '65',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01562',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '紋別',
      type: '郡',
      kana: 'もんべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西興部',
    type: '村',
    kana: 'にしおこっぺ',
    typeKana: 'むら',
    compartmentCode: '65',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01563',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '紋別',
      type: '郡',
      kana: 'もんべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '雄武',
    type: '町',
    kana: 'おうむ',
    typeKana: 'ちょう',
    compartmentCode: '65',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01564',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '網走',
      type: '郡',
      kana: 'あばしり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大空',
    type: '町',
    kana: 'おおぞら',
    typeKana: 'ちょう',
    compartmentCode: '43',
    zone: {
      name: '東藻琴、東藻琴清浦、東藻琴栄、東藻琴新富、東藻琴末広、東藻琴大進、東藻琴千草、東藻琴西倉、東藻琴福富、東藻琴明生及び東藻琴山園に限る。',
      scale: '',
    },
  },
  {
    code: '01564',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '網走',
      type: '郡',
      kana: 'あばしり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大空',
    type: '町',
    kana: 'おおぞら',
    typeKana: 'ちょう',
    compartmentCode: '45',
    zone: {
      name: '東藻琴、東藻琴清浦、東藻琴栄、東藻琴新富、東藻琴末広、東藻琴大進、東藻琴千草、東藻琴西倉、東藻琴福富、東藻琴明生及び東藻琴山園を除く。',
      scale: '',
    },
  },
  {
    code: '01571',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '豊浦',
    type: '町',
    kana: 'とようら',
    typeKana: 'ちょう',
    compartmentCode: '32',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01575',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '有珠',
      type: '郡',
      kana: 'うす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '壮瞥',
    type: '町',
    kana: 'そうべつ',
    typeKana: 'ちょう',
    compartmentCode: '32',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01578',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '白老',
      type: '郡',
      kana: 'しらおい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白老',
    type: '町',
    kana: 'しらおい',
    typeKana: 'ちょう',
    compartmentCode: '34',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01581',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '勇払',
      type: '郡',
      kana: 'ゆうふつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '厚真',
    type: '町',
    kana: 'あつま',
    typeKana: 'ちょう',
    compartmentCode: '35',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01584',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '虻田',
      type: '郡',
      kana: 'あぶた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '洞爺湖',
    type: '町',
    kana: 'とうやこ',
    typeKana: 'ちょう',
    compartmentCode: '32',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01585',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '勇払',
      type: '郡',
      kana: 'ゆうふつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '安平',
    type: '町',
    kana: 'あびら',
    typeKana: 'ちょう',
    compartmentCode: '35',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01586',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '勇払',
      type: '郡',
      kana: 'ゆうふつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'むかわ',
    type: '町',
    kana: 'むかわ',
    typeKana: 'ちょう',
    compartmentCode: '36',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01601',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '沙流',
      type: '郡',
      kana: 'さる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日高',
    type: '町',
    kana: 'ひだか',
    typeKana: 'ちょう',
    compartmentCode: '37',
    zone: {
      name: '栄町西、栄町東、新町、千栄、富岡、日高、本町西、本町東、松風町、三岩、宮下町、山手町及び若葉町を除く。',
      scale: '',
    },
  },
  {
    code: '01601',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '沙流',
      type: '郡',
      kana: 'さる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日高',
    type: '町',
    kana: 'ひだか',
    typeKana: 'ちょう',
    compartmentCode: '38',
    zone: {
      name: '栄町西、栄町東、新町、千栄、富岡、日高、本町西、本町東、松風町、三岩、宮下町、山手町及び若葉町に限る。',
      scale: '',
    },
  },
  {
    code: '01602',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '沙流',
      type: '郡',
      kana: 'さる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '平取',
    type: '町',
    kana: 'びらとり',
    typeKana: 'ちょう',
    compartmentCode: '38',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01604',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '新冠',
      type: '郡',
      kana: 'にいかっぷ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新冠',
    type: '町',
    kana: 'にいかっぷ',
    typeKana: 'ちょう',
    compartmentCode: '37',
    zone: {
      name: '里平',
      scale: '',
    },
  },
  {
    code: '01604',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '新冠',
      type: '郡',
      kana: 'にいかっぷ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新冠',
    type: '町',
    kana: 'にいかっぷ',
    typeKana: 'ちょう',
    compartmentCode: '41',
    zone: {
      name: '里平を除く。',
      scale: '',
    },
  },
  {
    code: '01607',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '浦河',
      type: '郡',
      kana: 'うらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '浦河',
    type: '町',
    kana: 'うらかわ',
    typeKana: 'ちょう',
    compartmentCode: '39-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01608',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '様似',
      type: '郡',
      kana: 'さまに',
      typeKana: 'ぐん',
      note: '',
    },
    name: '様似',
    type: '町',
    kana: 'さまに',
    typeKana: 'ちょう',
    compartmentCode: '39-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01609',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '幌泉',
      type: '郡',
      kana: 'ほろいずみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'えりも',
    type: '町',
    kana: 'えりも',
    typeKana: 'ちょう',
    compartmentCode: '42',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01610',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '日高',
      type: '郡',
      kana: 'ひだか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新ひだか',
    type: '町',
    kana: 'しんひだか',
    typeKana: 'ちょう',
    compartmentCode: '41',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01631',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '河東',
      type: '郡',
      kana: 'かとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '音更',
    type: '町',
    kana: 'おとふけ',
    typeKana: 'ちょう',
    compartmentCode: '53',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01632',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '河東',
      type: '郡',
      kana: 'かとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '士幌',
    type: '町',
    kana: 'しほろ',
    typeKana: 'ちょう',
    compartmentCode: '57',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01633',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '河東',
      type: '郡',
      kana: 'かとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上士幌',
    type: '町',
    kana: 'かみしほろ',
    typeKana: 'ちょう',
    compartmentCode: '57',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01634',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '河東',
      type: '郡',
      kana: 'かとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鹿追',
    type: '町',
    kana: 'しかおい',
    typeKana: 'ちょう',
    compartmentCode: '58',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01635',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '十勝',
    },
    name: '新得',
    type: '町',
    kana: 'しんとく',
    typeKana: 'ちょう',
    compartmentCode: '58',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01636',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '上川',
      type: '郡',
      kana: 'かみかわ',
      typeKana: 'ぐん',
      note: '十勝',
    },
    name: '清水',
    type: '町',
    kana: 'しみず',
    typeKana: 'ちょう',
    compartmentCode: '58',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01637',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '河西',
      type: '郡',
      kana: 'かさい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '芽室',
    type: '町',
    kana: 'めむろ',
    typeKana: 'ちょう',
    compartmentCode: '53',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01638',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '河西',
      type: '郡',
      kana: 'かさい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中札内',
    type: '村',
    kana: 'なかさつない',
    typeKana: 'むら',
    compartmentCode: '53',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01639',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '河西',
      type: '郡',
      kana: 'かさい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '更別',
    type: '村',
    kana: 'さらべつ',
    typeKana: 'むら',
    compartmentCode: '53',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01641',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '広尾',
      type: '郡',
      kana: 'ひろお',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大樹',
    type: '町',
    kana: 'たいき',
    typeKana: 'ちょう',
    compartmentCode: '55',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01642',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '広尾',
      type: '郡',
      kana: 'ひろお',
      typeKana: 'ぐん',
      note: '',
    },
    name: '広尾',
    type: '町',
    kana: 'ひろお',
    typeKana: 'ちょう',
    compartmentCode: '55',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01643',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '十勝',
    },
    name: '幕別',
    type: '町',
    kana: 'まくべつ',
    typeKana: 'ちょう',
    compartmentCode: '53',
    zone: {
      name: '忠類朝日、忠類共栄、忠類協徳、忠類公親、忠類幸町、忠類栄町、忠類白銀町、忠類新生、忠類東宝、忠類中当、忠類錦町、忠類西当、忠類晩成、忠類日和、忠類古里、忠類幌内、忠類明和、忠類元忠類及び忠類本町を除く。',
      scale: '',
    },
  },
  {
    code: '01643',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '十勝',
    },
    name: '幕別',
    type: '町',
    kana: 'まくべつ',
    typeKana: 'ちょう',
    compartmentCode: '55',
    zone: {
      name: '忠類朝日、忠類共栄、忠類協徳、忠類公親、忠類幸町、忠類栄町、忠類白銀町、忠類新生、忠類東宝、忠類中当、忠類錦町、忠類西当、忠類晩成、忠類日和、忠類古里、忠類幌内、忠類明和、忠類元忠類及び忠類本町に限る。',
      scale: '',
    },
  },
  {
    code: '01644',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '十勝',
    },
    name: '池田',
    type: '町',
    kana: 'いけだ',
    typeKana: 'ちょう',
    compartmentCode: '54',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01645',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '十勝',
    },
    name: '豊頃',
    type: '町',
    kana: 'とよころ',
    typeKana: 'ちょう',
    compartmentCode: '54',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01646',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '中川',
      type: '郡',
      kana: 'なかがわ',
      typeKana: 'ぐん',
      note: '十勝',
    },
    name: '本別',
    type: '町',
    kana: 'ほんべつ',
    typeKana: 'ちょう',
    compartmentCode: '56',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01647',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '足寄',
      type: '郡',
      kana: 'あしょろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '足寄',
    type: '町',
    kana: 'あしょろ',
    typeKana: 'ちょう',
    compartmentCode: '56',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01648',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '足寄',
      type: '郡',
      kana: 'あしょろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '陸別',
    type: '町',
    kana: 'りくべつ',
    typeKana: 'ちょう',
    compartmentCode: '56',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01649',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '十勝',
      type: '郡',
      kana: 'とかち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '浦幌',
    type: '町',
    kana: 'うらほろ',
    typeKana: 'ちょう',
    compartmentCode: '54',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01661',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '釧路',
      type: '郡',
      kana: 'くしろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '釧路',
    type: '町',
    kana: 'くしろ',
    typeKana: 'ちょう',
    compartmentCode: '50',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01662',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '厚岸',
      type: '郡',
      kana: 'あっけし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '厚岸',
    type: '町',
    kana: 'あっけし',
    typeKana: 'ちょう',
    compartmentCode: '46',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01663',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '厚岸',
      type: '郡',
      kana: 'あっけし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '浜中',
    type: '町',
    kana: 'はまなか',
    typeKana: 'ちょう',
    compartmentCode: '46',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01664',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '川上',
      type: '郡',
      kana: 'かわかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '標茶',
    type: '町',
    kana: 'しべちゃ',
    typeKana: 'ちょう',
    compartmentCode: '52',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01665',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '川上',
      type: '郡',
      kana: 'かわかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '弟子屈',
    type: '町',
    kana: 'てしかが',
    typeKana: 'ちょう',
    compartmentCode: '52',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01667',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '阿寒',
      type: '郡',
      kana: 'あかん',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鶴居',
    type: '村',
    kana: 'つるい',
    typeKana: 'むら',
    compartmentCode: '50',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01668',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '白糠',
      type: '郡',
      kana: 'しらぬか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白糠',
    type: '町',
    kana: 'しらぬか',
    typeKana: 'ちょう',
    compartmentCode: '51',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01691',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '野付',
      type: '郡',
      kana: 'のつけ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '別海',
    type: '町',
    kana: 'べつかい',
    typeKana: 'ちょう',
    compartmentCode: '48',
    zone: {
      name: '尾岱沼、尾岱沼港町、尾岱沼岬町、尾岱沼潮見町及び床丹を除く。',
      scale: '',
    },
  },
  {
    code: '01691',
    distinct: '2',
    pref: '北海道',
    county: {
      name: '野付',
      type: '郡',
      kana: 'のつけ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '別海',
    type: '町',
    kana: 'べつかい',
    typeKana: 'ちょう',
    compartmentCode: '49',
    zone: {
      name: '尾岱沼、尾岱沼港町、尾岱沼岬町、尾岱沼潮見町及び床丹に限る。',
      scale: '',
    },
  },
  {
    code: '01692',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '標津',
      type: '郡',
      kana: 'しべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中標津',
    type: '町',
    kana: 'なかしべつ',
    typeKana: 'ちょう',
    compartmentCode: '48',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01693',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '標津',
      type: '郡',
      kana: 'しべつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '標津',
    type: '町',
    kana: 'しべつ',
    typeKana: 'ちょう',
    compartmentCode: '49',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01694',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '目梨',
      type: '郡',
      kana: 'めなし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '羅臼',
    type: '町',
    kana: 'らうす',
    typeKana: 'ちょう',
    compartmentCode: '49',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01695',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '色丹',
    type: '村',
    kana: 'しこたん',
    typeKana: 'むら',
    compartmentCode: '-',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01696',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '泊',
    type: '村',
    kana: 'とまり',
    typeKana: 'むら',
    compartmentCode: '-',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01697',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '留夜別',
    type: '村',
    kana: 'るよべつ',
    typeKana: 'むら',
    compartmentCode: '-',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01698',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '留別',
    type: '村',
    kana: 'るべつ',
    typeKana: 'むら',
    compartmentCode: '-',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01699',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '紗那',
    type: '村',
    kana: 'しゃな',
    typeKana: 'むら',
    compartmentCode: '-',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '01700',
    distinct: '1',
    pref: '北海道',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '蘂取',
    type: '村',
    kana: 'しべとろ',
    typeKana: 'むら',
    compartmentCode: '-',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02201',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '青森',
    type: '市',
    kana: 'あおもり',
    typeKana: 'し',
    compartmentCode: '87',
    zone: {
      name: '浪岡',
      scale: '',
    },
  },
  {
    code: '02201',
    distinct: '2',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '青森',
    type: '市',
    kana: 'あおもり',
    typeKana: 'し',
    compartmentCode: '94-2',
    zone: {
      name: '浪岡を除く。',
      scale: '',
    },
  },
  {
    code: '02202',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '弘前',
    type: '市',
    kana: 'ひろさき',
    typeKana: 'し',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02203',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八戸',
    type: '市',
    kana: 'はちのへ',
    typeKana: 'し',
    compartmentCode: '95',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02204',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '黒石',
    type: '市',
    kana: 'くろいし',
    typeKana: 'し',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02205',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '五所川原',
    type: '市',
    kana: 'ごしょがわら',
    typeKana: 'し',
    compartmentCode: '88',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02206',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '十和田',
    type: '市',
    kana: 'とわだ',
    typeKana: 'し',
    compartmentCode: '93',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02207',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三沢',
    type: '市',
    kana: 'みさわ',
    typeKana: 'し',
    compartmentCode: '93',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02208',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'むつ',
    type: '市',
    kana: 'むつ',
    typeKana: 'し',
    compartmentCode: '91',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02209',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'つがる',
    type: '市',
    kana: 'つがる',
    typeKana: 'し',
    compartmentCode: '88',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02210',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '平川',
    type: '市',
    kana: 'ひらかわ',
    typeKana: 'し',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02301',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '東津軽',
      type: '郡',
      kana: 'ひがしつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '平内',
    type: '町',
    kana: 'ひらない',
    typeKana: 'まち',
    compartmentCode: '94-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02303',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '東津軽',
      type: '郡',
      kana: 'ひがしつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '今別',
    type: '町',
    kana: 'いまべつ',
    typeKana: 'まち',
    compartmentCode: '90',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02304',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '東津軽',
      type: '郡',
      kana: 'ひがしつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '蓬田',
    type: '村',
    kana: 'よもぎた',
    typeKana: 'むら',
    compartmentCode: '90',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02307',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '東津軽',
      type: '郡',
      kana: 'ひがしつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '外ヶ浜',
    type: '町',
    kana: 'そとがはま',
    typeKana: 'まち',
    compartmentCode: '90',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02321',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '西津軽',
      type: '郡',
      kana: 'にしつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鰺ヶ沢',
    type: '町',
    kana: 'あじがさわ',
    typeKana: 'まち',
    compartmentCode: '89',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02323',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '西津軽',
      type: '郡',
      kana: 'にしつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '深浦',
    type: '町',
    kana: 'ふかうら',
    typeKana: 'まち',
    compartmentCode: '89',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02343',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '中津軽',
      type: '郡',
      kana: 'なかつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西目屋',
    type: '村',
    kana: 'にしめや',
    typeKana: 'むら',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02361',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '南津軽',
      type: '郡',
      kana: 'みなみつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '藤崎',
    type: '町',
    kana: 'ふじさき',
    typeKana: 'まち',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02362',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '南津軽',
      type: '郡',
      kana: 'みなみつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大鰐',
    type: '町',
    kana: 'おおわに',
    typeKana: 'まち',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02367',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '南津軽',
      type: '郡',
      kana: 'みなみつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田舎館',
    type: '村',
    kana: 'いなかだて',
    typeKana: 'むら',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02381',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '北津軽',
      type: '郡',
      kana: 'きたつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '板柳',
    type: '町',
    kana: 'いたやなぎ',
    typeKana: 'まち',
    compartmentCode: '87',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02384',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '北津軽',
      type: '郡',
      kana: 'きたつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鶴田',
    type: '町',
    kana: 'つるた',
    typeKana: 'まち',
    compartmentCode: '88',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02387',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '北津軽',
      type: '郡',
      kana: 'きたつがる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中泊',
    type: '町',
    kana: 'なかどまり',
    typeKana: 'まち',
    compartmentCode: '88',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02401',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '野辺地',
    type: '町',
    kana: 'のへじ',
    typeKana: 'まち',
    compartmentCode: '92',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02402',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '七戸',
    type: '町',
    kana: 'しちのへ',
    typeKana: 'まち',
    compartmentCode: '93',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02405',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '六戸',
    type: '町',
    kana: 'ろくのへ',
    typeKana: 'まち',
    compartmentCode: '93',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02406',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '横浜',
    type: '町',
    kana: 'よこはま',
    typeKana: 'まち',
    compartmentCode: '92',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02408',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東北',
    type: '町',
    kana: 'とうほく',
    typeKana: 'まち',
    compartmentCode: '92',
    zone: {
      name: '旭北、旭南、上野、大浦、上北北、上北南及び新館を除く。',
      scale: '',
    },
  },
  {
    code: '02408',
    distinct: '2',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東北',
    type: '町',
    kana: 'とうほく',
    typeKana: 'まち',
    compartmentCode: '93',
    zone: {
      name: '旭北、旭南、上野、大浦、上北北、上北南及び新館に限る。',
      scale: '',
    },
  },
  {
    code: '02411',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '六ヶ所',
    type: '村',
    kana: 'ろっかしょ',
    typeKana: 'むら',
    compartmentCode: '92',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02412',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '上北',
      type: '郡',
      kana: 'かみきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'おいらせ',
    type: '町',
    kana: 'おいらせ',
    typeKana: 'ちょう',
    compartmentCode: '95',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02423',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '下北',
      type: '郡',
      kana: 'しもきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大間',
    type: '町',
    kana: 'おおま',
    typeKana: 'まち',
    compartmentCode: '91',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02424',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '下北',
      type: '郡',
      kana: 'しもきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東通',
    type: '村',
    kana: 'ひがしどおり',
    typeKana: 'むら',
    compartmentCode: '91',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02425',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '下北',
      type: '郡',
      kana: 'しもきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '風間浦',
    type: '村',
    kana: 'かざまうら',
    typeKana: 'むら',
    compartmentCode: '91',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02426',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '下北',
      type: '郡',
      kana: 'しもきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '佐井',
    type: '村',
    kana: 'さい',
    typeKana: 'むら',
    compartmentCode: '91',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02441',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '三戸',
      type: '郡',
      kana: 'さんのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三戸',
    type: '町',
    kana: 'さんのへ',
    typeKana: 'まち',
    compartmentCode: '96',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02442',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '三戸',
      type: '郡',
      kana: 'さんのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '五戸',
    type: '町',
    kana: 'ごのへ',
    typeKana: 'まち',
    compartmentCode: '95',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02443',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '三戸',
      type: '郡',
      kana: 'さんのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田子',
    type: '町',
    kana: 'たっこ',
    typeKana: 'まち',
    compartmentCode: '96',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02445',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '三戸',
      type: '郡',
      kana: 'さんのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南部',
    type: '町',
    kana: 'なんぶ',
    typeKana: 'ちょう',
    compartmentCode: '95',
    zone: {
      name: '相内、赤石、大向、沖田面、小向及び玉掛を除く。',
      scale: '',
    },
  },
  {
    code: '02445',
    distinct: '2',
    pref: '青森県',
    county: {
      name: '三戸',
      type: '郡',
      kana: 'さんのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南部',
    type: '町',
    kana: 'なんぶ',
    typeKana: 'ちょう',
    compartmentCode: '96',
    zone: {
      name: '相内、赤石、大向、沖田面、小向及び玉掛に限る。',
      scale: '',
    },
  },
  {
    code: '02446',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '三戸',
      type: '郡',
      kana: 'さんのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '階上',
    type: '町',
    kana: 'はしかみ',
    typeKana: 'ちょう',
    compartmentCode: '95',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '02450',
    distinct: '1',
    pref: '青森県',
    county: {
      name: '三戸',
      type: '郡',
      kana: 'さんのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新郷',
    type: '村',
    kana: 'しんごう',
    typeKana: 'むら',
    compartmentCode: '95',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03201',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '盛岡',
    type: '市',
    kana: 'もりおか',
    typeKana: 'し',
    compartmentCode: '116',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03202',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宮古',
    type: '市',
    kana: 'みやこ',
    typeKana: 'し',
    compartmentCode: '110',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03203',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大船渡',
    type: '市',
    kana: 'おおふなと',
    typeKana: 'し',
    compartmentCode: '109',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03205',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '花巻',
    type: '市',
    kana: 'はなまき',
    typeKana: 'し',
    compartmentCode: '119',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03206',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北上',
    type: '市',
    kana: 'きたかみ',
    typeKana: 'し',
    compartmentCode: '118',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03207',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '久慈',
    type: '市',
    kana: 'くじ',
    typeKana: 'し',
    compartmentCode: '112',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03208',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '遠野',
    type: '市',
    kana: 'とおの',
    typeKana: 'し',
    compartmentCode: '120',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03209',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '一関',
    type: '市',
    kana: 'いちのせき',
    typeKana: 'し',
    compartmentCode: '108',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03210',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '陸前高田',
    type: '市',
    kana: 'りくぜんたかた',
    typeKana: 'し',
    compartmentCode: '109',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03211',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '釜石',
    type: '市',
    kana: 'かまいし',
    typeKana: 'し',
    compartmentCode: '111',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03213',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '二戸',
    type: '市',
    kana: 'にのへ',
    typeKana: 'し',
    compartmentCode: '115',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03214',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八幡平',
    type: '市',
    kana: 'はちまんたい',
    typeKana: 'し',
    compartmentCode: '114',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03215',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '奥州',
    type: '市',
    kana: 'おうしゅう',
    typeKana: 'し',
    compartmentCode: '117',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03216',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '滝沢',
    type: '市',
    kana: 'たきざわ',
    typeKana: 'し',
    compartmentCode: '116',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03301',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '岩手',
      type: '郡',
      kana: 'いわて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '雫石',
    type: '町',
    kana: 'しずくいし',
    typeKana: 'ちょう',
    compartmentCode: '116',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03302',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '岩手',
      type: '郡',
      kana: 'いわて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '葛巻',
    type: '町',
    kana: 'くずまき',
    typeKana: 'まち',
    compartmentCode: '114',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03303',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '岩手',
      type: '郡',
      kana: 'いわて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '岩手',
    type: '町',
    kana: 'いわて',
    typeKana: 'まち',
    compartmentCode: '114',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03321',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '紫波',
      type: '郡',
      kana: 'しわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '紫波',
    type: '町',
    kana: 'しわ',
    typeKana: 'ちょう',
    compartmentCode: '116',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03322',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '紫波',
      type: '郡',
      kana: 'しわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '矢巾',
    type: '町',
    kana: 'やはば',
    typeKana: 'ちょう',
    compartmentCode: '116',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03366',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '和賀',
      type: '郡',
      kana: 'わが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西和賀',
    type: '町',
    kana: 'にしわが',
    typeKana: 'まち',
    compartmentCode: '118',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03381',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '胆沢',
      type: '郡',
      kana: 'いさわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '金ケ崎',
    type: '町',
    kana: 'かねがさき',
    typeKana: 'ちょう',
    compartmentCode: '117',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03402',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '西磐井',
      type: '郡',
      kana: 'にしいわい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '平泉',
    type: '町',
    kana: 'ひらいずみ',
    typeKana: 'ちょう',
    compartmentCode: '108',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03441',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '気仙',
      type: '郡',
      kana: 'けせん',
      typeKana: 'ぐん',
      note: '',
    },
    name: '住田',
    type: '町',
    kana: 'すみた',
    typeKana: 'ちょう',
    compartmentCode: '109',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03461',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '上閉伊',
      type: '郡',
      kana: 'かみへい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大槌',
    type: '町',
    kana: 'おおつち',
    typeKana: 'ちょう',
    compartmentCode: '111',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03482',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '下閉伊',
      type: '郡',
      kana: 'しもへい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山田',
    type: '町',
    kana: 'やまだ',
    typeKana: 'まち',
    compartmentCode: '110',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03483',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '下閉伊',
      type: '郡',
      kana: 'しもへい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '岩泉',
    type: '町',
    kana: 'いわいずみ',
    typeKana: 'ちょう',
    compartmentCode: '113',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03484',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '下閉伊',
      type: '郡',
      kana: 'しもへい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田野畑',
    type: '村',
    kana: 'たのはた',
    typeKana: 'むら',
    compartmentCode: '113',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03485',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '下閉伊',
      type: '郡',
      kana: 'しもへい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '普代',
    type: '村',
    kana: 'ふだい',
    typeKana: 'むら',
    compartmentCode: '113',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03501',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '九戸',
      type: '郡',
      kana: 'くのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '軽米',
    type: '町',
    kana: 'かるまい',
    typeKana: 'まち',
    compartmentCode: '115',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03503',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '九戸',
      type: '郡',
      kana: 'くのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '野田',
    type: '村',
    kana: 'のだ',
    typeKana: 'むら',
    compartmentCode: '112',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03506',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '九戸',
      type: '郡',
      kana: 'くのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '九戸',
    type: '村',
    kana: 'くのへ',
    typeKana: 'むら',
    compartmentCode: '115',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03507',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '九戸',
      type: '郡',
      kana: 'くのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '洋野',
    type: '町',
    kana: 'ひろの',
    typeKana: 'ちょう',
    compartmentCode: '112',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '03524',
    distinct: '1',
    pref: '岩手県',
    county: {
      name: '二戸',
      type: '郡',
      kana: 'にのへ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '一戸',
    type: '町',
    kana: 'いちのへ',
    typeKana: 'まち',
    compartmentCode: '115',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04100',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '仙台',
      type: '市',
      kana: 'せんだい',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04101',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '仙台',
      type: '市',
      kana: 'せんだい',
      typeKana: 'し',
      note: '',
    },
    name: '青葉',
    type: '区',
    kana: 'あおば',
    typeKana: 'く',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04102',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '仙台',
      type: '市',
      kana: 'せんだい',
      typeKana: 'し',
      note: '',
    },
    name: '宮城野',
    type: '区',
    kana: 'みやぎの',
    typeKana: 'く',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04103',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '仙台',
      type: '市',
      kana: 'せんだい',
      typeKana: 'し',
      note: '',
    },
    name: '若林',
    type: '区',
    kana: 'わかばやし',
    typeKana: 'く',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04104',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '仙台',
      type: '市',
      kana: 'せんだい',
      typeKana: 'し',
      note: '',
    },
    name: '太白',
    type: '区',
    kana: 'たいはく',
    typeKana: 'く',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04105',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '仙台',
      type: '市',
      kana: 'せんだい',
      typeKana: 'し',
      note: '',
    },
    name: '泉',
    type: '区',
    kana: 'いずみ',
    typeKana: 'く',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04202',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '石巻',
    type: '市',
    kana: 'いしのまき',
    typeKana: 'し',
    compartmentCode: '126',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04203',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '塩竈',
    type: '市',
    kana: 'しおがま',
    typeKana: 'し',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04205',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '気仙沼',
    type: '市',
    kana: 'けせんぬま',
    typeKana: 'し',
    compartmentCode: '127',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04206',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '白石',
    type: '市',
    kana: 'しろいし',
    typeKana: 'し',
    compartmentCode: '125',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04207',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '名取',
    type: '市',
    kana: 'なとり',
    typeKana: 'し',
    compartmentCode: '121',
    zone: {
      name: '堀内を除く。',
      scale: '',
    },
  },
  {
    code: '04207',
    distinct: '2',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '名取',
    type: '市',
    kana: 'なとり',
    typeKana: 'し',
    compartmentCode: '123',
    zone: {
      name: '堀内',
      scale: '',
    },
  },
  {
    code: '04208',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '角田',
    type: '市',
    kana: 'かくだ',
    typeKana: 'し',
    compartmentCode: '124',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04209',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '多賀城',
    type: '市',
    kana: 'たがじょう',
    typeKana: 'し',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04211',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岩沼',
    type: '市',
    kana: 'いわぬま',
    typeKana: 'し',
    compartmentCode: '123',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04212',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '登米',
    type: '市',
    kana: 'とめ',
    typeKana: 'し',
    compartmentCode: '122',
    zone: {
      name: '石越町、津山町及び豊里町を除く。',
      scale: '',
    },
  },
  {
    code: '04212',
    distinct: '2',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '登米',
    type: '市',
    kana: 'とめ',
    typeKana: 'し',
    compartmentCode: '126',
    zone: {
      name: '津山町及び豊里町に限る。',
      scale: '',
    },
  },
  {
    code: '04212',
    distinct: '3',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '登米',
    type: '市',
    kana: 'とめ',
    typeKana: 'し',
    compartmentCode: '128',
    zone: {
      name: '石越町',
      scale: '',
    },
  },
  {
    code: '04213',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '栗原',
    type: '市',
    kana: 'くりはら',
    typeKana: 'し',
    compartmentCode: '128',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04214',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東松島',
    type: '市',
    kana: 'ひがしまつしま',
    typeKana: 'し',
    compartmentCode: '126',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04215',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大崎',
    type: '市',
    kana: 'おおさき',
    typeKana: 'し',
    compartmentCode: '129',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04216',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富谷',
    type: '市',
    kana: 'とみや',
    typeKana: 'し',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04301',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '刈田',
      type: '郡',
      kana: 'かった',
      typeKana: 'ぐん',
      note: '',
    },
    name: '蔵王',
    type: '町',
    kana: 'ざおう',
    typeKana: 'まち',
    compartmentCode: '125',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04302',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '刈田',
      type: '郡',
      kana: 'かった',
      typeKana: 'ぐん',
      note: '',
    },
    name: '七ヶ宿',
    type: '町',
    kana: 'しちかしゅく',
    typeKana: 'まち',
    compartmentCode: '125',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04321',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '柴田',
      type: '郡',
      kana: 'しばた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大河原',
    type: '町',
    kana: 'おおがわら',
    typeKana: 'まち',
    compartmentCode: '124',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04322',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '柴田',
      type: '郡',
      kana: 'しばた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '村田',
    type: '町',
    kana: 'むらた',
    typeKana: 'まち',
    compartmentCode: '124',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04323',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '柴田',
      type: '郡',
      kana: 'しばた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '柴田',
    type: '町',
    kana: 'しばた',
    typeKana: 'まち',
    compartmentCode: '124',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04324',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '柴田',
      type: '郡',
      kana: 'しばた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川崎',
    type: '町',
    kana: 'かわさき',
    typeKana: 'まち',
    compartmentCode: '124',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04341',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '伊具',
      type: '郡',
      kana: 'いぐ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '丸森',
    type: '町',
    kana: 'まるもり',
    typeKana: 'まち',
    compartmentCode: '124',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04361',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '亘理',
      type: '郡',
      kana: 'わたり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '亘理',
    type: '町',
    kana: 'わたり',
    typeKana: 'ちょう',
    compartmentCode: '123',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04362',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '亘理',
      type: '郡',
      kana: 'わたり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山元',
    type: '町',
    kana: 'やまもと',
    typeKana: 'ちょう',
    compartmentCode: '123',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04401',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '宮城',
      type: '郡',
      kana: 'みやぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松島',
    type: '町',
    kana: 'まつしま',
    typeKana: 'まち',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04404',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '宮城',
      type: '郡',
      kana: 'みやぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '七ヶ浜',
    type: '町',
    kana: 'しちがはま',
    typeKana: 'まち',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04406',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '宮城',
      type: '郡',
      kana: 'みやぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '利府',
    type: '町',
    kana: 'りふ',
    typeKana: 'ちょう',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04421',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '黒川',
      type: '郡',
      kana: 'くろかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大和',
    type: '町',
    kana: 'たいわ',
    typeKana: 'ちょう',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04422',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '黒川',
      type: '郡',
      kana: 'くろかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大郷',
    type: '町',
    kana: 'おおさと',
    typeKana: 'ちょう',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04424',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '黒川',
      type: '郡',
      kana: 'くろかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大衡',
    type: '村',
    kana: 'おおひら',
    typeKana: 'むら',
    compartmentCode: '121',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04444',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '加美',
      type: '郡',
      kana: 'かみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '色麻',
    type: '町',
    kana: 'しかま',
    typeKana: 'ちょう',
    compartmentCode: '129',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04445',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '加美',
      type: '郡',
      kana: 'かみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '加美',
    type: '町',
    kana: 'かみ',
    typeKana: 'まち',
    compartmentCode: '129',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04501',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '遠田',
      type: '郡',
      kana: 'とおだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '涌谷',
    type: '町',
    kana: 'わくや',
    typeKana: 'ちょう',
    compartmentCode: '129',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04505',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '遠田',
      type: '郡',
      kana: 'とおだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美里',
    type: '町',
    kana: 'みさと',
    typeKana: 'まち',
    compartmentCode: '129',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04581',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '牡鹿',
      type: '郡',
      kana: 'おしか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '女川',
    type: '町',
    kana: 'おながわ',
    typeKana: 'ちょう',
    compartmentCode: '126',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '04606',
    distinct: '1',
    pref: '宮城県',
    county: {
      name: '本吉',
      type: '郡',
      kana: 'もとよし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南三陸',
    type: '町',
    kana: 'みなみさんりく',
    typeKana: 'ちょう',
    compartmentCode: '127',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05201',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '秋田',
    type: '市',
    kana: 'あきた',
    typeKana: 'し',
    compartmentCode: '107',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05202',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '能代',
    type: '市',
    kana: 'のしろ',
    typeKana: 'し',
    compartmentCode: '101',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05203',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '横手',
    type: '市',
    kana: 'よこて',
    typeKana: 'し',
    compartmentCode: '97',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05204',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大館',
    type: '市',
    kana: 'おおだて',
    typeKana: 'し',
    compartmentCode: '103',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05206',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '男鹿',
    type: '市',
    kana: 'おが',
    typeKana: 'し',
    compartmentCode: '100',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05207',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '湯沢',
    type: '市',
    kana: 'ゆざわ',
    typeKana: 'し',
    compartmentCode: '98',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05209',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鹿角',
    type: '市',
    kana: 'かづの',
    typeKana: 'し',
    compartmentCode: '102',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05210',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '由利本荘',
    type: '市',
    kana: 'ゆりほんじょう',
    typeKana: 'し',
    compartmentCode: '99',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05211',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '潟上',
    type: '市',
    kana: 'かたがみ',
    typeKana: 'し',
    compartmentCode: '107',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05212',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大仙',
    type: '市',
    kana: 'だいせん',
    typeKana: 'し',
    compartmentCode: '105',
    zone: {
      name: '大神成、上鶯野、北長野、協和荒川、協和稲沢、協和上淀川、協和小種、協和境、協和下淀川、協和中淀川、協和船岡、協和船沢、協和峰吉川、栗沢、清水、下鶯野、豊岡、豊川、長戸呂、長野及び鑓見内を除く。',
      scale: '',
    },
  },
  {
    code: '05212',
    distinct: '2',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大仙',
    type: '市',
    kana: 'だいせん',
    typeKana: 'し',
    compartmentCode: '106',
    zone: {
      name: '大神成、上鶯野、北長野、栗沢、清水、下鶯野、豊岡、豊川、長戸呂、長野及び鑓見内に限る。',
      scale: '',
    },
  },
  {
    code: '05212',
    distinct: '3',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大仙',
    type: '市',
    kana: 'だいせん',
    typeKana: 'し',
    compartmentCode: '107',
    zone: {
      name: '協和荒川、協和稲沢、協和上淀川、協和小種、協和境、協和下淀川、協和中淀川、協和船岡、協和船沢及び協和峰吉川に限る。',
      scale: '',
    },
  },
  {
    code: '05213',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北秋田',
    type: '市',
    kana: 'きたあきた',
    typeKana: 'し',
    compartmentCode: '104',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05214',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'にかほ',
    type: '市',
    kana: 'にかほ',
    typeKana: 'し',
    compartmentCode: '99',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05215',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '仙北',
    type: '市',
    kana: 'せんぼく',
    typeKana: 'し',
    compartmentCode: '106',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05303',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '鹿角',
      type: '郡',
      kana: 'かづの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小坂',
    type: '町',
    kana: 'こさか',
    typeKana: 'まち',
    compartmentCode: '102',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05327',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '北秋田',
      type: '郡',
      kana: 'きたあきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上小阿仁',
    type: '村',
    kana: 'かみこあに',
    typeKana: 'むら',
    compartmentCode: '104',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05346',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '山本',
      type: '郡',
      kana: 'やまもと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '藤里',
    type: '町',
    kana: 'ふじさと',
    typeKana: 'まち',
    compartmentCode: '101',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05348',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '山本',
      type: '郡',
      kana: 'やまもと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三種',
    type: '町',
    kana: 'みたね',
    typeKana: 'ちょう',
    compartmentCode: '101',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05349',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '山本',
      type: '郡',
      kana: 'やまもと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八峰',
    type: '町',
    kana: 'はっぽう',
    typeKana: 'ちょう',
    compartmentCode: '101',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05361',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '南秋田',
      type: '郡',
      kana: 'みなみあきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '五城目',
    type: '町',
    kana: 'ごじょうめ',
    typeKana: 'まち',
    compartmentCode: '107',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05363',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '南秋田',
      type: '郡',
      kana: 'みなみあきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八郎潟',
    type: '町',
    kana: 'はちろうがた',
    typeKana: 'まち',
    compartmentCode: '107',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05366',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '南秋田',
      type: '郡',
      kana: 'みなみあきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '井川',
    type: '町',
    kana: 'いかわ',
    typeKana: 'まち',
    compartmentCode: '107',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05368',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '南秋田',
      type: '郡',
      kana: 'みなみあきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大潟',
    type: '村',
    kana: 'おおがた',
    typeKana: 'むら',
    compartmentCode: '100',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05434',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '仙北',
      type: '郡',
      kana: 'せんぼく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美郷',
    type: '町',
    kana: 'みさと',
    typeKana: 'ちょう',
    compartmentCode: '97',
    zone: {
      name: '金沢及び野荒町に限る。',
      scale: '',
    },
  },
  {
    code: '05434',
    distinct: '2',
    pref: '秋田県',
    county: {
      name: '仙北',
      type: '郡',
      kana: 'せんぼく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美郷',
    type: '町',
    kana: 'みさと',
    typeKana: 'ちょう',
    compartmentCode: '105',
    zone: {
      name: '金沢及び野荒町を除く。',
      scale: '',
    },
  },
  {
    code: '05463',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '雄勝',
      type: '郡',
      kana: 'おがち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '羽後',
    type: '町',
    kana: 'うご',
    typeKana: 'まち',
    compartmentCode: '98',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '05464',
    distinct: '1',
    pref: '秋田県',
    county: {
      name: '雄勝',
      type: '郡',
      kana: 'おがち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東成瀬',
    type: '村',
    kana: 'ひがしなるせ',
    typeKana: 'むら',
    compartmentCode: '97',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06201',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山形',
    type: '市',
    kana: 'やまがた',
    typeKana: 'し',
    compartmentCode: '133',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06202',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '米沢',
    type: '市',
    kana: 'よねざわ',
    typeKana: 'し',
    compartmentCode: '136',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06203',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鶴岡',
    type: '市',
    kana: 'つるおか',
    typeKana: 'し',
    compartmentCode: '132',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06204',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '酒田',
    type: '市',
    kana: 'さかた',
    typeKana: 'し',
    compartmentCode: '131',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06205',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新庄',
    type: '市',
    kana: 'しんじょう',
    typeKana: 'し',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06206',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '寒河江',
    type: '市',
    kana: 'さがえ',
    typeKana: 'し',
    compartmentCode: '134',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06207',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上山',
    type: '市',
    kana: 'かみのやま',
    typeKana: 'し',
    compartmentCode: '133',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06208',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '村山',
    type: '市',
    kana: 'むらやま',
    typeKana: 'し',
    compartmentCode: '135',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06209',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長井',
    type: '市',
    kana: 'ながい',
    typeKana: 'し',
    compartmentCode: '137',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06210',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '天童',
    type: '市',
    kana: 'てんどう',
    typeKana: 'し',
    compartmentCode: '133',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06211',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東根',
    type: '市',
    kana: 'ひがしね',
    typeKana: 'し',
    compartmentCode: '135',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06212',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '尾花沢',
    type: '市',
    kana: 'おばなざわ',
    typeKana: 'し',
    compartmentCode: '135',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06213',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南陽',
    type: '市',
    kana: 'なんよう',
    typeKana: 'し',
    compartmentCode: '136',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06301',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '東村山',
      type: '郡',
      kana: 'ひがしむらやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山辺',
    type: '町',
    kana: 'やまのべ',
    typeKana: 'まち',
    compartmentCode: '133',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06302',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '東村山',
      type: '郡',
      kana: 'ひがしむらやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中山',
    type: '町',
    kana: 'なかやま',
    typeKana: 'まち',
    compartmentCode: '133',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06321',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '西村山',
      type: '郡',
      kana: 'にしむらやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '河北',
    type: '町',
    kana: 'かほく',
    typeKana: 'ちょう',
    compartmentCode: '134',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06322',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '西村山',
      type: '郡',
      kana: 'にしむらやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西川',
    type: '町',
    kana: 'にしかわ',
    typeKana: 'まち',
    compartmentCode: '134',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06323',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '西村山',
      type: '郡',
      kana: 'にしむらやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '朝日',
    type: '町',
    kana: 'あさひ',
    typeKana: 'まち',
    compartmentCode: '134',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06324',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '西村山',
      type: '郡',
      kana: 'にしむらやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大江',
    type: '町',
    kana: 'おおえ',
    typeKana: 'まち',
    compartmentCode: '134',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06341',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '北村山',
      type: '郡',
      kana: 'きたむらやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大石田',
    type: '町',
    kana: 'おおいしだ',
    typeKana: 'まち',
    compartmentCode: '135',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06361',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '最上',
      type: '郡',
      kana: 'もがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '金山',
    type: '町',
    kana: 'かねやま',
    typeKana: 'まち',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06362',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '最上',
      type: '郡',
      kana: 'もがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '最上',
    type: '町',
    kana: 'もがみ',
    typeKana: 'まち',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06363',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '最上',
      type: '郡',
      kana: 'もがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '舟形',
    type: '町',
    kana: 'ふながた',
    typeKana: 'まち',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06364',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '最上',
      type: '郡',
      kana: 'もがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '真室川',
    type: '町',
    kana: 'まむろがわ',
    typeKana: 'まち',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06365',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '最上',
      type: '郡',
      kana: 'もがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大蔵',
    type: '村',
    kana: 'おおくら',
    typeKana: 'むら',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06366',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '最上',
      type: '郡',
      kana: 'もがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鮭川',
    type: '村',
    kana: 'さけがわ',
    typeKana: 'むら',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06367',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '最上',
      type: '郡',
      kana: 'もがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '戸沢',
    type: '村',
    kana: 'とざわ',
    typeKana: 'むら',
    compartmentCode: '130',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06381',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '東置賜',
      type: '郡',
      kana: 'ひがしおきたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高畠',
    type: '町',
    kana: 'たかはた',
    typeKana: 'まち',
    compartmentCode: '136',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06382',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '東置賜',
      type: '郡',
      kana: 'ひがしおきたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川西',
    type: '町',
    kana: 'かわにし',
    typeKana: 'まち',
    compartmentCode: '136',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06401',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '西置賜',
      type: '郡',
      kana: 'にしおきたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小国',
    type: '町',
    kana: 'おぐに',
    typeKana: 'まち',
    compartmentCode: '137',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06402',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '西置賜',
      type: '郡',
      kana: 'にしおきたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白鷹',
    type: '町',
    kana: 'しらたか',
    typeKana: 'まち',
    compartmentCode: '137',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06403',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '西置賜',
      type: '郡',
      kana: 'にしおきたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '飯豊',
    type: '町',
    kana: 'いいで',
    typeKana: 'まち',
    compartmentCode: '137',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06426',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '東田川',
      type: '郡',
      kana: 'ひがしたがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三川',
    type: '町',
    kana: 'みかわ',
    typeKana: 'まち',
    compartmentCode: '132',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06428',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '東田川',
      type: '郡',
      kana: 'ひがしたがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '庄内',
    type: '町',
    kana: 'しょうない',
    typeKana: 'まち',
    compartmentCode: '131',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '06461',
    distinct: '1',
    pref: '山形県',
    county: {
      name: '飽海',
      type: '郡',
      kana: 'あくみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '遊佐',
    type: '町',
    kana: 'ゆざ',
    typeKana: 'まち',
    compartmentCode: '131',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07201',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福島',
    type: '市',
    kana: 'ふくしま',
    typeKana: 'し',
    compartmentCode: '146',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07202',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '会津若松',
    type: '市',
    kana: 'あいづわかまつ',
    typeKana: 'し',
    compartmentCode: '143',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07203',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '郡山',
    type: '市',
    kana: 'こおりやま',
    typeKana: 'し',
    compartmentCode: '152',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07204',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'いわき',
    type: '市',
    kana: 'いわき',
    typeKana: 'し',
    compartmentCode: '147',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07205',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '白河',
    type: '市',
    kana: 'しらかわ',
    typeKana: 'し',
    compartmentCode: '151',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07207',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '須賀川',
    type: '市',
    kana: 'すかがわ',
    typeKana: 'し',
    compartmentCode: '150',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07208',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '喜多方',
    type: '市',
    kana: 'きたかた',
    typeKana: 'し',
    compartmentCode: '139',
    zone: {
      name: '高郷町を除く。',
      scale: '',
    },
  },
  {
    code: '07208',
    distinct: '2',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '喜多方',
    type: '市',
    kana: 'きたかた',
    typeKana: 'し',
    compartmentCode: '142',
    zone: {
      name: '高郷町',
      scale: '',
    },
  },
  {
    code: '07209',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '相馬',
    type: '市',
    kana: 'そうま',
    typeKana: 'し',
    compartmentCode: '145',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07210',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '二本松',
    type: '市',
    kana: 'にほんまつ',
    typeKana: 'し',
    compartmentCode: '144',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07211',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '田村',
    type: '市',
    kana: 'たむら',
    typeKana: 'し',
    compartmentCode: '149',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07212',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南相馬',
    type: '市',
    kana: 'みなみそうま',
    typeKana: 'し',
    compartmentCode: '145',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07213',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊達',
    type: '市',
    kana: 'だて',
    typeKana: 'し',
    compartmentCode: '146',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07214',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '本宮',
    type: '市',
    kana: 'もとみや',
    typeKana: 'し',
    compartmentCode: '144',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07301',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '伊達',
      type: '郡',
      kana: 'だて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '桑折',
    type: '町',
    kana: 'こおり',
    typeKana: 'まち',
    compartmentCode: '146',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07303',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '伊達',
      type: '郡',
      kana: 'だて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '国見',
    type: '町',
    kana: 'くにみ',
    typeKana: 'まち',
    compartmentCode: '146',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07308',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '伊達',
      type: '郡',
      kana: 'だて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川俣',
    type: '町',
    kana: 'かわまた',
    typeKana: 'まち',
    compartmentCode: '146',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07322',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '安達',
      type: '郡',
      kana: 'あだち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大玉',
    type: '村',
    kana: 'おおたま',
    typeKana: 'むら',
    compartmentCode: '144',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07342',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '岩瀬',
      type: '郡',
      kana: 'いわせ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鏡石',
    type: '町',
    kana: 'かがみいし',
    typeKana: 'まち',
    compartmentCode: '150',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07344',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '岩瀬',
      type: '郡',
      kana: 'いわせ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '天栄',
    type: '村',
    kana: 'てんえい',
    typeKana: 'むら',
    compartmentCode: '150',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07362',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '南会津',
      type: '郡',
      kana: 'みなみあいづ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '下郷',
    type: '町',
    kana: 'しもごう',
    typeKana: 'まち',
    compartmentCode: '140',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07364',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '南会津',
      type: '郡',
      kana: 'みなみあいづ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '檜枝岐',
    type: '村',
    kana: 'ひのえまた',
    typeKana: 'むら',
    compartmentCode: '141',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07367',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '南会津',
      type: '郡',
      kana: 'みなみあいづ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '只見',
    type: '町',
    kana: 'ただみ',
    typeKana: 'まち',
    compartmentCode: '141',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07368',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '南会津',
      type: '郡',
      kana: 'みなみあいづ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南会津',
    type: '町',
    kana: 'みなみあいづ',
    typeKana: 'まち',
    compartmentCode: '140',
    zone: {
      name: '糸沢、金井沢、川島、栗生沢、高野、塩江、静川、関本、滝原、田島、田部、丹藤、藤生、中荒井、永田、長野、針生、福米沢及び水無に限る。',
      scale: '',
    },
  },
  {
    code: '07368',
    distinct: '2',
    pref: '福島県',
    county: {
      name: '南会津',
      type: '郡',
      kana: 'みなみあいづ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南会津',
    type: '町',
    kana: 'みなみあいづ',
    typeKana: 'まち',
    compartmentCode: '141',
    zone: {
      name: '糸沢、金井沢、川島、栗生沢、高野、塩江、静川、関本、滝原、田島、田部、丹藤、藤生、中荒井、永田、長野、針生、福米沢及び水無を除く。',
      scale: '',
    },
  },
  {
    code: '07402',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '耶麻',
      type: '郡',
      kana: 'やま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北塩原',
    type: '村',
    kana: 'きたしおばら',
    typeKana: 'むら',
    compartmentCode: '139',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07405',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '耶麻',
      type: '郡',
      kana: 'やま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西会津',
    type: '町',
    kana: 'にしあいづ',
    typeKana: 'まち',
    compartmentCode: '142',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07407',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '耶麻',
      type: '郡',
      kana: 'やま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '磐梯',
    type: '町',
    kana: 'ばんだい',
    typeKana: 'まち',
    compartmentCode: '143',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07408',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '耶麻',
      type: '郡',
      kana: 'やま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '猪苗代',
    type: '町',
    kana: 'いなわしろ',
    typeKana: 'まち',
    compartmentCode: '143',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07421',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '河沼',
      type: '郡',
      kana: 'かわぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '会津坂下',
    type: '町',
    kana: 'あいづばんげ',
    typeKana: 'まち',
    compartmentCode: '143',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07422',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '河沼',
      type: '郡',
      kana: 'かわぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湯川',
    type: '村',
    kana: 'ゆがわ',
    typeKana: 'むら',
    compartmentCode: '139',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07423',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '河沼',
      type: '郡',
      kana: 'かわぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '柳津',
    type: '町',
    kana: 'やないづ',
    typeKana: 'まち',
    compartmentCode: '142',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07444',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '大沼',
      type: '郡',
      kana: 'おおぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三島',
    type: '町',
    kana: 'みしま',
    typeKana: 'まち',
    compartmentCode: '142',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07445',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '大沼',
      type: '郡',
      kana: 'おおぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '金山',
    type: '町',
    kana: 'かねやま',
    typeKana: 'まち',
    compartmentCode: '142',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07446',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '大沼',
      type: '郡',
      kana: 'おおぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '昭和',
    type: '村',
    kana: 'しょうわ',
    typeKana: 'むら',
    compartmentCode: '142',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07447',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '大沼',
      type: '郡',
      kana: 'おおぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '会津美里',
    type: '町',
    kana: 'あいづみさと',
    typeKana: 'まち',
    compartmentCode: '143',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07461',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '西白河',
      type: '郡',
      kana: 'にししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西郷',
    type: '村',
    kana: 'にしごう',
    typeKana: 'むら',
    compartmentCode: '151',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07464',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '西白河',
      type: '郡',
      kana: 'にししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '泉崎',
    type: '村',
    kana: 'いずみざき',
    typeKana: 'むら',
    compartmentCode: '151',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07465',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '西白河',
      type: '郡',
      kana: 'にししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中島',
    type: '村',
    kana: 'なかじま',
    typeKana: 'むら',
    compartmentCode: '151',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07466',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '西白河',
      type: '郡',
      kana: 'にししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '矢吹',
    type: '町',
    kana: 'やぶき',
    typeKana: 'まち',
    compartmentCode: '151',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07481',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '東白川',
      type: '郡',
      kana: 'ひがししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '棚倉',
    type: '町',
    kana: 'たなぐら',
    typeKana: 'まち',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07482',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '東白川',
      type: '郡',
      kana: 'ひがししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '矢祭',
    type: '町',
    kana: 'やまつり',
    typeKana: 'まち',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07483',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '東白川',
      type: '郡',
      kana: 'ひがししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '塙',
    type: '町',
    kana: 'はなわ',
    typeKana: 'まち',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07484',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '東白川',
      type: '郡',
      kana: 'ひがししらかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鮫川',
    type: '村',
    kana: 'さめがわ',
    typeKana: 'むら',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07501',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '石川',
      type: '郡',
      kana: 'いしかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '石川',
    type: '町',
    kana: 'いしかわ',
    typeKana: 'まち',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07502',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '石川',
      type: '郡',
      kana: 'いしかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '玉川',
    type: '村',
    kana: 'たまかわ',
    typeKana: 'むら',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07503',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '石川',
      type: '郡',
      kana: 'いしかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '平田',
    type: '村',
    kana: 'ひらた',
    typeKana: 'むら',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07504',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '石川',
      type: '郡',
      kana: 'いしかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '浅川',
    type: '町',
    kana: 'あさかわ',
    typeKana: 'まち',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07505',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '石川',
      type: '郡',
      kana: 'いしかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '古殿',
    type: '町',
    kana: 'ふるどの',
    typeKana: 'まち',
    compartmentCode: '148',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07521',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '田村',
      type: '郡',
      kana: 'たむら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三春',
    type: '町',
    kana: 'みはる',
    typeKana: 'まち',
    compartmentCode: '149',
    zone: {
      name: '上舞木、斎藤、下舞木及び沼沢を除く。',
      scale: '',
    },
  },
  {
    code: '07521',
    distinct: '2',
    pref: '福島県',
    county: {
      name: '田村',
      type: '郡',
      kana: 'たむら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三春',
    type: '町',
    kana: 'みはる',
    typeKana: 'まち',
    compartmentCode: '152',
    zone: {
      name: '上舞木、斎藤、下舞木及び沼沢に限る。',
      scale: '',
    },
  },
  {
    code: '07522',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '田村',
      type: '郡',
      kana: 'たむら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小野',
    type: '町',
    kana: 'おの',
    typeKana: 'まち',
    compartmentCode: '149',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07541',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '広野',
    type: '町',
    kana: 'ひろの',
    typeKana: 'まち',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07542',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '楢葉',
    type: '町',
    kana: 'ならは',
    typeKana: 'まち',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07543',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '富岡',
    type: '町',
    kana: 'とみおか',
    typeKana: 'まち',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07544',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川内',
    type: '村',
    kana: 'かわうち',
    typeKana: 'むら',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07545',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大熊',
    type: '町',
    kana: 'おおくま',
    typeKana: 'まち',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07546',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '双葉',
    type: '町',
    kana: 'ふたば',
    typeKana: 'まち',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07547',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '浪江',
    type: '町',
    kana: 'なみえ',
    typeKana: 'まち',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07548',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '双葉',
      type: '郡',
      kana: 'ふたば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '葛尾',
    type: '村',
    kana: 'かつらお',
    typeKana: 'むら',
    compartmentCode: '138',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07561',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '相馬',
      type: '郡',
      kana: 'そうま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新地',
    type: '町',
    kana: 'しんち',
    typeKana: 'まち',
    compartmentCode: '145',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '07564',
    distinct: '1',
    pref: '福島県',
    county: {
      name: '相馬',
      type: '郡',
      kana: 'そうま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '飯舘',
    type: '村',
    kana: 'いいたて',
    typeKana: 'むら',
    compartmentCode: '145',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08201',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '水戸',
    type: '市',
    kana: 'みと',
    typeKana: 'し',
    compartmentCode: '207',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08202',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日立',
    type: '市',
    kana: 'ひたち',
    typeKana: 'し',
    compartmentCode: '209',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08203',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '土浦',
    type: '市',
    kana: 'つちうら',
    typeKana: 'し',
    compartmentCode: '216',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08204',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '古河',
    type: '市',
    kana: 'こが',
    typeKana: 'し',
    compartmentCode: '194',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08205',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '石岡',
    type: '市',
    kana: 'いしおか',
    typeKana: 'し',
    compartmentCode: '217',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08207',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '結城',
    type: '市',
    kana: 'ゆうき',
    typeKana: 'し',
    compartmentCode: '213',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08208',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '龍ケ崎',
    type: '市',
    kana: 'りゅうがさき',
    typeKana: 'し',
    compartmentCode: '214',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08210',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '下妻',
    type: '市',
    kana: 'しもつま',
    typeKana: 'し',
    compartmentCode: '213',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08211',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '常総',
    type: '市',
    kana: 'じょうそう',
    typeKana: 'し',
    compartmentCode: '215',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08212',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '常陸太田',
    type: '市',
    kana: 'ひたちおおた',
    typeKana: 'し',
    compartmentCode: '209',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08214',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高萩',
    type: '市',
    kana: 'たかはぎ',
    typeKana: 'し',
    compartmentCode: '208',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08215',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北茨城',
    type: '市',
    kana: 'きたいばらき',
    typeKana: 'し',
    compartmentCode: '208',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08216',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '笠間',
    type: '市',
    kana: 'かさま',
    typeKana: 'し',
    compartmentCode: '212',
    zone: {
      name: '安居、泉、泉市野谷入会地、市野谷、押辺、上郷、下郷、土師、福島及び吉岡を除く。',
      scale: '',
    },
  },
  {
    code: '08216',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '笠間',
    type: '市',
    kana: 'かさま',
    typeKana: 'し',
    compartmentCode: '217',
    zone: {
      name: '安居、泉、泉市野谷入会地、市野谷、押辺、上郷、下郷、土師、福島及び吉岡に限る。',
      scale: '',
    },
  },
  {
    code: '08217',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '取手',
    type: '市',
    kana: 'とりで',
    typeKana: 'し',
    compartmentCode: '214',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08219',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '牛久',
    type: '市',
    kana: 'うしく',
    typeKana: 'し',
    compartmentCode: '216',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08220',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'つくば',
    type: '市',
    kana: 'つくば',
    typeKana: 'し',
    compartmentCode: '216',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08221',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'ひたちなか',
    type: '市',
    kana: 'ひたちなか',
    typeKana: 'し',
    compartmentCode: '207',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08222',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鹿嶋',
    type: '市',
    kana: 'かしま',
    typeKana: 'し',
    compartmentCode: '218',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08223',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '潮来',
    type: '市',
    kana: 'いたこ',
    typeKana: 'し',
    compartmentCode: '218',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08224',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '守谷',
    type: '市',
    kana: 'もりや',
    typeKana: 'し',
    compartmentCode: '215',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08225',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '常陸大宮',
    type: '市',
    kana: 'ひたちおおみや',
    typeKana: 'し',
    compartmentCode: '210-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08226',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '那珂',
    type: '市',
    kana: 'なか',
    typeKana: 'し',
    compartmentCode: '207',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08227',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '筑西',
    type: '市',
    kana: 'ちくせい',
    typeKana: 'し',
    compartmentCode: '213',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08228',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '坂東',
    type: '市',
    kana: 'ばんどう',
    typeKana: 'し',
    compartmentCode: '194',
    zone: {
      name: '生子、生子新田、逆井、菅谷及び山に限る。',
      scale: '',
    },
  },
  {
    code: '08228',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '坂東',
    type: '市',
    kana: 'ばんどう',
    typeKana: 'し',
    compartmentCode: '215',
    zone: {
      name: '生子、生子新田、逆井、菅谷及び山を除く。',
      scale: '',
    },
  },
  {
    code: '08229',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稲敷',
    type: '市',
    kana: 'いなしき',
    typeKana: 'し',
    compartmentCode: '214',
    zone: {
      name: '伊崎、伊佐津、戌渡、太田、小野、上根本、柴崎、下太田、下根本、角崎、寺内、中山、羽賀浦、堀川、狸穴及び南太田に限る。',
      scale: '',
    },
  },
  {
    code: '08229',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稲敷',
    type: '市',
    kana: 'いなしき',
    typeKana: 'し',
    compartmentCode: '216',
    zone: {
      name: '阿波崎、阿波崎新田、飯島、伊崎、伊佐津、伊佐部、市崎、戌渡、今、大島、太田、押砂、小野、釜井、上須田、上根本、上之島、結佐、神崎新宿、神崎本宿、幸田、石納、境島、佐原、佐原組新田、佐原下手、柴崎、清水、下太田、下須田、下須田新田、下根本、新橋、角崎、清久島、手賀組新田、寺内、中島、中山、西代、野間谷原、羽賀浦、橋向、八千石、東大沼、福田、堀川、曲渕、町田、狸穴、三島、光葉、南太田、本新、八筋川、余津谷、四ッ谷、六角及び脇川を除く。',
      scale: '',
    },
  },
  {
    code: '08229',
    distinct: '3',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稲敷',
    type: '市',
    kana: 'いなしき',
    typeKana: 'し',
    compartmentCode: '218',
    zone: {
      name: '阿波崎、阿波崎新田、飯島、伊佐部、市崎、今、大島、押砂、釜井、上須田、上之島、結佐、神崎神宿、神崎本宿、幸田、石納、境島、佐原、佐原下手、佐原組新田、清水、下須田、下須田新田、新橋、清久島、手賀組新田、中島、西代、野間谷原、橋向、八千石、東大沼、福田、曲渕、町田、三島、光葉、本新、八筋川、余津谷、四ッ谷、六角及び脇川に限る。',
      scale: '',
    },
  },
  {
    code: '08230',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'かすみがうら',
    type: '市',
    kana: 'かすみがうら',
    typeKana: 'し',
    compartmentCode: '216',
    zone: {
      name: '有河、安食、一の瀬、一の瀬上流、岩坪、牛渡、大和田、男神、柏崎、上大堤、上軽部、加茂、坂、宍倉、志戸崎、下大堤、下軽部、田伏、戸崎、中台、西成井、深谷、三ツ木及び南根本に限る。',
      scale: '',
    },
  },
  {
    code: '08230',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'かすみがうら',
    type: '市',
    kana: 'かすみがうら',
    typeKana: 'し',
    compartmentCode: '217',
    zone: {
      name: '有河、安食、一の瀬、一の瀬上流、岩坪、牛渡、大和田、男神、柏崎、上大堤、上軽部、加茂、坂、宍倉、志戸崎、下大堤、下軽部、田伏、戸崎、中台、西成井、深谷、三ツ木及び南根本を除く。',
      scale: '',
    },
  },
  {
    code: '08231',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '桜川',
    type: '市',
    kana: 'さくらがわ',
    typeKana: 'し',
    compartmentCode: '212',
    zone: {
      name: '青木、阿部田、大国玉、大曽根、金敷、高久、高森、羽田、東飯田、真壁町及び本木を除く。',
      scale: '',
    },
  },
  {
    code: '08231',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '桜川',
    type: '市',
    kana: 'さくらがわ',
    typeKana: 'し',
    compartmentCode: '213',
    zone: {
      name: '青木、阿部田、大国玉、大曽根、金敷、高久、高森、羽田、東飯田、真壁町及び本木に限る。',
      scale: '',
    },
  },
  {
    code: '08232',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '神栖',
    type: '市',
    kana: 'かみす',
    typeKana: 'し',
    compartmentCode: '218',
    zone: {
      name: '太田、太田新町、須田、砂山、土合北、土合中央、土合西、土合東、土合本町、土合南、波崎、波崎新港、矢田部、柳川、柳川中央及び若松中央を除く。',
      scale: '',
    },
  },
  {
    code: '08232',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '神栖',
    type: '市',
    kana: 'かみす',
    typeKana: 'し',
    compartmentCode: '252',
    zone: {
      name: '太田、太田新町、須田、砂山、土合北、土合中央、土合西、土合東、土合本町、土合南、波崎、波崎新港、矢田部、柳川、柳川中央及び若松中央に限る。',
      scale: '',
    },
  },
  {
    code: '08233',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '行方',
    type: '市',
    kana: 'なめがた',
    typeKana: 'し',
    compartmentCode: '206',
    zone: {
      name: '内宿、小貫、小幡、北高岡、中根、長野江、次木、成田、繁昌、南高岡、三和、山田、行戸、吉川及び両宿に限る。',
      scale: '',
    },
  },
  {
    code: '08233',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '行方',
    type: '市',
    kana: 'なめがた',
    typeKana: 'し',
    compartmentCode: '217',
    zone: {
      name: '荒宿、井上、井上藤井、沖洲、西蓮寺、芹沢、玉造乙、玉造甲、手賀、捻木、羽生、浜、藤井、八木蒔、谷島及び若海に限る。',
      scale: '',
    },
  },
  {
    code: '08233',
    distinct: '3',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '行方',
    type: '市',
    kana: 'なめがた',
    typeKana: 'し',
    compartmentCode: '218',
    zone: {
      name: '荒宿、井上、井上藤井、内宿、沖洲、小貫、小幡、北高岡、西蓮寺、芹沢、玉造乙、玉造甲、手賀、中根、長野江、次木、成田、捻木、羽生、浜、繁昌、藤井、南高岡、三和、八木蒔、谷島、山田、行戸、吉川、両宿及び若海を除く。',
      scale: '',
    },
  },
  {
    code: '08234',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鉾田',
    type: '市',
    kana: 'ほこた',
    typeKana: 'し',
    compartmentCode: '206',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08235',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'つくばみらい',
    type: '市',
    kana: 'つくばみらい',
    typeKana: 'し',
    compartmentCode: '215',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08236',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小美玉',
    type: '市',
    kana: 'おみたま',
    typeKana: 'し',
    compartmentCode: '217',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08302',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '東茨城',
      type: '郡',
      kana: 'ひがしいばらき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '茨城',
    type: '町',
    kana: 'いばらき',
    typeKana: 'まち',
    compartmentCode: '207',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08309',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '東茨城',
      type: '郡',
      kana: 'ひがしいばらき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大洗',
    type: '町',
    kana: 'おおあらい',
    typeKana: 'まち',
    compartmentCode: '207',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08310',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '東茨城',
      type: '郡',
      kana: 'ひがしいばらき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '城里',
    type: '町',
    kana: 'しろさと',
    typeKana: 'まち',
    compartmentCode: '207',
    zone: {
      name: '大網、小勝、上赤沢、真端、塩子、下赤沢及び徳蔵を除く。',
      scale: '',
    },
  },
  {
    code: '08310',
    distinct: '2',
    pref: '茨城県',
    county: {
      name: '東茨城',
      type: '郡',
      kana: 'ひがしいばらき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '城里',
    type: '町',
    kana: 'しろさと',
    typeKana: 'まち',
    compartmentCode: '212',
    zone: {
      name: '大網、小勝、上赤沢、真端、塩子、下赤沢及び徳蔵に限る。',
      scale: '',
    },
  },
  {
    code: '08341',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '那珂',
      type: '郡',
      kana: 'なか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東海',
    type: '村',
    kana: 'とうかい',
    typeKana: 'むら',
    compartmentCode: '207',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08364',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '久慈',
      type: '郡',
      kana: 'くじ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大子',
    type: '町',
    kana: 'だいご',
    typeKana: 'まち',
    compartmentCode: '211',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08442',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '稲敷',
      type: '郡',
      kana: 'いなしき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美浦',
    type: '村',
    kana: 'みほ',
    typeKana: 'むら',
    compartmentCode: '216',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08443',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '稲敷',
      type: '郡',
      kana: 'いなしき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '阿見',
    type: '町',
    kana: 'あみ',
    typeKana: 'まち',
    compartmentCode: '216',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08447',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '稲敷',
      type: '郡',
      kana: 'いなしき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '河内',
    type: '町',
    kana: 'かわち',
    typeKana: 'まち',
    compartmentCode: '214',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08521',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '結城',
      type: '郡',
      kana: 'ゆうき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八千代',
    type: '町',
    kana: 'やちよ',
    typeKana: 'まち',
    compartmentCode: '213',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08542',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '猿島',
      type: '郡',
      kana: 'さしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '五霞',
    type: '町',
    kana: 'ごか',
    typeKana: 'まち',
    compartmentCode: '194',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08546',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '猿島',
      type: '郡',
      kana: 'さしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '境',
    type: '町',
    kana: 'さかい',
    typeKana: 'まち',
    compartmentCode: '194',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '08564',
    distinct: '1',
    pref: '茨城県',
    county: {
      name: '北相馬',
      type: '郡',
      kana: 'きたそうま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '利根',
    type: '町',
    kana: 'とね',
    typeKana: 'まち',
    compartmentCode: '214',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09201',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇都宮',
    type: '市',
    kana: 'うつのみや',
    typeKana: 'し',
    compartmentCode: '200',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09202',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '足利',
    type: '市',
    kana: 'あしかが',
    typeKana: 'し',
    compartmentCode: '197',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09203',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '栃木',
    type: '市',
    kana: 'とちぎ',
    typeKana: 'し',
    compartmentCode: '195',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09204',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐野',
    type: '市',
    kana: 'さの',
    typeKana: 'し',
    compartmentCode: '196',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09205',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鹿沼',
    type: '市',
    kana: 'かぬま',
    typeKana: 'し',
    compartmentCode: '205',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09206',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日光',
    type: '市',
    kana: 'にっこう',
    typeKana: 'し',
    compartmentCode: '204',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09208',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小山',
    type: '市',
    kana: 'おやま',
    typeKana: 'し',
    compartmentCode: '194',
    zone: {
      name: '下生井',
      scale: '',
    },
  },
  {
    code: '09208',
    distinct: '2',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小山',
    type: '市',
    kana: 'おやま',
    typeKana: 'し',
    compartmentCode: '195',
    zone: {
      name: '上生井及び白鳥に限る。',
      scale: '',
    },
  },
  {
    code: '09208',
    distinct: '3',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小山',
    type: '市',
    kana: 'おやま',
    typeKana: 'し',
    compartmentCode: '199',
    zone: {
      name: '上生井、中河原、中島、下生井、白鳥、福良及び梁を除く。',
      scale: '',
    },
  },
  {
    code: '09208',
    distinct: '4',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小山',
    type: '市',
    kana: 'おやま',
    typeKana: 'し',
    compartmentCode: '213',
    zone: {
      name: '中河原、中島、福良及び梁に限る。',
      scale: '',
    },
  },
  {
    code: '09209',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '真岡',
    type: '市',
    kana: 'もおか',
    typeKana: 'し',
    compartmentCode: '198',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09210',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大田原',
    type: '市',
    kana: 'おおたわら',
    typeKana: 'し',
    compartmentCode: '202',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09211',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '矢板',
    type: '市',
    kana: 'やいた',
    typeKana: 'し',
    compartmentCode: '202',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09213',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '那須塩原',
    type: '市',
    kana: 'なすしおばら',
    typeKana: 'し',
    compartmentCode: '201',
    zone: {
      name: '東町、あたご町、井口、石林、一区町、宇都野、扇町、遅野沢、折戸、金沢、上赤田、上大貫、上塩原、上横林、北赤田、北二つ室、五軒町、笹沼、三区町、塩原、下大貫、下田野、下永田、新南、関根、関谷、千本松、高阿津、高柳、太夫塚、槻沢、中塩原、永田町、二区町、西赤田、西朝日町、西遅沢、西幸町、西栄町、西富山、西原町、西三島、西大和、接骨木、東赤田、東遅沢、東関根、東三島、蟇沼、二つ室、三島、緑、南赤田、南郷屋、南町、睦、湯本塩原、横林及び四区町を除く。',
      scale: '',
    },
  },
  {
    code: '09213',
    distinct: '2',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '那須塩原',
    type: '市',
    kana: 'なすしおばら',
    typeKana: 'し',
    compartmentCode: '202',
    zone: {
      name: '東町、あたご町、井口、石林、一区町、宇都野、扇町、遅野沢、折戸、金沢、上赤田、上大貫、上塩原、上横林、北赤田、北二つ室、五軒町、笹沼、三区町、塩原、下大貫、下田野、下永田、新南、関根、関谷、千本松、高阿津、高柳、太夫塚、槻沢、中塩原、永田町、二区町、西赤田、西朝日町、西遅沢、西幸町、西栄町、西富山、西原町、西三島、西大和、接骨木、東赤田、東遅沢、東関根、東三島、蟇沼、二つ室、三島、緑、南赤田、南郷屋、南町、睦、湯本塩原、横林及び四区町に限る。',
      scale: '',
    },
  },
  {
    code: '09214',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'さくら',
    type: '市',
    kana: 'さくら',
    typeKana: 'し',
    compartmentCode: '200',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09215',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '那須烏山',
    type: '市',
    kana: 'なすからすやま',
    typeKana: 'し',
    compartmentCode: '203',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09216',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '下野',
    type: '市',
    kana: 'しもつけ',
    typeKana: 'し',
    compartmentCode: '199',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09301',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '河内',
      type: '郡',
      kana: 'かわち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上三川',
    type: '町',
    kana: 'かみのかわ',
    typeKana: 'まち',
    compartmentCode: '199',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09342',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '芳賀',
      type: '郡',
      kana: 'はが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '益子',
    type: '町',
    kana: 'ましこ',
    typeKana: 'まち',
    compartmentCode: '198',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09343',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '芳賀',
      type: '郡',
      kana: 'はが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '茂木',
    type: '町',
    kana: 'もてぎ',
    typeKana: 'まち',
    compartmentCode: '198',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09344',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '芳賀',
      type: '郡',
      kana: 'はが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '市貝',
    type: '町',
    kana: 'いちかい',
    typeKana: 'まち',
    compartmentCode: '198',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09345',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '芳賀',
      type: '郡',
      kana: 'はが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '芳賀',
    type: '町',
    kana: 'はが',
    typeKana: 'まち',
    compartmentCode: '200',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09361',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '下都賀',
      type: '郡',
      kana: 'しもつが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '壬生',
    type: '町',
    kana: 'みぶ',
    typeKana: 'まち',
    compartmentCode: '195',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09364',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '下都賀',
      type: '郡',
      kana: 'しもつが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '野木',
    type: '町',
    kana: 'のぎ',
    typeKana: 'まち',
    compartmentCode: '194',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09384',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '塩谷',
      type: '郡',
      kana: 'しおや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '塩谷',
    type: '町',
    kana: 'しおや',
    typeKana: 'まち',
    compartmentCode: '202',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09386',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '塩谷',
      type: '郡',
      kana: 'しおや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高根沢',
    type: '町',
    kana: 'たかねざわ',
    typeKana: 'まち',
    compartmentCode: '200',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09407',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '那須',
      type: '郡',
      kana: 'なす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '那須',
    type: '町',
    kana: 'なす',
    typeKana: 'まち',
    compartmentCode: '201',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '09411',
    distinct: '1',
    pref: '栃木県',
    county: {
      name: '那須',
      type: '郡',
      kana: 'なす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '那珂川',
    type: '町',
    kana: 'なかがわ',
    typeKana: 'まち',
    compartmentCode: '203',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10201',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '前橋',
    type: '市',
    kana: 'まえばし',
    typeKana: 'し',
    compartmentCode: '185',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10202',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高崎',
    type: '市',
    kana: 'たかさき',
    typeKana: 'し',
    compartmentCode: '186',
    zone: {
      name: '新町を除く。',
      scale: '',
    },
  },
  {
    code: '10202',
    distinct: '2',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高崎',
    type: '市',
    kana: 'たかさき',
    typeKana: 'し',
    compartmentCode: '188',
    zone: {
      name: '新町',
      scale: '',
    },
  },
  {
    code: '10203',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '桐生',
    type: '市',
    kana: 'きりゅう',
    typeKana: 'し',
    compartmentCode: '190',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10204',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊勢崎',
    type: '市',
    kana: 'いせさき',
    typeKana: 'し',
    compartmentCode: '184',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10205',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '太田',
    type: '市',
    kana: 'おおた',
    typeKana: 'し',
    compartmentCode: '189',
    zone: {
      name: '市場町、大久保町、大原町、藪塚町、山之神町、寄合町及び六千石町を除く。',
      scale: '',
    },
  },
  {
    code: '10205',
    distinct: '2',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '太田',
    type: '市',
    kana: 'おおた',
    typeKana: 'し',
    compartmentCode: '190',
    zone: {
      name: '大久保町、大原町、藪塚町、山之神町、寄合町及び六千石町に限る。',
      scale: '',
    },
  },
  {
    code: '10205',
    distinct: '3',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '太田',
    type: '市',
    kana: 'おおた',
    typeKana: 'し',
    compartmentCode: '197',
    zone: {
      name: '市場町',
      scale: '',
    },
  },
  {
    code: '10206',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '沼田',
    type: '市',
    kana: 'ぬまた',
    typeKana: 'し',
    compartmentCode: '191',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10207',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '館林',
    type: '市',
    kana: 'たてばやし',
    typeKana: 'し',
    compartmentCode: '189',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10208',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '渋川',
    type: '市',
    kana: 'しぶかわ',
    typeKana: 'し',
    compartmentCode: '192',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10209',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '藤岡',
    type: '市',
    kana: 'ふじおか',
    typeKana: 'し',
    compartmentCode: '188',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10210',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富岡',
    type: '市',
    kana: 'とみおか',
    typeKana: 'し',
    compartmentCode: '187',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10211',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '安中',
    type: '市',
    kana: 'あんなか',
    typeKana: 'し',
    compartmentCode: '186',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10212',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'みどり',
    type: '市',
    kana: 'みどり',
    typeKana: 'し',
    compartmentCode: '190',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10344',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '北群馬',
      type: '郡',
      kana: 'きたぐんま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '榛東',
    type: '村',
    kana: 'しんとう',
    typeKana: 'むら',
    compartmentCode: '192',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10345',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '北群馬',
      type: '郡',
      kana: 'きたぐんま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉岡',
    type: '町',
    kana: 'よしおか',
    typeKana: 'まち',
    compartmentCode: '192',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10366',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '多野',
      type: '郡',
      kana: 'たの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上野',
    type: '村',
    kana: 'うえの',
    typeKana: 'むら',
    compartmentCode: '188',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10367',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '多野',
      type: '郡',
      kana: 'たの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神流',
    type: '町',
    kana: 'かんな',
    typeKana: 'まち',
    compartmentCode: '188',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10382',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '甘楽',
      type: '郡',
      kana: 'かんら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '下仁田',
    type: '町',
    kana: 'しもにた',
    typeKana: 'まち',
    compartmentCode: '187',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10383',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '甘楽',
      type: '郡',
      kana: 'かんら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南牧',
    type: '村',
    kana: 'なんもく',
    typeKana: 'むら',
    compartmentCode: '187',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10384',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '甘楽',
      type: '郡',
      kana: 'かんら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '甘楽',
    type: '町',
    kana: 'かんら',
    typeKana: 'まち',
    compartmentCode: '187',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10421',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '吾妻',
      type: '郡',
      kana: 'あがつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中之条',
    type: '町',
    kana: 'なかのじょう',
    typeKana: 'まち',
    compartmentCode: '192',
    zone: {
      name: '赤岩、入山、太子、小雨、生須及び日影を除く。',
      scale: '',
    },
  },
  {
    code: '10421',
    distinct: '2',
    pref: '群馬県',
    county: {
      name: '吾妻',
      type: '郡',
      kana: 'あがつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中之条',
    type: '町',
    kana: 'なかのじょう',
    typeKana: 'まち',
    compartmentCode: '193',
    zone: {
      name: '赤岩、入山、太子、小雨、生須及び日影に限る。',
      scale: '',
    },
  },
  {
    code: '10424',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '吾妻',
      type: '郡',
      kana: 'あがつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長野原',
    type: '町',
    kana: 'ながのはら',
    typeKana: 'まち',
    compartmentCode: '193',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10425',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '吾妻',
      type: '郡',
      kana: 'あがつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '嬬恋',
    type: '村',
    kana: 'つまごい',
    typeKana: 'むら',
    compartmentCode: '193',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10426',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '吾妻',
      type: '郡',
      kana: 'あがつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '草津',
    type: '町',
    kana: 'くさつ',
    typeKana: 'まち',
    compartmentCode: '193',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10428',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '吾妻',
      type: '郡',
      kana: 'あがつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高山',
    type: '村',
    kana: 'たかやま',
    typeKana: 'むら',
    compartmentCode: '192',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10429',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '吾妻',
      type: '郡',
      kana: 'あがつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東吾妻',
    type: '町',
    kana: 'ひがしあがつま',
    typeKana: 'まち',
    compartmentCode: '192',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10443',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '利根',
      type: '郡',
      kana: 'とね',
      typeKana: 'ぐん',
      note: '',
    },
    name: '片品',
    type: '村',
    kana: 'かたしな',
    typeKana: 'むら',
    compartmentCode: '191',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10444',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '利根',
      type: '郡',
      kana: 'とね',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川場',
    type: '村',
    kana: 'かわば',
    typeKana: 'むら',
    compartmentCode: '191',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10448',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '利根',
      type: '郡',
      kana: 'とね',
      typeKana: 'ぐん',
      note: '',
    },
    name: '昭和',
    type: '村',
    kana: 'しょうわ',
    typeKana: 'むら',
    compartmentCode: '191',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10449',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '利根',
      type: '郡',
      kana: 'とね',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'みなかみ',
    type: '町',
    kana: 'みなかみ',
    typeKana: 'まち',
    compartmentCode: '191',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10464',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '佐波',
      type: '郡',
      kana: 'さわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '玉村',
    type: '町',
    kana: 'たまむら',
    typeKana: 'まち',
    compartmentCode: '184',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10521',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '邑楽',
      type: '郡',
      kana: 'おうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '板倉',
    type: '町',
    kana: 'いたくら',
    typeKana: 'まち',
    compartmentCode: '189',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10522',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '邑楽',
      type: '郡',
      kana: 'おうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '明和',
    type: '町',
    kana: 'めいわ',
    typeKana: 'まち',
    compartmentCode: '189',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10523',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '邑楽',
      type: '郡',
      kana: 'おうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '千代田',
    type: '町',
    kana: 'ちよだ',
    typeKana: 'まち',
    compartmentCode: '189',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10524',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '邑楽',
      type: '郡',
      kana: 'おうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大泉',
    type: '町',
    kana: 'おおいずみ',
    typeKana: 'まち',
    compartmentCode: '189',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '10525',
    distinct: '1',
    pref: '群馬県',
    county: {
      name: '邑楽',
      type: '郡',
      kana: 'おうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '邑楽',
    type: '町',
    kana: 'おうら',
    typeKana: 'まち',
    compartmentCode: '189',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11100',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11101',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11102',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11103',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '大宮',
    type: '区',
    kana: 'おおみや',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11104',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '見沼',
    type: '区',
    kana: 'みぬま',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11105',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11106',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '桜',
    type: '区',
    kana: 'さくら',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11107',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '浦和',
    type: '区',
    kana: 'うらわ',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11108',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11109',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '緑',
    type: '区',
    kana: 'みどり',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11110',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: 'さいたま',
      type: '市',
      kana: 'さいたま',
      typeKana: 'し',
      note: '',
    },
    name: '岩槻',
    type: '区',
    kana: 'いわつき',
    typeKana: 'く',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11201',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '川越',
    type: '市',
    kana: 'かわごえ',
    typeKana: 'し',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11202',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '熊谷',
    type: '市',
    kana: 'くまがや',
    typeKana: 'し',
    compartmentCode: '189',
    zone: {
      name: '妻沼小島',
      scale: '',
    },
  },
  {
    code: '11202',
    distinct: '2',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '熊谷',
    type: '市',
    kana: 'くまがや',
    typeKana: 'し',
    compartmentCode: '257-2',
    zone: {
      name: '相上、冑山、吉所敷、小八林、高本、玉作、津田、津田新田、沼黒、船木台、箕輪、向谷及び妻沼小島を除く。',
      scale: '',
    },
  },
  {
    code: '11202',
    distinct: '3',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '熊谷',
    type: '市',
    kana: 'くまがや',
    typeKana: 'し',
    compartmentCode: '260',
    zone: {
      name: '相上、冑山、吉所敷、小八林、高本、玉作、津田、津田新田、沼黒、船木台、箕輪及び向谷に限る。',
      scale: '',
    },
  },
  {
    code: '11203',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '川口',
    type: '市',
    kana: 'かわぐち',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11206',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '行田',
    type: '市',
    kana: 'ぎょうだ',
    typeKana: 'し',
    compartmentCode: '257-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11207',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '秩父',
    type: '市',
    kana: 'ちちぶ',
    typeKana: 'し',
    compartmentCode: '261',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11208',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '所沢',
    type: '市',
    kana: 'ところざわ',
    typeKana: 'し',
    compartmentCode: '227',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11209',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '飯能',
    type: '市',
    kana: 'はんのう',
    typeKana: 'し',
    compartmentCode: '228',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11210',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加須',
    type: '市',
    kana: 'かぞ',
    typeKana: 'し',
    compartmentCode: '194',
    zone: {
      name: '飯積、伊賀袋、小野袋、柏戸、駒場、栄、本郷、向古河、麦倉、柳生及び陽光台に限る。',
      scale: '',
    },
  },
  {
    code: '11210',
    distinct: '2',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加須',
    type: '市',
    kana: 'かぞ',
    typeKana: 'し',
    compartmentCode: '256',
    zone: {
      name: '飯積、伊賀袋、小野袋、柏戸、駒場、栄、本郷、向古河、麦倉、柳生及び陽光台を除く。',
      scale: '',
    },
  },
  {
    code: '11211',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '本庄',
    type: '市',
    kana: 'ほんじょう',
    typeKana: 'し',
    compartmentCode: '262',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11212',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東松山',
    type: '市',
    kana: 'ひがしまつやま',
    typeKana: 'し',
    compartmentCode: '260',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11214',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '春日部',
    type: '市',
    kana: 'かすかべ',
    typeKana: 'し',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11215',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '狭山',
    type: '市',
    kana: 'さやま',
    typeKana: 'し',
    compartmentCode: '227',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11216',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '羽生',
    type: '市',
    kana: 'はにゅう',
    typeKana: 'し',
    compartmentCode: '257-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11217',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鴻巣',
    type: '市',
    kana: 'こうのす',
    typeKana: 'し',
    compartmentCode: '257-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11218',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '深谷',
    type: '市',
    kana: 'ふかや',
    typeKana: 'し',
    compartmentCode: '257-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11219',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上尾',
    type: '市',
    kana: 'あげお',
    typeKana: 'し',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11221',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '草加',
    type: '市',
    kana: 'そうか',
    typeKana: 'し',
    compartmentCode: '258',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11222',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '越谷',
    type: '市',
    kana: 'こしがや',
    typeKana: 'し',
    compartmentCode: '258',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11223',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '蕨',
    type: '市',
    kana: 'わらび',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11224',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '戸田',
    type: '市',
    kana: 'とだ',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11225',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '入間',
    type: '市',
    kana: 'いるま',
    typeKana: 'し',
    compartmentCode: '227',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11227',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '朝霞',
    type: '市',
    kana: 'あさか',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11228',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '志木',
    type: '市',
    kana: 'しき',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11229',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '和光',
    type: '市',
    kana: 'わこう',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11230',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新座',
    type: '市',
    kana: 'にいざ',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '石神一丁目及び三丁目から五丁目まで、栗原、新堀、西堀並びに野寺一丁目及び五丁目に限る。',
      scale: '',
    },
  },
  {
    code: '11230',
    distinct: '2',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新座',
    type: '市',
    kana: 'にいざ',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '石神一丁目及び三丁目から五丁目まで、栗原、新堀、西堀並びに野寺一丁目及び五丁目を除く。',
      scale: '',
    },
  },
  {
    code: '11231',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '桶川',
    type: '市',
    kana: 'おけがわ',
    typeKana: 'し',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11232',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '久喜',
    type: '市',
    kana: 'くき',
    typeKana: 'し',
    compartmentCode: '256',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11233',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北本',
    type: '市',
    kana: 'きたもと',
    typeKana: 'し',
    compartmentCode: '257-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11234',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八潮',
    type: '市',
    kana: 'やしお',
    typeKana: 'し',
    compartmentCode: '258',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11235',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富士見',
    type: '市',
    kana: 'ふじみ',
    typeKana: 'し',
    compartmentCode: '255',
    zone: {
      name: '水谷東二丁目及び三丁目に限る。',
      scale: '',
    },
  },
  {
    code: '11235',
    distinct: '2',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富士見',
    type: '市',
    kana: 'ふじみ',
    typeKana: 'し',
    compartmentCode: '259',
    zone: {
      name: '水谷東二丁目及び三丁目を除く。',
      scale: '',
    },
  },
  {
    code: '11237',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三郷',
    type: '市',
    kana: 'みさと',
    typeKana: 'し',
    compartmentCode: '258',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11238',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '蓮田',
    type: '市',
    kana: 'はすだ',
    typeKana: 'し',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11239',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '坂戸',
    type: '市',
    kana: 'さかど',
    typeKana: 'し',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11240',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '幸手',
    type: '市',
    kana: 'さって',
    typeKana: 'し',
    compartmentCode: '256',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11241',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鶴ヶ島',
    type: '市',
    kana: 'つるがしま',
    typeKana: 'し',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11242',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日高',
    type: '市',
    kana: 'ひだか',
    typeKana: 'し',
    compartmentCode: '228',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11243',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '吉川',
    type: '市',
    kana: 'よしかわ',
    typeKana: 'し',
    compartmentCode: '258',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11245',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'ふじみ野',
    type: '市',
    kana: 'ふじみの',
    typeKana: 'し',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11246',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '白岡',
    type: '市',
    kana: 'しらおか',
    typeKana: 'し',
    compartmentCode: '256',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11301',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '北足立',
      type: '郡',
      kana: 'きたあだち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伊奈',
    type: '町',
    kana: 'いな',
    typeKana: 'まち',
    compartmentCode: '254',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11324',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '入間',
      type: '郡',
      kana: 'いるま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三芳',
    type: '町',
    kana: 'みよし',
    typeKana: 'まち',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11326',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '入間',
      type: '郡',
      kana: 'いるま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '毛呂山',
    type: '町',
    kana: 'もろやま',
    typeKana: 'まち',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11327',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '入間',
      type: '郡',
      kana: 'いるま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '越生',
    type: '町',
    kana: 'おごせ',
    typeKana: 'まち',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11341',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '比企',
      type: '郡',
      kana: 'ひき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '滑川',
    type: '町',
    kana: 'なめがわ',
    typeKana: 'まち',
    compartmentCode: '260',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11342',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '比企',
      type: '郡',
      kana: 'ひき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '嵐山',
    type: '町',
    kana: 'らんざん',
    typeKana: 'まち',
    compartmentCode: '260',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11343',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '比企',
      type: '郡',
      kana: 'ひき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小川',
    type: '町',
    kana: 'おがわ',
    typeKana: 'まち',
    compartmentCode: '260',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11346',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '比企',
      type: '郡',
      kana: 'ひき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川島',
    type: '町',
    kana: 'かわじま',
    typeKana: 'まち',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11347',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '比企',
      type: '郡',
      kana: 'ひき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉見',
    type: '町',
    kana: 'よしみ',
    typeKana: 'まち',
    compartmentCode: '260',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11348',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '比企',
      type: '郡',
      kana: 'ひき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鳩山',
    type: '町',
    kana: 'はとやま',
    typeKana: 'まち',
    compartmentCode: '259',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11349',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '比企',
      type: '郡',
      kana: 'ひき',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'ときがわ',
    type: '町',
    kana: 'ときがわ',
    typeKana: 'まち',
    compartmentCode: '260',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11361',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '秩父',
      type: '郡',
      kana: 'ちちぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '横瀬',
    type: '町',
    kana: 'よこぜ',
    typeKana: 'まち',
    compartmentCode: '261',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11362',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '秩父',
      type: '郡',
      kana: 'ちちぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '皆野',
    type: '町',
    kana: 'みなの',
    typeKana: 'まち',
    compartmentCode: '261',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11363',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '秩父',
      type: '郡',
      kana: 'ちちぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長瀞',
    type: '町',
    kana: 'ながとろ',
    typeKana: 'まち',
    compartmentCode: '261',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11365',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '秩父',
      type: '郡',
      kana: 'ちちぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小鹿野',
    type: '町',
    kana: 'おがの',
    typeKana: 'まち',
    compartmentCode: '261',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11369',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '秩父',
      type: '郡',
      kana: 'ちちぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東秩父',
    type: '村',
    kana: 'ひがしちちぶ',
    typeKana: 'むら',
    compartmentCode: '260',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11381',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '児玉',
      type: '郡',
      kana: 'こだま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美里',
    type: '町',
    kana: 'みさと',
    typeKana: 'まち',
    compartmentCode: '262',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11383',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '児玉',
      type: '郡',
      kana: 'こだま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神川',
    type: '町',
    kana: 'かみかわ',
    typeKana: 'まち',
    compartmentCode: '188',
    zone: {
      name: '上阿久原、下阿久原、矢納及び渡瀬に限る。',
      scale: '',
    },
  },
  {
    code: '11383',
    distinct: '2',
    pref: '埼玉県',
    county: {
      name: '児玉',
      type: '郡',
      kana: 'こだま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神川',
    type: '町',
    kana: 'かみかわ',
    typeKana: 'まち',
    compartmentCode: '262',
    zone: {
      name: '上阿久原、下阿久原、矢納及び渡瀬を除く。',
      scale: '',
    },
  },
  {
    code: '11385',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '児玉',
      type: '郡',
      kana: 'こだま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上里',
    type: '町',
    kana: 'かみさと',
    typeKana: 'まち',
    compartmentCode: '262',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11408',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '大里',
      type: '郡',
      kana: 'おおさと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '寄居',
    type: '町',
    kana: 'よりい',
    typeKana: 'まち',
    compartmentCode: '257-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11442',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '南埼玉',
      type: '郡',
      kana: 'みなみさいたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宮代',
    type: '町',
    kana: 'みやしろ',
    typeKana: 'まち',
    compartmentCode: '256',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11464',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '北葛飾',
      type: '郡',
      kana: 'きたかつしか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '杉戸',
    type: '町',
    kana: 'すぎと',
    typeKana: 'まち',
    compartmentCode: '256',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '11465',
    distinct: '1',
    pref: '埼玉県',
    county: {
      name: '北葛飾',
      type: '郡',
      kana: 'きたかつしか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松伏',
    type: '町',
    kana: 'まつぶし',
    typeKana: 'まち',
    compartmentCode: '258',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12100',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12100',
    distinct: '2',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '247',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12101',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12102',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '花見川',
    type: '区',
    kana: 'はなみがわ',
    typeKana: 'く',
    compartmentCode: '229',
    zone: {
      name: '柏井、柏井町及び横戸町を除く。',
      scale: '',
    },
  },
  {
    code: '12102',
    distinct: '2',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '花見川',
    type: '区',
    kana: 'はなみがわ',
    typeKana: 'く',
    compartmentCode: '247',
    zone: {
      name: '柏井、柏井町及び横戸町に限る。',
      scale: '',
    },
  },
  {
    code: '12103',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '稲毛',
    type: '区',
    kana: 'いなげ',
    typeKana: 'く',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12104',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '若葉',
    type: '区',
    kana: 'わかば',
    typeKana: 'く',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12105',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '緑',
    type: '区',
    kana: 'みどり',
    typeKana: 'く',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12106',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '千葉',
      type: '市',
      kana: 'ちば',
      typeKana: 'し',
      note: '',
    },
    name: '美浜',
    type: '区',
    kana: 'みはま',
    typeKana: 'く',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12202',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '銚子',
    type: '市',
    kana: 'ちょうし',
    typeKana: 'し',
    compartmentCode: '252',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12203',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '市川',
    type: '市',
    kana: 'いちかわ',
    typeKana: 'し',
    compartmentCode: '246',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12204',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '船橋',
    type: '市',
    kana: 'ふなばし',
    typeKana: 'し',
    compartmentCode: '246',
    zone: {
      name: '上山町一丁目、古作町、古作、西船五丁目から七丁目まで、東中山、藤原一丁目及び二丁目、二子町、本郷町並びに本中山に限る。',
      scale: '',
    },
  },
  {
    code: '12204',
    distinct: '2',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '船橋',
    type: '市',
    kana: 'ふなばし',
    typeKana: 'し',
    compartmentCode: '247',
    zone: {
      name: '上山町一丁目、古作町、古作、西船五丁目から七丁目まで、東中山、藤原一丁目及び二丁目、二子町、本郷町並びに本中山を除く。',
      scale: '',
    },
  },
  {
    code: '12205',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '館山',
    type: '市',
    kana: 'たてやま',
    typeKana: 'し',
    compartmentCode: '242',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12206',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '木更津',
    type: '市',
    kana: 'きさらづ',
    typeKana: 'し',
    compartmentCode: '231',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12207',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松戸',
    type: '市',
    kana: 'まつど',
    typeKana: 'し',
    compartmentCode: '246',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12208',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '野田',
    type: '市',
    kana: 'のだ',
    typeKana: 'し',
    compartmentCode: '245',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12210',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '茂原',
    type: '市',
    kana: 'もばら',
    typeKana: 'し',
    compartmentCode: '249',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12211',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '成田',
    type: '市',
    kana: 'なりた',
    typeKana: 'し',
    compartmentCode: '250',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12212',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐倉',
    type: '市',
    kana: 'さくら',
    typeKana: 'し',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12213',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東金',
    type: '市',
    kana: 'とうがね',
    typeKana: 'し',
    compartmentCode: '248',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12215',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '旭',
    type: '市',
    kana: 'あさひ',
    typeKana: 'し',
    compartmentCode: '253',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12216',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '習志野',
    type: '市',
    kana: 'ならしの',
    typeKana: 'し',
    compartmentCode: '247',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12217',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '柏',
    type: '市',
    kana: 'かしわ',
    typeKana: 'し',
    compartmentCode: '245',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12218',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '勝浦',
    type: '市',
    kana: 'かつうら',
    typeKana: 'し',
    compartmentCode: '243',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12219',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '市原',
    type: '市',
    kana: 'いちはら',
    typeKana: 'し',
    compartmentCode: '230',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12220',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '流山',
    type: '市',
    kana: 'ながれやま',
    typeKana: 'し',
    compartmentCode: '245',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12221',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八千代',
    type: '市',
    kana: 'やちよ',
    typeKana: 'し',
    compartmentCode: '247',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12222',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '我孫子',
    type: '市',
    kana: 'あびこ',
    typeKana: 'し',
    compartmentCode: '245',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12223',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鴨川',
    type: '市',
    kana: 'かもがわ',
    typeKana: 'し',
    compartmentCode: '244',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12224',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鎌ケ谷',
    type: '市',
    kana: 'かまがや',
    typeKana: 'し',
    compartmentCode: '246',
    zone: {
      name: 'くぬぎ山一丁目から四丁目までに限る。',
      scale: '',
    },
  },
  {
    code: '12224',
    distinct: '2',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鎌ケ谷',
    type: '市',
    kana: 'かまがや',
    typeKana: 'し',
    compartmentCode: '247',
    zone: {
      name: 'くぬぎ山一丁目から四丁目までを除く。',
      scale: '',
    },
  },
  {
    code: '12225',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '君津',
    type: '市',
    kana: 'きみつ',
    typeKana: 'し',
    compartmentCode: '232',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12226',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富津',
    type: '市',
    kana: 'ふっつ',
    typeKana: 'し',
    compartmentCode: '232',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12227',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '浦安',
    type: '市',
    kana: 'うらやす',
    typeKana: 'し',
    compartmentCode: '246',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12228',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '四街道',
    type: '市',
    kana: 'よつかいどう',
    typeKana: 'し',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12229',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '袖ケ浦',
    type: '市',
    kana: 'そでがうら',
    typeKana: 'し',
    compartmentCode: '231',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12230',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八街',
    type: '市',
    kana: 'やちまた',
    typeKana: 'し',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12231',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '印西',
    type: '市',
    kana: 'いんざい',
    typeKana: 'し',
    compartmentCode: '250',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12232',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '白井',
    type: '市',
    kana: 'しろい',
    typeKana: 'し',
    compartmentCode: '247',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12233',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富里',
    type: '市',
    kana: 'とみさと',
    typeKana: 'し',
    compartmentCode: '250',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12234',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南房総',
    type: '市',
    kana: 'みなみぼうそう',
    typeKana: 'し',
    compartmentCode: '242',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12235',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '匝瑳',
    type: '市',
    kana: 'そうさ',
    typeKana: 'し',
    compartmentCode: '253',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12236',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '香取',
    type: '市',
    kana: 'かとり',
    typeKana: 'し',
    compartmentCode: '251',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12237',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山武',
    type: '市',
    kana: 'さんむ',
    typeKana: 'し',
    compartmentCode: '248',
    zone: {
      name: '松尾町を除く。',
      scale: '',
    },
  },
  {
    code: '12237',
    distinct: '2',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山武',
    type: '市',
    kana: 'さんむ',
    typeKana: 'し',
    compartmentCode: '253',
    zone: {
      name: '松尾町',
      scale: '',
    },
  },
  {
    code: '12238',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'いすみ',
    type: '市',
    kana: 'いすみ',
    typeKana: 'し',
    compartmentCode: '243',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12239',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大網白里',
    type: '市',
    kana: 'おおあみしらさと',
    typeKana: 'し',
    compartmentCode: '248',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12322',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '印旛',
      type: '郡',
      kana: 'いんば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '酒々井',
    type: '町',
    kana: 'しすい',
    typeKana: 'まち',
    compartmentCode: '229',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12329',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '印旛',
      type: '郡',
      kana: 'いんば',
      typeKana: 'ぐん',
      note: '',
    },
    name: '栄',
    type: '町',
    kana: 'さかえ',
    typeKana: 'まち',
    compartmentCode: '250',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12342',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '香取',
      type: '郡',
      kana: 'かとり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神崎',
    type: '町',
    kana: 'こうざき',
    typeKana: 'まち',
    compartmentCode: '251',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12347',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '香取',
      type: '郡',
      kana: 'かとり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '多古',
    type: '町',
    kana: 'たこ',
    typeKana: 'まち',
    compartmentCode: '253',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12349',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '香取',
      type: '郡',
      kana: 'かとり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東庄',
    type: '町',
    kana: 'とうのしょう',
    typeKana: 'まち',
    compartmentCode: '251',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12403',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '山武',
      type: '郡',
      kana: 'さんぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '九十九里',
    type: '町',
    kana: 'くじゅうくり',
    typeKana: 'まち',
    compartmentCode: '248',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12409',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '山武',
      type: '郡',
      kana: 'さんぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '芝山',
    type: '町',
    kana: 'しばやま',
    typeKana: 'まち',
    compartmentCode: '253',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12410',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '山武',
      type: '郡',
      kana: 'さんぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '横芝光',
    type: '町',
    kana: 'よこしばひかり',
    typeKana: 'まち',
    compartmentCode: '253',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12421',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '長生',
      type: '郡',
      kana: 'ちょうせい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '一宮',
    type: '町',
    kana: 'いちのみや',
    typeKana: 'まち',
    compartmentCode: '249',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12422',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '長生',
      type: '郡',
      kana: 'ちょうせい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '睦沢',
    type: '町',
    kana: 'むつざわ',
    typeKana: 'まち',
    compartmentCode: '249',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12423',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '長生',
      type: '郡',
      kana: 'ちょうせい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長生',
    type: '村',
    kana: 'ちょうせい',
    typeKana: 'むら',
    compartmentCode: '249',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12424',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '長生',
      type: '郡',
      kana: 'ちょうせい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白子',
    type: '町',
    kana: 'しらこ',
    typeKana: 'まち',
    compartmentCode: '249',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12426',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '長生',
      type: '郡',
      kana: 'ちょうせい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長柄',
    type: '町',
    kana: 'ながら',
    typeKana: 'まち',
    compartmentCode: '249',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12427',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '長生',
      type: '郡',
      kana: 'ちょうせい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長南',
    type: '町',
    kana: 'ちょうなん',
    typeKana: 'まち',
    compartmentCode: '249',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12441',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '夷隅',
      type: '郡',
      kana: 'いすみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大多喜',
    type: '町',
    kana: 'おおたき',
    typeKana: 'まち',
    compartmentCode: '243',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12443',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '夷隅',
      type: '郡',
      kana: 'いすみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '御宿',
    type: '町',
    kana: 'おんじゅく',
    typeKana: 'まち',
    compartmentCode: '243',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '12463',
    distinct: '1',
    pref: '千葉県',
    county: {
      name: '安房',
      type: '郡',
      kana: 'あわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鋸南',
    type: '町',
    kana: 'きょなん',
    typeKana: 'まち',
    compartmentCode: '242',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13100',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '特別区',
    type: '部',
    kana: 'とくべつく',
    typeKana: 'ぶ',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13101',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '千代田',
    type: '区',
    kana: 'ちよだ',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13102',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13103',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '港',
    type: '区',
    kana: 'みなと',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13104',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新宿',
    type: '区',
    kana: 'しんじゅく',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13105',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '文京',
    type: '区',
    kana: 'ぶんきょう',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13106',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '台東',
    type: '区',
    kana: 'たいとう',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13107',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '墨田',
    type: '区',
    kana: 'すみだ',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13108',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '江東',
    type: '区',
    kana: 'こうとう',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13109',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '品川',
    type: '区',
    kana: 'しながわ',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13110',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '目黒',
    type: '区',
    kana: 'めぐろ',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13111',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大田',
    type: '区',
    kana: 'おおた',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13112',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '世田谷',
    type: '区',
    kana: 'せたがや',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13113',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '渋谷',
    type: '区',
    kana: 'しぶや',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13114',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中野',
    type: '区',
    kana: 'なかの',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13115',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '杉並',
    type: '区',
    kana: 'すぎなみ',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13116',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊島',
    type: '区',
    kana: 'としま',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13117',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13118',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '荒川',
    type: '区',
    kana: 'あらかわ',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13119',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '板橋',
    type: '区',
    kana: 'いたばし',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13120',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '練馬',
    type: '区',
    kana: 'ねりま',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13121',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '足立',
    type: '区',
    kana: 'あだち',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13122',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '葛飾',
    type: '区',
    kana: 'かつしか',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13123',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '江戸川',
    type: '区',
    kana: 'えどがわ',
    typeKana: 'く',
    compartmentCode: '219',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13201',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八王子',
    type: '市',
    kana: 'はちおうじ',
    typeKana: 'し',
    compartmentCode: '224',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13202',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '立川',
    type: '市',
    kana: 'たちかわ',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13203',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '武蔵野',
    type: '市',
    kana: 'むさしの',
    typeKana: 'し',
    compartmentCode: '220',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13204',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三鷹',
    type: '市',
    kana: 'みたか',
    typeKana: 'し',
    compartmentCode: '219',
    zone: {
      name: '中原一丁目',
      scale: '',
    },
  },
  {
    code: '13204',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三鷹',
    type: '市',
    kana: 'みたか',
    typeKana: 'し',
    compartmentCode: '220',
    zone: {
      name: '中原一丁目を除く。',
      scale: '',
    },
  },
  {
    code: '13205',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '青梅',
    type: '市',
    kana: 'おうめ',
    typeKana: 'し',
    compartmentCode: '226',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13206',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '府中',
    type: '市',
    kana: 'ふちゅう',
    typeKana: 'し',
    compartmentCode: '221',
    zone: {
      name: '押立町四丁目及び五丁目、北山町、西原町二丁目から四丁目まで並びに西府町四丁目を除く。',
      scale: '',
    },
  },
  {
    code: '13206',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '府中',
    type: '市',
    kana: 'ふちゅう',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '押立町四丁目及び五丁目に限る。',
      scale: '',
    },
  },
  {
    code: '13206',
    distinct: '3',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '府中',
    type: '市',
    kana: 'ふちゅう',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '北山町、西原町二丁目から四丁目まで及び西府町四丁目に限る。',
      scale: '',
    },
  },
  {
    code: '13207',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '昭島',
    type: '市',
    kana: 'あきしま',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13208',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '調布',
    type: '市',
    kana: 'ちょうふ',
    typeKana: 'し',
    compartmentCode: '219',
    zone: {
      name: '入間町、国領町八丁目、仙川町、西つつじヶ丘二丁目、東つつじヶ丘、緑ヶ丘及び若葉町に限る。',
      scale: '',
    },
  },
  {
    code: '13208',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '調布',
    type: '市',
    kana: 'ちょうふ',
    typeKana: 'し',
    compartmentCode: '220',
    zone: {
      name: '深大寺東町七丁目及び野水に限る。',
      scale: '',
    },
  },
  {
    code: '13208',
    distinct: '3',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '調布',
    type: '市',
    kana: 'ちょうふ',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '入間町、国領町八丁目、深大寺東町七丁目、仙川町、西つつじヶ丘二丁目、東つつじヶ丘、緑ヶ丘、若葉町及び野水を除く。',
      scale: '',
    },
  },
  {
    code: '13209',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '町田',
    type: '市',
    kana: 'まちだ',
    typeKana: 'し',
    compartmentCode: '225',
    zone: {
      name: '三輪町及び三輪緑山を除く。',
      scale: '',
    },
  },
  {
    code: '13209',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '町田',
    type: '市',
    kana: 'まちだ',
    typeKana: 'し',
    compartmentCode: '233',
    zone: {
      name: '三輪町及び三輪緑山に限る。',
      scale: '',
    },
  },
  {
    code: '13210',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小金井',
    type: '市',
    kana: 'こがねい',
    typeKana: 'し',
    compartmentCode: '220',
    zone: {
      name: '梶野町一丁目から四丁目まで並びに東町二丁目及び三丁目に限る。',
      scale: '',
    },
  },
  {
    code: '13210',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小金井',
    type: '市',
    kana: 'こがねい',
    typeKana: 'し',
    compartmentCode: '221',
    zone: {
      name: '梶野町一丁目から四丁目まで並びに東町二丁目及び三丁目を除く。',
      scale: '',
    },
  },
  {
    code: '13211',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小平',
    type: '市',
    kana: 'こだいら',
    typeKana: 'し',
    compartmentCode: '221',
    zone: {
      name: '鈴木町二丁目、花小金井及び花小金井南町を除く。',
      scale: '',
    },
  },
  {
    code: '13211',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小平',
    type: '市',
    kana: 'こだいら',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '鈴木町二丁目、花小金井及び花小金井南町に限る。',
      scale: '',
    },
  },
  {
    code: '13212',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日野',
    type: '市',
    kana: 'ひの',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13213',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東村山',
    type: '市',
    kana: 'ひがしむらやま',
    typeKana: 'し',
    compartmentCode: '221',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13214',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '国分寺',
    type: '市',
    kana: 'こくぶんじ',
    typeKana: 'し',
    compartmentCode: '221',
    zone: {
      name: '高木町、内藤、西町、光町、日吉町二丁目及び三丁目、富士本並びに戸倉三丁目を除く。',
      scale: '',
    },
  },
  {
    code: '13214',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '国分寺',
    type: '市',
    kana: 'こくぶんじ',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '高木町、内藤、西町、光町、日吉町二丁目及び三丁目、富士本並びに戸倉三丁目に限る。',
      scale: '',
    },
  },
  {
    code: '13215',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '国立',
    type: '市',
    kana: 'くにたち',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13218',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福生',
    type: '市',
    kana: 'ふっさ',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13219',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '狛江',
    type: '市',
    kana: 'こまえ',
    typeKana: 'し',
    compartmentCode: '219',
    zone: {
      name: '西和泉を除く。',
      scale: '',
    },
  },
  {
    code: '13219',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '狛江',
    type: '市',
    kana: 'こまえ',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '西和泉',
      scale: '',
    },
  },
  {
    code: '13220',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東大和',
    type: '市',
    kana: 'ひがしやまと',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13221',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '清瀬',
    type: '市',
    kana: 'きよせ',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13222',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東久留米',
    type: '市',
    kana: 'ひがしくるめ',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13223',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '武蔵村山',
    type: '市',
    kana: 'むさしむらやま',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13224',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '多摩',
    type: '市',
    kana: 'たま',
    typeKana: 'し',
    compartmentCode: '221',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13225',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稲城',
    type: '市',
    kana: 'いなぎ',
    typeKana: 'し',
    compartmentCode: '221',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13227',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '羽村',
    type: '市',
    kana: 'はむら',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13228',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'あきる野',
    type: '市',
    kana: 'あきるの',
    typeKana: 'し',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13229',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西東京',
    type: '市',
    kana: 'にしとうきょう',
    typeKana: 'し',
    compartmentCode: '220',
    zone: {
      name: '新町',
      scale: '',
    },
  },
  {
    code: '13229',
    distinct: '2',
    pref: '東京都',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西東京',
    type: '市',
    kana: 'にしとうきょう',
    typeKana: 'し',
    compartmentCode: '222',
    zone: {
      name: '新町を除く。',
      scale: '',
    },
  },
  {
    code: '13303',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '西多摩',
      type: '郡',
      kana: 'にしたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '瑞穂',
    type: '町',
    kana: 'みずほ',
    typeKana: 'まち',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13305',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '西多摩',
      type: '郡',
      kana: 'にしたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日の出',
    type: '町',
    kana: 'ひので',
    typeKana: 'まち',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13307',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '西多摩',
      type: '郡',
      kana: 'にしたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '檜原',
    type: '村',
    kana: 'ひのはら',
    typeKana: 'むら',
    compartmentCode: '223',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13308',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '西多摩',
      type: '郡',
      kana: 'にしたま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '奥多摩',
    type: '町',
    kana: 'おくたま',
    typeKana: 'まち',
    compartmentCode: '226',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13361',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '大島',
      type: '支庁',
      kana: 'おおしま',
      typeKana: 'しちょう',
      note: '',
    },
    name: '大島',
    type: '町',
    kana: 'おおしま',
    typeKana: 'まち',
    compartmentCode: '263',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13362',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '大島',
      type: '支庁',
      kana: 'おおしま',
      typeKana: 'しちょう',
      note: '',
    },
    name: '利島',
    type: '村',
    kana: 'としま',
    typeKana: 'むら',
    compartmentCode: '263',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13363',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '大島',
      type: '支庁',
      kana: 'おおしま',
      typeKana: 'しちょう',
      note: '',
    },
    name: '新島',
    type: '村',
    kana: 'にいじま',
    typeKana: 'むら',
    compartmentCode: '263',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13364',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '大島',
      type: '支庁',
      kana: 'おおしま',
      typeKana: 'しちょう',
      note: '',
    },
    name: '神津島',
    type: '村',
    kana: 'こうづしま',
    typeKana: 'むら',
    compartmentCode: '263',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13381',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '三宅',
      type: '支庁',
      kana: 'みやけ',
      typeKana: 'しちょう',
      note: '',
    },
    name: '三宅',
    type: '村',
    kana: 'みやけ',
    typeKana: 'むら',
    compartmentCode: '264',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13382',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '三宅',
      type: '支庁',
      kana: 'みやけ',
      typeKana: 'しちょう',
      note: '',
    },
    name: '御蔵島',
    type: '村',
    kana: 'みくらじま',
    typeKana: 'むら',
    compartmentCode: '264',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13401',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '八丈',
      type: '支庁',
      kana: 'はちじょう',
      typeKana: 'しちょう',
      note: '',
    },
    name: '八丈',
    type: '町',
    kana: 'はちじょう',
    typeKana: 'まち',
    compartmentCode: '265',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13402',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '八丈',
      type: '支庁',
      kana: 'はちじょう',
      typeKana: 'しちょう',
      note: '',
    },
    name: '青ヶ島',
    type: '村',
    kana: 'あおがしま',
    typeKana: 'むら',
    compartmentCode: '265',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '13421',
    distinct: '1',
    pref: '東京都',
    county: {
      name: '小笠原',
      type: '支庁',
      kana: 'おがさわら',
      typeKana: 'しちょう',
      note: '',
    },
    name: '小笠原',
    type: '村',
    kana: 'おがさわら',
    typeKana: 'むら',
    compartmentCode: '266',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14100',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14101',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '鶴見',
    type: '区',
    kana: 'つるみ',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14102',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '神奈川',
    type: '区',
    kana: 'かながわ',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14103',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14104',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '中',
    type: '区',
    kana: 'なか',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14105',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14106',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '保土ケ谷',
    type: '区',
    kana: 'ほどがや',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14107',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '磯子',
    type: '区',
    kana: 'いそご',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14108',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '金沢',
    type: '区',
    kana: 'かなざわ',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14109',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '港北',
    type: '区',
    kana: 'こうほく',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14110',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '戸塚',
    type: '区',
    kana: 'とつか',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14111',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '港南',
    type: '区',
    kana: 'こうなん',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14112',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '旭',
    type: '区',
    kana: 'あさひ',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14113',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '緑',
    type: '区',
    kana: 'みどり',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14114',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '瀬谷',
    type: '区',
    kana: 'せや',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14115',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '栄',
    type: '区',
    kana: 'さかえ',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14116',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '泉',
    type: '区',
    kana: 'いずみ',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14117',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '青葉',
    type: '区',
    kana: 'あおば',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14118',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '横浜',
      type: '市',
      kana: 'よこはま',
      typeKana: 'し',
      note: '',
    },
    name: '都筑',
    type: '区',
    kana: 'つづき',
    typeKana: 'く',
    compartmentCode: '234',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14130',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14131',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '川崎',
    type: '区',
    kana: 'かわさき',
    typeKana: 'く',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14132',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '幸',
    type: '区',
    kana: 'さいわい',
    typeKana: 'く',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14133',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '中原',
    type: '区',
    kana: 'なかはら',
    typeKana: 'く',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14134',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '高津',
    type: '区',
    kana: 'たかつ',
    typeKana: 'く',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14135',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '多摩',
    type: '区',
    kana: 'たま',
    typeKana: 'く',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14136',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '宮前',
    type: '区',
    kana: 'みやまえ',
    typeKana: 'く',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14137',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '川崎',
      type: '市',
      kana: 'かわさき',
      typeKana: 'し',
      note: '',
    },
    name: '麻生',
    type: '区',
    kana: 'あさお',
    typeKana: 'く',
    compartmentCode: '233',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14150',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '224',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14150',
    distinct: '2',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '225',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14150',
    distinct: '3',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '236-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14151',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '緑',
    type: '区',
    kana: 'みどり',
    typeKana: 'く',
    compartmentCode: '224',
    zone: {
      name: '小原、小渕、佐野川、澤井、寸沢嵐、千木良、名倉、日連、牧野、吉野、与瀬、与瀬本町及び若柳に限る。',
      scale: '',
    },
  },
  {
    code: '14151',
    distinct: '2',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '緑',
    type: '区',
    kana: 'みどり',
    typeKana: 'く',
    compartmentCode: '225',
    zone: {
      name: '小原、小渕、佐野川、澤井、寸沢嵐、千木良、名倉、日連、牧野、吉野、与瀬、与瀬本町及び若柳を除く。',
      scale: '',
    },
  },
  {
    code: '14152',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '225',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14153',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '225',
    zone: {
      name: '磯部、新磯野一丁目及び三丁目から五丁目まで、新戸、相武台並びに相武台団地を除く。',
      scale: '',
    },
  },
  {
    code: '14153',
    distinct: '2',
    pref: '神奈川県',
    county: {
      name: '相模原',
      type: '市',
      kana: 'さがみはら',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '236-2',
    zone: {
      name: '磯部、新磯野一丁目及び三丁目から五丁目まで、新戸、相武台並びに相武台団地に限る。',
      scale: '',
    },
  },
  {
    code: '14201',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '横須賀',
    type: '市',
    kana: 'よこすか',
    typeKana: 'し',
    compartmentCode: '241',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14203',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '平塚',
    type: '市',
    kana: 'ひらつか',
    typeKana: 'し',
    compartmentCode: '237',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14204',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鎌倉',
    type: '市',
    kana: 'かまくら',
    typeKana: 'し',
    compartmentCode: '240',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14205',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '藤沢',
    type: '市',
    kana: 'ふじさわ',
    typeKana: 'し',
    compartmentCode: '239',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14206',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小田原',
    type: '市',
    kana: 'おだわら',
    typeKana: 'し',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14207',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '茅ヶ崎',
    type: '市',
    kana: 'ちがさき',
    typeKana: 'し',
    compartmentCode: '240',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14208',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '逗子',
    type: '市',
    kana: 'ずし',
    typeKana: 'し',
    compartmentCode: '240',
    zone: {
      name: '小坪',
      scale: '',
    },
  },
  {
    code: '14208',
    distinct: '2',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '逗子',
    type: '市',
    kana: 'ずし',
    typeKana: 'し',
    compartmentCode: '241',
    zone: {
      name: '小坪を除く。',
      scale: '',
    },
  },
  {
    code: '14210',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三浦',
    type: '市',
    kana: 'みうら',
    typeKana: 'し',
    compartmentCode: '241',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14211',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '秦野',
    type: '市',
    kana: 'はだの',
    typeKana: 'し',
    compartmentCode: '237',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14212',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '厚木',
    type: '市',
    kana: 'あつぎ',
    typeKana: 'し',
    compartmentCode: '236-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14213',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大和',
    type: '市',
    kana: 'やまと',
    typeKana: 'し',
    compartmentCode: '236-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14214',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊勢原',
    type: '市',
    kana: 'いせはら',
    typeKana: 'し',
    compartmentCode: '237',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14215',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '海老名',
    type: '市',
    kana: 'えびな',
    typeKana: 'し',
    compartmentCode: '236-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14216',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '座間',
    type: '市',
    kana: 'ざま',
    typeKana: 'し',
    compartmentCode: '225',
    zone: {
      name: '相模が丘一丁目及び五丁目に限る。',
      scale: '',
    },
  },
  {
    code: '14216',
    distinct: '2',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '座間',
    type: '市',
    kana: 'ざま',
    typeKana: 'し',
    compartmentCode: '236-2',
    zone: {
      name: '相模が丘一丁目及び五丁目を除く。',
      scale: '',
    },
  },
  {
    code: '14217',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南足柄',
    type: '市',
    kana: 'みなみあしがら',
    typeKana: 'し',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14218',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '綾瀬',
    type: '市',
    kana: 'あやせ',
    typeKana: 'し',
    compartmentCode: '240',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14301',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '三浦',
      type: '郡',
      kana: 'みうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '葉山',
    type: '町',
    kana: 'はやま',
    typeKana: 'まち',
    compartmentCode: '241',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14321',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '高座',
      type: '郡',
      kana: 'こうざ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '寒川',
    type: '町',
    kana: 'さむかわ',
    typeKana: 'まち',
    compartmentCode: '240',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14341',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '中',
      type: '郡',
      kana: 'なか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大磯',
    type: '町',
    kana: 'おおいそ',
    typeKana: 'まち',
    compartmentCode: '237',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14342',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '中',
      type: '郡',
      kana: 'なか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '二宮',
    type: '町',
    kana: 'にのみや',
    typeKana: 'まち',
    compartmentCode: '237',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14361',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄上',
      type: '郡',
      kana: 'あしがらかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中井',
    type: '町',
    kana: 'なかい',
    typeKana: 'まち',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14362',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄上',
      type: '郡',
      kana: 'あしがらかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大井',
    type: '町',
    kana: 'おおい',
    typeKana: 'まち',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14363',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄上',
      type: '郡',
      kana: 'あしがらかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松田',
    type: '町',
    kana: 'まつだ',
    typeKana: 'まち',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14364',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄上',
      type: '郡',
      kana: 'あしがらかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山北',
    type: '町',
    kana: 'やまきた',
    typeKana: 'まち',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14366',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄上',
      type: '郡',
      kana: 'あしがらかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '開成',
    type: '町',
    kana: 'かいせい',
    typeKana: 'まち',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14382',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄下',
      type: '郡',
      kana: 'あしがらしも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '箱根',
    type: '町',
    kana: 'はこね',
    typeKana: 'まち',
    compartmentCode: '235',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14383',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄下',
      type: '郡',
      kana: 'あしがらしも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '真鶴',
    type: '町',
    kana: 'まなづる',
    typeKana: 'まち',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14384',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '足柄下',
      type: '郡',
      kana: 'あしがらしも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湯河原',
    type: '町',
    kana: 'ゆがわら',
    typeKana: 'まち',
    compartmentCode: '238',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14401',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '愛甲',
      type: '郡',
      kana: 'あいこう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '愛川',
    type: '町',
    kana: 'あいかわ',
    typeKana: 'まち',
    compartmentCode: '236-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '14402',
    distinct: '1',
    pref: '神奈川県',
    county: {
      name: '愛甲',
      type: '郡',
      kana: 'あいこう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '清川',
    type: '村',
    kana: 'きよかわ',
    typeKana: 'むら',
    compartmentCode: '236-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15100',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '153',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15100',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '154',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15100',
    distinct: '3',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '162',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15101',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15102',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15103',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15104',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '江南',
    type: '区',
    kana: 'こうなん',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15105',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '秋葉',
    type: '区',
    kana: 'あきは',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '覚路津',
      scale: '',
    },
  },
  {
    code: '15105',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '秋葉',
    type: '区',
    kana: 'あきは',
    typeKana: 'く',
    compartmentCode: '154',
    zone: {
      name: '覚路津を除く。',
      scale: '',
    },
  },
  {
    code: '15106',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15107',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15108',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '西蒲',
    type: '区',
    kana: 'にしかん',
    typeKana: 'く',
    compartmentCode: '153',
    zone: {
      name: '打越、姥島、潟浦新、上小吉、高野宮、河間、五之上、小吉、道上、中之口、長場、羽黒、針ヶ曽根、東小吉、東中、東船越、福島、真木、巻大原、牧ケ島、三ツ門、門田及び六部に限る。',
      scale: '',
    },
  },
  {
    code: '15108',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '新潟',
      type: '市',
      kana: 'にいがた',
      typeKana: 'し',
      note: '',
    },
    name: '西蒲',
    type: '区',
    kana: 'にしかん',
    typeKana: 'く',
    compartmentCode: '162',
    zone: {
      name: '打越、姥島、潟浦新、上小吉、高野宮、河間、五之上、小吉、道上、中之口、長場、羽黒、針ヶ曽根、東小吉、東中、東船越、福島、真木、巻大原、牧ケ島、三ツ門、門田及び六部を除く。',
      scale: '',
    },
  },
  {
    code: '15202',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長岡',
    type: '市',
    kana: 'ながおか',
    typeKana: 'し',
    compartmentCode: '162',
    zone: {
      name: '赤沼、大沼新田、小沼新田、下沼新田、寺泊小豆曽根、寺泊有信、寺泊入軽井、寺泊岩方、寺泊木島、寺泊北曽根、寺泊五分一、寺泊下桐、寺泊下中条、寺泊新長、寺泊高内、寺泊竹森、寺泊田尻、寺泊敦ケ曽根、寺泊当新田、寺泊中曽根、寺泊硲田、寺泊万善寺、寺泊平野新村新田、寺泊蛇塚、寺泊町軽井、寺泊求草、寺泊矢田、寺泊鰐口、中条新田、中之島西野及び真野代新田に限る。',
      scale: '',
    },
  },
  {
    code: '15202',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長岡',
    type: '市',
    kana: 'ながおか',
    typeKana: 'し',
    compartmentCode: '168',
    zone: {
      name: '赤沼、大沼新田、小沼新田、下沼新田、寺泊小豆曽根、寺泊有信、寺泊入軽井、寺泊岩方、寺泊木島、寺泊北曽根、寺泊五分一、寺泊下桐、寺泊下中条、寺泊新長、寺泊高内、寺泊竹森、寺泊田尻、寺泊敦ケ曽根、寺泊当新田、寺泊中曽根、寺泊硲田、寺泊万善寺、寺泊平野新村新田、寺泊蛇塚、寺泊町軽井、寺泊求草、寺泊矢田、寺泊鰐口、中条新田、中之島西野及び真野代新田を除く。',
      scale: '',
    },
  },
  {
    code: '15204',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三条',
    type: '市',
    kana: 'さんじょう',
    typeKana: 'し',
    compartmentCode: '163',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15205',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '柏崎',
    type: '市',
    kana: 'かしわざき',
    typeKana: 'し',
    compartmentCode: '165',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15206',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新発田',
    type: '市',
    kana: 'しばた',
    typeKana: 'し',
    compartmentCode: '155',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15208',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小千谷',
    type: '市',
    kana: 'おぢや',
    typeKana: 'し',
    compartmentCode: '168',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15209',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加茂',
    type: '市',
    kana: 'かも',
    typeKana: 'し',
    compartmentCode: '163',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15210',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '十日町',
    type: '市',
    kana: 'とおかまち',
    typeKana: 'し',
    compartmentCode: '161',
    zone: {
      name: '会沢、莇平、荒瀬、池尻、池之畑、犬伏、浦田、苧島、海老、片桐山、蒲生、儀明、桐山、木和田原、小荒戸、小池、小屋丸、清水、菅刈、仙納、太平、滝沢、田野倉、千年、寺田、峠、中子、名平、奈良立、福島、松代、松代下山、松代田沢、松代東山、松之山、松之山赤倉、松之山天水越、松之山天水島、松之山新山、松之山五十子平、松之山猪之名、松之山大荒戸、松之山上鰕池、松之山観音寺、松之山黒倉、松之山小谷、松之山坂下、松之山沢口、松之山下鰕池、松之山坪野、松之山藤内名、松之山中尾、松之山橋詰、松之山東川、松之山東山、松之山光間、松之山藤倉、松之山古戸、松之山松口、松之山三桶、松之山水梨、松之山湯本、松之山湯山、松山新田、室野、孟地及び蓬平に限る。',
      scale: '',
    },
  },
  {
    code: '15210',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '十日町',
    type: '市',
    kana: 'とおかまち',
    typeKana: 'し',
    compartmentCode: '164',
    zone: {
      name: '会沢、莇平、荒瀬、池尻、池之畑、犬伏、浦田、苧島、海老、片桐山、蒲生、儀明、桐山、木和田原、小荒戸、小池、小屋丸、清水、菅刈、仙納、太平、滝沢、田野倉、千年、寺田、峠、中子、名平、奈良立、福島、松代、松代下山、松代田沢、松代東山、松之山、松之山赤倉、松之山天水越、松之山天水島、松之山新山、松之山五十子平、松之山猪之名、松之山大荒戸、松之山上鰕池、松之山観音寺、松之山黒倉、松之山小谷、松之山坂下、松之山沢口、松之山下鰕池、松之山坪野、松之山藤内名、松之山中尾、松之山橋詰、松之山東川、松之山東山、松之山光間、松之山藤倉、松之山古戸、松之山松口、松之山三桶、松之山水梨、松之山湯本、松之山湯山、松山新田、室野、孟地及び蓬平を除く。',
      scale: '',
    },
  },
  {
    code: '15211',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '見附',
    type: '市',
    kana: 'みつけ',
    typeKana: 'し',
    compartmentCode: '168',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15212',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '村上',
    type: '市',
    kana: 'むらかみ',
    typeKana: 'し',
    compartmentCode: '156',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15213',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '燕',
    type: '市',
    kana: 'つばめ',
    typeKana: 'し',
    compartmentCode: '162',
    zone: {
      name: '秋葉町、井土巻、大関、大船渡、大曲、上児木、勘新、蔵関、小池、小池新町、穀町、小関、小高、寿町、小中川、小古津新、小牧、幸町、桜町、佐渡、三王渕、下児木、新栄町、新生町、新町、水道町、杉名、杉柳、関崎、杣木、舘野、中央通、長所、長所乙、長所甲、次新、燕、道金、殿島、中川、仲町、長渡、二階堂、灰方、白山町、八王寺、花園町、花見、東太田、日之出町、物流センタ－、本町、又新、松橋、南、宮町、柳山、横田及び四ツ屋を除く。',
      scale: '',
    },
  },
  {
    code: '15213',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '燕',
    type: '市',
    kana: 'つばめ',
    typeKana: 'し',
    compartmentCode: '163',
    zone: {
      name: '秋葉町、井土巻、大関、大船渡、大曲、上児木、勘新、蔵関、小池、小池新町、穀町、小関、小高、寿町、小中川、小古津新、小牧、幸町、桜町、佐渡、三王渕、下児木、新栄町、新生町、新町、水道町、杉名、杉柳、関崎、杣木、舘野、中央通、長所、長所乙、長所甲、次新、燕、道金、殿島、中川、仲町、長渡、二階堂、灰方、白山町、八王寺、花園町、花見、東太田、日之出町、物流センタ－、本町、又新、松橋、南、宮町、柳山、横田及び四ツ屋に限る。',
      scale: '',
    },
  },
  {
    code: '15216',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '糸魚川',
    type: '市',
    kana: 'いといがわ',
    typeKana: 'し',
    compartmentCode: '160',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15217',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '妙高',
    type: '市',
    kana: 'みょうこう',
    typeKana: 'し',
    compartmentCode: '159',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15218',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '五泉',
    type: '市',
    kana: 'ごせん',
    typeKana: 'し',
    compartmentCode: '154',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15222',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上越',
    type: '市',
    kana: 'じょうえつ',
    typeKana: 'し',
    compartmentCode: '158',
    zone: {
      name: '板倉区、浦川原区、大島区、中郷区及び安塚区を除く。',
      scale: '',
    },
  },
  {
    code: '15222',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上越',
    type: '市',
    kana: 'じょうえつ',
    typeKana: 'し',
    compartmentCode: '159',
    zone: {
      name: '板倉区及び中郷区に限る。',
      scale: '',
    },
  },
  {
    code: '15222',
    distinct: '3',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上越',
    type: '市',
    kana: 'じょうえつ',
    typeKana: 'し',
    compartmentCode: '161',
    zone: {
      name: '浦川原区、大島区及び安塚区に限る。',
      scale: '',
    },
  },
  {
    code: '15223',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '阿賀野',
    type: '市',
    kana: 'あがの',
    typeKana: 'し',
    compartmentCode: '154',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15224',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐渡',
    type: '市',
    kana: 'さど',
    typeKana: 'し',
    compartmentCode: '169-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15225',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '魚沼',
    type: '市',
    kana: 'うおぬま',
    typeKana: 'し',
    compartmentCode: '167',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15226',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南魚沼',
    type: '市',
    kana: 'みなみうおぬま',
    typeKana: 'し',
    compartmentCode: '166',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15227',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '胎内',
    type: '市',
    kana: 'たいない',
    typeKana: 'し',
    compartmentCode: '155',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15307',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '北蒲原',
      type: '郡',
      kana: 'きたかんばら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '聖籠',
    type: '町',
    kana: 'せいろう',
    typeKana: 'まち',
    compartmentCode: '153',
    zone: {
      name: '位守町、亀塚、東港及び別條に限る。',
      scale: '',
    },
  },
  {
    code: '15307',
    distinct: '2',
    pref: '新潟県',
    county: {
      name: '北蒲原',
      type: '郡',
      kana: 'きたかんばら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '聖籠',
    type: '町',
    kana: 'せいろう',
    typeKana: 'まち',
    compartmentCode: '155',
    zone: {
      name: '位守町、亀塚、東港及び別條を除く｡',
      scale: '',
    },
  },
  {
    code: '15342',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '西蒲原',
      type: '郡',
      kana: 'にしかんばら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '弥彦',
    type: '村',
    kana: 'やひこ',
    typeKana: 'むら',
    compartmentCode: '162',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15361',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '南蒲原',
      type: '郡',
      kana: 'みなみかんばら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田上',
    type: '町',
    kana: 'たがみ',
    typeKana: 'まち',
    compartmentCode: '163',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15385',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '東蒲原',
      type: '郡',
      kana: 'ひがしかんばら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '阿賀',
    type: '町',
    kana: 'あが',
    typeKana: 'まち',
    compartmentCode: '157',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15405',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '三島',
      type: '郡',
      kana: 'さんとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '出雲崎',
    type: '町',
    kana: 'いずもざき',
    typeKana: 'まち',
    compartmentCode: '168',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15461',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '南魚沼',
      type: '郡',
      kana: 'みなみうおぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湯沢',
    type: '町',
    kana: 'ゆざわ',
    typeKana: 'まち',
    compartmentCode: '166',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15482',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '中魚沼',
      type: '郡',
      kana: 'なかうおぬま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '津南',
    type: '町',
    kana: 'つなん',
    typeKana: 'まち',
    compartmentCode: '164',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15504',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '刈羽',
      type: '郡',
      kana: 'かりわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '刈羽',
    type: '村',
    kana: 'かりわ',
    typeKana: 'むら',
    compartmentCode: '165',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15581',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '岩船',
      type: '郡',
      kana: 'いわふね',
      typeKana: 'ぐん',
      note: '',
    },
    name: '関川',
    type: '村',
    kana: 'せきかわ',
    typeKana: 'むら',
    compartmentCode: '156',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '15586',
    distinct: '1',
    pref: '新潟県',
    county: {
      name: '岩船',
      type: '郡',
      kana: 'いわふね',
      typeKana: 'ぐん',
      note: '',
    },
    name: '粟島浦',
    type: '村',
    kana: 'あわしまうら',
    typeKana: 'むら',
    compartmentCode: '156',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16201',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富山',
    type: '市',
    kana: 'とやま',
    typeKana: 'し',
    compartmentCode: '407-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16202',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高岡',
    type: '市',
    kana: 'たかおか',
    typeKana: 'し',
    compartmentCode: '409',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16204',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '魚津',
    type: '市',
    kana: 'うおづ',
    typeKana: 'し',
    compartmentCode: '408',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16205',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '氷見',
    type: '市',
    kana: 'ひみ',
    typeKana: 'し',
    compartmentCode: '409',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16206',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '滑川',
    type: '市',
    kana: 'なめりかわ',
    typeKana: 'し',
    compartmentCode: '407-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16207',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '黒部',
    type: '市',
    kana: 'くろべ',
    typeKana: 'し',
    compartmentCode: '408',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16208',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '砺波',
    type: '市',
    kana: 'となみ',
    typeKana: 'し',
    compartmentCode: '406',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16209',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小矢部',
    type: '市',
    kana: 'おやべ',
    typeKana: 'し',
    compartmentCode: '409',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16210',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南砺',
    type: '市',
    kana: 'なんと',
    typeKana: 'し',
    compartmentCode: '406',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16211',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '射水',
    type: '市',
    kana: 'いみず',
    typeKana: 'し',
    compartmentCode: '409',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16321',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '中新川',
      type: '郡',
      kana: 'なかにいかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '舟橋',
    type: '村',
    kana: 'ふなはし',
    typeKana: 'むら',
    compartmentCode: '407-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16322',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '中新川',
      type: '郡',
      kana: 'なかにいかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上市',
    type: '町',
    kana: 'かみいち',
    typeKana: 'まち',
    compartmentCode: '407-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16323',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '中新川',
      type: '郡',
      kana: 'なかにいかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '立山',
    type: '町',
    kana: 'たてやま',
    typeKana: 'まち',
    compartmentCode: '407-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16342',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '下新川',
      type: '郡',
      kana: 'しもにいかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '入善',
    type: '町',
    kana: 'にゅうぜん',
    typeKana: 'まち',
    compartmentCode: '408',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '16343',
    distinct: '1',
    pref: '富山県',
    county: {
      name: '下新川',
      type: '郡',
      kana: 'しもにいかわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '朝日',
    type: '町',
    kana: 'あさひ',
    typeKana: 'まち',
    compartmentCode: '408',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17201',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '金沢',
    type: '市',
    kana: 'かなざわ',
    typeKana: 'し',
    compartmentCode: '405-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17202',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '七尾',
    type: '市',
    kana: 'ななお',
    typeKana: 'し',
    compartmentCode: '410',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17203',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小松',
    type: '市',
    kana: 'こまつ',
    typeKana: 'し',
    compartmentCode: '402',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17204',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '輪島',
    type: '市',
    kana: 'わじま',
    typeKana: 'し',
    compartmentCode: '412',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17205',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '珠洲',
    type: '市',
    kana: 'すず',
    typeKana: 'し',
    compartmentCode: '413',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17206',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加賀',
    type: '市',
    kana: 'かが',
    typeKana: 'し',
    compartmentCode: '403-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17207',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '羽咋',
    type: '市',
    kana: 'はくい',
    typeKana: 'し',
    compartmentCode: '411',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17209',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'かほく',
    type: '市',
    kana: 'かほく',
    typeKana: 'し',
    compartmentCode: '405-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17210',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '白山',
    type: '市',
    kana: 'はくさん',
    typeKana: 'し',
    compartmentCode: '405-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17211',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '能美',
    type: '市',
    kana: 'のみ',
    typeKana: 'し',
    compartmentCode: '402',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17212',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '野々市',
    type: '市',
    kana: 'ののいち',
    typeKana: 'し',
    compartmentCode: '405-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17324',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '能美',
      type: '郡',
      kana: 'のみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川北',
    type: '町',
    kana: 'かわきた',
    typeKana: 'まち',
    compartmentCode: '405-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17361',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '河北',
      type: '郡',
      kana: 'かほく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '津幡',
    type: '町',
    kana: 'つばた',
    typeKana: 'まち',
    compartmentCode: '405-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17365',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '河北',
      type: '郡',
      kana: 'かほく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '内灘',
    type: '町',
    kana: 'うちなだ',
    typeKana: 'まち',
    compartmentCode: '405-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17384',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '羽咋',
      type: '郡',
      kana: 'はくい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '志賀',
    type: '町',
    kana: 'しか',
    typeKana: 'まち',
    compartmentCode: '411',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17386',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '羽咋',
      type: '郡',
      kana: 'はくい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宝達志水',
    type: '町',
    kana: 'ほうだつしみず',
    typeKana: 'ちょう',
    compartmentCode: '411',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17407',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '鹿島',
      type: '郡',
      kana: 'かしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中能登',
    type: '町',
    kana: 'なかのと',
    typeKana: 'まち',
    compartmentCode: '410',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17461',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '鳳珠',
      type: '郡',
      kana: 'ほうす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '穴水',
    type: '町',
    kana: 'あなみず',
    typeKana: 'まち',
    compartmentCode: '412',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '17463',
    distinct: '1',
    pref: '石川県',
    county: {
      name: '鳳珠',
      type: '郡',
      kana: 'ほうす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '能登',
    type: '町',
    kana: 'のと',
    typeKana: 'ちょう',
    compartmentCode: '413',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18201',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福井',
    type: '市',
    kana: 'ふくい',
    typeKana: 'し',
    compartmentCode: '424-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18202',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '敦賀',
    type: '市',
    kana: 'つるが',
    typeKana: 'し',
    compartmentCode: '414',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18204',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小浜',
    type: '市',
    kana: 'おばま',
    typeKana: 'し',
    compartmentCode: '415',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18205',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大野',
    type: '市',
    kana: 'おおの',
    typeKana: 'し',
    compartmentCode: '426',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18206',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '勝山',
    type: '市',
    kana: 'かつやま',
    typeKana: 'し',
    compartmentCode: '426',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18207',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鯖江',
    type: '市',
    kana: 'さばえ',
    typeKana: 'し',
    compartmentCode: '425',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18208',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'あわら',
    type: '市',
    kana: 'あわら',
    typeKana: 'し',
    compartmentCode: '424-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18209',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '越前',
    type: '市',
    kana: 'えちぜん',
    typeKana: 'し',
    compartmentCode: '425',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18210',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '坂井',
    type: '市',
    kana: 'さかい',
    typeKana: 'し',
    compartmentCode: '424-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18322',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '吉田',
      type: '郡',
      kana: 'よしだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '永平寺',
    type: '町',
    kana: 'えいへいじ',
    typeKana: 'ちょう',
    compartmentCode: '424-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18382',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '今立',
      type: '郡',
      kana: 'いまだて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '池田',
    type: '町',
    kana: 'いけだ',
    typeKana: 'ちょう',
    compartmentCode: '425',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18404',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '南条',
      type: '郡',
      kana: 'なんじょう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南越前',
    type: '町',
    kana: 'みなみえちぜん',
    typeKana: 'ちょう',
    compartmentCode: '425',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18423',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '丹生',
      type: '郡',
      kana: 'にゅう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '越前',
    type: '町',
    kana: 'えちぜん',
    typeKana: 'ちょう',
    compartmentCode: '425',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18442',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '三方',
      type: '郡',
      kana: 'みかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美浜',
    type: '町',
    kana: 'みはま',
    typeKana: 'ちょう',
    compartmentCode: '414',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18481',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '大飯',
      type: '郡',
      kana: 'おおい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高浜',
    type: '町',
    kana: 'たかはま',
    typeKana: 'ちょう',
    compartmentCode: '415',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18483',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '大飯',
      type: '郡',
      kana: 'おおい',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'おおい',
    type: '町',
    kana: 'おおい',
    typeKana: 'ちょう',
    compartmentCode: '415',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '18501',
    distinct: '1',
    pref: '福井県',
    county: {
      name: '三方上中',
      type: '郡',
      kana: 'みかたかみなか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '若狭',
    type: '町',
    kana: 'わかさ',
    typeKana: 'ちょう',
    compartmentCode: '414',
    zone: {
      name: '相田、生倉、井崎、岩屋、上野、海山、上瀬、小川、北前川、気山、倉見、佐古、島の内、塩坂越、成願寺、白屋、世久見、田井、田上、館川、田名、中央、常神、鳥浜、成出、能登野、東黒田、藤井、三方、神子、南前川、向笠、遊子及び横渡に限る。',
      scale: '',
    },
  },
  {
    code: '18501',
    distinct: '2',
    pref: '福井県',
    county: {
      name: '三方上中',
      type: '郡',
      kana: 'みかたかみなか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '若狭',
    type: '町',
    kana: 'わかさ',
    typeKana: 'ちょう',
    compartmentCode: '415',
    zone: {
      name: '相田、生倉、井崎、岩屋、上野、海山、上瀬、小川、北前川、気山、倉見、佐古、島の内、塩坂越、成願寺、白屋、世久見、田井、田上、館川、田名、中央、常神、鳥浜、成出、能登野、東黒田、藤井、三方、神子、南前川、向笠、遊子及び横渡を除く。',
      scale: '',
    },
  },
  {
    code: '19201',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '甲府',
    type: '市',
    kana: 'こうふ',
    typeKana: 'し',
    compartmentCode: '291',
    zone: {
      name: '梯町及び古関町を除く。',
      scale: '',
    },
  },
  {
    code: '19201',
    distinct: '2',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '甲府',
    type: '市',
    kana: 'こうふ',
    typeKana: 'し',
    compartmentCode: '294',
    zone: {
      name: '梯町及び古関町に限る。',
      scale: '',
    },
  },
  {
    code: '19202',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富士吉田',
    type: '市',
    kana: 'ふじよしだ',
    typeKana: 'し',
    compartmentCode: '294',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19204',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '都留',
    type: '市',
    kana: 'つる',
    typeKana: 'し',
    compartmentCode: '293',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19205',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山梨',
    type: '市',
    kana: 'やまなし',
    typeKana: 'し',
    compartmentCode: '292',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19206',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大月',
    type: '市',
    kana: 'おおつき',
    typeKana: 'し',
    compartmentCode: '293',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19207',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '韮崎',
    type: '市',
    kana: 'にらさき',
    typeKana: 'し',
    compartmentCode: '290',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19208',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南アルプス',
    type: '市',
    kana: 'みなみあるぷす',
    typeKana: 'し',
    compartmentCode: '291',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19209',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北杜',
    type: '市',
    kana: 'ほくと',
    typeKana: 'し',
    compartmentCode: '290',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19210',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '甲斐',
    type: '市',
    kana: 'かい',
    typeKana: 'し',
    compartmentCode: '290',
    zone: {
      name: '岩森、宇津谷、大垈、志田、下今井、菖蒲澤、團子新居及び龍地に限る。',
      scale: '',
    },
  },
  {
    code: '19210',
    distinct: '2',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '甲斐',
    type: '市',
    kana: 'かい',
    typeKana: 'し',
    compartmentCode: '291',
    zone: {
      name: '岩森、宇津谷、大垈、志田、下今井、菖蒲澤、團子新居及び龍地を除く。',
      scale: '',
    },
  },
  {
    code: '19211',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '笛吹',
    type: '市',
    kana: 'ふえふき',
    typeKana: 'し',
    compartmentCode: '291',
    zone: {
      name: '一宮町及び春日居町を除く。',
      scale: '',
    },
  },
  {
    code: '19211',
    distinct: '2',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '笛吹',
    type: '市',
    kana: 'ふえふき',
    typeKana: 'し',
    compartmentCode: '292',
    zone: {
      name: '一宮町及び春日居町に限る。',
      scale: '',
    },
  },
  {
    code: '19212',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上野原',
    type: '市',
    kana: 'うえのはら',
    typeKana: 'し',
    compartmentCode: '293',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19213',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '甲州',
    type: '市',
    kana: 'こうしゅう',
    typeKana: 'し',
    compartmentCode: '292',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19214',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中央',
    type: '市',
    kana: 'ちゅうおう',
    typeKana: 'し',
    compartmentCode: '291',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19346',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '西八代',
      type: '郡',
      kana: 'にしやつしろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '市川三郷',
    type: '町',
    kana: 'いちかわみさと',
    typeKana: 'ちょう',
    compartmentCode: '291',
    zone: {
      name: '岩下、岩間、落居、鴨狩津向、楠甫、五八、葛籠沢、寺所及び宮原を除く。',
      scale: '',
    },
  },
  {
    code: '19346',
    distinct: '2',
    pref: '山梨県',
    county: {
      name: '西八代',
      type: '郡',
      kana: 'にしやつしろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '市川三郷',
    type: '町',
    kana: 'いちかわみさと',
    typeKana: 'ちょう',
    compartmentCode: '295',
    zone: {
      name: '岩下、岩間、落居、鴨狩津向、楠甫、五八、葛籠沢、寺所及び宮原に限る。',
      scale: '',
    },
  },
  {
    code: '19364',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南巨摩',
      type: '郡',
      kana: 'みなみこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '早川',
    type: '町',
    kana: 'はやかわ',
    typeKana: 'ちょう',
    compartmentCode: '295',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19365',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南巨摩',
      type: '郡',
      kana: 'みなみこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '身延',
    type: '町',
    kana: 'みのぶ',
    typeKana: 'ちょう',
    compartmentCode: '295',
    zone: {
      name: '相又、粟倉、梅平、大崩、大島、大城、大垈、大野、小田船原、帯金、門野、上八木沢、下八木沢、下山、清子、角打、椿草里、波木井、樋之上、丸滝、光子沢、身延、横根中及び和田を除く。',
      scale: '',
    },
  },
  {
    code: '19365',
    distinct: '2',
    pref: '山梨県',
    county: {
      name: '南巨摩',
      type: '郡',
      kana: 'みなみこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '身延',
    type: '町',
    kana: 'みのぶ',
    typeKana: 'ちょう',
    compartmentCode: '296',
    zone: {
      name: '相又、粟倉、梅平、大崩、大島、大城、大垈、大野、小田船原、帯金、門野、上八木沢、下八木沢、下山、清子、角打、椿草里、波木井、樋之上、丸滝、光子沢、身延、横根中及び和田に限る。',
      scale: '',
    },
  },
  {
    code: '19366',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南巨摩',
      type: '郡',
      kana: 'みなみこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南部',
    type: '町',
    kana: 'なんぶ',
    typeKana: 'ちょう',
    compartmentCode: '296',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19368',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南巨摩',
      type: '郡',
      kana: 'みなみこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '富士川',
    type: '町',
    kana: 'ふじかわ',
    typeKana: 'ちょう',
    compartmentCode: '295',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19384',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '中巨摩',
      type: '郡',
      kana: 'なかこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '昭和',
    type: '町',
    kana: 'しょうわ',
    typeKana: 'ちょう',
    compartmentCode: '291',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19422',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南都留',
      type: '郡',
      kana: 'みなみつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '道志',
    type: '村',
    kana: 'どうし',
    typeKana: 'むら',
    compartmentCode: '293',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19423',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南都留',
      type: '郡',
      kana: 'みなみつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西桂',
    type: '町',
    kana: 'にしかつら',
    typeKana: 'ちょう',
    compartmentCode: '294',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19424',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南都留',
      type: '郡',
      kana: 'みなみつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '忍野',
    type: '村',
    kana: 'おしの',
    typeKana: 'むら',
    compartmentCode: '294',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19425',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南都留',
      type: '郡',
      kana: 'みなみつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山中湖',
    type: '村',
    kana: 'やまなかこ',
    typeKana: 'むら',
    compartmentCode: '294',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19429',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南都留',
      type: '郡',
      kana: 'みなみつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鳴沢',
    type: '村',
    kana: 'なるさわ',
    typeKana: 'むら',
    compartmentCode: '294',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19430',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '南都留',
      type: '郡',
      kana: 'みなみつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '富士河口湖',
    type: '町',
    kana: 'ふじかわぐちこ',
    typeKana: 'まち',
    compartmentCode: '294',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19442',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '北都留',
      type: '郡',
      kana: 'きたつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小菅',
    type: '村',
    kana: 'こすげ',
    typeKana: 'むら',
    compartmentCode: '226',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '19443',
    distinct: '1',
    pref: '山梨県',
    county: {
      name: '北都留',
      type: '郡',
      kana: 'きたつる',
      typeKana: 'ぐん',
      note: '',
    },
    name: '丹波山',
    type: '村',
    kana: 'たばやま',
    typeKana: 'むら',
    compartmentCode: '226',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20201',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長野',
    type: '市',
    kana: 'ながの',
    typeKana: 'し',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20202',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松本',
    type: '市',
    kana: 'まつもと',
    typeKana: 'し',
    compartmentCode: '174',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20203',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上田',
    type: '市',
    kana: 'うえだ',
    typeKana: 'し',
    compartmentCode: '181',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20204',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岡谷',
    type: '市',
    kana: 'おかや',
    typeKana: 'し',
    compartmentCode: '178',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20205',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '飯田',
    type: '市',
    kana: 'いいだ',
    typeKana: 'し',
    compartmentCode: '171',
    zone: {
      name: '上村、南信濃木沢、南信濃南和田、南信濃八重河内及び南信濃和田に限る。',
      scale: '',
    },
  },
  {
    code: '20205',
    distinct: '2',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '飯田',
    type: '市',
    kana: 'いいだ',
    typeKana: 'し',
    compartmentCode: '177',
    zone: {
      name: '上村、南信濃木沢、南信濃南和田、南信濃八重河内及び南信濃和田を除く。',
      scale: '',
    },
  },
  {
    code: '20206',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '諏訪',
    type: '市',
    kana: 'すわ',
    typeKana: 'し',
    compartmentCode: '178',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20207',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '須坂',
    type: '市',
    kana: 'すざか',
    typeKana: 'し',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20208',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小諸',
    type: '市',
    kana: 'こもろ',
    typeKana: 'し',
    compartmentCode: '179',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20209',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊那',
    type: '市',
    kana: 'いな',
    typeKana: 'し',
    compartmentCode: '176',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20210',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '駒ヶ根',
    type: '市',
    kana: 'こまがね',
    typeKana: 'し',
    compartmentCode: '176',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20211',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中野',
    type: '市',
    kana: 'なかの',
    typeKana: 'し',
    compartmentCode: '182',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20212',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大町',
    type: '市',
    kana: 'おおまち',
    typeKana: 'し',
    compartmentCode: '172',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20213',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '飯山',
    type: '市',
    kana: 'いいやま',
    typeKana: 'し',
    compartmentCode: '183',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20214',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '茅野',
    type: '市',
    kana: 'ちの',
    typeKana: 'し',
    compartmentCode: '178',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20215',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '塩尻',
    type: '市',
    kana: 'しおじり',
    typeKana: 'し',
    compartmentCode: '174',
    zone: {
      name: '木曽平沢、奈良井及び贄川を除く。',
      scale: '',
    },
  },
  {
    code: '20215',
    distinct: '2',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '塩尻',
    type: '市',
    kana: 'しおじり',
    typeKana: 'し',
    compartmentCode: '175',
    zone: {
      name: '木曽平沢、奈良井及び贄川に限る。',
      scale: '',
    },
  },
  {
    code: '20217',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐久',
    type: '市',
    kana: 'さく',
    typeKana: 'し',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20218',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '千曲',
    type: '市',
    kana: 'ちくま',
    typeKana: 'し',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20219',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東御',
    type: '市',
    kana: 'とうみ',
    typeKana: 'し',
    compartmentCode: '181',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20220',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '安曇野',
    type: '市',
    kana: 'あづみの',
    typeKana: 'し',
    compartmentCode: '174',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20303',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '南佐久',
      type: '郡',
      kana: 'みなみさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小海',
    type: '町',
    kana: 'こうみ',
    typeKana: 'まち',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20304',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '南佐久',
      type: '郡',
      kana: 'みなみさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川上',
    type: '村',
    kana: 'かわかみ',
    typeKana: 'むら',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20305',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '南佐久',
      type: '郡',
      kana: 'みなみさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南牧',
    type: '村',
    kana: 'みなみまき',
    typeKana: 'むら',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20306',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '南佐久',
      type: '郡',
      kana: 'みなみさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南相木',
    type: '村',
    kana: 'みなみあいき',
    typeKana: 'むら',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20307',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '南佐久',
      type: '郡',
      kana: 'みなみさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北相木',
    type: '村',
    kana: 'きたあいき',
    typeKana: 'むら',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20309',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '南佐久',
      type: '郡',
      kana: 'みなみさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '佐久穂',
    type: '町',
    kana: 'さくほ',
    typeKana: 'まち',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20321',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '北佐久',
      type: '郡',
      kana: 'きたさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '軽井沢',
    type: '町',
    kana: 'かるいざわ',
    typeKana: 'まち',
    compartmentCode: '179',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20323',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '北佐久',
      type: '郡',
      kana: 'きたさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '御代田',
    type: '町',
    kana: 'みよた',
    typeKana: 'まち',
    compartmentCode: '179',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20324',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '北佐久',
      type: '郡',
      kana: 'きたさく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '立科',
    type: '町',
    kana: 'たてしな',
    typeKana: 'まち',
    compartmentCode: '180',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20349',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '小県',
      type: '郡',
      kana: 'ちいさがた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '青木',
    type: '村',
    kana: 'あおき',
    typeKana: 'むら',
    compartmentCode: '181',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20350',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '小県',
      type: '郡',
      kana: 'ちいさがた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長和',
    type: '町',
    kana: 'ながわ',
    typeKana: 'まち',
    compartmentCode: '181',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20361',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '諏訪',
      type: '郡',
      kana: 'すわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '下諏訪',
    type: '町',
    kana: 'しもすわ',
    typeKana: 'まち',
    compartmentCode: '178',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20362',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '諏訪',
      type: '郡',
      kana: 'すわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '富士見',
    type: '町',
    kana: 'ふじみ',
    typeKana: 'まち',
    compartmentCode: '178',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20363',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '諏訪',
      type: '郡',
      kana: 'すわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '原',
    type: '村',
    kana: 'はら',
    typeKana: 'むら',
    compartmentCode: '178',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20382',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上伊那',
      type: '郡',
      kana: 'かみいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '辰野',
    type: '町',
    kana: 'たつの',
    typeKana: 'まち',
    compartmentCode: '178',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20383',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上伊那',
      type: '郡',
      kana: 'かみいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '箕輪',
    type: '町',
    kana: 'みのわ',
    typeKana: 'まち',
    compartmentCode: '176',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20384',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上伊那',
      type: '郡',
      kana: 'かみいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '飯島',
    type: '町',
    kana: 'いいじま',
    typeKana: 'まち',
    compartmentCode: '176',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20385',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上伊那',
      type: '郡',
      kana: 'かみいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南箕輪',
    type: '村',
    kana: 'みなみみのわ',
    typeKana: 'むら',
    compartmentCode: '176',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20386',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上伊那',
      type: '郡',
      kana: 'かみいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中川',
    type: '村',
    kana: 'なかがわ',
    typeKana: 'むら',
    compartmentCode: '176',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20388',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上伊那',
      type: '郡',
      kana: 'かみいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宮田',
    type: '村',
    kana: 'みやだ',
    typeKana: 'むら',
    compartmentCode: '176',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20402',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松川',
    type: '町',
    kana: 'まつかわ',
    typeKana: 'まち',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20403',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高森',
    type: '町',
    kana: 'たかもり',
    typeKana: 'まち',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20404',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '阿南',
    type: '町',
    kana: 'あなん',
    typeKana: 'ちょう',
    compartmentCode: '171',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20407',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '阿智',
    type: '村',
    kana: 'あち',
    typeKana: 'むら',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20409',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '平谷',
    type: '村',
    kana: 'ひらや',
    typeKana: 'むら',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20410',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '根羽',
    type: '村',
    kana: 'ねば',
    typeKana: 'むら',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20411',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '下條',
    type: '村',
    kana: 'しもじょう',
    typeKana: 'むら',
    compartmentCode: '171',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20412',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '売木',
    type: '村',
    kana: 'うるぎ',
    typeKana: 'むら',
    compartmentCode: '171',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20413',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '天龍',
    type: '村',
    kana: 'てんりゅう',
    typeKana: 'むら',
    compartmentCode: '171',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20414',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '泰阜',
    type: '村',
    kana: 'やすおか',
    typeKana: 'むら',
    compartmentCode: '171',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20415',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '喬木',
    type: '村',
    kana: 'たかぎ',
    typeKana: 'むら',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20416',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '豊丘',
    type: '村',
    kana: 'とよおか',
    typeKana: 'むら',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20417',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下伊那',
      type: '郡',
      kana: 'しもいな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大鹿',
    type: '村',
    kana: 'おおしか',
    typeKana: 'むら',
    compartmentCode: '177',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20422',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '木曽',
      type: '郡',
      kana: 'きそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上松',
    type: '町',
    kana: 'あげまつ',
    typeKana: 'まち',
    compartmentCode: '175',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20423',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '木曽',
      type: '郡',
      kana: 'きそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南木曽',
    type: '町',
    kana: 'なぎそ',
    typeKana: 'まち',
    compartmentCode: '175',
    zone: {
      name: '田立を除く。',
      scale: '',
    },
  },
  {
    code: '20423',
    distinct: '2',
    pref: '長野県',
    county: {
      name: '木曽',
      type: '郡',
      kana: 'きそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南木曽',
    type: '町',
    kana: 'なぎそ',
    typeKana: 'まち',
    compartmentCode: '316',
    zone: {
      name: '田立',
      scale: '',
    },
  },
  {
    code: '20425',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '木曽',
      type: '郡',
      kana: 'きそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '木祖',
    type: '村',
    kana: 'きそ',
    typeKana: 'むら',
    compartmentCode: '175',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20429',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '木曽',
      type: '郡',
      kana: 'きそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '王滝',
    type: '村',
    kana: 'おうたき',
    typeKana: 'むら',
    compartmentCode: '175',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20430',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '木曽',
      type: '郡',
      kana: 'きそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大桑',
    type: '村',
    kana: 'おおくわ',
    typeKana: 'むら',
    compartmentCode: '175',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20432',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '木曽',
      type: '郡',
      kana: 'きそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '木曽',
    type: '町',
    kana: 'きそ',
    typeKana: 'まち',
    compartmentCode: '175',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20446',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '東筑摩',
      type: '郡',
      kana: 'ひがしちくま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '麻績',
    type: '村',
    kana: 'おみ',
    typeKana: 'むら',
    compartmentCode: '174',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20448',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '東筑摩',
      type: '郡',
      kana: 'ひがしちくま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '生坂',
    type: '村',
    kana: 'いくさか',
    typeKana: 'むら',
    compartmentCode: '174',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20450',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '東筑摩',
      type: '郡',
      kana: 'ひがしちくま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山形',
    type: '村',
    kana: 'やまがた',
    typeKana: 'むら',
    compartmentCode: '174',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20451',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '東筑摩',
      type: '郡',
      kana: 'ひがしちくま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '朝日',
    type: '村',
    kana: 'あさひ',
    typeKana: 'むら',
    compartmentCode: '174',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20452',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '東筑摩',
      type: '郡',
      kana: 'ひがしちくま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '筑北',
    type: '村',
    kana: 'ちくほく',
    typeKana: 'むら',
    compartmentCode: '174',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20481',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '北安曇',
      type: '郡',
      kana: 'きたあづみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '池田',
    type: '町',
    kana: 'いけだ',
    typeKana: 'まち',
    compartmentCode: '172',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20482',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '北安曇',
      type: '郡',
      kana: 'きたあづみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松川',
    type: '村',
    kana: 'まつかわ',
    typeKana: 'むら',
    compartmentCode: '172',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20485',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '北安曇',
      type: '郡',
      kana: 'きたあづみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白馬',
    type: '村',
    kana: 'はくば',
    typeKana: 'むら',
    compartmentCode: '172',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20486',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '北安曇',
      type: '郡',
      kana: 'きたあづみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小谷',
    type: '村',
    kana: 'おたり',
    typeKana: 'むら',
    compartmentCode: '172',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20521',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '埴科',
      type: '郡',
      kana: 'はにしな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '坂城',
    type: '町',
    kana: 'さかき',
    typeKana: 'まち',
    compartmentCode: '181',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20541',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上高井',
      type: '郡',
      kana: 'かみたかい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小布施',
    type: '町',
    kana: 'おぶせ',
    typeKana: 'まち',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20543',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上高井',
      type: '郡',
      kana: 'かみたかい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高山',
    type: '村',
    kana: 'たかやま',
    typeKana: 'むら',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20561',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下高井',
      type: '郡',
      kana: 'しもたかい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山ノ内',
    type: '町',
    kana: 'やまのうち',
    typeKana: 'まち',
    compartmentCode: '182',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20562',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下高井',
      type: '郡',
      kana: 'しもたかい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '木島平',
    type: '村',
    kana: 'きじまだいら',
    typeKana: 'むら',
    compartmentCode: '183',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20563',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下高井',
      type: '郡',
      kana: 'しもたかい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '野沢温泉',
    type: '村',
    kana: 'のざわおんせん',
    typeKana: 'むら',
    compartmentCode: '183',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20583',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上水内',
      type: '郡',
      kana: 'かみみのち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '信濃',
    type: '町',
    kana: 'しなの',
    typeKana: 'まち',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20588',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上水内',
      type: '郡',
      kana: 'かみみのち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小川',
    type: '村',
    kana: 'おがわ',
    typeKana: 'むら',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20590',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '上水内',
      type: '郡',
      kana: 'かみみのち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '飯綱',
    type: '町',
    kana: 'いいづな',
    typeKana: 'まち',
    compartmentCode: '173',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '20602',
    distinct: '1',
    pref: '長野県',
    county: {
      name: '下水内',
      type: '郡',
      kana: 'しもみのち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '栄',
    type: '村',
    kana: 'さかえ',
    typeKana: 'むら',
    compartmentCode: '183',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21201',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岐阜',
    type: '市',
    kana: 'ぎふ',
    typeKana: 'し',
    compartmentCode: '325-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21202',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大垣',
    type: '市',
    kana: 'おおがき',
    typeKana: 'し',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21203',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高山',
    type: '市',
    kana: 'たかやま',
    typeKana: 'し',
    compartmentCode: '322',
    zone: {
      name: '荘川町',
      scale: '',
    },
  },
  {
    code: '21203',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高山',
    type: '市',
    kana: 'たかやま',
    typeKana: 'し',
    compartmentCode: '323',
    zone: {
      name: '奥飛騨温泉郷、上宝町及び荘川町を除く。',
      scale: '',
    },
  },
  {
    code: '21203',
    distinct: '3',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高山',
    type: '市',
    kana: 'たかやま',
    typeKana: 'し',
    compartmentCode: '324',
    zone: {
      name: '奥飛騨温泉郷及び上宝町に限る。',
      scale: '',
    },
  },
  {
    code: '21204',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '多治見',
    type: '市',
    kana: 'たじみ',
    typeKana: 'し',
    compartmentCode: '314',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21205',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '関',
    type: '市',
    kana: 'せき',
    typeKana: 'し',
    compartmentCode: '319',
    zone: {
      name: '板取、洞戸阿部、洞戸市場、洞戸大野、洞戸小瀬見、洞戸尾倉、洞戸片、洞戸栗原、洞戸黒谷、洞戸高賀、洞戸高見、洞戸小坂、洞戸菅谷、洞戸通元寺及び洞戸飛瀬を除く。',
      scale: '',
    },
  },
  {
    code: '21205',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '関',
    type: '市',
    kana: 'せき',
    typeKana: 'し',
    compartmentCode: '326-3',
    zone: {
      name: '板取、洞戸阿部、洞戸市場、洞戸大野、洞戸小瀬見、洞戸尾倉、洞戸片、洞戸栗原、洞戸黒谷、洞戸高賀、洞戸高見、洞戸小坂、洞戸菅谷、洞戸通元寺及び洞戸飛瀬に限る。',
      scale: '',
    },
  },
  {
    code: '21206',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中津川',
    type: '市',
    kana: 'なかつがわ',
    typeKana: 'し',
    compartmentCode: '315',
    zone: {
      name: '蛭川',
      scale: '',
    },
  },
  {
    code: '21206',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中津川',
    type: '市',
    kana: 'なかつがわ',
    typeKana: 'し',
    compartmentCode: '316',
    zone: {
      name: '蛭川を除く。',
      scale: '',
    },
  },
  {
    code: '21207',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '美濃',
    type: '市',
    kana: 'みの',
    typeKana: 'し',
    compartmentCode: '319',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21208',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '瑞浪',
    type: '市',
    kana: 'みずなみ',
    typeKana: 'し',
    compartmentCode: '314',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21209',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '羽島',
    type: '市',
    kana: 'はしま',
    typeKana: 'し',
    compartmentCode: '325-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21210',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '恵那',
    type: '市',
    kana: 'えな',
    typeKana: 'し',
    compartmentCode: '314',
    zone: {
      name: '明智町吉良見',
      scale: '',
    },
  },
  {
    code: '21210',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '恵那',
    type: '市',
    kana: 'えな',
    typeKana: 'し',
    compartmentCode: '315',
    zone: {
      name: '明智町吉良見を除く。',
      scale: '',
    },
  },
  {
    code: '21211',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '美濃加茂',
    type: '市',
    kana: 'みのかも',
    typeKana: 'し',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21212',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '土岐',
    type: '市',
    kana: 'とき',
    typeKana: 'し',
    compartmentCode: '314',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21213',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '各務原',
    type: '市',
    kana: 'かかみがはら',
    typeKana: 'し',
    compartmentCode: '325-2',
    zone: {
      name: '川島笠田町、川島北山町、川島小網町、川島河田町、川島竹早町、川島松倉町、川島松原町、川島緑町及び川島渡町を除く。',
      scale: '',
    },
  },
  {
    code: '21213',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '各務原',
    type: '市',
    kana: 'かかみがはら',
    typeKana: 'し',
    compartmentCode: '334-2',
    zone: {
      name: '川島笠田町、川島北山町、川島小網町、川島河田町、川島竹早町、川島松倉町、川島松原町、川島緑町及び川島渡町に限る。',
      scale: '',
    },
  },
  {
    code: '21214',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '可児',
    type: '市',
    kana: 'かに',
    typeKana: 'し',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21215',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山県',
    type: '市',
    kana: 'やまがた',
    typeKana: 'し',
    compartmentCode: '326-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21216',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '瑞穂',
    type: '市',
    kana: 'みずほ',
    typeKana: 'し',
    compartmentCode: '325-2',
    zone: {
      name: '呂久を除く｡',
      scale: '',
    },
  },
  {
    code: '21216',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '瑞穂',
    type: '市',
    kana: 'みずほ',
    typeKana: 'し',
    compartmentCode: '332',
    zone: {
      name: '呂久',
      scale: '',
    },
  },
  {
    code: '21217',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '飛騨',
    type: '市',
    kana: 'ひだ',
    typeKana: 'し',
    compartmentCode: '323',
    zone: {
      name: '神岡町を除く。',
      scale: '',
    },
  },
  {
    code: '21217',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '飛騨',
    type: '市',
    kana: 'ひだ',
    typeKana: 'し',
    compartmentCode: '324',
    zone: {
      name: '神岡町',
      scale: '',
    },
  },
  {
    code: '21218',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '本巣',
    type: '市',
    kana: 'もとす',
    typeKana: 'し',
    compartmentCode: '325-2',
    zone: {
      name: '浅木、海老、上真桑、軽海、小柿、国領、下福島、下真桑、十四条、宗慶、温井、政田、有里、石神、石原、数屋、上高屋、上保、北野、郡府、七五三、随原、長屋、早野、春近、仏生寺、三橋、見延及び屋井に限る｡',
      scale: '',
    },
  },
  {
    code: '21218',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '本巣',
    type: '市',
    kana: 'もとす',
    typeKana: 'し',
    compartmentCode: '326-3',
    zone: {
      name: '浅木、海老、上真桑、軽海、小柿、国領、下福島、下真桑、十四条、宗慶、温井、政田、有里、石神、石原、数屋、上高屋、上保、北野、郡府、七五三、随原、長屋、早野、春近、仏生寺、三橋、見延及び屋井を除く｡',
      scale: '',
    },
  },
  {
    code: '21219',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '郡上',
    type: '市',
    kana: 'ぐじょう',
    typeKana: 'し',
    compartmentCode: '320',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21220',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '下呂',
    type: '市',
    kana: 'げろ',
    typeKana: 'し',
    compartmentCode: '321',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21221',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '海津',
    type: '市',
    kana: 'かいづ',
    typeKana: 'し',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21302',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '羽島',
      type: '郡',
      kana: 'はしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '岐南',
    type: '町',
    kana: 'ぎなん',
    typeKana: 'ちょう',
    compartmentCode: '325-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21303',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '羽島',
      type: '郡',
      kana: 'はしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '笠松',
    type: '町',
    kana: 'かさまつ',
    typeKana: 'ちょう',
    compartmentCode: '325-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21341',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '養老',
      type: '郡',
      kana: 'ようろう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '養老',
    type: '町',
    kana: 'ようろう',
    typeKana: 'ちょう',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21361',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '不破',
      type: '郡',
      kana: 'ふわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '垂井',
    type: '町',
    kana: 'たるい',
    typeKana: 'ちょう',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21362',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '不破',
      type: '郡',
      kana: 'ふわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '関ケ原',
    type: '町',
    kana: 'せきがはら',
    typeKana: 'ちょう',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21381',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '安八',
      type: '郡',
      kana: 'あんぱち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神戸',
    type: '町',
    kana: 'ごうど',
    typeKana: 'ちょう',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21382',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '安八',
      type: '郡',
      kana: 'あんぱち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '輪之内',
    type: '町',
    kana: 'わのうち',
    typeKana: 'ちょう',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21383',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '安八',
      type: '郡',
      kana: 'あんぱち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '安八',
    type: '町',
    kana: 'あんぱち',
    typeKana: 'ちょう',
    compartmentCode: '332',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21401',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '揖斐',
      type: '郡',
      kana: 'いび',
      typeKana: 'ぐん',
      note: '',
    },
    name: '揖斐川',
    type: '町',
    kana: 'いびがわ',
    typeKana: 'ちょう',
    compartmentCode: '326-3',
    zone: {
      name: '徳山',
      scale: '',
    },
  },
  {
    code: '21401',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '揖斐',
      type: '郡',
      kana: 'いび',
      typeKana: 'ぐん',
      note: '',
    },
    name: '揖斐川',
    type: '町',
    kana: 'いびがわ',
    typeKana: 'ちょう',
    compartmentCode: '333',
    zone: {
      name: '徳山を除く。',
      scale: '',
    },
  },
  {
    code: '21403',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '揖斐',
      type: '郡',
      kana: 'いび',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大野',
    type: '町',
    kana: 'おおの',
    typeKana: 'ちょう',
    compartmentCode: '333',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21404',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '揖斐',
      type: '郡',
      kana: 'いび',
      typeKana: 'ぐん',
      note: '',
    },
    name: '池田',
    type: '町',
    kana: 'いけだ',
    typeKana: 'ちょう',
    compartmentCode: '332',
    zone: {
      name: '市橋',
      scale: '',
    },
  },
  {
    code: '21404',
    distinct: '2',
    pref: '岐阜県',
    county: {
      name: '揖斐',
      type: '郡',
      kana: 'いび',
      typeKana: 'ぐん',
      note: '',
    },
    name: '池田',
    type: '町',
    kana: 'いけだ',
    typeKana: 'ちょう',
    compartmentCode: '333',
    zone: {
      name: '市橋を除く。',
      scale: '',
    },
  },
  {
    code: '21421',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '本巣',
      type: '郡',
      kana: 'もとす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北方',
    type: '町',
    kana: 'きたがた',
    typeKana: 'ちょう',
    compartmentCode: '325-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21501',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '加茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '坂祝',
    type: '町',
    kana: 'さかほぎ',
    typeKana: 'ちょう',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21502',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '加茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '富加',
    type: '町',
    kana: 'とみか',
    typeKana: 'ちょう',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21503',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '加茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川辺',
    type: '町',
    kana: 'かわべ',
    typeKana: 'ちょう',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21504',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '加茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '七宗',
    type: '町',
    kana: 'ひちそう',
    typeKana: 'ちょう',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21505',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '加茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八百津',
    type: '町',
    kana: 'やおつ',
    typeKana: 'ちょう',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21506',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '加茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白川',
    type: '町',
    kana: 'しらかわ',
    typeKana: 'ちょう',
    compartmentCode: '318',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21507',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '加茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東白川',
    type: '村',
    kana: 'ひがししらかわ',
    typeKana: 'むら',
    compartmentCode: '318',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21521',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '可児',
      type: '郡',
      kana: 'かに',
      typeKana: 'ぐん',
      note: '',
    },
    name: '御嵩',
    type: '町',
    kana: 'みたけ',
    typeKana: 'ちょう',
    compartmentCode: '317',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '21604',
    distinct: '1',
    pref: '岐阜県',
    county: {
      name: '大野',
      type: '郡',
      kana: 'おおの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白川',
    type: '村',
    kana: 'しらかわ',
    typeKana: 'むら',
    compartmentCode: '322',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22100',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '静岡',
      type: '市',
      kana: 'しずおか',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '283-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22101',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '静岡',
      type: '市',
      kana: 'しずおか',
      typeKana: 'し',
      note: '',
    },
    name: '葵',
    type: '区',
    kana: 'あおい',
    typeKana: 'く',
    compartmentCode: '283-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22102',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '静岡',
      type: '市',
      kana: 'しずおか',
      typeKana: 'し',
      note: '',
    },
    name: '駿河',
    type: '区',
    kana: 'するが',
    typeKana: 'く',
    compartmentCode: '283-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22103',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '静岡',
      type: '市',
      kana: 'しずおか',
      typeKana: 'し',
      note: '',
    },
    name: '清水',
    type: '区',
    kana: 'しみず',
    typeKana: 'く',
    compartmentCode: '283-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22130',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22131',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '中',
    type: '区',
    kana: 'なか',
    typeKana: 'く',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22132',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22133',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22134',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22135',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22136',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '浜北',
    type: '区',
    kana: 'はまきた',
    typeKana: 'く',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22137',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '浜松',
      type: '市',
      kana: 'はままつ',
      typeKana: 'し',
      note: '',
    },
    name: '天竜',
    type: '区',
    kana: 'てんりゅう',
    typeKana: 'く',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22203',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '沼津',
    type: '市',
    kana: 'ぬまづ',
    typeKana: 'し',
    compartmentCode: '298',
    zone: {
      name: '井田及び戸田に限る。',
      scale: '',
    },
  },
  {
    code: '22203',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '沼津',
    type: '市',
    kana: 'ぬまづ',
    typeKana: 'し',
    compartmentCode: '300',
    zone: {
      name: '井田及び戸田を除く。',
      scale: '',
    },
  },
  {
    code: '22205',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '熱海',
    type: '市',
    kana: 'あたみ',
    typeKana: 'し',
    compartmentCode: '238',
    zone: {
      name: '泉元宮上分、泉元宮下分及び泉元門川分に限る。',
      scale: '',
    },
  },
  {
    code: '22205',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '熱海',
    type: '市',
    kana: 'あたみ',
    typeKana: 'し',
    compartmentCode: '297',
    zone: {
      name: '泉元宮上分、泉元宮下分及び泉元門川分を除く。',
      scale: '',
    },
  },
  {
    code: '22206',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三島',
    type: '市',
    kana: 'みしま',
    typeKana: 'し',
    compartmentCode: '300',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22207',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富士宮',
    type: '市',
    kana: 'ふじのみや',
    typeKana: 'し',
    compartmentCode: '285',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22208',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊東',
    type: '市',
    kana: 'いとう',
    typeKana: 'し',
    compartmentCode: '297',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22209',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '島田',
    type: '市',
    kana: 'しまだ',
    typeKana: 'し',
    compartmentCode: '287',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22210',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富士',
    type: '市',
    kana: 'ふじ',
    typeKana: 'し',
    compartmentCode: '286',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22211',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '磐田',
    type: '市',
    kana: 'いわた',
    typeKana: 'し',
    compartmentCode: '281',
    zone: {
      name: '家田、壱貫地、岩室、大平、大当所、掛下、上神増、上野部、神増、合代島、敷地、下神増、下野部、新開、惣兵衛下新田、平松、松之木島、万瀬、三家、虫生及び社山を除く。',
      scale: '',
    },
  },
  {
    code: '22211',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '磐田',
    type: '市',
    kana: 'いわた',
    typeKana: 'し',
    compartmentCode: '282',
    zone: {
      name: '家田、壱貫地、岩室、大平、大当所、掛下、上神増、上野部、神増、合代島、敷地、下神増、下野部、新開、惣兵衛下新田、平松、松之木島、万瀬、三家、虫生及び社山に限る。',
      scale: '',
    },
  },
  {
    code: '22212',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '焼津',
    type: '市',
    kana: 'やいづ',
    typeKana: 'し',
    compartmentCode: '283-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22213',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '掛川',
    type: '市',
    kana: 'かけがわ',
    typeKana: 'し',
    compartmentCode: '280',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22214',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '藤枝',
    type: '市',
    kana: 'ふじえだ',
    typeKana: 'し',
    compartmentCode: '283-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22215',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '御殿場',
    type: '市',
    kana: 'ごてんば',
    typeKana: 'し',
    compartmentCode: '289',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22216',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '袋井',
    type: '市',
    kana: 'ふくろい',
    typeKana: 'し',
    compartmentCode: '281',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22219',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '下田',
    type: '市',
    kana: 'しもだ',
    typeKana: 'し',
    compartmentCode: '299',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22220',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '裾野',
    type: '市',
    kana: 'すその',
    typeKana: 'し',
    compartmentCode: '235',
    zone: {
      name: '茶畑',
      scale: '',
    },
  },
  {
    code: '22220',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '裾野',
    type: '市',
    kana: 'すその',
    typeKana: 'し',
    compartmentCode: '300',
    zone: {
      name: '茶畑を除く。',
      scale: '',
    },
  },
  {
    code: '22221',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '湖西',
    type: '市',
    kana: 'こさい',
    typeKana: 'し',
    compartmentCode: '268',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22222',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊豆',
    type: '市',
    kana: 'いず',
    typeKana: 'し',
    compartmentCode: '297',
    zone: {
      name: '冷川',
      scale: '',
    },
  },
  {
    code: '22222',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊豆',
    type: '市',
    kana: 'いず',
    typeKana: 'し',
    compartmentCode: '298',
    zone: {
      name: '冷川を除く。',
      scale: '',
    },
  },
  {
    code: '22223',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '御前崎',
    type: '市',
    kana: 'おまえざき',
    typeKana: 'し',
    compartmentCode: '280',
    zone: {
      name: '御前崎、白羽及び港を除く。',
      scale: '',
    },
  },
  {
    code: '22223',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '御前崎',
    type: '市',
    kana: 'おまえざき',
    typeKana: 'し',
    compartmentCode: '288',
    zone: {
      name: '御前崎、白羽及び港に限る。',
      scale: '',
    },
  },
  {
    code: '22224',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '菊川',
    type: '市',
    kana: 'きくがわ',
    typeKana: 'し',
    compartmentCode: '280',
    zone: {
      name: '牛渕、倉沢、小沢及び沢水加を除く。',
      scale: '',
    },
  },
  {
    code: '22224',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '菊川',
    type: '市',
    kana: 'きくがわ',
    typeKana: 'し',
    compartmentCode: '288',
    zone: {
      name: '牛渕、倉沢、小沢及び沢水加に限る。',
      scale: '',
    },
  },
  {
    code: '22225',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊豆の国',
    type: '市',
    kana: 'いずのくに',
    typeKana: 'し',
    compartmentCode: '298',
    zone: {
      name: '浮橋、大仁、神島、下畑、白山堂、宗光寺、田京、立花、田中山、田原野、長者原、中島、御門、三福、守木及び吉田に限る。',
      scale: '',
    },
  },
  {
    code: '22225',
    distinct: '2',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊豆の国',
    type: '市',
    kana: 'いずのくに',
    typeKana: 'し',
    compartmentCode: '300',
    zone: {
      name: '浮橋、大仁、神島、下畑、白山堂、宗光寺、田京、立花、田中山、田原野、長者原、中島、御門、三福、守木及び吉田を除く。',
      scale: '',
    },
  },
  {
    code: '22226',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '牧之原',
    type: '市',
    kana: 'まきのはら',
    typeKana: 'し',
    compartmentCode: '288',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22301',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '賀茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東伊豆',
    type: '町',
    kana: 'ひがしいず',
    typeKana: 'ちょう',
    compartmentCode: '297',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22302',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '賀茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '河津',
    type: '町',
    kana: 'かわづ',
    typeKana: 'ちょう',
    compartmentCode: '299',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22304',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '賀茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南伊豆',
    type: '町',
    kana: 'みなみいず',
    typeKana: 'ちょう',
    compartmentCode: '299',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22305',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '賀茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松崎',
    type: '町',
    kana: 'まつざき',
    typeKana: 'ちょう',
    compartmentCode: '299',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22306',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '賀茂',
      type: '郡',
      kana: 'かも',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西伊豆',
    type: '町',
    kana: 'にしいず',
    typeKana: 'ちょう',
    compartmentCode: '299',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22325',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '田方',
      type: '郡',
      kana: 'たがた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '函南',
    type: '町',
    kana: 'かんなみ',
    typeKana: 'ちょう',
    compartmentCode: '300',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22341',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '駿東',
      type: '郡',
      kana: 'すんとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '清水',
    type: '町',
    kana: 'しみず',
    typeKana: 'ちょう',
    compartmentCode: '300',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22342',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '駿東',
      type: '郡',
      kana: 'すんとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長泉',
    type: '町',
    kana: 'ながいずみ',
    typeKana: 'ちょう',
    compartmentCode: '300',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22344',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '駿東',
      type: '郡',
      kana: 'すんとう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小山',
    type: '町',
    kana: 'おやま',
    typeKana: 'ちょう',
    compartmentCode: '289',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22424',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '榛原',
      type: '郡',
      kana: 'はいばら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉田',
    type: '町',
    kana: 'よしだ',
    typeKana: 'ちょう',
    compartmentCode: '288',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22429',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '榛原',
      type: '郡',
      kana: 'はいばら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川根本',
    type: '町',
    kana: 'かわねほん',
    typeKana: 'ちょう',
    compartmentCode: '287',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '22461',
    distinct: '1',
    pref: '静岡県',
    county: {
      name: '周智',
      type: '郡',
      kana: 'しゅうち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '森',
    type: '町',
    kana: 'もり',
    typeKana: 'まち',
    compartmentCode: '281',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23100',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23101',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '千種',
    type: '区',
    kana: 'ちくさ',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23102',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23103',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23104',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23105',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '中村',
    type: '区',
    kana: 'なかむら',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23106',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '中',
    type: '区',
    kana: 'なか',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23107',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '昭和',
    type: '区',
    kana: 'しょうわ',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23108',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '瑞穂',
    type: '区',
    kana: 'みずほ',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23109',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '熱田',
    type: '区',
    kana: 'あつた',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23110',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '中川',
    type: '区',
    kana: 'なかがわ',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23111',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '港',
    type: '区',
    kana: 'みなと',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23112',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23113',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '守山',
    type: '区',
    kana: 'もりやま',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23114',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '緑',
    type: '区',
    kana: 'みどり',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23115',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '名東',
    type: '区',
    kana: 'めいとう',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23116',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '名古屋',
      type: '市',
      kana: 'なごや',
      typeKana: 'し',
      note: '',
    },
    name: '天白',
    type: '区',
    kana: 'てんぱく',
    typeKana: 'く',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23201',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊橋',
    type: '市',
    kana: 'とよはし',
    typeKana: 'し',
    compartmentCode: '272',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23202',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岡崎',
    type: '市',
    kana: 'おかざき',
    typeKana: 'し',
    compartmentCode: '306',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23203',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '一宮',
    type: '市',
    kana: 'いちのみや',
    typeKana: 'し',
    compartmentCode: '334-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23204',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '瀬戸',
    type: '市',
    kana: 'せと',
    typeKana: 'し',
    compartmentCode: '301-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23205',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '半田',
    type: '市',
    kana: 'はんだ',
    typeKana: 'し',
    compartmentCode: '313',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23206',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '春日井',
    type: '市',
    kana: 'かすがい',
    typeKana: 'し',
    compartmentCode: '312',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23207',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊川',
    type: '市',
    kana: 'とよかわ',
    typeKana: 'し',
    compartmentCode: '273',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23208',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '津島',
    type: '市',
    kana: 'つしま',
    typeKana: 'し',
    compartmentCode: '309-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23209',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '碧南',
    type: '市',
    kana: 'へきなん',
    typeKana: 'し',
    compartmentCode: '308',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23210',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '刈谷',
    type: '市',
    kana: 'かりや',
    typeKana: 'し',
    compartmentCode: '308',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23211',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊田',
    type: '市',
    kana: 'とよた',
    typeKana: 'し',
    compartmentCode: '307',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23212',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '安城',
    type: '市',
    kana: 'あんじょう',
    typeKana: 'し',
    compartmentCode: '308',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23213',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西尾',
    type: '市',
    kana: 'にしお',
    typeKana: 'し',
    compartmentCode: '305',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23214',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '蒲郡',
    type: '市',
    kana: 'がまごおり',
    typeKana: 'し',
    compartmentCode: '273',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23215',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '犬山',
    type: '市',
    kana: 'いぬやま',
    typeKana: 'し',
    compartmentCode: '312',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23216',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '常滑',
    type: '市',
    kana: 'とこなめ',
    typeKana: 'し',
    compartmentCode: '313',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23217',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '江南',
    type: '市',
    kana: 'こうなん',
    typeKana: 'し',
    compartmentCode: '336',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23219',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小牧',
    type: '市',
    kana: 'こまき',
    typeKana: 'し',
    compartmentCode: '312',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23220',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稲沢',
    type: '市',
    kana: 'いなざわ',
    typeKana: 'し',
    compartmentCode: '309-2',
    zone: {
      name: '平和町',
      scale: '',
    },
  },
  {
    code: '23220',
    distinct: '2',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稲沢',
    type: '市',
    kana: 'いなざわ',
    typeKana: 'し',
    compartmentCode: '334-2',
    zone: {
      name: '西島町、生出上山町、生出河戸町、生出郷前町、生出西道根町、生出東道根町、生出横西町及び横野町に限る。',
      scale: '',
    },
  },
  {
    code: '23220',
    distinct: '3',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '稲沢',
    type: '市',
    kana: 'いなざわ',
    typeKana: 'し',
    compartmentCode: '336',
    zone: {
      name: '西島町、生出上山町、生出河戸町、生出郷前町、生出西道根町、生出東道根町、生出横西町、平和町及び横野町を除く。',
      scale: '',
    },
  },
  {
    code: '23221',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新城',
    type: '市',
    kana: 'しんしろ',
    typeKana: 'し',
    compartmentCode: '274-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23222',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東海',
    type: '市',
    kana: 'とうかい',
    typeKana: 'し',
    compartmentCode: '267',
    zone: {
      name: '大田町、加木屋町、高横須賀町、元浜町、養父町、横須賀町、中ノ池及び中央町を除く。',
      scale: '',
    },
  },
  {
    code: '23222',
    distinct: '2',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東海',
    type: '市',
    kana: 'とうかい',
    typeKana: 'し',
    compartmentCode: '304',
    zone: {
      name: '大田町、加木屋町、高横須賀町、元浜町、養父町、横須賀町、中ノ池及び中央町に限る。',
      scale: '',
    },
  },
  {
    code: '23223',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大府',
    type: '市',
    kana: 'おおぶ',
    typeKana: 'し',
    compartmentCode: '304',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23224',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '知多',
    type: '市',
    kana: 'ちた',
    typeKana: 'し',
    compartmentCode: '304',
    zone: {
      name: '神田、新広見、旭南、金沢、新舞子及び南粕谷を除く。',
      scale: '',
    },
  },
  {
    code: '23224',
    distinct: '2',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '知多',
    type: '市',
    kana: 'ちた',
    typeKana: 'し',
    compartmentCode: '313',
    zone: {
      name: '神田、新広見、旭南、金沢、新舞子及び南粕谷に限る。',
      scale: '',
    },
  },
  {
    code: '23225',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '知立',
    type: '市',
    kana: 'ちりゅう',
    typeKana: 'し',
    compartmentCode: '308',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23226',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '尾張旭',
    type: '市',
    kana: 'おわりあさひ',
    typeKana: 'し',
    compartmentCode: '267',
    zone: {
      name: '霞ヶ丘町、庄南町、東名西町、西山町、東山町及び吉岡町に限る。',
      scale: '',
    },
  },
  {
    code: '23226',
    distinct: '2',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '尾張旭',
    type: '市',
    kana: 'おわりあさひ',
    typeKana: 'し',
    compartmentCode: '301-3',
    zone: {
      name: '霞ヶ丘町、庄南町、東名西町、西山町、東山町及び吉岡町を除く。',
      scale: '',
    },
  },
  {
    code: '23227',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高浜',
    type: '市',
    kana: 'たかはま',
    typeKana: 'し',
    compartmentCode: '308',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23228',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岩倉',
    type: '市',
    kana: 'いわくら',
    typeKana: 'し',
    compartmentCode: '336',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23229',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊明',
    type: '市',
    kana: 'とよあけ',
    typeKana: 'し',
    compartmentCode: '304',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23230',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日進',
    type: '市',
    kana: 'にっしん',
    typeKana: 'し',
    compartmentCode: '267',
    zone: {
      name: '赤池町、赤池、浅田町、浅田平子、梅森町及び香久山に限る。',
      scale: '',
    },
  },
  {
    code: '23230',
    distinct: '2',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日進',
    type: '市',
    kana: 'にっしん',
    typeKana: 'し',
    compartmentCode: '301-3',
    zone: {
      name: '赤池町、赤池、浅田町、浅田平子、梅森町及び香久山を除く。',
      scale: '',
    },
  },
  {
    code: '23231',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '田原',
    type: '市',
    kana: 'たはら',
    typeKana: 'し',
    compartmentCode: '269-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23232',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '愛西',
    type: '市',
    kana: 'あいさい',
    typeKana: 'し',
    compartmentCode: '309-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23233',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '清須',
    type: '市',
    kana: 'きよす',
    typeKana: 'し',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23234',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '北名古屋',
    type: '市',
    kana: 'きたなごや',
    typeKana: 'し',
    compartmentCode: '312',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23235',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '弥富',
    type: '市',
    kana: 'やとみ',
    typeKana: 'し',
    compartmentCode: '309-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23236',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'みよし',
    type: '市',
    kana: 'みよし',
    typeKana: 'し',
    compartmentCode: '301-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23237',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'あま',
    type: '市',
    kana: 'あま',
    typeKana: 'し',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23238',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長久手',
    type: '市',
    kana: 'ながくて',
    typeKana: 'し',
    compartmentCode: '301-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23302',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '愛知',
      type: '郡',
      kana: 'あいち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東郷',
    type: '町',
    kana: 'とうごう',
    typeKana: 'ちょう',
    compartmentCode: '301-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23342',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '西春日井',
      type: '郡',
      kana: 'にしかすがい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '豊山',
    type: '町',
    kana: 'とよやま',
    typeKana: 'ちょう',
    compartmentCode: '312',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23361',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '丹羽',
      type: '郡',
      kana: 'にわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大口',
    type: '町',
    kana: 'おおぐち',
    typeKana: 'ちょう',
    compartmentCode: '336',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23362',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '丹羽',
      type: '郡',
      kana: 'にわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '扶桑',
    type: '町',
    kana: 'ふそう',
    typeKana: 'ちょう',
    compartmentCode: '336',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23424',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '海部',
      type: '郡',
      kana: 'あま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大治',
    type: '町',
    kana: 'おおはる',
    typeKana: 'ちょう',
    compartmentCode: '267',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23425',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '海部',
      type: '郡',
      kana: 'あま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '蟹江',
    type: '町',
    kana: 'かにえ',
    typeKana: 'ちょう',
    compartmentCode: '309-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23427',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '海部',
      type: '郡',
      kana: 'あま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '飛島',
    type: '村',
    kana: 'とびしま',
    typeKana: 'むら',
    compartmentCode: '309-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23441',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '知多',
      type: '郡',
      kana: 'ちた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '阿久比',
    type: '町',
    kana: 'あぐい',
    typeKana: 'ちょう',
    compartmentCode: '313',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23442',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '知多',
      type: '郡',
      kana: 'ちた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東浦',
    type: '町',
    kana: 'ひがしうら',
    typeKana: 'ちょう',
    compartmentCode: '304',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23445',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '知多',
      type: '郡',
      kana: 'ちた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南知多',
    type: '町',
    kana: 'みなみちた',
    typeKana: 'ちょう',
    compartmentCode: '313',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23446',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '知多',
      type: '郡',
      kana: 'ちた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美浜',
    type: '町',
    kana: 'みはま',
    typeKana: 'ちょう',
    compartmentCode: '313',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23447',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '知多',
      type: '郡',
      kana: 'ちた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '武豊',
    type: '町',
    kana: 'たけとよ',
    typeKana: 'ちょう',
    compartmentCode: '313',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23501',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '額田',
      type: '郡',
      kana: 'ぬかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '幸田',
    type: '町',
    kana: 'こうた',
    typeKana: 'ちょう',
    compartmentCode: '306',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23561',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '北設楽',
      type: '郡',
      kana: 'きたしたら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '設楽',
    type: '町',
    kana: 'したら',
    typeKana: 'ちょう',
    compartmentCode: '276-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23562',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '北設楽',
      type: '郡',
      kana: 'きたしたら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東栄',
    type: '町',
    kana: 'とうえい',
    typeKana: 'ちょう',
    compartmentCode: '276-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '23563',
    distinct: '1',
    pref: '愛知県',
    county: {
      name: '北設楽',
      type: '郡',
      kana: 'きたしたら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '豊根',
    type: '村',
    kana: 'とよね',
    typeKana: 'むら',
    compartmentCode: '276-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24201',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '津',
    type: '市',
    kana: 'つ',
    typeKana: 'し',
    compartmentCode: '337',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24202',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '四日市',
    type: '市',
    kana: 'よっかいち',
    typeKana: 'し',
    compartmentCode: '338',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24203',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊勢',
    type: '市',
    kana: 'いせ',
    typeKana: 'し',
    compartmentCode: '343',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24204',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松阪',
    type: '市',
    kana: 'まつさか',
    typeKana: 'し',
    compartmentCode: '349-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24205',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '桑名',
    type: '市',
    kana: 'くわな',
    typeKana: 'し',
    compartmentCode: '339',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24207',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鈴鹿',
    type: '市',
    kana: 'すずか',
    typeKana: 'し',
    compartmentCode: '338',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24208',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '名張',
    type: '市',
    kana: 'なばり',
    typeKana: 'し',
    compartmentCode: '340',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24209',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '尾鷲',
    type: '市',
    kana: 'おわせ',
    typeKana: 'し',
    compartmentCode: '344-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24210',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '亀山',
    type: '市',
    kana: 'かめやま',
    typeKana: 'し',
    compartmentCode: '341-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24211',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鳥羽',
    type: '市',
    kana: 'とば',
    typeKana: 'し',
    compartmentCode: '357',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24212',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '熊野',
    type: '市',
    kana: 'くまの',
    typeKana: 'し',
    compartmentCode: '347',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24214',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'いなべ',
    type: '市',
    kana: 'いなべ',
    typeKana: 'し',
    compartmentCode: '339',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24215',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '志摩',
    type: '市',
    kana: 'しま',
    typeKana: 'し',
    compartmentCode: '358-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24216',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊賀',
    type: '市',
    kana: 'いが',
    typeKana: 'し',
    compartmentCode: '340',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24303',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '桑名',
      type: '郡',
      kana: 'くわな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '木曽岬',
    type: '町',
    kana: 'きそさき',
    typeKana: 'ちょう',
    compartmentCode: '309-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24324',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '員弁',
      type: '郡',
      kana: 'いなべ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東員',
    type: '町',
    kana: 'とういん',
    typeKana: 'ちょう',
    compartmentCode: '339',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24341',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '三重',
      type: '郡',
      kana: 'みえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '菰野',
    type: '町',
    kana: 'こもの',
    typeKana: 'ちょう',
    compartmentCode: '338',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24343',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '三重',
      type: '郡',
      kana: 'みえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '朝日',
    type: '町',
    kana: 'あさひ',
    typeKana: 'ちょう',
    compartmentCode: '338',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24344',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '三重',
      type: '郡',
      kana: 'みえ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川越',
    type: '町',
    kana: 'かわごえ',
    typeKana: 'ちょう',
    compartmentCode: '338',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24441',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '多気',
      type: '郡',
      kana: 'たき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '多気',
    type: '町',
    kana: 'たき',
    typeKana: 'ちょう',
    compartmentCode: '349-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24442',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '多気',
      type: '郡',
      kana: 'たき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '明和',
    type: '町',
    kana: 'めいわ',
    typeKana: 'ちょう',
    compartmentCode: '343',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24443',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '多気',
      type: '郡',
      kana: 'たき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大台',
    type: '町',
    kana: 'おおだい',
    typeKana: 'ちょう',
    compartmentCode: '355-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24461',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '度会',
      type: '郡',
      kana: 'わたらい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '玉城',
    type: '町',
    kana: 'たまき',
    typeKana: 'ちょう',
    compartmentCode: '343',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24470',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '度会',
      type: '郡',
      kana: 'わたらい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '度会',
    type: '町',
    kana: 'わたらい',
    typeKana: 'ちょう',
    compartmentCode: '343',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24471',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '度会',
      type: '郡',
      kana: 'わたらい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大紀',
    type: '町',
    kana: 'たいき',
    typeKana: 'ちょう',
    compartmentCode: '355-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24472',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '度会',
      type: '郡',
      kana: 'わたらい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南伊勢',
    type: '町',
    kana: 'みなみいせ',
    typeKana: 'ちょう',
    compartmentCode: '343',
    zone: {
      name: '阿曽浦、大江、大方竈 、小方竈、神前浦、河内、古和浦、新桑竈、慥柄浦、棚橋竈、東宮、栃木竈、奈屋浦、贄浦、方座浦、道方、道行竈及び村山に限る。',
      scale: '',
    },
  },
  {
    code: '24472',
    distinct: '2',
    pref: '三重県',
    county: {
      name: '度会',
      type: '郡',
      kana: 'わたらい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南伊勢',
    type: '町',
    kana: 'みなみいせ',
    typeKana: 'ちょう',
    compartmentCode: '358-2',
    zone: {
      name: '阿曽浦、大江、大方竈 、小方竈、神前浦、河内、古和浦、新桑竈、慥柄浦、棚橋竈、東宮、栃木竈、奈屋浦、贄浦、方座浦、道方、道行竈及び村山を除く。',
      scale: '',
    },
  },
  {
    code: '24543',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '北牟婁',
      type: '郡',
      kana: 'きたむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '紀北',
    type: '町',
    kana: 'きほく',
    typeKana: 'ちょう',
    compartmentCode: '344-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24561',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '南牟婁',
      type: '郡',
      kana: 'みなみむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '御浜',
    type: '町',
    kana: 'みはま',
    typeKana: 'ちょう',
    compartmentCode: '348',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '24562',
    distinct: '1',
    pref: '三重県',
    county: {
      name: '南牟婁',
      type: '郡',
      kana: 'みなみむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '紀宝',
    type: '町',
    kana: 'きほう',
    typeKana: 'ちょう',
    compartmentCode: '374',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25201',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大津',
    type: '市',
    kana: 'おおつ',
    typeKana: 'し',
    compartmentCode: '423',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25202',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '彦根',
    type: '市',
    kana: 'ひこね',
    typeKana: 'し',
    compartmentCode: '399',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25203',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長浜',
    type: '市',
    kana: 'ながはま',
    typeKana: 'し',
    compartmentCode: '400',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25204',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '近江八幡',
    type: '市',
    kana: 'おうみはちまん',
    typeKana: 'し',
    compartmentCode: '397',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25206',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '草津',
    type: '市',
    kana: 'くさつ',
    typeKana: 'し',
    compartmentCode: '423',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25207',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '守山',
    type: '市',
    kana: 'もりやま',
    typeKana: 'し',
    compartmentCode: '423',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25208',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '栗東',
    type: '市',
    kana: 'りっとう',
    typeKana: 'し',
    compartmentCode: '423',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25209',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '甲賀',
    type: '市',
    kana: 'こうか',
    typeKana: 'し',
    compartmentCode: '398',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25210',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '野洲',
    type: '市',
    kana: 'やす',
    typeKana: 'し',
    compartmentCode: '423',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25211',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '湖南',
    type: '市',
    kana: 'こなん',
    typeKana: 'し',
    compartmentCode: '398',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25212',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高島',
    type: '市',
    kana: 'たかしま',
    typeKana: 'し',
    compartmentCode: '381',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25213',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東近江',
    type: '市',
    kana: 'ひがしおうみ',
    typeKana: 'し',
    compartmentCode: '397',
    zone: {
      name: '愛東外町、青山町、池庄町、池之尻町、市ヶ原町、今在家町、妹町、上中野町、梅林町、大沢町、大清水町、大萩町、大林町、小倉町、長町、上岸本町、上山町、祇園町、北坂町、北清水町、北花沢町、北菩提寺町、小池町、小田苅町、小八木町、下一色町、下岸本町、下里町、下中野町、清水中町、勝堂町、僧坊町、曽根町、園町、大覚寺町、中一色町、中岸本町、中里町、中戸町、鯰江町、西菩提寺町、百済寺甲町、百済寺町、百済寺本町、平尾町、平松町、平柳町、南清水町、南花沢町、南菩提寺町、湯屋町、横溝町及び読合堂町を除く。',
      scale: '',
    },
  },
  {
    code: '25213',
    distinct: '2',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東近江',
    type: '市',
    kana: 'ひがしおうみ',
    typeKana: 'し',
    compartmentCode: '399',
    zone: {
      name: '愛東外町、青山町、池庄町、池之尻町、市ヶ原町、今在家町、妹町、上中野町、梅林町、大沢町、大清水町、大萩町、大林町、小倉町、長町、上岸本町、上山町、祇園町、北坂町、北清水町、北花沢町、北菩提寺町、小池町、小田苅町、小八木町、下一色町、下岸本町、下里町、下中野町、清水中町、勝堂町、僧坊町、曽根町、園町、大覚寺町、中一色町、中岸本町、中里町、中戸町、鯰江町、西菩提寺町、百済寺甲町、百済寺町、百済寺本町、平尾町、平松町、平柳町、南清水町、南花沢町、南菩提寺町、湯屋町、横溝町及び読合堂町に限る。',
      scale: '',
    },
  },
  {
    code: '25214',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '米原',
    type: '市',
    kana: 'まいばら',
    typeKana: 'し',
    compartmentCode: '400',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25383',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '蒲生',
      type: '郡',
      kana: 'がもう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日野',
    type: '町',
    kana: 'ひの',
    typeKana: 'ちょう',
    compartmentCode: '397',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25384',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '蒲生',
      type: '郡',
      kana: 'がもう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '竜王',
    type: '町',
    kana: 'りゅうおう',
    typeKana: 'ちょう',
    compartmentCode: '397',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25425',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '愛知',
      type: '郡',
      kana: 'えち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '愛荘',
    type: '町',
    kana: 'あいしょう',
    typeKana: 'ちょう',
    compartmentCode: '399',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25441',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '犬上',
      type: '郡',
      kana: 'いぬかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '豊郷',
    type: '町',
    kana: 'とよさと',
    typeKana: 'ちょう',
    compartmentCode: '399',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25442',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '犬上',
      type: '郡',
      kana: 'いぬかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '甲良',
    type: '町',
    kana: 'こうら',
    typeKana: 'ちょう',
    compartmentCode: '399',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '25443',
    distinct: '1',
    pref: '滋賀県',
    county: {
      name: '犬上',
      type: '郡',
      kana: 'いぬかみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '多賀',
    type: '町',
    kana: 'たが',
    typeKana: 'ちょう',
    compartmentCode: '399',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26100',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26100',
    distinct: '2',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '417',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26100',
    distinct: '3',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '423',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26101',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26102',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '上京',
    type: '区',
    kana: 'かみぎょう',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26103',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '左京',
    type: '区',
    kana: 'さきょう',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26104',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '中京',
    type: '区',
    kana: 'なかぎょう',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26105',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '東山',
    type: '区',
    kana: 'ひがしやま',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26106',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '下京',
    type: '区',
    kana: 'しもぎょう',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26107',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26108',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '右京',
    type: '区',
    kana: 'うきょう',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '京北室谷町を除く。',
      scale: '',
    },
  },
  {
    code: '26108',
    distinct: '2',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '右京',
    type: '区',
    kana: 'うきょう',
    typeKana: 'く',
    compartmentCode: '417',
    zone: {
      name: '京北室谷町',
      scale: '',
    },
  },
  {
    code: '26109',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '伏見',
    type: '区',
    kana: 'ふしみ',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '醍醐（一ノ切町、二ノ切町及び三ノ切町に限る。）を除く。',
      scale: '',
    },
  },
  {
    code: '26109',
    distinct: '2',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '伏見',
    type: '区',
    kana: 'ふしみ',
    typeKana: 'く',
    compartmentCode: '423',
    zone: {
      name: '醍醐（一ノ切町、二ノ切町及び三ノ切町に限る。）',
      scale: '',
    },
  },
  {
    code: '26110',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '山科',
    type: '区',
    kana: 'やましな',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26111',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '京都',
      type: '市',
      kana: 'きょうと',
      typeKana: 'し',
      note: '',
    },
    name: '西京',
    type: '区',
    kana: 'にしきょう',
    typeKana: 'く',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26201',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福知山',
    type: '市',
    kana: 'ふくちやま',
    typeKana: 'し',
    compartmentCode: '420',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26202',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '舞鶴',
    type: '市',
    kana: 'まいづる',
    typeKana: 'し',
    compartmentCode: '421',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26203',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '綾部',
    type: '市',
    kana: 'あやべ',
    typeKana: 'し',
    compartmentCode: '420',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26204',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇治',
    type: '市',
    kana: 'うじ',
    typeKana: 'し',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26205',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宮津',
    type: '市',
    kana: 'みやづ',
    typeKana: 'し',
    compartmentCode: '418',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26206',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '亀岡',
    type: '市',
    kana: 'かめおか',
    typeKana: 'し',
    compartmentCode: '416',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26207',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '城陽',
    type: '市',
    kana: 'じょうよう',
    typeKana: 'し',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26208',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '向日',
    type: '市',
    kana: 'むこう',
    typeKana: 'し',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26209',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長岡京',
    type: '市',
    kana: 'ながおかきょう',
    typeKana: 'し',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26210',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八幡',
    type: '市',
    kana: 'やわた',
    typeKana: 'し',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26211',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '京田辺',
    type: '市',
    kana: 'きょうたなべ',
    typeKana: 'し',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26212',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '京丹後',
    type: '市',
    kana: 'きょうたんご',
    typeKana: 'し',
    compartmentCode: '419',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26213',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南丹',
    type: '市',
    kana: 'なんたん',
    typeKana: 'し',
    compartmentCode: '416',
    zone: {
      name: '八木町',
      scale: '',
    },
  },
  {
    code: '26213',
    distinct: '2',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南丹',
    type: '市',
    kana: 'なんたん',
    typeKana: 'し',
    compartmentCode: '417',
    zone: {
      name: '八木町を除く。',
      scale: '',
    },
  },
  {
    code: '26214',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '木津川',
    type: '市',
    kana: 'きづがわ',
    typeKana: 'し',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26303',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '乙訓',
      type: '郡',
      kana: 'おとくに',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大山崎',
    type: '町',
    kana: 'おおやまざき',
    typeKana: 'ちょう',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26322',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '久世',
      type: '郡',
      kana: 'くせ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '久御山',
    type: '町',
    kana: 'くみやま',
    typeKana: 'ちょう',
    compartmentCode: '401',
    zone: {
      name: '市田、栄、佐古、佐山、下津屋、田井及び林を除く。',
      scale: '',
    },
  },
  {
    code: '26322',
    distinct: '2',
    pref: '京都府',
    county: {
      name: '久世',
      type: '郡',
      kana: 'くせ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '久御山',
    type: '町',
    kana: 'くみやま',
    typeKana: 'ちょう',
    compartmentCode: '422',
    zone: {
      name: '市田、栄、佐古、佐山、下津屋、田井及び林に限る。',
      scale: '',
    },
  },
  {
    code: '26343',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '綴喜',
      type: '郡',
      kana: 'つづき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '井手',
    type: '町',
    kana: 'いで',
    typeKana: 'ちょう',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26344',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '綴喜',
      type: '郡',
      kana: 'つづき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宇治田原',
    type: '町',
    kana: 'うじたわら',
    typeKana: 'ちょう',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26364',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '相楽',
      type: '郡',
      kana: 'そうらく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '笠置',
    type: '町',
    kana: 'かさぎ',
    typeKana: 'ちょう',
    compartmentCode: '383-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26365',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '相楽',
      type: '郡',
      kana: 'そうらく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '和束',
    type: '町',
    kana: 'わづか',
    typeKana: 'ちょう',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26366',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '相楽',
      type: '郡',
      kana: 'そうらく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '精華',
    type: '町',
    kana: 'せいか',
    typeKana: 'ちょう',
    compartmentCode: '422',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26367',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '相楽',
      type: '郡',
      kana: 'そうらく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南山城',
    type: '村',
    kana: 'みなみやましろ',
    typeKana: 'むら',
    compartmentCode: '383-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26407',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '船井',
      type: '郡',
      kana: 'ふない',
      typeKana: 'ぐん',
      note: '',
    },
    name: '京丹波',
    type: '町',
    kana: 'きょうたんば',
    typeKana: 'ちょう',
    compartmentCode: '417',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26463',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '与謝',
      type: '郡',
      kana: 'よさ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伊根',
    type: '町',
    kana: 'いね',
    typeKana: 'ちょう',
    compartmentCode: '418',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '26465',
    distinct: '1',
    pref: '京都府',
    county: {
      name: '与謝',
      type: '郡',
      kana: 'よさ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '与謝野',
    type: '町',
    kana: 'よさの',
    typeKana: 'ちょう',
    compartmentCode: '418',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27100',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27100',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27102',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '都島',
    type: '区',
    kana: 'みやこじま',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27103',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '福島',
    type: '区',
    kana: 'ふくしま',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27104',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '此花',
    type: '区',
    kana: 'このはな',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27106',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27107',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '港',
    type: '区',
    kana: 'みなと',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27108',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '大正',
    type: '区',
    kana: 'たいしょう',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27109',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '天王寺',
    type: '区',
    kana: 'てんのうじ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27111',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '浪速',
    type: '区',
    kana: 'なにわ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27113',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '西淀川',
    type: '区',
    kana: 'にしよどがわ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27114',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '東淀川',
    type: '区',
    kana: 'ひがしよどがわ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27115',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '東成',
    type: '区',
    kana: 'ひがしなり',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27116',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '生野',
    type: '区',
    kana: 'いくの',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27117',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '旭',
    type: '区',
    kana: 'あさひ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27118',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '城東',
    type: '区',
    kana: 'じょうとう',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27119',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '阿倍野',
    type: '区',
    kana: 'あべの',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27120',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '住吉',
    type: '区',
    kana: 'すみよし',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27121',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '東住吉',
    type: '区',
    kana: 'ひがしすみよし',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '東住吉区矢田七丁目及び平野区長吉川辺四丁目を除く。',
      scale: '',
    },
  },
  {
    code: '27121',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '東住吉',
    type: '区',
    kana: 'ひがしすみよし',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '東住吉区矢田七丁目及び平野区長吉川辺四丁目に限る。',
      scale: '',
    },
  },
  {
    code: '27122',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '西成',
    type: '区',
    kana: 'にしなり',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27123',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '淀川',
    type: '区',
    kana: 'よどがわ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27124',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '鶴見',
    type: '区',
    kana: 'つるみ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27125',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '住之江',
    type: '区',
    kana: 'すみのえ',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27126',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '平野',
    type: '区',
    kana: 'ひらの',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '東住吉区矢田七丁目及び平野区長吉川辺四丁目を除く。',
      scale: '',
    },
  },
  {
    code: '27126',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '平野',
    type: '区',
    kana: 'ひらの',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '東住吉区矢田七丁目及び平野区長吉川辺四丁目に限る。',
      scale: '',
    },
  },
  {
    code: '27127',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27128',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '大阪',
      type: '市',
      kana: 'おおさか',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27140',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27141',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '堺',
    type: '区',
    kana: 'さかい',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27142',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '中',
    type: '区',
    kana: 'なか',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27143',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27144',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27145',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27146',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27147',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '堺',
      type: '市',
      kana: 'さかい',
      typeKana: 'し',
      note: '',
    },
    name: '美原',
    type: '区',
    kana: 'みはら',
    typeKana: 'く',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27202',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岸和田',
    type: '市',
    kana: 'きしわだ',
    typeKana: 'し',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27203',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊中',
    type: '市',
    kana: 'とよなか',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27204',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '池田',
    type: '市',
    kana: 'いけだ',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '空港',
      scale: '',
    },
  },
  {
    code: '27204',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '池田',
    type: '市',
    kana: 'いけだ',
    typeKana: 'し',
    compartmentCode: '371',
    zone: {
      name: '空港を除く。',
      scale: '',
    },
  },
  {
    code: '27205',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '吹田',
    type: '市',
    kana: 'すいた',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27206',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '泉大津',
    type: '市',
    kana: 'いずみおおつ',
    typeKana: 'し',
    compartmentCode: '369',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27207',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高槻',
    type: '市',
    kana: 'たかつき',
    typeKana: 'し',
    compartmentCode: '370',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27208',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '貝塚',
    type: '市',
    kana: 'かいづか',
    typeKana: 'し',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27209',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '守口',
    type: '市',
    kana: 'もりぐち',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27210',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '枚方',
    type: '市',
    kana: 'ひらかた',
    typeKana: 'し',
    compartmentCode: '364',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27211',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '茨木',
    type: '市',
    kana: 'いばらき',
    typeKana: 'し',
    compartmentCode: '370',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27212',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八尾',
    type: '市',
    kana: 'やお',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '竹渕、竹渕西及び竹渕東に限る。',
      scale: '',
    },
  },
  {
    code: '27212',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八尾',
    type: '市',
    kana: 'やお',
    typeKana: 'し',
    compartmentCode: '372',
    zone: {
      name: '竹渕、竹渕西及び竹渕東を除く。',
      scale: '',
    },
  },
  {
    code: '27213',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '泉佐野',
    type: '市',
    kana: 'いずみさの',
    typeKana: 'し',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27214',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富田林',
    type: '市',
    kana: 'とんだばやし',
    typeKana: 'し',
    compartmentCode: '365',
    zone: {
      name: '青葉丘、加太、廿山、五軒家及び新青葉丘町を除く。',
      scale: '',
    },
  },
  {
    code: '27214',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '富田林',
    type: '市',
    kana: 'とんだばやし',
    typeKana: 'し',
    compartmentCode: '366-2',
    zone: {
      name: '青葉丘、加太、廿山、五軒家及び新青葉丘町に限る。',
      scale: '',
    },
  },
  {
    code: '27215',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '寝屋川',
    type: '市',
    kana: 'ねやがわ',
    typeKana: 'し',
    compartmentCode: '364',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27216',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '河内長野',
    type: '市',
    kana: 'かわちながの',
    typeKana: 'し',
    compartmentCode: '365',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27217',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松原',
    type: '市',
    kana: 'まつばら',
    typeKana: 'し',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27218',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大東',
    type: '市',
    kana: 'だいとう',
    typeKana: 'し',
    compartmentCode: '364',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27219',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '和泉',
    type: '市',
    kana: 'いずみ',
    typeKana: 'し',
    compartmentCode: '369',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27220',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '箕面',
    type: '市',
    kana: 'みのお',
    typeKana: 'し',
    compartmentCode: '371',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27221',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '柏原',
    type: '市',
    kana: 'かしわら',
    typeKana: 'し',
    compartmentCode: '372',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27222',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '羽曳野',
    type: '市',
    kana: 'はびきの',
    typeKana: 'し',
    compartmentCode: '372',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27223',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '門真',
    type: '市',
    kana: 'かどま',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '石原町、泉町、一番町、大倉町、垣内町、桑才新町、幸福町、寿町、栄町、小路町、新橋町、末広町、月出町、堂山町、殿島町、中町、浜町、速見町、東田町、深田町、古川町、本町、松生町、松葉町、御堂町、向島町、元町、柳田町及び柳町に限る。',
      scale: '',
    },
  },
  {
    code: '27223',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '門真',
    type: '市',
    kana: 'かどま',
    typeKana: 'し',
    compartmentCode: '364',
    zone: {
      name: '石原町、泉町、一番町、大倉町、垣内町、桑才新町、幸福町、寿町、栄町、小路町、新橋町、末広町、月出町、堂山町、殿島町、中町、浜町、速見町、東田町、深田町、古川町、本町、松生町、松葉町、御堂町、向島町、元町、柳田町及び柳町を除く。',
      scale: '',
    },
  },
  {
    code: '27224',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '摂津',
    type: '市',
    kana: 'せっつ',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '北別府町、新在家、正雀、正雀本町、庄屋、千里丘、千里丘新町、千里丘東四丁目及び五丁目、西一津屋、浜町、東正雀、東一津屋、東別府、一津屋、別府、三島、南千里丘並びに南別府町に限る。',
      scale: '',
    },
  },
  {
    code: '27224',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '摂津',
    type: '市',
    kana: 'せっつ',
    typeKana: 'し',
    compartmentCode: '370',
    zone: {
      name: '北別府町、新在家、正雀、正雀本町、庄屋、千里丘、千里丘新町、千里丘東四丁目及び五丁目、西一津屋、浜町、東正雀、東一津屋、東別府、一津屋、別府、三島、南千里丘並びに南別府町を除く。',
      scale: '',
    },
  },
  {
    code: '27225',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高石',
    type: '市',
    kana: 'たかいし',
    typeKana: 'し',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27226',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '藤井寺',
    type: '市',
    kana: 'ふじいでら',
    typeKana: 'し',
    compartmentCode: '372',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27227',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東大阪',
    type: '市',
    kana: 'ひがしおおさか',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '旭町、池島町、池之端町、出雲井町、出雲井本町、稲葉、今米、岩田町（三丁目を除く。）、瓜生堂一丁目、加納、上石切町、上四条町、上六万寺町、川中、川田、河内町、神田町、喜里川町、北石切町、北鴻池町、客坊町、日下町、五条町、鴻池町、鴻池徳庵町、鴻池本町、鴻池元町、古箕輪、桜町、四条町、島之内、下六万寺町、昭和町、新池島町、新鴻池町、新町、新庄、末広町、角田、善根寺町、鷹殿町、宝町、立花町、玉串町西、玉串町東、玉串元町、豊浦町、鳥居町、中石切町、中新開、中野、中鴻池町、南荘町、西石切町、西岩田一丁目、西鴻池町、額田町、布市町、箱殿町、花園西町、花園東町、花園本町、東石切町、東鴻池町、東豊浦町、東山町、菱江、菱屋東、瓢箪山町、本庄中一丁目、本町、松原、松原南、水走、南鴻池町、南四条町、箕輪、御幸町、元町、山手町、弥生町、横小路町、横枕、横枕西、横枕南、吉田、吉田本町、吉田下島、吉原、六万寺町及び若草町を除く。',
      scale: '',
    },
  },
  {
    code: '27227',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東大阪',
    type: '市',
    kana: 'ひがしおおさか',
    typeKana: 'し',
    compartmentCode: '364',
    zone: {
      name: '加納五丁目から八丁目までに限る。',
      scale: '',
    },
  },
  {
    code: '27227',
    distinct: '3',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東大阪',
    type: '市',
    kana: 'ひがしおおさか',
    typeKana: 'し',
    compartmentCode: '372',
    zone: {
      name: '旭町、池島町、池之端町、出雲井町、出雲井本町、稲葉、今米、岩田町（三丁目を除く。）、瓜生堂一丁目、加納（一丁目から四丁目までに限る。）、上石切町、上四条町、上六万寺町、川中、川田、河内町、神田町、喜里川町、北石切町、北鴻池町、客坊町、日下町、五条町、鴻池町、鴻池徳庵町、鴻池本町、鴻池元町、古箕輪、桜町、四条町、島之内、下六万寺町、昭和町、新池島町、新鴻池町、新町、新庄、末広町、角田、善根寺町、鷹殿町、宝町、立花町、玉串町西、玉串町東、玉串元町、豊浦町、鳥居町、中石切町、中新開、中野、中鴻池町、南荘町、西石切町、西岩田一丁目、西鴻池町、額田町、布市町、箱殿町、花園西町、花園東町、花園本町、東石切町、東鴻池町、東豊浦町、東山町、菱江、菱屋東、瓢箪山町、本庄中一丁目、本町、松原、松原南、水走、南鴻池町、南四条町、箕輪、御幸町、元町、山手町、弥生町、横小路町、横枕、横枕西、横枕南、吉田、吉田本町、吉田下島、吉原、六万寺町及び若草町に限る。',
      scale: '',
    },
  },
  {
    code: '27228',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '泉南',
    type: '市',
    kana: 'せんなん',
    typeKana: 'し',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27229',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '四條畷',
    type: '市',
    kana: 'しじょうなわて',
    typeKana: 'し',
    compartmentCode: '364',
    zone: {
      name: '上田原、さつきヶ丘、下田原、田原台及び緑風台を除く。',
      scale: '',
    },
  },
  {
    code: '27229',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '四條畷',
    type: '市',
    kana: 'しじょうなわて',
    typeKana: 'し',
    compartmentCode: '383-2',
    zone: {
      name: '上田原、さつきヶ丘、下田原、田原台及び緑風台に限る。',
      scale: '',
    },
  },
  {
    code: '27230',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '交野',
    type: '市',
    kana: 'かたの',
    typeKana: 'し',
    compartmentCode: '364',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27231',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大阪狭山',
    type: '市',
    kana: 'おおさかさやま',
    typeKana: 'し',
    compartmentCode: '366-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27232',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '阪南',
    type: '市',
    kana: 'はんなん',
    typeKana: 'し',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27301',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '三島',
      type: '郡',
      kana: 'みしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '島本',
    type: '町',
    kana: 'しまもと',
    typeKana: 'ちょう',
    compartmentCode: '401',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27321',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '豊能',
      type: '郡',
      kana: 'とよの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '豊能',
    type: '町',
    kana: 'とよの',
    typeKana: 'ちょう',
    compartmentCode: '371',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27322',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '豊能',
      type: '郡',
      kana: 'とよの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '能勢',
    type: '町',
    kana: 'のせ',
    typeKana: 'ちょう',
    compartmentCode: '371',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27341',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '泉北',
      type: '郡',
      kana: 'せんぼく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '忠岡',
    type: '町',
    kana: 'ただおか',
    typeKana: 'ちょう',
    compartmentCode: '368',
    zone: {
      name: '新浜',
      scale: '',
    },
  },
  {
    code: '27341',
    distinct: '2',
    pref: '大阪府',
    county: {
      name: '泉北',
      type: '郡',
      kana: 'せんぼく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '忠岡',
    type: '町',
    kana: 'ただおか',
    typeKana: 'ちょう',
    compartmentCode: '369',
    zone: {
      name: '新浜を除く。',
      scale: '',
    },
  },
  {
    code: '27361',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '泉南',
      type: '郡',
      kana: 'せんなん',
      typeKana: 'ぐん',
      note: '',
    },
    name: '熊取',
    type: '町',
    kana: 'くまとり',
    typeKana: 'ちょう',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27362',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '泉南',
      type: '郡',
      kana: 'せんなん',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田尻',
    type: '町',
    kana: 'たじり',
    typeKana: 'ちょう',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27366',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '泉南',
      type: '郡',
      kana: 'せんなん',
      typeKana: 'ぐん',
      note: '',
    },
    name: '岬',
    type: '町',
    kana: 'みさき',
    typeKana: 'ちょう',
    compartmentCode: '368',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27381',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '南河内',
      type: '郡',
      kana: 'みなみかわち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '太子',
    type: '町',
    kana: 'たいし',
    typeKana: 'ちょう',
    compartmentCode: '365',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27382',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '南河内',
      type: '郡',
      kana: 'みなみかわち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '河南',
    type: '町',
    kana: 'かなん',
    typeKana: 'ちょう',
    compartmentCode: '365',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '27383',
    distinct: '1',
    pref: '大阪府',
    county: {
      name: '南河内',
      type: '郡',
      kana: 'みなみかわち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '千早赤阪',
    type: '村',
    kana: 'ちはやあかさか',
    typeKana: 'むら',
    compartmentCode: '365',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28100',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28101',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '東灘',
    type: '区',
    kana: 'ひがしなだ',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28102',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '灘',
    type: '区',
    kana: 'なだ',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28105',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '兵庫',
    type: '区',
    kana: 'ひょうご',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28106',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '長田',
    type: '区',
    kana: 'ながた',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28107',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '須磨',
    type: '区',
    kana: 'すま',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28108',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '垂水',
    type: '区',
    kana: 'たるみ',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28109',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28110',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28111',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神戸',
      type: '市',
      kana: 'こうべ',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28201',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '姫路',
    type: '市',
    kana: 'ひめじ',
    typeKana: 'し',
    compartmentCode: '429',
    zone: {
      name: '安富町',
      scale: '',
    },
  },
  {
    code: '28201',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '姫路',
    type: '市',
    kana: 'ひめじ',
    typeKana: 'し',
    compartmentCode: '435-2',
    zone: {
      name: '安富町を除く。',
      scale: '',
    },
  },
  {
    code: '28202',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '尼崎',
    type: '市',
    kana: 'あまがさき',
    typeKana: 'し',
    compartmentCode: '363',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28203',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '明石',
    type: '市',
    kana: 'あかし',
    typeKana: 'し',
    compartmentCode: '428',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28204',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西宮',
    type: '市',
    kana: 'にしのみや',
    typeKana: 'し',
    compartmentCode: '428',
    zone: {
      name: '北六甲台、すみれ台及び山口町に限る。',
      scale: '',
    },
  },
  {
    code: '28204',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西宮',
    type: '市',
    kana: 'にしのみや',
    typeKana: 'し',
    compartmentCode: '446',
    zone: {
      name: '清瀬台、塩瀬町名塩、塩瀬町生瀬、名塩ガーデン、名塩山荘、名塩新町、名塩茶園町、名塩南台、生瀬高台、生瀬町、生瀬町東町、花の峯、東山台、宝生ヶ丘及び青葉台に限る。',
      scale: '',
    },
  },
  {
    code: '28204',
    distinct: '3',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西宮',
    type: '市',
    kana: 'にしのみや',
    typeKana: 'し',
    compartmentCode: '447',
    zone: {
      name: '北六甲台、すみれ台、山口町、清瀬台、塩瀬町名塩、塩瀬町生瀬、名塩ガーデン、名塩山荘、名塩新町、名塩茶園町、名塩南台、生瀬高台、生瀬町、生瀬町東町、花の峯、東山台、宝生ヶ丘及び青葉台を除く。',
      scale: '',
    },
  },
  {
    code: '28205',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '洲本',
    type: '市',
    kana: 'すもと',
    typeKana: 'し',
    compartmentCode: '448',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28206',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '芦屋',
    type: '市',
    kana: 'あしや',
    typeKana: 'し',
    compartmentCode: '446',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28207',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊丹',
    type: '市',
    kana: 'いたみ',
    typeKana: 'し',
    compartmentCode: '371',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28208',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '相生',
    type: '市',
    kana: 'あいおい',
    typeKana: 'し',
    compartmentCode: '432-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28209',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊岡',
    type: '市',
    kana: 'とよおか',
    typeKana: 'し',
    compartmentCode: '443',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28210',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加古川',
    type: '市',
    kana: 'かこがわ',
    typeKana: 'し',
    compartmentCode: '428',
    zone: {
      name: '平岡町土山',
      scale: '',
    },
  },
  {
    code: '28210',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加古川',
    type: '市',
    kana: 'かこがわ',
    typeKana: 'し',
    compartmentCode: '438',
    zone: {
      name: '平岡町土山を除く。',
      scale: '',
    },
  },
  {
    code: '28212',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '赤穂',
    type: '市',
    kana: 'あこう',
    typeKana: 'し',
    compartmentCode: '432-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28213',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西脇',
    type: '市',
    kana: 'にしわき',
    typeKana: 'し',
    compartmentCode: '440',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28214',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宝塚',
    type: '市',
    kana: 'たからづか',
    typeKana: 'し',
    compartmentCode: '371',
    zone: {
      name: '長尾台、花屋敷荘園、花屋敷つつじガ丘、花屋敷松ガ丘、雲雀丘、雲雀丘山手及びふじガ丘に限る。',
      scale: '',
    },
  },
  {
    code: '28214',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宝塚',
    type: '市',
    kana: 'たからづか',
    typeKana: 'し',
    compartmentCode: '446',
    zone: {
      name: '鹿塩、駒の町、新明和町、長尾台、仁川旭ガ丘、仁川うぐいす台、仁川北、仁川清風台、仁川台、仁川高台、仁川高丸、仁川団地、仁川月見ガ丘、仁川宮西町、花屋敷荘園、花屋敷つつじガ丘、花屋敷松ガ丘、雲雀丘、雲雀丘山手及びふじガ丘を除く。',
      scale: '',
    },
  },
  {
    code: '28214',
    distinct: '3',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宝塚',
    type: '市',
    kana: 'たからづか',
    typeKana: 'し',
    compartmentCode: '447',
    zone: {
      name: '鹿塩、駒の町、新明和町、仁川旭ガ丘、仁川うぐいす台、仁川北、仁川清風台、仁川台、仁川高台、仁川高丸、仁川団地、仁川月見ガ丘及び仁川宮西町に限る。',
      scale: '',
    },
  },
  {
    code: '28215',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三木',
    type: '市',
    kana: 'みき',
    typeKana: 'し',
    compartmentCode: '439',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28216',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高砂',
    type: '市',
    kana: 'たかさご',
    typeKana: 'し',
    compartmentCode: '435-2',
    zone: {
      name: '北浜町北脇及び北浜町西浜に限る。',
      scale: '',
    },
  },
  {
    code: '28216',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高砂',
    type: '市',
    kana: 'たかさご',
    typeKana: 'し',
    compartmentCode: '438',
    zone: {
      name: '北浜町北脇及び北浜町西浜を除く。',
      scale: '',
    },
  },
  {
    code: '28217',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '川西',
    type: '市',
    kana: 'かわにし',
    typeKana: 'し',
    compartmentCode: '371',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28218',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小野',
    type: '市',
    kana: 'おの',
    typeKana: 'し',
    compartmentCode: '439',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28219',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三田',
    type: '市',
    kana: 'さんだ',
    typeKana: 'し',
    compartmentCode: '441',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28220',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加西',
    type: '市',
    kana: 'かさい',
    typeKana: 'し',
    compartmentCode: '430',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28221',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '丹波篠山',
    type: '市',
    kana: 'たんばささやま',
    typeKana: 'し',
    compartmentCode: '441',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28222',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '養父',
    type: '市',
    kana: 'やぶ',
    typeKana: 'し',
    compartmentCode: '445',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28223',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '丹波',
    type: '市',
    kana: 'たんば',
    typeKana: 'し',
    compartmentCode: '442',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28224',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南あわじ',
    type: '市',
    kana: 'みなみあわじ',
    typeKana: 'し',
    compartmentCode: '448',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28225',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '朝来',
    type: '市',
    kana: 'あさご',
    typeKana: 'し',
    compartmentCode: '445',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28226',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '淡路',
    type: '市',
    kana: 'あわじ',
    typeKana: 'し',
    compartmentCode: '449',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28227',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宍粟',
    type: '市',
    kana: 'しそう',
    typeKana: 'し',
    compartmentCode: '429',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28228',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '加東',
    type: '市',
    kana: 'かとう',
    typeKana: 'し',
    compartmentCode: '440',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28229',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'たつの',
    type: '市',
    kana: 'たつの',
    typeKana: 'し',
    compartmentCode: '431',
    zone: {
      name: '新宮町角亀及び御津町を除く。',
      scale: '',
    },
  },
  {
    code: '28229',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'たつの',
    type: '市',
    kana: 'たつの',
    typeKana: 'し',
    compartmentCode: '432-2',
    zone: {
      name: '新宮町角亀',
      scale: '',
    },
  },
  {
    code: '28229',
    distinct: '3',
    pref: '兵庫県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'たつの',
    type: '市',
    kana: 'たつの',
    typeKana: 'し',
    compartmentCode: '435-2',
    zone: {
      name: '御津町',
      scale: '',
    },
  },
  {
    code: '28301',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '川辺',
      type: '郡',
      kana: 'かわべ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '猪名川',
    type: '町',
    kana: 'いながわ',
    typeKana: 'ちょう',
    compartmentCode: '371',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28365',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '多可',
      type: '郡',
      kana: 'たか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '多可',
    type: '町',
    kana: 'たか',
    typeKana: 'ちょう',
    compartmentCode: '440',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28381',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '加古',
      type: '郡',
      kana: 'かこ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '稲美',
    type: '町',
    kana: 'いなみ',
    typeKana: 'ちょう',
    compartmentCode: '438',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28382',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '加古',
      type: '郡',
      kana: 'かこ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '播磨',
    type: '町',
    kana: 'はりま',
    typeKana: 'ちょう',
    compartmentCode: '428',
    zone: {
      name: '上野添、北野添、古宮、西野添、野添、野添城、東野添、東新島及び二子に限る。',
      scale: '',
    },
  },
  {
    code: '28382',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '加古',
      type: '郡',
      kana: 'かこ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '播磨',
    type: '町',
    kana: 'はりま',
    typeKana: 'ちょう',
    compartmentCode: '438',
    zone: {
      name: '上野添、北野添、古宮、西野添、野添、野添城、東野添、東新島及び二子を除く。',
      scale: '',
    },
  },
  {
    code: '28442',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神崎',
      type: '郡',
      kana: 'かんざき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '市川',
    type: '町',
    kana: 'いちかわ',
    typeKana: 'ちょう',
    compartmentCode: '430',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28443',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神崎',
      type: '郡',
      kana: 'かんざき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '福崎',
    type: '町',
    kana: 'ふくさき',
    typeKana: 'ちょう',
    compartmentCode: '430',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28446',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '神崎',
      type: '郡',
      kana: 'かんざき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神河',
    type: '町',
    kana: 'かみかわ',
    typeKana: 'ちょう',
    compartmentCode: '430',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28464',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '揖保',
      type: '郡',
      kana: 'いぼ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '太子',
    type: '町',
    kana: 'たいし',
    typeKana: 'ちょう',
    compartmentCode: '435-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28481',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '赤穂',
      type: '郡',
      kana: 'あこう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上郡',
    type: '町',
    kana: 'かみごおり',
    typeKana: 'ちょう',
    compartmentCode: '432-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28501',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '佐用',
      type: '郡',
      kana: 'さよう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '佐用',
    type: '町',
    kana: 'さよう',
    typeKana: 'ちょう',
    compartmentCode: '429',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '28585',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '美方',
      type: '郡',
      kana: 'みかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '香美',
    type: '町',
    kana: 'かみ',
    typeKana: 'ちょう',
    compartmentCode: '443',
    zone: {
      name: '香住区',
      scale: '',
    },
  },
  {
    code: '28585',
    distinct: '2',
    pref: '兵庫県',
    county: {
      name: '美方',
      type: '郡',
      kana: 'みかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '香美',
    type: '町',
    kana: 'かみ',
    typeKana: 'ちょう',
    compartmentCode: '444',
    zone: {
      name: '香住区を除く。',
      scale: '',
    },
  },
  {
    code: '28586',
    distinct: '1',
    pref: '兵庫県',
    county: {
      name: '美方',
      type: '郡',
      kana: 'みかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新温泉',
    type: '町',
    kana: 'しんおんせん',
    typeKana: 'ちょう',
    compartmentCode: '444',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29201',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '奈良',
    type: '市',
    kana: 'なら',
    typeKana: 'し',
    compartmentCode: '382',
    zone: {
      name: '藺生町、荻町、小倉町、上深川町、下深川町、月ヶ瀬石打、月ヶ瀬尾山、月ヶ瀬嵩、月ヶ瀬月瀬、月ヶ瀬長引、月ヶ瀬桃香野、都祁小山戸町、都祁甲岡町、都祁こぶしが丘、都祁白石町、都祁相河町、都祁友田町、都祁吐山町、都祁馬場町、都祁南之庄町、針ヶ別所町、針町及び来迎寺町を除く。',
      scale: '',
    },
  },
  {
    code: '29201',
    distinct: '2',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '奈良',
    type: '市',
    kana: 'なら',
    typeKana: 'し',
    compartmentCode: '383-2',
    zone: {
      name: '藺生町、荻町、小倉町、上深川町、下深川町、月ヶ瀬石打、月ヶ瀬尾山、月ヶ瀬嵩、月ヶ瀬月瀬、月ヶ瀬長引、月ヶ瀬桃香野、都祁小山戸町、都祁甲岡町、都祁こぶしが丘、都祁白石町、都祁相河町、都祁友田町、都祁吐山町、都祁馬場町、都祁南之庄町、針ヶ別所町、針町及び来迎寺町に限る。',
      scale: '',
    },
  },
  {
    code: '29202',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大和高田',
    type: '市',
    kana: 'やまとたかだ',
    typeKana: 'し',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29203',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大和郡山',
    type: '市',
    kana: 'やまとこおりやま',
    typeKana: 'し',
    compartmentCode: '383-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29204',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '天理',
    type: '市',
    kana: 'てんり',
    typeKana: 'し',
    compartmentCode: '383-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29205',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '橿原',
    type: '市',
    kana: 'かしはら',
    typeKana: 'し',
    compartmentCode: '385-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29206',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '桜井',
    type: '市',
    kana: 'さくらい',
    typeKana: 'し',
    compartmentCode: '385-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29207',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '五條',
    type: '市',
    kana: 'ごじょう',
    typeKana: 'し',
    compartmentCode: '394-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29208',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '御所',
    type: '市',
    kana: 'ごせ',
    typeKana: 'し',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29209',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '生駒',
    type: '市',
    kana: 'いこま',
    typeKana: 'し',
    compartmentCode: '383-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29210',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '香芝',
    type: '市',
    kana: 'かしば',
    typeKana: 'し',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29211',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '葛城',
    type: '市',
    kana: 'かつらぎ',
    typeKana: 'し',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29212',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇陀',
    type: '市',
    kana: 'うだ',
    typeKana: 'し',
    compartmentCode: '383-2',
    zone: {
      name: '室生小原、室生染田、室生多田及び室生無山に限る。',
      scale: '',
    },
  },
  {
    code: '29212',
    distinct: '2',
    pref: '奈良県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇陀',
    type: '市',
    kana: 'うだ',
    typeKana: 'し',
    compartmentCode: '388',
    zone: {
      name: '室生小原、室生染田、室生多田及び室生無山を除く。',
      scale: '',
    },
  },
  {
    code: '29322',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '山辺',
      type: '郡',
      kana: 'やまべ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山添',
    type: '村',
    kana: 'やまぞえ',
    typeKana: 'むら',
    compartmentCode: '383-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29342',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '生駒',
      type: '郡',
      kana: 'いこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '平群',
    type: '町',
    kana: 'へぐり',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29343',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '生駒',
      type: '郡',
      kana: 'いこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三郷',
    type: '町',
    kana: 'さんごう',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29344',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '生駒',
      type: '郡',
      kana: 'いこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '斑鳩',
    type: '町',
    kana: 'いかるが',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29345',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '生駒',
      type: '郡',
      kana: 'いこま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '安堵',
    type: '町',
    kana: 'あんど',
    typeKana: 'ちょう',
    compartmentCode: '383-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29361',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '磯城',
      type: '郡',
      kana: 'しき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川西',
    type: '町',
    kana: 'かわにし',
    typeKana: 'ちょう',
    compartmentCode: '383-2',
    zone: {
      name: '下永',
      scale: '',
    },
  },
  {
    code: '29361',
    distinct: '2',
    pref: '奈良県',
    county: {
      name: '磯城',
      type: '郡',
      kana: 'しき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川西',
    type: '町',
    kana: 'かわにし',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '下永を除く。',
      scale: '',
    },
  },
  {
    code: '29362',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '磯城',
      type: '郡',
      kana: 'しき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三宅',
    type: '町',
    kana: 'みやけ',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29363',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '磯城',
      type: '郡',
      kana: 'しき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田原本',
    type: '町',
    kana: 'たわらもと',
    typeKana: 'ちょう',
    compartmentCode: '385-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29385',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '宇陀',
      type: '郡',
      kana: 'うだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '曽爾',
    type: '村',
    kana: 'そに',
    typeKana: 'むら',
    compartmentCode: '388',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29386',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '宇陀',
      type: '郡',
      kana: 'うだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '御杖',
    type: '村',
    kana: 'みつえ',
    typeKana: 'むら',
    compartmentCode: '388',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29401',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '高市',
      type: '郡',
      kana: 'たかいち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高取',
    type: '町',
    kana: 'たかとり',
    typeKana: 'ちょう',
    compartmentCode: '385-2',
    zone: {
      name: '越智、車木、寺崎及び丹生谷を除く。',
      scale: '',
    },
  },
  {
    code: '29401',
    distinct: '2',
    pref: '奈良県',
    county: {
      name: '高市',
      type: '郡',
      kana: 'たかいち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高取',
    type: '町',
    kana: 'たかとり',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '越智、車木、寺崎及び丹生谷に限る。',
      scale: '',
    },
  },
  {
    code: '29402',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '高市',
      type: '郡',
      kana: 'たかいち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '明日香',
    type: '村',
    kana: 'あすか',
    typeKana: 'むら',
    compartmentCode: '385-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29424',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '北葛城',
      type: '郡',
      kana: 'きたかつらぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上牧',
    type: '町',
    kana: 'かんまき',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29425',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '北葛城',
      type: '郡',
      kana: 'きたかつらぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '王寺',
    type: '町',
    kana: 'おうじ',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29426',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '北葛城',
      type: '郡',
      kana: 'きたかつらぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '広陵',
    type: '町',
    kana: 'こうりょう',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29427',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '北葛城',
      type: '郡',
      kana: 'きたかつらぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '河合',
    type: '町',
    kana: 'かわい',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29441',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉野',
    type: '町',
    kana: 'よしの',
    typeKana: 'ちょう',
    compartmentCode: '389-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29442',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大淀',
    type: '町',
    kana: 'おおよど',
    typeKana: 'ちょう',
    compartmentCode: '387',
    zone: {
      name: '今木、大岩及び鉾立に限る。',
      scale: '',
    },
  },
  {
    code: '29442',
    distinct: '2',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大淀',
    type: '町',
    kana: 'おおよど',
    typeKana: 'ちょう',
    compartmentCode: '389-2',
    zone: {
      name: '北野、北六田、中増、西増、新野、馬佐、比曽及び増口に限る。',
      scale: '',
    },
  },
  {
    code: '29442',
    distinct: '3',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大淀',
    type: '町',
    kana: 'おおよど',
    typeKana: 'ちょう',
    compartmentCode: '396',
    zone: {
      name: '今木、大岩、北野、北六田、中増、西増、新野、馬佐、比曽、鉾立及び増口を除く。',
      scale: '',
    },
  },
  {
    code: '29443',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '下市',
    type: '町',
    kana: 'しもいち',
    typeKana: 'ちょう',
    compartmentCode: '396',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29444',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '黒滝',
    type: '村',
    kana: 'くろたき',
    typeKana: 'むら',
    compartmentCode: '396',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29446',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '天川',
    type: '村',
    kana: 'てんかわ',
    typeKana: 'むら',
    compartmentCode: '396',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29447',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '野迫川',
    type: '村',
    kana: 'のせがわ',
    typeKana: 'むら',
    compartmentCode: '394-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29449',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '十津川',
    type: '村',
    kana: 'とつかわ',
    typeKana: 'むら',
    compartmentCode: '392',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29450',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '下北山',
    type: '村',
    kana: 'しもきたやま',
    typeKana: 'むら',
    compartmentCode: '393',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29451',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上北山',
    type: '村',
    kana: 'かみきたやま',
    typeKana: 'むら',
    compartmentCode: '393',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29452',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川上',
    type: '村',
    kana: 'かわかみ',
    typeKana: 'むら',
    compartmentCode: '389-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '29453',
    distinct: '1',
    pref: '奈良県',
    county: {
      name: '吉野',
      type: '郡',
      kana: 'よしの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東吉野',
    type: '村',
    kana: 'ひがしよしの',
    typeKana: 'むら',
    compartmentCode: '389-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30201',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '和歌山',
    type: '市',
    kana: 'わかやま',
    typeKana: 'し',
    compartmentCode: '373-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30202',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '海南',
    type: '市',
    kana: 'かいなん',
    typeKana: 'し',
    compartmentCode: '373-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30203',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '橋本',
    type: '市',
    kana: 'はしもと',
    typeKana: 'し',
    compartmentCode: '377',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30204',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '有田',
    type: '市',
    kana: 'ありだ',
    typeKana: 'し',
    compartmentCode: '378',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30205',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '御坊',
    type: '市',
    kana: 'ごぼう',
    typeKana: 'し',
    compartmentCode: '379',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30206',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '田辺',
    type: '市',
    kana: 'たなべ',
    typeKana: 'し',
    compartmentCode: '374',
    zone: {
      name: '本宮町',
      scale: '',
    },
  },
  {
    code: '30206',
    distinct: '2',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '田辺',
    type: '市',
    kana: 'たなべ',
    typeKana: 'し',
    compartmentCode: '380',
    zone: {
      name: '本宮町を除く。',
      scale: '',
    },
  },
  {
    code: '30207',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新宮',
    type: '市',
    kana: 'しんぐう',
    typeKana: 'し',
    compartmentCode: '374',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30208',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '紀の川',
    type: '市',
    kana: 'きのかわ',
    typeKana: 'し',
    compartmentCode: '376',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30209',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岩出',
    type: '市',
    kana: 'いわで',
    typeKana: 'し',
    compartmentCode: '376',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30304',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '海草',
      type: '郡',
      kana: 'かいそう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '紀美野',
    type: '町',
    kana: 'きみの',
    typeKana: 'ちょう',
    compartmentCode: '373-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30341',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '伊都',
      type: '郡',
      kana: 'いと',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'かつらぎ',
    type: '町',
    kana: 'かつらぎ',
    typeKana: 'ちょう',
    compartmentCode: '377',
    zone: {
      name: '花園新子、花園池ノ窪、花園北寺、花園久木、花園中南及び花園梁瀬を除く。',
      scale: '',
    },
  },
  {
    code: '30341',
    distinct: '2',
    pref: '和歌山県',
    county: {
      name: '伊都',
      type: '郡',
      kana: 'いと',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'かつらぎ',
    type: '町',
    kana: 'かつらぎ',
    typeKana: 'ちょう',
    compartmentCode: '378',
    zone: {
      name: '花園新子、花園池ノ窪、花園北寺、花園久木、花園中南及び花園梁瀬に限る。',
      scale: '',
    },
  },
  {
    code: '30343',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '伊都',
      type: '郡',
      kana: 'いと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '九度山',
    type: '町',
    kana: 'くどやま',
    typeKana: 'ちょう',
    compartmentCode: '377',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30344',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '伊都',
      type: '郡',
      kana: 'いと',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高野',
    type: '町',
    kana: 'こうや',
    typeKana: 'ちょう',
    compartmentCode: '377',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30361',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '有田',
      type: '郡',
      kana: 'ありだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湯浅',
    type: '町',
    kana: 'ゆあさ',
    typeKana: 'ちょう',
    compartmentCode: '378',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30362',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '有田',
      type: '郡',
      kana: 'ありだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '広川',
    type: '町',
    kana: 'ひろがわ',
    typeKana: 'ちょう',
    compartmentCode: '378',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30366',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '有田',
      type: '郡',
      kana: 'ありだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '有田川',
    type: '町',
    kana: 'ありだがわ',
    typeKana: 'ちょう',
    compartmentCode: '378',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30381',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '日高',
      type: '郡',
      kana: 'ひだか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美浜',
    type: '町',
    kana: 'みはま',
    typeKana: 'ちょう',
    compartmentCode: '379',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30382',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '日高',
      type: '郡',
      kana: 'ひだか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日高',
    type: '町',
    kana: 'ひだか',
    typeKana: 'ちょう',
    compartmentCode: '379',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30383',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '日高',
      type: '郡',
      kana: 'ひだか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '由良',
    type: '町',
    kana: 'ゆら',
    typeKana: 'ちょう',
    compartmentCode: '379',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30390',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '日高',
      type: '郡',
      kana: 'ひだか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '印南',
    type: '町',
    kana: 'いなみ',
    typeKana: 'ちょう',
    compartmentCode: '379',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30391',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '日高',
      type: '郡',
      kana: 'ひだか',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'みなべ',
    type: '町',
    kana: 'みなべ',
    typeKana: 'ちょう',
    compartmentCode: '380',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30392',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '日高',
      type: '郡',
      kana: 'ひだか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日高川',
    type: '町',
    kana: 'ひだかがわ',
    typeKana: 'ちょう',
    compartmentCode: '379',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30401',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '西牟婁',
      type: '郡',
      kana: 'にしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白浜',
    type: '町',
    kana: 'しらはま',
    typeKana: 'ちょう',
    compartmentCode: '380',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30404',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '西牟婁',
      type: '郡',
      kana: 'にしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上富田',
    type: '町',
    kana: 'かみとんだ',
    typeKana: 'ちょう',
    compartmentCode: '380',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30406',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '西牟婁',
      type: '郡',
      kana: 'にしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'すさみ',
    type: '町',
    kana: 'すさみ',
    typeKana: 'ちょう',
    compartmentCode: '380',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30421',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '東牟婁',
      type: '郡',
      kana: 'ひがしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '那智勝浦',
    type: '町',
    kana: 'なちかつうら',
    typeKana: 'ちょう',
    compartmentCode: '374',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30422',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '東牟婁',
      type: '郡',
      kana: 'ひがしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '太地',
    type: '町',
    kana: 'たいじ',
    typeKana: 'ちょう',
    compartmentCode: '374',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30424',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '東牟婁',
      type: '郡',
      kana: 'ひがしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '古座川',
    type: '町',
    kana: 'こざがわ',
    typeKana: 'ちょう',
    compartmentCode: '375',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30427',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '東牟婁',
      type: '郡',
      kana: 'ひがしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北山',
    type: '村',
    kana: 'きたやま',
    typeKana: 'むら',
    compartmentCode: '374',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '30428',
    distinct: '1',
    pref: '和歌山県',
    county: {
      name: '東牟婁',
      type: '郡',
      kana: 'ひがしむろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '串本',
    type: '町',
    kana: 'くしもと',
    typeKana: 'ちょう',
    compartmentCode: '375',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31201',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鳥取',
    type: '市',
    kana: 'とっとり',
    typeKana: 'し',
    compartmentCode: '513',
    zone: {
      name: '河原町、佐治町及び用瀬町を除く。',
      scale: '',
    },
  },
  {
    code: '31201',
    distinct: '2',
    pref: '鳥取県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鳥取',
    type: '市',
    kana: 'とっとり',
    typeKana: 'し',
    compartmentCode: '514',
    zone: {
      name: '河原町、佐治町及び用瀬町に限る。',
      scale: '',
    },
  },
  {
    code: '31202',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '米子',
    type: '市',
    kana: 'よなご',
    typeKana: 'し',
    compartmentCode: '516',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31203',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '倉吉',
    type: '市',
    kana: 'くらよし',
    typeKana: 'し',
    compartmentCode: '515',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31204',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '境港',
    type: '市',
    kana: 'さかいみなと',
    typeKana: 'し',
    compartmentCode: '516',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31302',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '岩美',
      type: '郡',
      kana: 'いわみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '岩美',
    type: '町',
    kana: 'いわみ',
    typeKana: 'ちょう',
    compartmentCode: '513',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31325',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '八頭',
      type: '郡',
      kana: 'やず',
      typeKana: 'ぐん',
      note: '',
    },
    name: '若桜',
    type: '町',
    kana: 'わかさ',
    typeKana: 'ちょう',
    compartmentCode: '514',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31328',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '八頭',
      type: '郡',
      kana: 'やず',
      typeKana: 'ぐん',
      note: '',
    },
    name: '智頭',
    type: '町',
    kana: 'ちづ',
    typeKana: 'ちょう',
    compartmentCode: '514',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31329',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '八頭',
      type: '郡',
      kana: 'やず',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八頭',
    type: '町',
    kana: 'やず',
    typeKana: 'ちょう',
    compartmentCode: '514',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31364',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '東伯',
      type: '郡',
      kana: 'とうはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三朝',
    type: '町',
    kana: 'みささ',
    typeKana: 'ちょう',
    compartmentCode: '515',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31370',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '東伯',
      type: '郡',
      kana: 'とうはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湯梨浜',
    type: '町',
    kana: 'ゆりはま',
    typeKana: 'ちょう',
    compartmentCode: '515',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31371',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '東伯',
      type: '郡',
      kana: 'とうはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '琴浦',
    type: '町',
    kana: 'ことうら',
    typeKana: 'ちょう',
    compartmentCode: '515',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31372',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '東伯',
      type: '郡',
      kana: 'とうはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北栄',
    type: '町',
    kana: 'ほくえい',
    typeKana: 'ちょう',
    compartmentCode: '515',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31384',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '西伯',
      type: '郡',
      kana: 'さいはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日吉津',
    type: '村',
    kana: 'ひえづ',
    typeKana: 'そん',
    compartmentCode: '516',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31386',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '西伯',
      type: '郡',
      kana: 'さいはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大山',
    type: '町',
    kana: 'だいせん',
    typeKana: 'ちょう',
    compartmentCode: '515',
    zone: {
      name: '赤坂、石井垣、上市、岡、栄田、塩津、下市、下甲、住吉、退休寺、高橋、田中、潮音寺、束積、殿河内、長野、羽田井、樋口、松河原、御崎及び八重に限る。',
      scale: '',
    },
  },
  {
    code: '31386',
    distinct: '2',
    pref: '鳥取県',
    county: {
      name: '西伯',
      type: '郡',
      kana: 'さいはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大山',
    type: '町',
    kana: 'だいせん',
    typeKana: 'ちょう',
    compartmentCode: '516',
    zone: {
      name: '赤坂、石井垣、上市、岡、栄田、塩津、下市、下甲、住吉、退休寺、高橋、田中、潮音寺、束積、殿河内、長野、羽田井、樋口、松河原、御崎及び八重を除く。',
      scale: '',
    },
  },
  {
    code: '31389',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '西伯',
      type: '郡',
      kana: 'さいはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南部',
    type: '町',
    kana: 'なんぶ',
    typeKana: 'ちょう',
    compartmentCode: '516',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31390',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '西伯',
      type: '郡',
      kana: 'さいはく',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伯耆',
    type: '町',
    kana: 'ほうき',
    typeKana: 'ちょう',
    compartmentCode: '516',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31401',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '日野',
      type: '郡',
      kana: 'ひの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日南',
    type: '町',
    kana: 'にちなん',
    typeKana: 'ちょう',
    compartmentCode: '517',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31402',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '日野',
      type: '郡',
      kana: 'ひの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日野',
    type: '町',
    kana: 'ひの',
    typeKana: 'ちょう',
    compartmentCode: '517',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '31403',
    distinct: '1',
    pref: '鳥取県',
    county: {
      name: '日野',
      type: '郡',
      kana: 'ひの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '江府',
    type: '町',
    kana: 'こうふ',
    typeKana: 'ちょう',
    compartmentCode: '517',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32201',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松江',
    type: '市',
    kana: 'まつえ',
    typeKana: 'し',
    compartmentCode: '502',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32202',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '浜田',
    type: '市',
    kana: 'はまだ',
    typeKana: 'し',
    compartmentCode: '508',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32203',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '出雲',
    type: '市',
    kana: 'いずも',
    typeKana: 'し',
    compartmentCode: '503',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32204',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '益田',
    type: '市',
    kana: 'ますだ',
    typeKana: 'し',
    compartmentCode: '511',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32205',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大田',
    type: '市',
    kana: 'おおだ',
    typeKana: 'し',
    compartmentCode: '507',
    zone: {
      name: '川合町及び温泉津町を除く。',
      scale: '',
    },
  },
  {
    code: '32205',
    distinct: '2',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大田',
    type: '市',
    kana: 'おおだ',
    typeKana: 'し',
    compartmentCode: '509',
    zone: {
      name: '湯泉津町',
      scale: '',
    },
  },
  {
    code: '32205',
    distinct: '3',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大田',
    type: '市',
    kana: 'おおだ',
    typeKana: 'し',
    compartmentCode: '510',
    zone: {
      name: '川合町',
      scale: '',
    },
  },
  {
    code: '32206',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '安来',
    type: '市',
    kana: 'やすぎ',
    typeKana: 'し',
    compartmentCode: '504',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32207',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '江津',
    type: '市',
    kana: 'ごうつ',
    typeKana: 'し',
    compartmentCode: '509',
    zone: {
      name: '桜江町を除く。',
      scale: '',
    },
  },
  {
    code: '32207',
    distinct: '2',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '江津',
    type: '市',
    kana: 'ごうつ',
    typeKana: 'し',
    compartmentCode: '510',
    zone: {
      name: '桜江町',
      scale: '',
    },
  },
  {
    code: '32209',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '雲南',
    type: '市',
    kana: 'うんなん',
    typeKana: 'し',
    compartmentCode: '505',
    zone: {
      name: '掛合町及び吉田町を除く。',
      scale: '',
    },
  },
  {
    code: '32209',
    distinct: '2',
    pref: '島根県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '雲南',
    type: '市',
    kana: 'うんなん',
    typeKana: 'し',
    compartmentCode: '506',
    zone: {
      name: '掛合町及び吉田町に限る。',
      scale: '',
    },
  },
  {
    code: '32343',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '仁多',
      type: '郡',
      kana: 'にた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '奥出雲',
    type: '町',
    kana: 'おくいずも',
    typeKana: 'ちょう',
    compartmentCode: '505',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32386',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '飯石',
      type: '郡',
      kana: 'いいし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '飯南',
    type: '町',
    kana: 'いいなん',
    typeKana: 'ちょう',
    compartmentCode: '506',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32441',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '邑智',
      type: '郡',
      kana: 'おおち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川本',
    type: '町',
    kana: 'かわもと',
    typeKana: 'まち',
    compartmentCode: '510',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32448',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '邑智',
      type: '郡',
      kana: 'おおち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美郷',
    type: '町',
    kana: 'みさと',
    typeKana: 'ちょう',
    compartmentCode: '510',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32449',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '邑智',
      type: '郡',
      kana: 'おおち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '邑南',
    type: '町',
    kana: 'おおなん',
    typeKana: 'ちょう',
    compartmentCode: '510',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32501',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '鹿足',
      type: '郡',
      kana: 'かのあし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '津和野',
    type: '町',
    kana: 'つわの',
    typeKana: 'ちょう',
    compartmentCode: '512',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32505',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '鹿足',
      type: '郡',
      kana: 'かのあし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉賀',
    type: '町',
    kana: 'よしか',
    typeKana: 'ちょう',
    compartmentCode: '512',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32525',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '隠岐',
      type: '郡',
      kana: 'おき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '海士',
    type: '町',
    kana: 'あま',
    typeKana: 'ちょう',
    compartmentCode: '501',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32526',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '隠岐',
      type: '郡',
      kana: 'おき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西ノ島',
    type: '町',
    kana: 'にしのしま',
    typeKana: 'ちょう',
    compartmentCode: '501',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32527',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '隠岐',
      type: '郡',
      kana: 'おき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '知夫',
    type: '村',
    kana: 'ちぶ',
    typeKana: 'むら',
    compartmentCode: '501',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '32528',
    distinct: '1',
    pref: '島根県',
    county: {
      name: '隠岐',
      type: '郡',
      kana: 'おき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '隠岐の島',
    type: '町',
    kana: 'おきのしま',
    typeKana: 'ちょう',
    compartmentCode: '500',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33100',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '岡山',
      type: '市',
      kana: 'おかやま',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '518',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33100',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '岡山',
      type: '市',
      kana: 'おかやま',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '519',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33101',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '岡山',
      type: '市',
      kana: 'おかやま',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '519',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33102',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '岡山',
      type: '市',
      kana: 'おかやま',
      typeKana: 'し',
      note: '',
    },
    name: '中',
    type: '区',
    kana: 'なか',
    typeKana: 'く',
    compartmentCode: '519',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33103',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '岡山',
      type: '市',
      kana: 'おかやま',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '519',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33104',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '岡山',
      type: '市',
      kana: 'おかやま',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '518',
    zone: {
      name: '植松、西畦及び箕島に限る。',
      scale: '',
    },
  },
  {
    code: '33104',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '岡山',
      type: '市',
      kana: 'おかやま',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '519',
    zone: {
      name: '植松、西畦及び箕島を除く。',
      scale: '',
    },
  },
  {
    code: '33202',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '倉敷',
    type: '市',
    kana: 'くらしき',
    typeKana: 'し',
    compartmentCode: '518',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33203',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '津山',
    type: '市',
    kana: 'つやま',
    typeKana: 'し',
    compartmentCode: '532',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33204',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '玉野',
    type: '市',
    kana: 'たまの',
    typeKana: 'し',
    compartmentCode: '520',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33205',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '笠岡',
    type: '市',
    kana: 'かさおか',
    typeKana: 'し',
    compartmentCode: '525',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33207',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '井原',
    type: '市',
    kana: 'いばら',
    typeKana: 'し',
    compartmentCode: '527',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33208',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '総社',
    type: '市',
    kana: 'そうじゃ',
    typeKana: 'し',
    compartmentCode: '528',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33209',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高梁',
    type: '市',
    kana: 'たかはし',
    typeKana: 'し',
    compartmentCode: '526',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33210',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新見',
    type: '市',
    kana: 'にいみ',
    typeKana: 'し',
    compartmentCode: '529',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33211',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '備前',
    type: '市',
    kana: 'びぜん',
    typeKana: 'し',
    compartmentCode: '534',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33212',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '瀬戸内',
    type: '市',
    kana: 'せとうち',
    typeKana: 'し',
    compartmentCode: '519',
    zone: {
      name: '邑久町（福山、大富、北島及び向山に限る。）',
      scale: '',
    },
  },
  {
    code: '33212',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '瀬戸内',
    type: '市',
    kana: 'せとうち',
    typeKana: 'し',
    compartmentCode: '534',
    zone: {
      name: '長船町長船',
      scale: '',
    },
  },
  {
    code: '33212',
    distinct: '3',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '瀬戸内',
    type: '市',
    kana: 'せとうち',
    typeKana: 'し',
    compartmentCode: '535-2',
    zone: {
      name: '邑久町（福山、大富、北島及び向山に限る。）及び長船町長船を除く。',
      scale: '',
    },
  },
  {
    code: '33213',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '赤磐',
    type: '市',
    kana: 'あかいわ',
    typeKana: 'し',
    compartmentCode: '519',
    zone: {
      name: '穂崎及び馬屋に限る。',
      scale: '',
    },
  },
  {
    code: '33213',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '赤磐',
    type: '市',
    kana: 'あかいわ',
    typeKana: 'し',
    compartmentCode: '538-2',
    zone: {
      name: '穂崎及び馬屋を除く。',
      scale: '',
    },
  },
  {
    code: '33214',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '真庭',
    type: '市',
    kana: 'まにわ',
    typeKana: 'し',
    compartmentCode: '526',
    zone: {
      name: '阿口、上呰部、上中津井、上水田、五名、下呰部、下中津井、宮地及び山田に限る。',
      scale: '',
    },
  },
  {
    code: '33214',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '真庭',
    type: '市',
    kana: 'まにわ',
    typeKana: 'し',
    compartmentCode: '530',
    zone: {
      name: '阿口、上呰部、上中津井、上水田、五名、下呰部、下中津井、宮地及び山田を除く。',
      scale: '',
    },
  },
  {
    code: '33215',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '美作',
    type: '市',
    kana: 'みまさか',
    typeKana: 'し',
    compartmentCode: '533',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33216',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '浅口',
    type: '市',
    kana: 'あさくち',
    typeKana: 'し',
    compartmentCode: '522-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33346',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '和気',
      type: '郡',
      kana: 'わけ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '和気',
    type: '町',
    kana: 'わけ',
    typeKana: 'ちょう',
    compartmentCode: '534',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33423',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '都窪',
      type: '郡',
      kana: 'つくぼ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '早島',
    type: '町',
    kana: 'はやしま',
    typeKana: 'ちょう',
    compartmentCode: '518',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33445',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '浅口',
      type: '郡',
      kana: 'あさくち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '里庄',
    type: '町',
    kana: 'さとしょう',
    typeKana: 'ちょう',
    compartmentCode: '525',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33461',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '小田',
      type: '郡',
      kana: 'おだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '矢掛',
    type: '町',
    kana: 'やかげ',
    typeKana: 'ちょう',
    compartmentCode: '527',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33586',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '真庭',
      type: '郡',
      kana: 'まにわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新庄',
    type: '村',
    kana: 'しんじょう',
    typeKana: 'そん',
    compartmentCode: '530',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33606',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '苫田',
      type: '郡',
      kana: 'とまた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鏡野',
    type: '町',
    kana: 'かがみの',
    typeKana: 'ちょう',
    compartmentCode: '530',
    zone: {
      name: '大、楠、富仲間、富西谷及び富東谷に限る。',
      scale: '',
    },
  },
  {
    code: '33606',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '苫田',
      type: '郡',
      kana: 'とまた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鏡野',
    type: '町',
    kana: 'かがみの',
    typeKana: 'ちょう',
    compartmentCode: '532',
    zone: {
      name: '大、楠、富仲間、富西谷及び富東谷を除く。',
      scale: '',
    },
  },
  {
    code: '33622',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '勝田',
      type: '郡',
      kana: 'かつた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '勝央',
    type: '町',
    kana: 'しょうおう',
    typeKana: 'ちょう',
    compartmentCode: '532',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33623',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '勝田',
      type: '郡',
      kana: 'かつた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '奈義',
    type: '町',
    kana: 'なぎ',
    typeKana: 'ちょう',
    compartmentCode: '532',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33643',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '英田',
      type: '郡',
      kana: 'あいだ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西粟倉',
    type: '村',
    kana: 'にしあわくら',
    typeKana: 'そん',
    compartmentCode: '533',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33663',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '久米',
      type: '郡',
      kana: 'くめ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '久米南',
    type: '町',
    kana: 'くめなん',
    typeKana: 'ちょう',
    compartmentCode: '519',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '33666',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '久米',
      type: '郡',
      kana: 'くめ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美咲',
    type: '町',
    kana: 'みさき',
    typeKana: 'ちょう',
    compartmentCode: '531',
    zone: {
      name: '上口、江与味、北、小山、里、栃原、中、中垪和、西、西川、西川上、西垪和、東垪和及び南に限る。',
      scale: '',
    },
  },
  {
    code: '33666',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '久米',
      type: '郡',
      kana: 'くめ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美咲',
    type: '町',
    kana: 'みさき',
    typeKana: 'ちょう',
    compartmentCode: '532',
    zone: {
      name: '飯岡、上口、江与味、北、高下、小山、里、栃原、中、中垪和、西、西川、西川上、西垪和、東垪和及び南を除く。',
      scale: '',
    },
  },
  {
    code: '33666',
    distinct: '3',
    pref: '岡山県',
    county: {
      name: '久米',
      type: '郡',
      kana: 'くめ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美咲',
    type: '町',
    kana: 'みさき',
    typeKana: 'ちょう',
    compartmentCode: '538-2',
    zone: {
      name: '飯岡及び高下に限る。',
      scale: '',
    },
  },
  {
    code: '33681',
    distinct: '1',
    pref: '岡山県',
    county: {
      name: '加賀',
      type: '郡',
      kana: 'かが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉備中央',
    type: '町',
    kana: 'きびちゅうおう',
    typeKana: 'ちょう',
    compartmentCode: '526',
    zone: {
      name: '上竹、北、黒土、黒山、岨谷、竹荘、田土、豊野、西、納地、宮地、湯山及び吉川に限る。',
      scale: '',
    },
  },
  {
    code: '33681',
    distinct: '2',
    pref: '岡山県',
    county: {
      name: '加賀',
      type: '郡',
      kana: 'かが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉備中央',
    type: '町',
    kana: 'きびちゅうおう',
    typeKana: 'ちょう',
    compartmentCode: '531',
    zone: {
      name: '上竹、北、黒土、黒山、岨谷、竹荘、田土、豊野、西、納地、宮地、湯山及び吉川を除く。',
      scale: '',
    },
  },
  {
    code: '34100',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34100',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '467',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34101',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '中',
    type: '区',
    kana: 'なか',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34102',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34103',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34104',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34105',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '安佐南',
    type: '区',
    kana: 'あさみなみ',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34106',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '安佐北',
    type: '区',
    kana: 'あさきた',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34107',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '安芸',
    type: '区',
    kana: 'あき',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34108',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '佐伯',
    type: '区',
    kana: 'さえき',
    typeKana: 'く',
    compartmentCode: '450',
    zone: {
      name: '杉並台及び湯来町を除く。',
      scale: '',
    },
  },
  {
    code: '34108',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '広島',
      type: '市',
      kana: 'ひろしま',
      typeKana: 'し',
      note: '',
    },
    name: '佐伯',
    type: '区',
    kana: 'さえき',
    typeKana: 'く',
    compartmentCode: '467',
    zone: {
      name: '杉並台及び湯来町に限る。',
      scale: '',
    },
  },
  {
    code: '34202',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '呉',
    type: '市',
    kana: 'くれ',
    typeKana: 'し',
    compartmentCode: '453',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34203',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '竹原',
    type: '市',
    kana: 'たけはら',
    typeKana: 'し',
    compartmentCode: '486',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34204',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三原',
    type: '市',
    kana: 'みはら',
    typeKana: 'し',
    compartmentCode: '489-3',
    zone: {
      name: '久井町及び大和町に限る。',
      scale: '',
    },
  },
  {
    code: '34204',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三原',
    type: '市',
    kana: 'みはら',
    typeKana: 'し',
    compartmentCode: '496-2',
    zone: {
      name: '久井町及び大和町を除く。',
      scale: '',
    },
  },
  {
    code: '34205',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '尾道',
    type: '市',
    kana: 'おのみち',
    typeKana: 'し',
    compartmentCode: '485',
    zone: {
      name: '因島大浜町、因島鏡浦町、因島重井町、因島洲江町、因島田熊町、因島外浦町、因島中庄町、因島土生町、因島原町、因島三庄町、因島椋浦町及び瀬戸田町に限る。',
      scale: '',
    },
  },
  {
    code: '34205',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '尾道',
    type: '市',
    kana: 'おのみち',
    typeKana: 'し',
    compartmentCode: '496-2',
    zone: {
      name: '因島大浜町、因島鏡浦町、因島重井町、因島洲江町、因島田熊町、因島外浦町、因島中庄町、因島土生町、因島原町、因島三庄町、因島椋浦町及び瀬戸田町を除く。',
      scale: '',
    },
  },
  {
    code: '34207',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福山',
    type: '市',
    kana: 'ふくやま',
    typeKana: 'し',
    compartmentCode: '488-2',
    zone: {
      name: '新市町',
      scale: '',
    },
  },
  {
    code: '34207',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福山',
    type: '市',
    kana: 'ふくやま',
    typeKana: 'し',
    compartmentCode: '498',
    zone: {
      name: '今津町、金江町金見、金江町藁江、神村町、高西町川尻、高西町真田、高西町南、東村町、藤江町、本郷町、松永町、南松永町、宮前町及び柳津町に限る。',
      scale: '',
    },
  },
  {
    code: '34207',
    distinct: '3',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福山',
    type: '市',
    kana: 'ふくやま',
    typeKana: 'し',
    compartmentCode: '499',
    zone: {
      name: '今津町、金江町金見、金江町藁江、神村町、新市町、高西町川尻、高西町真田、高西町南、東村町、藤江町、本郷町、松永町、南松永町、宮前町及び柳津町を除く。',
      scale: '',
    },
  },
  {
    code: '34208',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '府中',
    type: '市',
    kana: 'ふちゅう',
    typeKana: 'し',
    compartmentCode: '488-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34209',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三次',
    type: '市',
    kana: 'みよし',
    typeKana: 'し',
    compartmentCode: '454',
    zone: {
      name: '甲奴町を除く。',
      scale: '',
    },
  },
  {
    code: '34209',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三次',
    type: '市',
    kana: 'みよし',
    typeKana: 'し',
    compartmentCode: '488-2',
    zone: {
      name: '甲奴町',
      scale: '',
    },
  },
  {
    code: '34210',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '庄原',
    type: '市',
    kana: 'しょうばら',
    typeKana: 'し',
    compartmentCode: '456-2',
    zone: {
      name: '東城町を除く。',
      scale: '',
    },
  },
  {
    code: '34210',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '庄原',
    type: '市',
    kana: 'しょうばら',
    typeKana: 'し',
    compartmentCode: '494',
    zone: {
      name: '東城町',
      scale: '',
    },
  },
  {
    code: '34211',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大竹',
    type: '市',
    kana: 'おおたけ',
    typeKana: 'し',
    compartmentCode: '465-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34212',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東広島',
    type: '市',
    kana: 'ひがしひろしま',
    typeKana: 'し',
    compartmentCode: '453',
    zone: {
      name: '黒瀬学園台、黒瀬春日野、黒瀬切田が丘、黒瀬桜が丘、黒瀬町市飯田、黒瀬町大多田、黒瀬町小多田、黒瀬町兼沢、黒瀬町兼広、黒瀬町上保田、黒瀬町川角、黒瀬町切田、黒瀬町国近、黒瀬町菅田、黒瀬町津江、黒瀬町乃美尾、黒瀬町丸山、黒瀬町南方、黒瀬町宗近柳国、黒瀬楢原北、黒瀬楢原西、黒瀬楢原東及び黒瀬松ケ丘に限る。',
      scale: '',
    },
  },
  {
    code: '34212',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東広島',
    type: '市',
    kana: 'ひがしひろしま',
    typeKana: 'し',
    compartmentCode: '455',
    zone: {
      name: '安芸津町、黒瀬学園台、黒瀬春日野、黒瀬切田が丘、黒瀬桜が丘、黒瀬町市飯田、黒瀬町大多田、黒瀬町小多田、黒瀬町兼沢、黒瀬町兼広、黒瀬町上保田、黒瀬町川角、黒瀬町切田、黒瀬町国近、黒瀬町菅田、黒瀬町津江、黒瀬町乃美尾、黒瀬町丸山、黒瀬町南方、黒瀬町宗近柳国、黒瀬楢原北、黒瀬楢原西、黒瀬楢原東及び黒瀬松ケ丘を除く。',
      scale: '',
    },
  },
  {
    code: '34212',
    distinct: '3',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東広島',
    type: '市',
    kana: 'ひがしひろしま',
    typeKana: 'し',
    compartmentCode: '486',
    zone: {
      name: '安芸津町',
      scale: '',
    },
  },
  {
    code: '34213',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '廿日市',
    type: '市',
    kana: 'はつかいち',
    typeKana: 'し',
    compartmentCode: '467',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34214',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '安芸高田',
    type: '市',
    kana: 'あきたかた',
    typeKana: 'し',
    compartmentCode: '462',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34215',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '江田島',
    type: '市',
    kana: 'えたじま',
    typeKana: 'し',
    compartmentCode: '453',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34302',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '府中',
    type: '町',
    kana: 'ふちゅう',
    typeKana: 'ちょう',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34304',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '海田',
    type: '町',
    kana: 'かいた',
    typeKana: 'ちょう',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34307',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '熊野',
    type: '町',
    kana: 'くまの',
    typeKana: 'ちょう',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34309',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '坂',
    type: '町',
    kana: 'さか',
    typeKana: 'ちょう',
    compartmentCode: '450',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34368',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '山県',
      type: '郡',
      kana: 'やまがた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '安芸太田',
    type: '町',
    kana: 'あきおおた',
    typeKana: 'ちょう',
    compartmentCode: '463-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34369',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '山県',
      type: '郡',
      kana: 'やまがた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北広島',
    type: '町',
    kana: 'きたひろしま',
    typeKana: 'ちょう',
    compartmentCode: '461',
    zone: {
      name: '雲耕、移原、大暮、大利原、大元、奥中原、奥原、苅屋形、川小田、草安、荒神原、小原、才乙、高野、土橋、中祖、南門原、西八幡原、橋山、東八幡原、細見、政所、溝口、宮地及び米沢を除く。',
      scale: '',
    },
  },
  {
    code: '34369',
    distinct: '2',
    pref: '広島県',
    county: {
      name: '山県',
      type: '郡',
      kana: 'やまがた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北広島',
    type: '町',
    kana: 'きたひろしま',
    typeKana: 'ちょう',
    compartmentCode: '463-2',
    zone: {
      name: '雲耕、移原、大暮、大利原、大元、奥中原、奥原、苅屋形、川小田、草安、荒神原、小原、才乙、高野、土橋、中祖、南門原、西八幡原、橋山、東八幡原、細見、政所、溝口、宮地及び米沢に限る。',
      scale: '',
    },
  },
  {
    code: '34431',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '豊田',
      type: '郡',
      kana: 'とよた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大崎上島',
    type: '町',
    kana: 'おおさきかみじま',
    typeKana: 'ちょう',
    compartmentCode: '487',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34462',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '世羅',
      type: '郡',
      kana: 'せら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '世羅',
    type: '町',
    kana: 'せら',
    typeKana: 'ちょう',
    compartmentCode: '489-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '34545',
    distinct: '1',
    pref: '広島県',
    county: {
      name: '神石',
      type: '郡',
      kana: 'じんせき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神石高原',
    type: '町',
    kana: 'じんせきこうげん',
    typeKana: 'ちょう',
    compartmentCode: '495',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35201',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '下関',
    type: '市',
    kana: 'しものせき',
    typeKana: 'し',
    compartmentCode: '468-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35202',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇部',
    type: '市',
    kana: 'うべ',
    typeKana: 'し',
    compartmentCode: '472',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35203',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山口',
    type: '市',
    kana: 'やまぐち',
    typeKana: 'し',
    compartmentCode: '471',
    zone: {
      name: '徳地伊賀地、徳地小古祖、徳地上村、徳地岸見、徳地串、徳地鯖河内、徳地島地、徳地野谷、徳地引谷、徳地深谷、徳地藤木、徳地船路、徳地堀、徳地三谷、徳地八坂、徳地山畑及び徳地柚木に限る。',
      scale: '',
    },
  },
  {
    code: '35203',
    distinct: '2',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山口',
    type: '市',
    kana: 'やまぐち',
    typeKana: 'し',
    compartmentCode: '472',
    zone: {
      name: '阿知須',
      scale: '',
    },
  },
  {
    code: '35203',
    distinct: '3',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山口',
    type: '市',
    kana: 'やまぐち',
    typeKana: 'し',
    compartmentCode: '481-3',
    zone: {
      name: '阿知須、徳地伊賀地、徳地小古祖、徳地上村、徳地岸見、徳地串、徳地鯖河内、徳地島地、徳地野谷、徳地引谷、徳地深谷、徳地藤木、徳地船路、徳地堀、徳地三谷、徳地八坂、徳地山畑及び徳地柚木を除く。',
      scale: '',
    },
  },
  {
    code: '35204',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '萩',
    type: '市',
    kana: 'はぎ',
    typeKana: 'し',
    compartmentCode: '477',
    zone: {
      name: '江崎、片俣、上小川西分、上小川東分、上田万、吉部上、吉部下、下小川、下田万、須佐、鈴野川、高佐上、高佐下、中小川、弥富上及び弥富下を除く。',
      scale: '',
    },
  },
  {
    code: '35204',
    distinct: '2',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '萩',
    type: '市',
    kana: 'はぎ',
    typeKana: 'し',
    compartmentCode: '478',
    zone: {
      name: '江崎、上小川西分、上小川東分、上田万、下小川、下田万、須佐、鈴野川、中小川、弥富上及び弥富下に限る。',
      scale: '',
    },
  },
  {
    code: '35204',
    distinct: '3',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '萩',
    type: '市',
    kana: 'はぎ',
    typeKana: 'し',
    compartmentCode: '479',
    zone: {
      name: '片俣、吉部上、吉部下、高佐上及び高佐下に限る。',
      scale: '',
    },
  },
  {
    code: '35206',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '防府',
    type: '市',
    kana: 'ほうふ',
    typeKana: 'し',
    compartmentCode: '471',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35207',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '下松',
    type: '市',
    kana: 'くだまつ',
    typeKana: 'し',
    compartmentCode: '469',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35208',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '岩国',
    type: '市',
    kana: 'いわくに',
    typeKana: 'し',
    compartmentCode: '465-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35210',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '光',
    type: '市',
    kana: 'ひかり',
    typeKana: 'し',
    compartmentCode: '451',
    zone: {
      name: '岩田、岩田立野、塩田、束荷及び三輪に限る。',
      scale: '',
    },
  },
  {
    code: '35210',
    distinct: '2',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '光',
    type: '市',
    kana: 'ひかり',
    typeKana: 'し',
    compartmentCode: '469',
    zone: {
      name: '岩田、岩田立野、塩田、束荷及び三輪を除く。',
      scale: '',
    },
  },
  {
    code: '35211',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長門',
    type: '市',
    kana: 'ながと',
    typeKana: 'し',
    compartmentCode: '474',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35212',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '柳井',
    type: '市',
    kana: 'やない',
    typeKana: 'し',
    compartmentCode: '451',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35213',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '美祢',
    type: '市',
    kana: 'みね',
    typeKana: 'し',
    compartmentCode: '475-2',
    zone: {
      name: '美東町を除く。',
      scale: '',
    },
  },
  {
    code: '35213',
    distinct: '2',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '美祢',
    type: '市',
    kana: 'みね',
    typeKana: 'し',
    compartmentCode: '483',
    zone: {
      name: '美東町',
      scale: '',
    },
  },
  {
    code: '35215',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '周南',
    type: '市',
    kana: 'しゅうなん',
    typeKana: 'し',
    compartmentCode: '469',
    zone: {
      name: '大河内、奥関屋、勝間ケ丘、勝間原、熊毛中央、御所尾原、小松原、幸ケ丘、自由ケ丘、新清光台、清光台、清尾、高水原、鶴見台、中村、原、樋口、緑ケ丘、八代、安田、夢ケ丘、呼坂及び呼坂本町に限る｡',
      scale: '',
    },
  },
  {
    code: '35215',
    distinct: '2',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '周南',
    type: '市',
    kana: 'しゅうなん',
    typeKana: 'し',
    compartmentCode: '470',
    zone: {
      name: '大河内、奥関屋、勝間ケ丘、勝間原、熊毛中央、御所尾原、小松原、幸ケ丘、自由ケ丘、新清光台、清光台、清尾、高水原、鶴見台、中村、原、樋口、緑ケ丘、八代、安田、夢ケ丘、呼坂及び呼坂本町を除く｡ ',
      scale: '',
    },
  },
  {
    code: '35216',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山陽小野田',
    type: '市',
    kana: 'さんようおのだ',
    typeKana: 'し',
    compartmentCode: '472',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35305',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '周防大島',
    type: '町',
    kana: 'すおうおおしま',
    typeKana: 'ちょう',
    compartmentCode: '452',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35321',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '玖珂',
      type: '郡',
      kana: 'くが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '和木',
    type: '町',
    kana: 'わき',
    typeKana: 'ちょう',
    compartmentCode: '465-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35341',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '熊毛',
      type: '郡',
      kana: 'くまげ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上関',
    type: '町',
    kana: 'かみのせき',
    typeKana: 'ちょう',
    compartmentCode: '451',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35343',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '熊毛',
      type: '郡',
      kana: 'くまげ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田布施',
    type: '町',
    kana: 'たぶせ',
    typeKana: 'ちょう',
    compartmentCode: '451',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35344',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '熊毛',
      type: '郡',
      kana: 'くまげ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '平生',
    type: '町',
    kana: 'ひらお',
    typeKana: 'ちょう',
    compartmentCode: '451',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '35502',
    distinct: '1',
    pref: '山口県',
    county: {
      name: '阿武',
      type: '郡',
      kana: 'あぶ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '阿武',
    type: '町',
    kana: 'あぶ',
    typeKana: 'ちょう',
    compartmentCode: '479',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36201',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '徳島',
    type: '市',
    kana: 'とくしま',
    typeKana: 'し',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36202',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鳴門',
    type: '市',
    kana: 'なると',
    typeKana: 'し',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36203',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小松島',
    type: '市',
    kana: 'こまつしま',
    typeKana: 'し',
    compartmentCode: '555-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36204',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '阿南',
    type: '市',
    kana: 'あなん',
    typeKana: 'し',
    compartmentCode: '552',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36205',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '吉野川',
    type: '市',
    kana: 'よしのがわ',
    typeKana: 'し',
    compartmentCode: '549',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36206',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '阿波',
    type: '市',
    kana: 'あわ',
    typeKana: 'し',
    compartmentCode: '549',
    zone: {
      name: '秋月、浦池、柿原、郡、五条、西条、高尾、土成、成当、水田、宮川内及び吉田を除く。',
      scale: '',
    },
  },
  {
    code: '36206',
    distinct: '2',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '阿波',
    type: '市',
    kana: 'あわ',
    typeKana: 'し',
    compartmentCode: '557-2',
    zone: {
      name: '秋月、浦池、柿原、郡、五条、西条、高尾、土成、成当、水田、宮川内及び吉田に限る。',
      scale: '',
    },
  },
  {
    code: '36207',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '美馬',
    type: '市',
    kana: 'みま',
    typeKana: 'し',
    compartmentCode: '550',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36208',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三好',
    type: '市',
    kana: 'みよし',
    typeKana: 'し',
    compartmentCode: '551',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36301',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '勝浦',
      type: '郡',
      kana: 'かつうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '勝浦',
    type: '町',
    kana: 'かつうら',
    typeKana: 'ちょう',
    compartmentCode: '555-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36302',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '勝浦',
      type: '郡',
      kana: 'かつうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上勝',
    type: '町',
    kana: 'かみかつ',
    typeKana: 'ちょう',
    compartmentCode: '555-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36321',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '名東',
      type: '郡',
      kana: 'みょうどう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '佐那河内',
    type: '村',
    kana: 'さなごうち',
    typeKana: 'そん',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36341',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '名西',
      type: '郡',
      kana: 'みょうざい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '石井',
    type: '町',
    kana: 'いしい',
    typeKana: 'ちょう',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36342',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '名西',
      type: '郡',
      kana: 'みょうざい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '神山',
    type: '町',
    kana: 'かみやま',
    typeKana: 'ちょう',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36368',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '那賀',
      type: '郡',
      kana: 'なか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '那賀',
    type: '町',
    kana: 'なか',
    typeKana: 'ちょう',
    compartmentCode: '553',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36383',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '海部',
      type: '郡',
      kana: 'かいふ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '牟岐',
    type: '町',
    kana: 'むぎ',
    typeKana: 'ちょう',
    compartmentCode: '554-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36387',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '海部',
      type: '郡',
      kana: 'かいふ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美波',
    type: '町',
    kana: 'みなみ',
    typeKana: 'ちょう',
    compartmentCode: '554-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36388',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '海部',
      type: '郡',
      kana: 'かいふ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '海陽',
    type: '町',
    kana: 'かいよう',
    typeKana: 'ちょう',
    compartmentCode: '554-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36401',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '板野',
      type: '郡',
      kana: 'いたの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松茂',
    type: '町',
    kana: 'まつしげ',
    typeKana: 'ちょう',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36402',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '板野',
      type: '郡',
      kana: 'いたの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北島',
    type: '町',
    kana: 'きたじま',
    typeKana: 'ちょう',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36403',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '板野',
      type: '郡',
      kana: 'いたの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '藍住',
    type: '町',
    kana: 'あいずみ',
    typeKana: 'ちょう',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36404',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '板野',
      type: '郡',
      kana: 'いたの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '板野',
    type: '町',
    kana: 'いたの',
    typeKana: 'ちょう',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36405',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '板野',
      type: '郡',
      kana: 'いたの',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上板',
    type: '町',
    kana: 'かみいた',
    typeKana: 'ちょう',
    compartmentCode: '557-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36468',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '美馬',
      type: '郡',
      kana: 'みま',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'つるぎ',
    type: '町',
    kana: 'つるぎ',
    typeKana: 'ちょう',
    compartmentCode: '550',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '36489',
    distinct: '1',
    pref: '徳島県',
    county: {
      name: '三好',
      type: '郡',
      kana: 'みよし',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東みよし',
    type: '町',
    kana: 'ひがしみよし',
    typeKana: 'ちょう',
    compartmentCode: '551',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37201',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高松',
    type: '市',
    kana: 'たかまつ',
    typeKana: 'し',
    compartmentCode: '542',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37202',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '丸亀',
    type: '市',
    kana: 'まるがめ',
    typeKana: 'し',
    compartmentCode: '541',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37203',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '坂出',
    type: '市',
    kana: 'さかいで',
    typeKana: 'し',
    compartmentCode: '541',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37204',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '善通寺',
    type: '市',
    kana: 'ぜんつうじ',
    typeKana: 'し',
    compartmentCode: '541',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37205',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '観音寺',
    type: '市',
    kana: 'かんおんじ',
    typeKana: 'し',
    compartmentCode: '540',
    zone: {
      name: '豊浜町箕浦を除く。',
      scale: '',
    },
  },
  {
    code: '37205',
    distinct: '2',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '観音寺',
    type: '市',
    kana: 'かんおんじ',
    typeKana: 'し',
    compartmentCode: '572',
    zone: {
      name: '豊浜町箕浦',
      scale: '',
    },
  },
  {
    code: '37206',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'さぬき',
    type: '市',
    kana: 'さぬき',
    typeKana: 'し',
    compartmentCode: '542',
    zone: {
      name: '小田、鴨部、鴨庄、志度及び末に限る。',
      scale: '',
    },
  },
  {
    code: '37206',
    distinct: '2',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'さぬき',
    type: '市',
    kana: 'さぬき',
    typeKana: 'し',
    compartmentCode: '543',
    zone: {
      name: '小田、鴨部、鴨庄、志度及び末を除く。',
      scale: '',
    },
  },
  {
    code: '37207',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東かがわ',
    type: '市',
    kana: 'ひがしかがわ',
    typeKana: 'し',
    compartmentCode: '543',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37208',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '三豊',
    type: '市',
    kana: 'みとよ',
    typeKana: 'し',
    compartmentCode: '540',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37322',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '小豆',
      type: '郡',
      kana: 'しょうず',
      typeKana: 'ぐん',
      note: '',
    },
    name: '土庄',
    type: '町',
    kana: 'とのしょう',
    typeKana: 'ちょう',
    compartmentCode: '544',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37324',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '小豆',
      type: '郡',
      kana: 'しょうず',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小豆島',
    type: '町',
    kana: 'しょうどしま',
    typeKana: 'ちょう',
    compartmentCode: '544',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37341',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '木田',
      type: '郡',
      kana: 'きた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三木',
    type: '町',
    kana: 'みき',
    typeKana: 'ちょう',
    compartmentCode: '542',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37364',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '香川',
      type: '郡',
      kana: 'かがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '直島',
    type: '町',
    kana: 'なおしま',
    typeKana: 'ちょう',
    compartmentCode: '542',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37386',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '綾歌',
      type: '郡',
      kana: 'あやうた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宇多津',
    type: '町',
    kana: 'うたづ',
    typeKana: 'ちょう',
    compartmentCode: '541',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37387',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '綾歌',
      type: '郡',
      kana: 'あやうた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '綾川',
    type: '町',
    kana: 'あやがわ',
    typeKana: 'ちょう',
    compartmentCode: '542',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37403',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '仲多度',
      type: '郡',
      kana: 'なかたど',
      typeKana: 'ぐん',
      note: '',
    },
    name: '琴平',
    type: '町',
    kana: 'ことひら',
    typeKana: 'ちょう',
    compartmentCode: '541',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37404',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '仲多度',
      type: '郡',
      kana: 'なかたど',
      typeKana: 'ぐん',
      note: '',
    },
    name: '多度津',
    type: '町',
    kana: 'たどつ',
    typeKana: 'ちょう',
    compartmentCode: '541',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '37406',
    distinct: '1',
    pref: '香川県',
    county: {
      name: '仲多度',
      type: '郡',
      kana: 'なかたど',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'まんのう',
    type: '町',
    kana: 'まんのう',
    typeKana: 'ちょう',
    compartmentCode: '541',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38201',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松山',
    type: '市',
    kana: 'まつやま',
    typeKana: 'し',
    compartmentCode: '576',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38202',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '今治',
    type: '市',
    kana: 'いまばり',
    typeKana: 'し',
    compartmentCode: '573',
    zone: {
      name: '大三島町、上浦町、関前大下、関前岡村、関前小大下、伯方町、宮窪町及び吉海町に限る。',
      scale: '',
    },
  },
  {
    code: '38202',
    distinct: '2',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '今治',
    type: '市',
    kana: 'いまばり',
    typeKana: 'し',
    compartmentCode: '575',
    zone: {
      name: '大三島町、上浦町、関前大下、関前岡村、関前小大下、伯方町、宮窪町及び吉海町を除く。',
      scale: '',
    },
  },
  {
    code: '38203',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇和島',
    type: '市',
    kana: 'うわじま',
    typeKana: 'し',
    compartmentCode: '570',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38204',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八幡浜',
    type: '市',
    kana: 'やわたはま',
    typeKana: 'し',
    compartmentCode: '568',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38205',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '新居浜',
    type: '市',
    kana: 'にいはま',
    typeKana: 'し',
    compartmentCode: '574',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38206',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西条',
    type: '市',
    kana: 'さいじょう',
    typeKana: 'し',
    compartmentCode: '574',
    zone: {
      name: '明理川、石田、石延、今在家、円海寺、大新田、大野、上市、河之内、河原津、河原津新田、喜多台、楠、国安、黒谷、桑村、小松町、周布、新市、新町、実報寺、高田、玉之江、旦之上、丹原町、壬生川、広江、広岡、福成寺、北条、三津屋、三津屋東、三津屋南、宮之内、三芳、安用、安用出作及び吉田を除く。',
      scale: '',
    },
  },
  {
    code: '38206',
    distinct: '2',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西条',
    type: '市',
    kana: 'さいじょう',
    typeKana: 'し',
    compartmentCode: '575',
    zone: {
      name: '明理川、石田、石延、今在家、円海寺、大新田、大野、上市、河之内、河原津、河原津新田、喜多台、楠、国安、黒谷、桑村、小松町、実報寺、周布、新市、新町、高田、玉之江、旦之上、丹原町、壬生川、広江、広岡、福成寺、北条、三津屋、三津屋東、三津屋南、宮之内、三芳、安用、安用出作及び吉田に限る。',
      scale: '',
    },
  },
  {
    code: '38207',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大洲',
    type: '市',
    kana: 'おおず',
    typeKana: 'し',
    compartmentCode: '567',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38210',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊予',
    type: '市',
    kana: 'いよ',
    typeKana: 'し',
    compartmentCode: '576',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38213',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '四国中央',
    type: '市',
    kana: 'しこくちゅうおう',
    typeKana: 'し',
    compartmentCode: '572',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38214',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西予',
    type: '市',
    kana: 'せいよ',
    typeKana: 'し',
    compartmentCode: '568',
    zone: {
      name: '三瓶町',
      scale: '',
    },
  },
  {
    code: '38214',
    distinct: '2',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西予',
    type: '市',
    kana: 'せいよ',
    typeKana: 'し',
    compartmentCode: '569',
    zone: {
      name: '三瓶町を除く。',
      scale: '',
    },
  },
  {
    code: '38215',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '東温',
    type: '市',
    kana: 'とうおん',
    typeKana: 'し',
    compartmentCode: '576',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38356',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '越智',
      type: '郡',
      kana: 'おち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上島',
    type: '町',
    kana: 'かみじま',
    typeKana: 'ちょう',
    compartmentCode: '573',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38386',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '上浮穴',
      type: '郡',
      kana: 'かみうけな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '久万高原',
    type: '町',
    kana: 'くまこうげん',
    typeKana: 'ちょう',
    compartmentCode: '566',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38401',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '伊予',
      type: '郡',
      kana: 'いよ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松前',
    type: '町',
    kana: 'まさき',
    typeKana: 'ちょう',
    compartmentCode: '576',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38402',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '伊予',
      type: '郡',
      kana: 'いよ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '砥部',
    type: '町',
    kana: 'とべ',
    typeKana: 'ちょう',
    compartmentCode: '576',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38422',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '喜多',
      type: '郡',
      kana: 'きた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '内子',
    type: '町',
    kana: 'うちこ',
    typeKana: 'ちょう',
    compartmentCode: '566',
    zone: {
      name: '臼杵、大平、小田、上川、上田渡、立石、寺村、中川、中田渡、日野川、本川、南山及び吉野川に限る。',
      scale: '',
    },
  },
  {
    code: '38422',
    distinct: '2',
    pref: '愛媛県',
    county: {
      name: '喜多',
      type: '郡',
      kana: 'きた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '内子',
    type: '町',
    kana: 'うちこ',
    typeKana: 'ちょう',
    compartmentCode: '567',
    zone: {
      name: '臼杵、大平、小田、上川、上田渡、立石、寺村、中川、中田渡、日野川、本川、南山及び吉野川を除く。',
      scale: '',
    },
  },
  {
    code: '38442',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '西宇和',
      type: '郡',
      kana: 'にしうわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伊方',
    type: '町',
    kana: 'いかた',
    typeKana: 'ちょう',
    compartmentCode: '568',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38484',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '北宇和',
      type: '郡',
      kana: 'きたうわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '松野',
    type: '町',
    kana: 'まつの',
    typeKana: 'ちょう',
    compartmentCode: '570',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38488',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '北宇和',
      type: '郡',
      kana: 'きたうわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鬼北',
    type: '町',
    kana: 'きほく',
    typeKana: 'ちょう',
    compartmentCode: '570',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '38506',
    distinct: '1',
    pref: '愛媛県',
    county: {
      name: '南宇和',
      type: '郡',
      kana: 'みなみうわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '愛南',
    type: '町',
    kana: 'あいなん',
    typeKana: 'ちょう',
    compartmentCode: '571',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39201',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '高知',
    type: '市',
    kana: 'こうち',
    typeKana: 'し',
    compartmentCode: '563-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39202',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '室戸',
    type: '市',
    kana: 'むろと',
    typeKana: 'し',
    compartmentCode: '559-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39203',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '安芸',
    type: '市',
    kana: 'あき',
    typeKana: 'し',
    compartmentCode: '560-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39204',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南国',
    type: '市',
    kana: 'なんこく',
    typeKana: 'し',
    compartmentCode: '563-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39205',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '土佐',
    type: '市',
    kana: 'とさ',
    typeKana: 'し',
    compartmentCode: '563-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39206',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '須崎',
    type: '市',
    kana: 'すさき',
    typeKana: 'し',
    compartmentCode: '563-2',
    zone: {
      name: '浦ノ内出見、浦ノ内今川内、浦ノ内塩間、浦ノ内下中山、浦ノ内灰方及び浦ノ内福良に限る。',
      scale: '',
    },
  },
  {
    code: '39206',
    distinct: '2',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '須崎',
    type: '市',
    kana: 'すさき',
    typeKana: 'し',
    compartmentCode: '565',
    zone: {
      name: '浦ノ内出見、浦ノ内今川内、浦ノ内塩間、浦ノ内下中山、浦ノ内灰方及び浦ノ内福良を除く。',
      scale: '',
    },
  },
  {
    code: '39208',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宿毛',
    type: '市',
    kana: 'すくも',
    typeKana: 'し',
    compartmentCode: '546',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39209',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '土佐清水',
    type: '市',
    kana: 'とさしみず',
    typeKana: 'し',
    compartmentCode: '548',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39210',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '四万十',
    type: '市',
    kana: 'しまんと',
    typeKana: 'し',
    compartmentCode: '545',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39211',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '香南',
    type: '市',
    kana: 'こうなん',
    typeKana: 'し',
    compartmentCode: '562-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39212',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '香美',
    type: '市',
    kana: 'かみ',
    typeKana: 'し',
    compartmentCode: '562-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39301',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東洋',
    type: '町',
    kana: 'とうよう',
    typeKana: 'ちょう',
    compartmentCode: '559-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39302',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '奈半利',
    type: '町',
    kana: 'なはり',
    typeKana: 'ちょう',
    compartmentCode: '560-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39303',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '田野',
    type: '町',
    kana: 'たの',
    typeKana: 'ちょう',
    compartmentCode: '560-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39304',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '安田',
    type: '町',
    kana: 'やすだ',
    typeKana: 'ちょう',
    compartmentCode: '560-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39305',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北川',
    type: '村',
    kana: 'きたがわ',
    typeKana: 'むら',
    compartmentCode: '560-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39306',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '馬路',
    type: '村',
    kana: 'うまじ',
    typeKana: 'むら',
    compartmentCode: '560-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39307',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '安芸',
      type: '郡',
      kana: 'あき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '芸西',
    type: '村',
    kana: 'げいせい',
    typeKana: 'むら',
    compartmentCode: '560-3',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39341',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '長岡',
      type: '郡',
      kana: 'ながおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '本山',
    type: '町',
    kana: 'もとやま',
    typeKana: 'ちょう',
    compartmentCode: '558',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39344',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '長岡',
      type: '郡',
      kana: 'ながおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大豊',
    type: '町',
    kana: 'おおとよ',
    typeKana: 'ちょう',
    compartmentCode: '558',
    zone: {
      name: '馬瀬、角茂谷、久寿軒及び戸手野を除く。',
      scale: '',
    },
  },
  {
    code: '39344',
    distinct: '2',
    pref: '高知県',
    county: {
      name: '長岡',
      type: '郡',
      kana: 'ながおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大豊',
    type: '町',
    kana: 'おおとよ',
    typeKana: 'ちょう',
    compartmentCode: '562-2',
    zone: {
      name: '馬瀬、角茂谷、久寿軒及び戸手野に限る。',
      scale: '',
    },
  },
  {
    code: '39363',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '土佐',
      type: '郡',
      kana: 'とさ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '土佐',
    type: '町',
    kana: 'とさ',
    typeKana: 'ちょう',
    compartmentCode: '558',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39364',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '土佐',
      type: '郡',
      kana: 'とさ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大川',
    type: '村',
    kana: 'おおかわ',
    typeKana: 'むら',
    compartmentCode: '558',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39386',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '吾川',
      type: '郡',
      kana: 'あがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'いの',
    type: '町',
    kana: 'いの',
    typeKana: 'ちょう',
    compartmentCode: '563-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39387',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '吾川',
      type: '郡',
      kana: 'あがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '仁淀川',
    type: '町',
    kana: 'によどがわ',
    typeKana: 'ちょう',
    compartmentCode: '564',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39401',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '高岡',
      type: '郡',
      kana: 'たかおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中土佐',
    type: '町',
    kana: 'なかとさ',
    typeKana: 'ちょう',
    compartmentCode: '565',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39402',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '高岡',
      type: '郡',
      kana: 'たかおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '佐川',
    type: '町',
    kana: 'さかわ',
    typeKana: 'ちょう',
    compartmentCode: '564',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39403',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '高岡',
      type: '郡',
      kana: 'たかおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '越知',
    type: '町',
    kana: 'おち',
    typeKana: 'ちょう',
    compartmentCode: '564',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39405',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '高岡',
      type: '郡',
      kana: 'たかおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '檮原',
    type: '町',
    kana: 'ゆすはら',
    typeKana: 'ちょう',
    compartmentCode: '565',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39410',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '高岡',
      type: '郡',
      kana: 'たかおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日高',
    type: '村',
    kana: 'ひだか',
    typeKana: 'むら',
    compartmentCode: '564',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39411',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '高岡',
      type: '郡',
      kana: 'たかおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '津野',
    type: '町',
    kana: 'つの',
    typeKana: 'ちょう',
    compartmentCode: '565',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39412',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '高岡',
      type: '郡',
      kana: 'たかおか',
      typeKana: 'ぐん',
      note: '',
    },
    name: '四万十',
    type: '町',
    kana: 'しまんと',
    typeKana: 'ちょう',
    compartmentCode: '547-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39424',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '幡多',
      type: '郡',
      kana: 'はた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大月',
    type: '町',
    kana: 'おおつき',
    typeKana: 'ちょう',
    compartmentCode: '546',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39427',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '幡多',
      type: '郡',
      kana: 'はた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三原',
    type: '村',
    kana: 'みはら',
    typeKana: 'むら',
    compartmentCode: '545',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '39428',
    distinct: '1',
    pref: '高知県',
    county: {
      name: '幡多',
      type: '郡',
      kana: 'はた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '黒潮',
    type: '町',
    kana: 'くろしお',
    typeKana: 'ちょう',
    compartmentCode: '545',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40100',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40101',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '門司',
    type: '区',
    kana: 'もじ',
    typeKana: 'く',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40103',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '若松',
    type: '区',
    kana: 'わかまつ',
    typeKana: 'く',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40105',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '戸畑',
    type: '区',
    kana: 'とばた',
    typeKana: 'く',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40106',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '小倉北',
    type: '区',
    kana: 'こくらきた',
    typeKana: 'く',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40107',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '小倉南',
    type: '区',
    kana: 'こくらみなみ',
    typeKana: 'く',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40108',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '八幡東',
    type: '区',
    kana: 'やはたひがし',
    typeKana: 'く',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40109',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '北九州',
      type: '市',
      kana: 'きたきゅうしゅう',
      typeKana: 'し',
      note: '',
    },
    name: '八幡西',
    type: '区',
    kana: 'やはたにし',
    typeKana: 'く',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40130',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40131',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40132',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '博多',
    type: '区',
    kana: 'はかた',
    typeKana: 'く',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40133',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40134',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40135',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40136',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '城南',
    type: '区',
    kana: 'じょうなん',
    typeKana: 'く',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40137',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '福岡',
      type: '市',
      kana: 'ふくおか',
      typeKana: 'し',
      note: '',
    },
    name: '早良',
    type: '区',
    kana: 'さわら',
    typeKana: 'く',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40202',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大牟田',
    type: '市',
    kana: 'おおむた',
    typeKana: 'し',
    compartmentCode: '588',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40203',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '久留米',
    type: '市',
    kana: 'くるめ',
    typeKana: 'し',
    compartmentCode: '585',
    zone: {
      name: '田主丸町を除く。',
      scale: '',
    },
  },
  {
    code: '40203',
    distinct: '2',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '久留米',
    type: '市',
    kana: 'くるめ',
    typeKana: 'し',
    compartmentCode: '587',
    zone: {
      name: '田主丸町',
      scale: '',
    },
  },
  {
    code: '40204',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '直方',
    type: '市',
    kana: 'のおがた',
    typeKana: 'し',
    compartmentCode: '592',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40205',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '飯塚',
    type: '市',
    kana: 'いいづか',
    typeKana: 'し',
    compartmentCode: '591',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40206',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '田川',
    type: '市',
    kana: 'たがわ',
    typeKana: 'し',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40207',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '柳川',
    type: '市',
    kana: 'やながわ',
    typeKana: 'し',
    compartmentCode: '588',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40210',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八女',
    type: '市',
    kana: 'やめ',
    typeKana: 'し',
    compartmentCode: '586',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40211',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '筑後',
    type: '市',
    kana: 'ちくご',
    typeKana: 'し',
    compartmentCode: '585',
    zone: {
      name: '下妻、富安及び馬間田を除く。',
      scale: '',
    },
  },
  {
    code: '40211',
    distinct: '2',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '筑後',
    type: '市',
    kana: 'ちくご',
    typeKana: 'し',
    compartmentCode: '588',
    zone: {
      name: '下妻、富安及び馬間田に限る。',
      scale: '',
    },
  },
  {
    code: '40212',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大川',
    type: '市',
    kana: 'おおかわ',
    typeKana: 'し',
    compartmentCode: '588',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40213',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '行橋',
    type: '市',
    kana: 'ゆくはし',
    typeKana: 'し',
    compartmentCode: '583',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40214',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊前',
    type: '市',
    kana: 'ぶぜん',
    typeKana: 'し',
    compartmentCode: '630',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40215',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中間',
    type: '市',
    kana: 'なかま',
    typeKana: 'し',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40216',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小郡',
    type: '市',
    kana: 'おごおり',
    typeKana: 'し',
    compartmentCode: '585',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40217',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '筑紫野',
    type: '市',
    kana: 'ちくしの',
    typeKana: 'し',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40218',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '春日',
    type: '市',
    kana: 'かすが',
    typeKana: 'し',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40219',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大野城',
    type: '市',
    kana: 'おおのじょう',
    typeKana: 'し',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40220',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宗像',
    type: '市',
    kana: 'むなかた',
    typeKana: 'し',
    compartmentCode: '584',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40221',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '太宰府',
    type: '市',
    kana: 'だざいふ',
    typeKana: 'し',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40223',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '古賀',
    type: '市',
    kana: 'こが',
    typeKana: 'し',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40224',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '福津',
    type: '市',
    kana: 'ふくつ',
    typeKana: 'し',
    compartmentCode: '584',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40225',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'うきは',
    type: '市',
    kana: 'うきは',
    typeKana: 'し',
    compartmentCode: '587',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40226',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宮若',
    type: '市',
    kana: 'みやわか',
    typeKana: 'し',
    compartmentCode: '592',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40227',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '嘉麻',
    type: '市',
    kana: 'かま',
    typeKana: 'し',
    compartmentCode: '591',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40228',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '朝倉',
    type: '市',
    kana: 'あさくら',
    typeKana: 'し',
    compartmentCode: '589',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40229',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'みやま',
    type: '市',
    kana: 'みやま',
    typeKana: 'し',
    compartmentCode: '585',
    zone: {
      name: '瀬高町長田',
      scale: '',
    },
  },
  {
    code: '40229',
    distinct: '2',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'みやま',
    type: '市',
    kana: 'みやま',
    typeKana: 'し',
    compartmentCode: '588',
    zone: {
      name: '瀬高町長田を除く。',
      scale: '',
    },
  },
  {
    code: '40230',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '糸島',
    type: '市',
    kana: 'いとしま',
    typeKana: 'し',
    compartmentCode: '577',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40231',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '那珂川',
    type: '市',
    kana: 'なかがわ',
    typeKana: 'し',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40341',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '糟屋',
      type: '郡',
      kana: 'かすや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宇美',
    type: '町',
    kana: 'うみ',
    typeKana: 'まち',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40342',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '糟屋',
      type: '郡',
      kana: 'かすや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '篠栗',
    type: '町',
    kana: 'ささぐり',
    typeKana: 'まち',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40343',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '糟屋',
      type: '郡',
      kana: 'かすや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '志免',
    type: '町',
    kana: 'しめ',
    typeKana: 'まち',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40344',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '糟屋',
      type: '郡',
      kana: 'かすや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '須恵',
    type: '町',
    kana: 'すえ',
    typeKana: 'まち',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40345',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '糟屋',
      type: '郡',
      kana: 'かすや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新宮',
    type: '町',
    kana: 'しんぐう',
    typeKana: 'まち',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40348',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '糟屋',
      type: '郡',
      kana: 'かすや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '久山',
    type: '町',
    kana: 'ひさやま',
    typeKana: 'まち',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40349',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '糟屋',
      type: '郡',
      kana: 'かすや',
      typeKana: 'ぐん',
      note: '',
    },
    name: '粕屋',
    type: '町',
    kana: 'かすや',
    typeKana: 'まち',
    compartmentCode: '578',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40381',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '遠賀',
      type: '郡',
      kana: 'おんが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '芦屋',
    type: '町',
    kana: 'あしや',
    typeKana: 'まち',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40382',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '遠賀',
      type: '郡',
      kana: 'おんが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '水巻',
    type: '町',
    kana: 'みずまき',
    typeKana: 'まち',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40383',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '遠賀',
      type: '郡',
      kana: 'おんが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '岡垣',
    type: '町',
    kana: 'おかがき',
    typeKana: 'まち',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40384',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '遠賀',
      type: '郡',
      kana: 'おんが',
      typeKana: 'ぐん',
      note: '',
    },
    name: '遠賀',
    type: '町',
    kana: 'おんが',
    typeKana: 'ちょう',
    compartmentCode: '582',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40401',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '鞍手',
      type: '郡',
      kana: 'くらて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小竹',
    type: '町',
    kana: 'こたけ',
    typeKana: 'まち',
    compartmentCode: '593',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40402',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '鞍手',
      type: '郡',
      kana: 'くらて',
      typeKana: 'ぐん',
      note: '',
    },
    name: '鞍手',
    type: '町',
    kana: 'くらて',
    typeKana: 'まち',
    compartmentCode: '592',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40421',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '嘉穂',
      type: '郡',
      kana: 'かほ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '桂川',
    type: '町',
    kana: 'けいせん',
    typeKana: 'まち',
    compartmentCode: '591',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40447',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '朝倉',
      type: '郡',
      kana: 'あさくら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '筑前',
    type: '町',
    kana: 'ちくぜん',
    typeKana: 'まち',
    compartmentCode: '589',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40448',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '朝倉',
      type: '郡',
      kana: 'あさくら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東峰',
    type: '村',
    kana: 'とうほう',
    typeKana: 'むら',
    compartmentCode: '589',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40503',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '三井',
      type: '郡',
      kana: 'みい',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大刀洗',
    type: '町',
    kana: 'たちあらい',
    typeKana: 'まち',
    compartmentCode: '585',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40522',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '三潴',
      type: '郡',
      kana: 'みづま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大木',
    type: '町',
    kana: 'おおき',
    typeKana: 'まち',
    compartmentCode: '588',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40544',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '八女',
      type: '郡',
      kana: 'やめ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '広川',
    type: '町',
    kana: 'ひろかわ',
    typeKana: 'まち',
    compartmentCode: '585',
    zone: {
      name: '広川及び藤田に限る。',
      scale: '',
    },
  },
  {
    code: '40544',
    distinct: '2',
    pref: '福岡県',
    county: {
      name: '八女',
      type: '郡',
      kana: 'やめ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '広川',
    type: '町',
    kana: 'ひろかわ',
    typeKana: 'まち',
    compartmentCode: '586',
    zone: {
      name: '広川及び藤田を除く。',
      scale: '',
    },
  },
  {
    code: '40601',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '田川',
      type: '郡',
      kana: 'たがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '香春',
    type: '町',
    kana: 'かわら',
    typeKana: 'まち',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40602',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '田川',
      type: '郡',
      kana: 'たがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '添田',
    type: '町',
    kana: 'そえだ',
    typeKana: 'まち',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40604',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '田川',
      type: '郡',
      kana: 'たがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '糸田',
    type: '町',
    kana: 'いとだ',
    typeKana: 'まち',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40605',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '田川',
      type: '郡',
      kana: 'たがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川崎',
    type: '町',
    kana: 'かわさき',
    typeKana: 'まち',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40608',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '田川',
      type: '郡',
      kana: 'たがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大任',
    type: '町',
    kana: 'おおとう',
    typeKana: 'まち',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40609',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '田川',
      type: '郡',
      kana: 'たがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '赤',
    type: '村',
    kana: 'あか',
    typeKana: 'むら',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40610',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '田川',
      type: '郡',
      kana: 'たがわ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '福智',
    type: '町',
    kana: 'ふくち',
    typeKana: 'まち',
    compartmentCode: '590',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40621',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '京都',
      type: '郡',
      kana: 'みやこ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '苅田',
    type: '町',
    kana: 'かんだ',
    typeKana: 'まち',
    compartmentCode: '582',
    zone: {
      name: '与原、新津、下新津、二崎、下片島、稲光、法正寺、葛川、上片島、谷、鋤崎、山口、岡崎及び黒添を除く。',
      scale: '',
    },
  },
  {
    code: '40621',
    distinct: '2',
    pref: '福岡県',
    county: {
      name: '京都',
      type: '郡',
      kana: 'みやこ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '苅田',
    type: '町',
    kana: 'かんだ',
    typeKana: 'まち',
    compartmentCode: '583',
    zone: {
      name: '与原、新津、下新津、二崎、下片島、稲光、法正寺、葛川、上片島、谷、鋤崎、山口、岡崎及び黒添に限る。',
      scale: '',
    },
  },
  {
    code: '40625',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '京都',
      type: '郡',
      kana: 'みやこ',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'みやこ',
    type: '町',
    kana: 'みやこ',
    typeKana: 'まち',
    compartmentCode: '583',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40642',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '築上',
      type: '郡',
      kana: 'ちくじょう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉富',
    type: '町',
    kana: 'よしとみ',
    typeKana: 'まち',
    compartmentCode: '630',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40646',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '築上',
      type: '郡',
      kana: 'ちくじょう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上毛',
    type: '町',
    kana: 'こうげ',
    typeKana: 'まち',
    compartmentCode: '630',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '40647',
    distinct: '1',
    pref: '福岡県',
    county: {
      name: '築上',
      type: '郡',
      kana: 'ちくじょう',
      typeKana: 'ぐん',
      note: '',
    },
    name: '築上',
    type: '町',
    kana: 'ちくじょう',
    typeKana: 'まち',
    compartmentCode: '583',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41201',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐賀',
    type: '市',
    kana: 'さが',
    typeKana: 'し',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41202',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '唐津',
    type: '市',
    kana: 'からつ',
    typeKana: 'し',
    compartmentCode: '598',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41203',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鳥栖',
    type: '市',
    kana: 'とす',
    typeKana: 'し',
    compartmentCode: '585',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41204',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '多久',
    type: '市',
    kana: 'たく',
    typeKana: 'し',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41205',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊万里',
    type: '市',
    kana: 'いまり',
    typeKana: 'し',
    compartmentCode: '599',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41206',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '武雄',
    type: '市',
    kana: 'たけお',
    typeKana: 'し',
    compartmentCode: '596',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41207',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鹿島',
    type: '市',
    kana: 'かしま',
    typeKana: 'し',
    compartmentCode: '597',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41208',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小城',
    type: '市',
    kana: 'おぎ',
    typeKana: 'し',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41209',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '嬉野',
    type: '市',
    kana: 'うれしの',
    typeKana: 'し',
    compartmentCode: '596',
    zone: {
      name: '嬉野町',
      scale: '',
    },
  },
  {
    code: '41209',
    distinct: '2',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '嬉野',
    type: '市',
    kana: 'うれしの',
    typeKana: 'し',
    compartmentCode: '597',
    zone: {
      name: '嬉野町を除く。',
      scale: '',
    },
  },
  {
    code: '41210',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '神埼',
    type: '市',
    kana: 'かんざき',
    typeKana: 'し',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41327',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '神埼',
      type: '郡',
      kana: 'かんざき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '吉野ヶ里',
    type: '町',
    kana: 'よしのがり',
    typeKana: 'ちょう',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41341',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '三養基',
      type: '郡',
      kana: 'みやき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '基山',
    type: '町',
    kana: 'きやま',
    typeKana: 'ちょう',
    compartmentCode: '585',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41345',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '三養基',
      type: '郡',
      kana: 'みやき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '上峰',
    type: '町',
    kana: 'かみみね',
    typeKana: 'ちょう',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41346',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '三養基',
      type: '郡',
      kana: 'みやき',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'みやき',
    type: '町',
    kana: 'みやき',
    typeKana: 'ちょう',
    compartmentCode: '585',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41387',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '東松浦',
      type: '郡',
      kana: 'ひがしまつうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '玄海',
    type: '町',
    kana: 'げんかい',
    typeKana: 'ちょう',
    compartmentCode: '598',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41401',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '西松浦',
      type: '郡',
      kana: 'にしまつうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '有田',
    type: '町',
    kana: 'ありた',
    typeKana: 'ちょう',
    compartmentCode: '599',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41423',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '杵島',
      type: '郡',
      kana: 'きしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大町',
    type: '町',
    kana: 'おおまち',
    typeKana: 'ちょう',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41424',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '杵島',
      type: '郡',
      kana: 'きしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '江北',
    type: '町',
    kana: 'こうほく',
    typeKana: 'まち',
    compartmentCode: '595',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '41425',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '杵島',
      type: '郡',
      kana: 'きしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白石',
    type: '町',
    kana: 'しろいし',
    typeKana: 'ちょう',
    compartmentCode: '595',
    zone: {
      name: '牛屋、坂田、新開、新明、田野上、戸ヶ里、深浦及び辺田を除く。',
      scale: '',
    },
  },
  {
    code: '41425',
    distinct: '2',
    pref: '佐賀県',
    county: {
      name: '杵島',
      type: '郡',
      kana: 'きしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '白石',
    type: '町',
    kana: 'しろいし',
    typeKana: 'ちょう',
    compartmentCode: '597',
    zone: {
      name: '牛屋、坂田、新開、新明、田野上、戸ヶ里、深浦及び辺田に限る。',
      scale: '',
    },
  },
  {
    code: '41441',
    distinct: '1',
    pref: '佐賀県',
    county: {
      name: '藤津',
      type: '郡',
      kana: 'ふじつ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '太良',
    type: '町',
    kana: 'たら',
    typeKana: 'ちょう',
    compartmentCode: '597',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42201',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長崎',
    type: '市',
    kana: 'ながさき',
    typeKana: 'し',
    compartmentCode: '603',
    zone: {
      name: '赤首町、池島町、上大野町、上黒崎町、神浦江川町、神浦扇山町、神浦上大中尾町、神浦上道徳町、神浦北大中尾町、神浦口福町、神浦下大中尾町、神浦下道徳町、神浦夏井町、神浦丸尾町、神浦向町、下大野町、下黒崎町、新牧野町、永田町、西出津町及び東出津町を除く。',
      scale: '',
    },
  },
  {
    code: '42201',
    distinct: '2',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '長崎',
    type: '市',
    kana: 'ながさき',
    typeKana: 'し',
    compartmentCode: '604',
    zone: {
      name: '赤首町、池島町、上大野町、上黒崎町、神浦江川町、神浦扇山町、神浦上大中尾町、神浦上道徳町、神浦北大中尾町、神浦口福町、神浦下大中尾町、神浦下道徳町、神浦夏井町、神浦丸尾町、神浦向町、下大野町、下黒崎町、新牧野町、永田町、西出津町及び東出津町に限る。',
      scale: '',
    },
  },
  {
    code: '42202',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐世保',
    type: '市',
    kana: 'させぼ',
    typeKana: 'し',
    compartmentCode: '600',
    zone: {
      name: '宇久町を除く。',
      scale: '',
    },
  },
  {
    code: '42202',
    distinct: '2',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐世保',
    type: '市',
    kana: 'させぼ',
    typeKana: 'し',
    compartmentCode: '606',
    zone: {
      name: '宇久町',
      scale: '',
    },
  },
  {
    code: '42203',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '島原',
    type: '市',
    kana: 'しまばら',
    typeKana: 'し',
    compartmentCode: '602',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42204',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '諫早',
    type: '市',
    kana: 'いさはや',
    typeKana: 'し',
    compartmentCode: '601',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42205',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大村',
    type: '市',
    kana: 'おおむら',
    typeKana: 'し',
    compartmentCode: '601',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42207',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '平戸',
    type: '市',
    kana: 'ひらど',
    typeKana: 'し',
    compartmentCode: '594',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42208',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松浦',
    type: '市',
    kana: 'まつうら',
    typeKana: 'し',
    compartmentCode: '599',
    zone: {
      name: '鷹島町及び福島町に限る。',
      scale: '',
    },
  },
  {
    code: '42208',
    distinct: '2',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '松浦',
    type: '市',
    kana: 'まつうら',
    typeKana: 'し',
    compartmentCode: '600',
    zone: {
      name: '鷹島町及び福島町を除く。',
      scale: '',
    },
  },
  {
    code: '42209',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '対馬',
    type: '市',
    kana: 'つしま',
    typeKana: 'し',
    compartmentCode: '580',
    zone: {
      name: '厳原町、豊玉町及び美津島町に限る｡',
      scale: '',
    },
  },
  {
    code: '42209',
    distinct: '2',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '対馬',
    type: '市',
    kana: 'つしま',
    typeKana: 'し',
    compartmentCode: '581',
    zone: {
      name: '上県町、上対馬町及び峰町に限る｡ ',
      scale: '',
    },
  },
  {
    code: '42210',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '壱岐',
    type: '市',
    kana: 'いき',
    typeKana: 'し',
    compartmentCode: '579',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42211',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '五島',
    type: '市',
    kana: 'ごとう',
    typeKana: 'し',
    compartmentCode: '605',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42212',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西海',
    type: '市',
    kana: 'さいかい',
    typeKana: 'し',
    compartmentCode: '604',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42213',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '雲仙',
    type: '市',
    kana: 'うんぜん',
    typeKana: 'し',
    compartmentCode: '601',
    zone: {
      name: '愛野町、吾妻町及び千々石町に限る。',
      scale: '',
    },
  },
  {
    code: '42213',
    distinct: '2',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '雲仙',
    type: '市',
    kana: 'うんぜん',
    typeKana: 'し',
    compartmentCode: '602',
    zone: {
      name: '愛野町、吾妻町及び千々石町を除く。',
      scale: '',
    },
  },
  {
    code: '42214',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南島原',
    type: '市',
    kana: 'みなみしまばら',
    typeKana: 'し',
    compartmentCode: '602',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42307',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '西彼杵',
      type: '郡',
      kana: 'にしそのぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長与',
    type: '町',
    kana: 'ながよ',
    typeKana: 'ちょう',
    compartmentCode: '603',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42308',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '西彼杵',
      type: '郡',
      kana: 'にしそのぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '時津',
    type: '町',
    kana: 'とぎつ',
    typeKana: 'ちょう',
    compartmentCode: '603',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42321',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '東彼杵',
      type: '郡',
      kana: 'ひがしそのぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東彼杵',
    type: '町',
    kana: 'ひがしそのぎ',
    typeKana: 'ちょう',
    compartmentCode: '601',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42322',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '東彼杵',
      type: '郡',
      kana: 'ひがしそのぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川棚',
    type: '町',
    kana: 'かわたな',
    typeKana: 'ちょう',
    compartmentCode: '600',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42323',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '東彼杵',
      type: '郡',
      kana: 'ひがしそのぎ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '波佐見',
    type: '町',
    kana: 'はさみ',
    typeKana: 'ちょう',
    compartmentCode: '600',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42383',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '北松浦',
      type: '郡',
      kana: 'きたまつうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小値賀',
    type: '町',
    kana: 'おぢか',
    typeKana: 'ちょう',
    compartmentCode: '606',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42391',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '北松浦',
      type: '郡',
      kana: 'きたまつうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '佐々',
    type: '町',
    kana: 'さざ',
    typeKana: 'ちょう',
    compartmentCode: '600',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '42411',
    distinct: '1',
    pref: '長崎県',
    county: {
      name: '南松浦',
      type: '郡',
      kana: 'みなみまつうら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新上五島',
    type: '町',
    kana: 'しんかみごとう',
    typeKana: 'ちょう',
    compartmentCode: '606',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43100',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43100',
    distinct: '2',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '',
    type: '',
    kana: '',
    typeKana: '',
    compartmentCode: '608',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43101',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '中央',
    type: '区',
    kana: 'ちゅうおう',
    typeKana: 'く',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43102',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '東',
    type: '区',
    kana: 'ひがし',
    typeKana: 'く',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43103',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '西',
    type: '区',
    kana: 'にし',
    typeKana: 'く',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43104',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '607',
    zone: {
      name: '城南町赤見、城南町阿高、城南町碇、城南町出水、城南町今吉野、城南町隈庄、城南町坂野、城南町さんさん、城南町沈目、城南町島田、城南町下宮地、城南町陳内、城南町高、城南町千町、城南町築地、城南町塚原、城南町永、城南町丹生宮、城南町東阿高、城南町藤山、城南町舞原、城南町宮地、城南町六田及び城南町鰐瀬を除く。',
      scale: '',
    },
  },
  {
    code: '43104',
    distinct: '2',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '南',
    type: '区',
    kana: 'みなみ',
    typeKana: 'く',
    compartmentCode: '608',
    zone: {
      name: '城南町赤見、城南町阿高、城南町碇、城南町出水、城南町今吉野、城南町隈庄、城南町坂野、城南町さんさん、城南町沈目、城南町島田、城南町下宮地、城南町陳内、城南町高、城南町千町、城南町築地、城南町塚原、城南町永、城南町丹生宮、城南町東阿高、城南町藤山、城南町舞原、城南町宮地、城南町六田及び城南町鰐瀬に限る。',
      scale: '',
    },
  },
  {
    code: '43105',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '熊本',
      type: '市',
      kana: 'くまもと',
      typeKana: 'し',
      note: '',
    },
    name: '北',
    type: '区',
    kana: 'きた',
    typeKana: 'く',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43202',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '八代',
    type: '市',
    kana: 'やつしろ',
    typeKana: 'し',
    compartmentCode: '609',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43203',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '人吉',
    type: '市',
    kana: 'ひとよし',
    typeKana: 'し',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43204',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '荒尾',
    type: '市',
    kana: 'あらお',
    typeKana: 'し',
    compartmentCode: '588',
    zone: {
      name: '上井手及び下井手に限る。',
      scale: '',
    },
  },
  {
    code: '43204',
    distinct: '2',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '荒尾',
    type: '市',
    kana: 'あらお',
    typeKana: 'し',
    compartmentCode: '616',
    zone: {
      name: '上井手及び下井手を除く。',
      scale: '',
    },
  },
  {
    code: '43205',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '水俣',
    type: '市',
    kana: 'みなまた',
    typeKana: 'し',
    compartmentCode: '611',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43206',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '玉名',
    type: '市',
    kana: 'たまな',
    typeKana: 'し',
    compartmentCode: '616',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43208',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '山鹿',
    type: '市',
    kana: 'やまが',
    typeKana: 'し',
    compartmentCode: '615',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43210',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '菊池',
    type: '市',
    kana: 'きくち',
    typeKana: 'し',
    compartmentCode: '615',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43211',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇土',
    type: '市',
    kana: 'うと',
    typeKana: 'し',
    compartmentCode: '608',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43212',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上天草',
    type: '市',
    kana: 'かみあまくさ',
    typeKana: 'し',
    compartmentCode: '608',
    zone: {
      name: '大矢野町',
      scale: '',
    },
  },
  {
    code: '43212',
    distinct: '2',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '上天草',
    type: '市',
    kana: 'かみあまくさ',
    typeKana: 'し',
    compartmentCode: '617-2',
    zone: {
      name: '大矢野町を除く。',
      scale: '',
    },
  },
  {
    code: '43213',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇城',
    type: '市',
    kana: 'うき',
    typeKana: 'し',
    compartmentCode: '608',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43214',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '阿蘇',
    type: '市',
    kana: 'あそ',
    typeKana: 'し',
    compartmentCode: '612',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43215',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '天草',
    type: '市',
    kana: 'あまくさ',
    typeKana: 'し',
    compartmentCode: '617-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43216',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '合志',
    type: '市',
    kana: 'こうし',
    typeKana: 'し',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43348',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '下益城',
      type: '郡',
      kana: 'しもましき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美里',
    type: '町',
    kana: 'みさと',
    typeKana: 'まち',
    compartmentCode: '608',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43364',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '玉名',
      type: '郡',
      kana: 'たまな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '玉東',
    type: '町',
    kana: 'ぎょくとう',
    typeKana: 'まち',
    compartmentCode: '616',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43367',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '玉名',
      type: '郡',
      kana: 'たまな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南関',
    type: '町',
    kana: 'なんかん',
    typeKana: 'まち',
    compartmentCode: '616',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43368',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '玉名',
      type: '郡',
      kana: 'たまな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長洲',
    type: '町',
    kana: 'ながす',
    typeKana: 'まち',
    compartmentCode: '616',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43369',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '玉名',
      type: '郡',
      kana: 'たまな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '和水',
    type: '町',
    kana: 'なごみ',
    typeKana: 'まち',
    compartmentCode: '615',
    zone: {
      name: '板楠、岩、大田黒、上板楠、上十町、上和仁、津田、中十町、中林、中和仁、西吉地、野田、東吉地、平野、山十町及び和仁に限る。',
      scale: '',
    },
  },
  {
    code: '43369',
    distinct: '2',
    pref: '熊本県',
    county: {
      name: '玉名',
      type: '郡',
      kana: 'たまな',
      typeKana: 'ぐん',
      note: '',
    },
    name: '和水',
    type: '町',
    kana: 'なごみ',
    typeKana: 'まち',
    compartmentCode: '616',
    zone: {
      name: '板楠、岩、大田黒、上板楠、上十町、上和仁、津田、中十町、中林、中和仁、西吉地、野田、東吉地、平野、山十町及び和仁を除く。',
      scale: '',
    },
  },
  {
    code: '43403',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '菊池',
      type: '郡',
      kana: 'きくち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大津',
    type: '町',
    kana: 'おおづ',
    typeKana: 'まち',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43404',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '菊池',
      type: '郡',
      kana: 'きくち',
      typeKana: 'ぐん',
      note: '',
    },
    name: '菊陽',
    type: '町',
    kana: 'きくよう',
    typeKana: 'まち',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43423',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '阿蘇',
      type: '郡',
      kana: 'あそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南小国',
    type: '町',
    kana: 'みなみおぐに',
    typeKana: 'まち',
    compartmentCode: '612',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43424',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '阿蘇',
      type: '郡',
      kana: 'あそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '小国',
    type: '町',
    kana: 'おぐに',
    typeKana: 'まち',
    compartmentCode: '612',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43425',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '阿蘇',
      type: '郡',
      kana: 'あそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '産山',
    type: '村',
    kana: 'うぶやま',
    typeKana: 'むら',
    compartmentCode: '612',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43428',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '阿蘇',
      type: '郡',
      kana: 'あそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高森',
    type: '町',
    kana: 'たかもり',
    typeKana: 'まち',
    compartmentCode: '614',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43432',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '阿蘇',
      type: '郡',
      kana: 'あそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西原',
    type: '村',
    kana: 'にしはら',
    typeKana: 'むら',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43433',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '阿蘇',
      type: '郡',
      kana: 'あそ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南阿蘇',
    type: '村',
    kana: 'みなみあそ',
    typeKana: 'むら',
    compartmentCode: '614',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43441',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '上益城',
      type: '郡',
      kana: 'かみましき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '御船',
    type: '町',
    kana: 'みふね',
    typeKana: 'まち',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43442',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '上益城',
      type: '郡',
      kana: 'かみましき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '嘉島',
    type: '町',
    kana: 'かしま',
    typeKana: 'まち',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43443',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '上益城',
      type: '郡',
      kana: 'かみましき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '益城',
    type: '町',
    kana: 'ましき',
    typeKana: 'まち',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43444',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '上益城',
      type: '郡',
      kana: 'かみましき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '甲佐',
    type: '町',
    kana: 'こうさ',
    typeKana: 'まち',
    compartmentCode: '607',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43447',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '上益城',
      type: '郡',
      kana: 'かみましき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山都',
    type: '町',
    kana: 'やまと',
    typeKana: 'ちょう',
    compartmentCode: '613',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43468',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '八代',
      type: '郡',
      kana: 'やつしろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '氷川',
    type: '町',
    kana: 'ひかわ',
    typeKana: 'ちょう',
    compartmentCode: '608',
    zone: {
      name: '高塚及び吉本に限る。',
      scale: '',
    },
  },
  {
    code: '43468',
    distinct: '2',
    pref: '熊本県',
    county: {
      name: '八代',
      type: '郡',
      kana: 'やつしろ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '氷川',
    type: '町',
    kana: 'ひかわ',
    typeKana: 'ちょう',
    compartmentCode: '609',
    zone: {
      name: '高塚及び吉本を除く。',
      scale: '',
    },
  },
  {
    code: '43482',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '葦北',
      type: '郡',
      kana: 'あしきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '芦北',
    type: '町',
    kana: 'あしきた',
    typeKana: 'まち',
    compartmentCode: '611',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43484',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '葦北',
      type: '郡',
      kana: 'あしきた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '津奈木',
    type: '町',
    kana: 'つなぎ',
    typeKana: 'まち',
    compartmentCode: '611',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43501',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '錦',
    type: '町',
    kana: 'にしき',
    typeKana: 'まち',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43505',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '多良木',
    type: '町',
    kana: 'たらぎ',
    typeKana: 'まち',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43506',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湯前',
    type: '町',
    kana: 'ゆのまえ',
    typeKana: 'まち',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43507',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '水上',
    type: '村',
    kana: 'みずかみ',
    typeKana: 'むら',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43510',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '相良',
    type: '村',
    kana: 'さがら',
    typeKana: 'むら',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43511',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '五木',
    type: '村',
    kana: 'いつき',
    typeKana: 'むら',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43512',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '山江',
    type: '村',
    kana: 'やまえ',
    typeKana: 'むら',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43513',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '球磨',
    type: '村',
    kana: 'くま',
    typeKana: 'むら',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43514',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '球磨',
      type: '郡',
      kana: 'くま',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'あさぎり',
    type: '町',
    kana: 'あさぎり',
    typeKana: 'ちょう',
    compartmentCode: '610',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '43531',
    distinct: '1',
    pref: '熊本県',
    county: {
      name: '天草',
      type: '郡',
      kana: 'あまくさ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '苓北',
    type: '町',
    kana: 'れいほく',
    typeKana: 'まち',
    compartmentCode: '617-2',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44201',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '大分',
    type: '市',
    kana: 'おおいた',
    typeKana: 'し',
    compartmentCode: '625',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44202',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '別府',
    type: '市',
    kana: 'べっぷ',
    typeKana: 'し',
    compartmentCode: '626',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44203',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '中津',
    type: '市',
    kana: 'なかつ',
    typeKana: 'し',
    compartmentCode: '630',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44204',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日田',
    type: '市',
    kana: 'ひた',
    typeKana: 'し',
    compartmentCode: '621',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44205',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '佐伯',
    type: '市',
    kana: 'さいき',
    typeKana: 'し',
    compartmentCode: '619',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44206',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '臼杵',
    type: '市',
    kana: 'うすき',
    typeKana: 'し',
    compartmentCode: '620',
    zone: {
      name: '野津町を除く。',
      scale: '',
    },
  },
  {
    code: '44206',
    distinct: '2',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '臼杵',
    type: '市',
    kana: 'うすき',
    typeKana: 'し',
    compartmentCode: '623',
    zone: {
      name: '野津町',
      scale: '',
    },
  },
  {
    code: '44207',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '津久見',
    type: '市',
    kana: 'つくみ',
    typeKana: 'し',
    compartmentCode: '620',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44208',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '竹田',
    type: '市',
    kana: 'たけた',
    typeKana: 'し',
    compartmentCode: '624',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44209',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊後高田',
    type: '市',
    kana: 'ぶんごたかだ',
    typeKana: 'し',
    compartmentCode: '628',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44210',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '杵築',
    type: '市',
    kana: 'きつき',
    typeKana: 'し',
    compartmentCode: '626',
    zone: {
      name: '山香町',
      scale: '',
    },
  },
  {
    code: '44210',
    distinct: '2',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '杵築',
    type: '市',
    kana: 'きつき',
    typeKana: 'し',
    compartmentCode: '628',
    zone: {
      name: '大田石丸、大田小野、大田沓掛、大田白木原、大田永松、大田波多方及び大田俣水に限る。',
      scale: '',
    },
  },
  {
    code: '44210',
    distinct: '3',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '杵築',
    type: '市',
    kana: 'きつき',
    typeKana: 'し',
    compartmentCode: '629',
    zone: {
      name: '大田石丸、大田小野、大田沓掛、大田白木原、大田永松、大田波多方、大田俣水及び山香町を除く。',
      scale: '',
    },
  },
  {
    code: '44211',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宇佐',
    type: '市',
    kana: 'うさ',
    typeKana: 'し',
    compartmentCode: '628',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44212',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊後大野',
    type: '市',
    kana: 'ぶんごおおの',
    typeKana: 'し',
    compartmentCode: '623',
    zone: {
      name: '朝地町及び犬飼町を除く。',
      scale: '',
    },
  },
  {
    code: '44212',
    distinct: '2',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊後大野',
    type: '市',
    kana: 'ぶんごおおの',
    typeKana: 'し',
    compartmentCode: '624',
    zone: {
      name: '朝地町',
      scale: '',
    },
  },
  {
    code: '44212',
    distinct: '3',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊後大野',
    type: '市',
    kana: 'ぶんごおおの',
    typeKana: 'し',
    compartmentCode: '625',
    zone: {
      name: '犬飼町',
      scale: '',
    },
  },
  {
    code: '44213',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '由布',
    type: '市',
    kana: 'ゆふ',
    typeKana: 'し',
    compartmentCode: '625',
    zone: {
      name: '湯布院町を除く。',
      scale: '',
    },
  },
  {
    code: '44213',
    distinct: '2',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '由布',
    type: '市',
    kana: 'ゆふ',
    typeKana: 'し',
    compartmentCode: '626',
    zone: {
      name: '湯布院町',
      scale: '',
    },
  },
  {
    code: '44214',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '国東',
    type: '市',
    kana: 'くにさき',
    typeKana: 'し',
    compartmentCode: '627',
    zone: {
      name: '国東町及び国見町に限る。',
      scale: '',
    },
  },
  {
    code: '44214',
    distinct: '2',
    pref: '大分県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '国東',
    type: '市',
    kana: 'くにさき',
    typeKana: 'し',
    compartmentCode: '629',
    zone: {
      name: '国東町及び国見町を除く。',
      scale: '',
    },
  },
  {
    code: '44322',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '東国東',
      type: '郡',
      kana: 'ひがしくにさき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '姫島',
    type: '村',
    kana: 'ひめしま',
    typeKana: 'むら',
    compartmentCode: '627',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44341',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '速見',
      type: '郡',
      kana: 'はやみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日出',
    type: '町',
    kana: 'ひじ',
    typeKana: 'まち',
    compartmentCode: '626',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44461',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '玖珠',
      type: '郡',
      kana: 'くす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '九重',
    type: '町',
    kana: 'ここのえ',
    typeKana: 'まち',
    compartmentCode: '622',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '44462',
    distinct: '1',
    pref: '大分県',
    county: {
      name: '玖珠',
      type: '郡',
      kana: 'くす',
      typeKana: 'ぐん',
      note: '',
    },
    name: '玖珠',
    type: '町',
    kana: 'くす',
    typeKana: 'まち',
    compartmentCode: '622',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45201',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宮崎',
    type: '市',
    kana: 'みやざき',
    typeKana: 'し',
    compartmentCode: '641',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45202',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '都城',
    type: '市',
    kana: 'みやこのじょう',
    typeKana: 'し',
    compartmentCode: '642',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45203',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '延岡',
    type: '市',
    kana: 'のべおか',
    typeKana: 'し',
    compartmentCode: '636',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45204',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日南',
    type: '市',
    kana: 'にちなん',
    typeKana: 'し',
    compartmentCode: '643',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45205',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '小林',
    type: '市',
    kana: 'こばやし',
    typeKana: 'し',
    compartmentCode: '640',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45206',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日向',
    type: '市',
    kana: 'ひゅうが',
    typeKana: 'し',
    compartmentCode: '637',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45207',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '串間',
    type: '市',
    kana: 'くしま',
    typeKana: 'し',
    compartmentCode: '643',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45208',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西都',
    type: '市',
    kana: 'さいと',
    typeKana: 'し',
    compartmentCode: '639',
    zone: {
      name: '現王島を除く。',
      scale: '',
    },
  },
  {
    code: '45208',
    distinct: '2',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西都',
    type: '市',
    kana: 'さいと',
    typeKana: 'し',
    compartmentCode: '641',
    zone: {
      name: '現王島',
      scale: '',
    },
  },
  {
    code: '45209',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'えびの',
    type: '市',
    kana: 'えびの',
    typeKana: 'し',
    compartmentCode: '640',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45341',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '北諸県',
      type: '郡',
      kana: 'きたもろかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三股',
    type: '町',
    kana: 'みまた',
    typeKana: 'ちょう',
    compartmentCode: '642',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45361',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '西諸県',
      type: '郡',
      kana: 'にしもろかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高原',
    type: '町',
    kana: 'たかはる',
    typeKana: 'ちょう',
    compartmentCode: '640',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45382',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '東諸県',
      type: '郡',
      kana: 'ひがしもろかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '国富',
    type: '町',
    kana: 'くにとみ',
    typeKana: 'ちょう',
    compartmentCode: '641',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45383',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '東諸県',
      type: '郡',
      kana: 'ひがしもろかた',
      typeKana: 'ぐん',
      note: '',
    },
    name: '綾',
    type: '町',
    kana: 'あや',
    typeKana: 'ちょう',
    compartmentCode: '641',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45401',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '児湯',
      type: '郡',
      kana: 'こゆ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高鍋',
    type: '町',
    kana: 'たかなべ',
    typeKana: 'ちょう',
    compartmentCode: '639',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45402',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '児湯',
      type: '郡',
      kana: 'こゆ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '新富',
    type: '町',
    kana: 'しんとみ',
    typeKana: 'ちょう',
    compartmentCode: '639',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45403',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '児湯',
      type: '郡',
      kana: 'こゆ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西米良',
    type: '村',
    kana: 'にしめら',
    typeKana: 'そん',
    compartmentCode: '639',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45404',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '児湯',
      type: '郡',
      kana: 'こゆ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '木城',
    type: '町',
    kana: 'きじょう',
    typeKana: 'ちょう',
    compartmentCode: '636',
    zone: {
      name: '中之又',
      scale: '',
    },
  },
  {
    code: '45404',
    distinct: '2',
    pref: '宮崎県',
    county: {
      name: '児湯',
      type: '郡',
      kana: 'こゆ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '木城',
    type: '町',
    kana: 'きじょう',
    typeKana: 'ちょう',
    compartmentCode: '639',
    zone: {
      name: '中之又を除く。',
      scale: '',
    },
  },
  {
    code: '45405',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '児湯',
      type: '郡',
      kana: 'こゆ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '川南',
    type: '町',
    kana: 'かわみなみ',
    typeKana: 'ちょう',
    compartmentCode: '639',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45406',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '児湯',
      type: '郡',
      kana: 'こゆ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '都農',
    type: '町',
    kana: 'つの',
    typeKana: 'ちょう',
    compartmentCode: '639',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45421',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '東臼杵',
      type: '郡',
      kana: 'ひがしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '門川',
    type: '町',
    kana: 'かどがわ',
    typeKana: 'ちょう',
    compartmentCode: '637',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45429',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '東臼杵',
      type: '郡',
      kana: 'ひがしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '諸塚',
    type: '村',
    kana: 'もろつか',
    typeKana: 'そん',
    compartmentCode: '637',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45430',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '東臼杵',
      type: '郡',
      kana: 'ひがしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '椎葉',
    type: '村',
    kana: 'しいば',
    typeKana: 'そん',
    compartmentCode: '637',
    zone: {
      name: '大河内を除く。',
      scale: '',
    },
  },
  {
    code: '45430',
    distinct: '2',
    pref: '宮崎県',
    county: {
      name: '東臼杵',
      type: '郡',
      kana: 'ひがしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '椎葉',
    type: '村',
    kana: 'しいば',
    typeKana: 'そん',
    compartmentCode: '639',
    zone: {
      name: '大河内',
      scale: '',
    },
  },
  {
    code: '45431',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '東臼杵',
      type: '郡',
      kana: 'ひがしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '美郷',
    type: '町',
    kana: 'みさと',
    typeKana: 'ちょう',
    compartmentCode: '637',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45441',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '西臼杵',
      type: '郡',
      kana: 'にしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '高千穂',
    type: '町',
    kana: 'たかちほ',
    typeKana: 'ちょう',
    compartmentCode: '638',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45442',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '西臼杵',
      type: '郡',
      kana: 'にしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '日之影',
    type: '町',
    kana: 'ひのかげ',
    typeKana: 'ちょう',
    compartmentCode: '638',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '45443',
    distinct: '1',
    pref: '宮崎県',
    county: {
      name: '西臼杵',
      type: '郡',
      kana: 'にしうすき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '五ヶ瀬',
    type: '町',
    kana: 'ごかせ',
    typeKana: 'ちょう',
    compartmentCode: '638',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46201',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鹿児島',
    type: '市',
    kana: 'かごしま',
    typeKana: 'し',
    compartmentCode: '646',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46203',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鹿屋',
    type: '市',
    kana: 'かのや',
    typeKana: 'し',
    compartmentCode: '649',
    zone: {
      name: '輝北町',
      scale: '',
    },
  },
  {
    code: '46203',
    distinct: '2',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '鹿屋',
    type: '市',
    kana: 'かのや',
    typeKana: 'し',
    compartmentCode: '650',
    zone: {
      name: '輝北町を除く。',
      scale: '',
    },
  },
  {
    code: '46204',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '枕崎',
    type: '市',
    kana: 'まくらざき',
    typeKana: 'し',
    compartmentCode: '648',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46206',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '阿久根',
    type: '市',
    kana: 'あくね',
    typeKana: 'し',
    compartmentCode: '654',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46208',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '出水',
    type: '市',
    kana: 'いずみ',
    typeKana: 'し',
    compartmentCode: '654',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46210',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '指宿',
    type: '市',
    kana: 'いぶすき',
    typeKana: 'し',
    compartmentCode: '647',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46213',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '西之表',
    type: '市',
    kana: 'にしのおもて',
    typeKana: 'し',
    compartmentCode: '659',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46214',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '垂水',
    type: '市',
    kana: 'たるみず',
    typeKana: 'し',
    compartmentCode: '650',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46215',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '薩摩川内',
    type: '市',
    kana: 'さつませんだい',
    typeKana: 'し',
    compartmentCode: '655',
    zone: {
      name: '鹿島町、上甑町、里町及び下甑町を除く。',
      scale: '',
    },
  },
  {
    code: '46215',
    distinct: '2',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '薩摩川内',
    type: '市',
    kana: 'さつませんだい',
    typeKana: 'し',
    compartmentCode: '656',
    zone: {
      name: '鹿島町、上甑町、里町及び下甑町に限る。',
      scale: '',
    },
  },
  {
    code: '46216',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '日置',
    type: '市',
    kana: 'ひおき',
    typeKana: 'し',
    compartmentCode: '646',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46217',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '曽於',
    type: '市',
    kana: 'そお',
    typeKana: 'し',
    compartmentCode: '642',
    zone: {
      name: '大隅町を除く。',
      scale: '',
    },
  },
  {
    code: '46217',
    distinct: '2',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '曽於',
    type: '市',
    kana: 'そお',
    typeKana: 'し',
    compartmentCode: '649',
    zone: {
      name: '大隅町',
      scale: '',
    },
  },
  {
    code: '46218',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '霧島',
    type: '市',
    kana: 'きりしま',
    typeKana: 'し',
    compartmentCode: '652',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46219',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'いちき串木野',
    type: '市',
    kana: 'いちきくしきの',
    typeKana: 'し',
    compartmentCode: '655',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46220',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南さつま',
    type: '市',
    kana: 'みなみさつま',
    typeKana: 'し',
    compartmentCode: '648',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46221',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '志布志',
    type: '市',
    kana: 'しぶし',
    typeKana: 'し',
    compartmentCode: '649',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46222',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '奄美',
    type: '市',
    kana: 'あまみ',
    typeKana: 'し',
    compartmentCode: '657',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46223',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南九州',
    type: '市',
    kana: 'みなみきゅうしゅう',
    typeKana: 'し',
    compartmentCode: '647',
    zone: {
      name: '頴娃町',
      scale: '',
    },
  },
  {
    code: '46223',
    distinct: '2',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南九州',
    type: '市',
    kana: 'みなみきゅうしゅう',
    typeKana: 'し',
    compartmentCode: '648',
    zone: {
      name: '頴娃町を除く。',
      scale: '',
    },
  },
  {
    code: '46224',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '伊佐',
    type: '市',
    kana: 'いさ',
    typeKana: 'し',
    compartmentCode: '653',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46225',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '姶良',
    type: '市',
    kana: 'あいら',
    typeKana: 'し',
    compartmentCode: '652',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46303',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '鹿児島',
      type: '郡',
      kana: 'かごしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '三島',
    type: '村',
    kana: 'みしま',
    typeKana: 'むら',
    compartmentCode: '645',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46304',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '鹿児島',
      type: '郡',
      kana: 'かごしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '十島',
    type: '村',
    kana: 'としま',
    typeKana: 'むら',
    compartmentCode: '644',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46392',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '薩摩',
      type: '郡',
      kana: 'さつま',
      typeKana: 'ぐん',
      note: '',
    },
    name: 'さつま',
    type: '町',
    kana: 'さつま',
    typeKana: 'ちょう',
    compartmentCode: '655',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46404',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '出水',
      type: '郡',
      kana: 'いずみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '長島',
    type: '町',
    kana: 'ながしま',
    typeKana: 'ちょう',
    compartmentCode: '654',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46452',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '姶良',
      type: '郡',
      kana: 'あいら',
      typeKana: 'ぐん',
      note: '',
    },
    name: '湧水',
    type: '町',
    kana: 'ゆうすい',
    typeKana: 'ちょう',
    compartmentCode: '652',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46468',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '曽於',
      type: '郡',
      kana: 'そお',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大崎',
    type: '町',
    kana: 'おおさき',
    typeKana: 'ちょう',
    compartmentCode: '649',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46482',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '肝属',
      type: '郡',
      kana: 'きもつき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東串良',
    type: '町',
    kana: 'ひがしくしら',
    typeKana: 'ちょう',
    compartmentCode: '650',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46490',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '肝属',
      type: '郡',
      kana: 'きもつき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '錦江',
    type: '町',
    kana: 'きんこう',
    typeKana: 'ちょう',
    compartmentCode: '651',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46491',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '肝属',
      type: '郡',
      kana: 'きもつき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南大隅',
    type: '町',
    kana: 'みなみおおすみ',
    typeKana: 'ちょう',
    compartmentCode: '651',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46492',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '肝属',
      type: '郡',
      kana: 'きもつき',
      typeKana: 'ぐん',
      note: '',
    },
    name: '肝付',
    type: '町',
    kana: 'きもつき',
    typeKana: 'ちょう',
    compartmentCode: '650',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46501',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '熊毛',
      type: '郡',
      kana: 'くまげ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中種子',
    type: '町',
    kana: 'なかたね',
    typeKana: 'ちょう',
    compartmentCode: '659',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46502',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '熊毛',
      type: '郡',
      kana: 'くまげ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南種子',
    type: '町',
    kana: 'みなみたね',
    typeKana: 'ちょう',
    compartmentCode: '659',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46505',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '熊毛',
      type: '郡',
      kana: 'くまげ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '屋久島',
    type: '町',
    kana: 'やくしま',
    typeKana: 'ちょう',
    compartmentCode: '660',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46523',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大和',
    type: '村',
    kana: 'やまと',
    typeKana: 'そん',
    compartmentCode: '657',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46524',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宇検',
    type: '村',
    kana: 'うけん',
    typeKana: 'そん',
    compartmentCode: '657',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46525',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '瀬戸内',
    type: '町',
    kana: 'せとうち',
    typeKana: 'ちょう',
    compartmentCode: '661',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46527',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '龍郷',
    type: '町',
    kana: 'たつごう',
    typeKana: 'ちょう',
    compartmentCode: '657',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46529',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '喜界',
    type: '町',
    kana: 'きかい',
    typeKana: 'ちょう',
    compartmentCode: '657',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46530',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '徳之島',
    type: '町',
    kana: 'とくのしま',
    typeKana: 'ちょう',
    compartmentCode: '658',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46531',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '天城',
    type: '町',
    kana: 'あまぎ',
    typeKana: 'ちょう',
    compartmentCode: '658',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46532',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伊仙',
    type: '町',
    kana: 'いせん',
    typeKana: 'ちょう',
    compartmentCode: '658',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46533',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '和泊',
    type: '町',
    kana: 'わどまり',
    typeKana: 'ちょう',
    compartmentCode: '658',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46534',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '知名',
    type: '町',
    kana: 'ちな',
    typeKana: 'ちょう',
    compartmentCode: '658',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '46535',
    distinct: '1',
    pref: '鹿児島県',
    county: {
      name: '大島',
      type: '郡',
      kana: 'おおしま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '与論',
    type: '町',
    kana: 'よろん',
    typeKana: 'ちょう',
    compartmentCode: '658',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47201',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '那覇',
    type: '市',
    kana: 'なは',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47205',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宜野湾',
    type: '市',
    kana: 'ぎのわん',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47207',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '石垣',
    type: '市',
    kana: 'いしがき',
    typeKana: 'し',
    compartmentCode: '635',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47208',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '浦添',
    type: '市',
    kana: 'うらそえ',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47209',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '名護',
    type: '市',
    kana: 'なご',
    typeKana: 'し',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47210',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '糸満',
    type: '市',
    kana: 'いとまん',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47211',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '沖縄',
    type: '市',
    kana: 'おきなわ',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47212',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '豊見城',
    type: '市',
    kana: 'とみぐすく',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47213',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: 'うるま',
    type: '市',
    kana: 'うるま',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47214',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '宮古島',
    type: '市',
    kana: 'みやこじま',
    typeKana: 'し',
    compartmentCode: '634',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47215',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '',
      type: '',
      kana: '',
      typeKana: '',
      note: '',
    },
    name: '南城',
    type: '市',
    kana: 'なんじょう',
    typeKana: 'し',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47301',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '国頭',
    type: '村',
    kana: 'くにがみ',
    typeKana: 'そん',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47302',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '大宜味',
    type: '村',
    kana: 'おおぎみ',
    typeKana: 'そん',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47303',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '東',
    type: '村',
    kana: 'ひがし',
    typeKana: 'そん',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47306',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '今帰仁',
    type: '村',
    kana: 'なきじん',
    typeKana: 'そん',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47308',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '本部',
    type: '町',
    kana: 'もとぶ',
    typeKana: 'ちょう',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47311',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '恩納',
    type: '村',
    kana: 'おんな',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47313',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '宜野座',
    type: '村',
    kana: 'ぎのざ',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47314',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '金武',
    type: '町',
    kana: 'きん',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47315',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '国頭',
      type: '郡',
      kana: 'くにがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伊江',
    type: '村',
    kana: 'いえ',
    typeKana: 'そん',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47324',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '中頭',
      type: '郡',
      kana: 'なかがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '読谷',
    type: '村',
    kana: 'よみたん',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47325',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '中頭',
      type: '郡',
      kana: 'なかがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '嘉手納',
    type: '町',
    kana: 'かでな',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47326',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '中頭',
      type: '郡',
      kana: 'なかがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北谷',
    type: '町',
    kana: 'ちゃたん',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47327',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '中頭',
      type: '郡',
      kana: 'なかがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北中城',
    type: '村',
    kana: 'きたなかぐすく',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47328',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '中頭',
      type: '郡',
      kana: 'なかがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '中城',
    type: '村',
    kana: 'なかぐすく',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47329',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '中頭',
      type: '郡',
      kana: 'なかがみ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '西原',
    type: '町',
    kana: 'にしはら',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47348',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '与那原',
    type: '町',
    kana: 'よなばる',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47350',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南風原',
    type: '町',
    kana: 'はえばる',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47353',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '渡嘉敷',
    type: '村',
    kana: 'とかしき',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47354',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '座間味',
    type: '村',
    kana: 'ざまみ',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47355',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '粟国',
    type: '村',
    kana: 'あぐに',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47356',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '渡名喜',
    type: '村',
    kana: 'となき',
    typeKana: 'そん',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47357',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '南大東',
    type: '村',
    kana: 'みなみだいとう',
    typeKana: 'そん',
    compartmentCode: '633',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47358',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '北大東',
    type: '村',
    kana: 'きただいとう',
    typeKana: 'そん',
    compartmentCode: '633',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47359',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伊平屋',
    type: '村',
    kana: 'いへや',
    typeKana: 'そん',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47360',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '伊是名',
    type: '村',
    kana: 'いぜな',
    typeKana: 'そん',
    compartmentCode: '632',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47361',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '久米島',
    type: '町',
    kana: 'くめじま',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47362',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '島尻',
      type: '郡',
      kana: 'しまじり',
      typeKana: 'ぐん',
      note: '',
    },
    name: '八重瀬',
    type: '町',
    kana: 'やえせ',
    typeKana: 'ちょう',
    compartmentCode: '631',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47375',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '宮古',
      type: '郡',
      kana: 'みやこ',
      typeKana: 'ぐん',
      note: '',
    },
    name: '多良間',
    type: '村',
    kana: 'たらま',
    typeKana: 'そん',
    compartmentCode: '634',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47381',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '八重山',
      type: '郡',
      kana: 'やえやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '竹富',
    type: '町',
    kana: 'たけとみ',
    typeKana: 'ちょう',
    compartmentCode: '635',
    zone: {
      name: '',
      scale: '',
    },
  },
  {
    code: '47382',
    distinct: '1',
    pref: '沖縄県',
    county: {
      name: '八重山',
      type: '郡',
      kana: 'やえやま',
      typeKana: 'ぐん',
      note: '',
    },
    name: '与那国',
    type: '町',
    kana: 'よなぐに',
    typeKana: 'ちょう',
    compartmentCode: '635',
    zone: {
      name: '',
      scale: '',
    },
  },
]

export default cityList;