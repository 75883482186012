export interface MACompInfo {
  codeMain: string;
  codeSub: string;
  MAnum: string;
  pref: string;
  MAName: string;
  areaCode: string;
  square: string;
  color: string;
}

const MACompList: MACompInfo[] = [
  {
    codeMain: '1',
    codeSub: '',
    MAnum: '1',
    pref: '北海道',
    MAName: '札幌',
    areaCode: '11',
    square: '861-622',
    color: 'red',
  },
  {
    codeMain: '2',
    codeSub: '',
    MAnum: '2',
    pref: '北海道',
    MAName: '千歳',
    areaCode: '123',
    square: '848-634',
    color: 'green',
  },
  {
    codeMain: '3',
    codeSub: '',
    MAnum: '3',
    pref: '北海道',
    MAName: '夕張',
    areaCode: '123',
    square: '861-647',
    color: 'green',
  },
  {
    codeMain: '4',
    codeSub: '2',
    MAnum: '4',
    pref: '北海道',
    MAName: '栗山',
    areaCode: '123',
    square: '861-639',
    color: 'green',
  },
  {
    codeMain: '6',
    codeSub: '',
    MAnum: '5',
    pref: '北海道',
    MAName: '芦別',
    areaCode: '124',
    square: '887-655',
    color: 'green',
  },
  {
    codeMain: '7',
    codeSub: '',
    MAnum: '6',
    pref: '北海道',
    MAName: '滝川',
    areaCode: '125',
    square: '889-644',
    color: 'green',
  },
  {
    codeMain: '8',
    codeSub: '2',
    MAnum: '7',
    pref: '北海道',
    MAName: '岩見沢',
    areaCode: '126',
    square: '869-638',
    color: 'green',
  },
  {
    codeMain: '10',
    codeSub: '',
    MAnum: '8',
    pref: '北海道',
    MAName: '岩見沢',
    areaCode: '1267',
    square: '869-638',
    color: 'green',
  },
  {
    codeMain: '11',
    codeSub: '2',
    MAnum: '9',
    pref: '北海道',
    MAName: '石狩',
    areaCode: '133',
    square: '872-621',
    color: 'blue',
  },
  {
    codeMain: '12',
    codeSub: '',
    MAnum: '10',
    pref: '北海道',
    MAName: '当別',
    areaCode: '133',
    square: '870-628',
    color: 'blue',
  },
  {
    codeMain: '15',
    codeSub: '',
    MAnum: '11',
    pref: '北海道',
    MAName: '小樽',
    areaCode: '134',
    square: '869-607',
    color: 'blue',
  },
  {
    codeMain: '16',
    codeSub: '',
    MAnum: '12',
    pref: '北海道',
    MAName: '余市',
    areaCode: '135',
    square: '869-599',
    color: 'blue',
  },
  {
    codeMain: '17',
    codeSub: '',
    MAnum: '13',
    pref: '北海道',
    MAName: '岩内',
    areaCode: '135',
    square: '857-587',
    color: 'blue',
  },
  {
    codeMain: '18',
    codeSub: '',
    MAnum: '14',
    pref: '北海道',
    MAName: '倶知安',
    areaCode: '136',
    square: '852-597',
    color: 'blue',
  },
  {
    codeMain: '19',
    codeSub: '',
    MAnum: '15',
    pref: '北海道',
    MAName: '寿都',
    areaCode: '136',
    square: '847-576',
    color: 'blue',
  },
  {
    codeMain: '20',
    codeSub: '',
    MAnum: '16',
    pref: '北海道',
    MAName: '鹿部',
    areaCode: '1372',
    square: '804-601',
    color: 'blue',
  },
  {
    codeMain: '21',
    codeSub: '',
    MAnum: '17',
    pref: '北海道',
    MAName: '森',
    areaCode: '1374',
    square: '809-591',
    color: 'blue',
  },
  {
    codeMain: '22',
    codeSub: '',
    MAnum: '18',
    pref: '北海道',
    MAName: '八雲',
    areaCode: '137',
    square: '817-578',
    color: 'blue',
  },
  {
    codeMain: '23',
    codeSub: '',
    MAnum: '19',
    pref: '北海道',
    MAName: '八雲',
    areaCode: '1377',
    square: '817-578',
    color: 'blue',
  },
  {
    codeMain: '24',
    codeSub: '',
    MAnum: '20',
    pref: '北海道',
    MAName: '今金',
    areaCode: '137',
    square: '826-567',
    color: 'blue',
  },
  {
    codeMain: '25',
    codeSub: '',
    MAnum: '21',
    pref: '北海道',
    MAName: '函館',
    areaCode: '138',
    square: '790-597',
    color: 'blue',
  },
  {
    codeMain: '26',
    codeSub: '',
    MAnum: '24',
    pref: '北海道',
    MAName: '木古内',
    areaCode: '1392',
    square: '785-585',
    color: 'blue',
  },
  {
    codeMain: '27',
    codeSub: '',
    MAnum: '22',
    pref: '北海道',
    MAName: '松前',
    areaCode: '139',
    square: '771-571',
    color: 'blue',
  },
  {
    codeMain: '28',
    codeSub: '2',
    MAnum: '23',
    pref: '北海道',
    MAName: '江差',
    areaCode: '139',
    square: '795-572',
    color: 'blue',
  },
  {
    codeMain: '30',
    codeSub: '',
    MAnum: '25',
    pref: '北海道',
    MAName: '奥尻',
    areaCode: '1397',
    square: '812-547',
    color: 'blue',
  },
  {
    codeMain: '31',
    codeSub: '',
    MAnum: '26',
    pref: '北海道',
    MAName: '熊石',
    areaCode: '1398',
    square: '810-566',
    color: 'blue',
  },
  {
    codeMain: '32',
    codeSub: '',
    MAnum: '27',
    pref: '北海道',
    MAName: '伊達',
    areaCode: '142',
    square: '829-602',
    color: 'yellow',
  },
  {
    codeMain: '33',
    codeSub: '',
    MAnum: '28',
    pref: '北海道',
    MAName: '室蘭',
    areaCode: '143',
    square: '820-607',
    color: 'yellow',
  },
  {
    codeMain: '34',
    codeSub: '',
    MAnum: '29',
    pref: '北海道',
    MAName: '苫小牧',
    areaCode: '144',
    square: '838-632',
    color: 'yellow',
  },
  {
    codeMain: '35',
    codeSub: '',
    MAnum: '30',
    pref: '北海道',
    MAName: '早来',
    areaCode: '145',
    square: '845-641',
    color: 'yellow',
  },
  {
    codeMain: '36',
    codeSub: '',
    MAnum: '31',
    pref: '北海道',
    MAName: '鵡川',
    areaCode: '145',
    square: '834-646',
    color: 'yellow',
  },
  {
    codeMain: '37',
    codeSub: '',
    MAnum: '32',
    pref: '北海道',
    MAName: '門別富川',
    areaCode: '1456',
    square: '831-650',
    color: 'yellow',
  },
  {
    codeMain: '38',
    codeSub: '',
    MAnum: '33',
    pref: '北海道',
    MAName: '門別富川',
    areaCode: '1457',
    square: '831-650',
    color: 'yellow',
  },
  {
    codeMain: '39',
    codeSub: '2',
    MAnum: '34',
    pref: '北海道',
    MAName: '浦河',
    areaCode: '146',
    square: '811-681',
    color: 'yellow',
  },
  {
    codeMain: '41',
    codeSub: '',
    MAnum: '35',
    pref: '北海道',
    MAName: '静内',
    areaCode: '146',
    square: '821-664',
    color: 'yellow',
  },
  {
    codeMain: '42',
    codeSub: '',
    MAnum: '36',
    pref: '北海道',
    MAName: 'えりも',
    areaCode: '1466',
    square: '803-697',
    color: 'yellow',
  },
  {
    codeMain: '43',
    codeSub: '',
    MAnum: '39',
    pref: '北海道',
    MAName: '網走',
    areaCode: '152',
    square: '915-738',
    color: 'blue',
  },
  {
    codeMain: '44',
    codeSub: '',
    MAnum: '40',
    pref: '北海道',
    MAName: '斜里',
    areaCode: '152',
    square: '909-754',
    color: 'blue',
  },
  {
    codeMain: '45',
    codeSub: '',
    MAnum: '41',
    pref: '北海道',
    MAName: '美幌',
    areaCode: '152',
    square: '904-732',
    color: 'blue',
  },
  {
    codeMain: '46',
    codeSub: '',
    MAnum: '42',
    pref: '北海道',
    MAName: '厚岸',
    areaCode: '153',
    square: '861-764',
    color: 'blue',
  },
  {
    codeMain: '47',
    codeSub: '',
    MAnum: '43',
    pref: '北海道',
    MAName: '根室',
    areaCode: '153',
    square: '877-793',
    color: 'blue',
  },
  {
    codeMain: '48',
    codeSub: '',
    MAnum: '44',
    pref: '北海道',
    MAName: '中標津',
    areaCode: '153',
    square: '889-767',
    color: 'blue',
  },
  {
    codeMain: '49',
    codeSub: '',
    MAnum: '45',
    pref: '北海道',
    MAName: '根室標津',
    areaCode: '153',
    square: '894-774',
    color: 'blue',
  },
  {
    codeMain: '50',
    codeSub: '',
    MAnum: '46',
    pref: '北海道',
    MAName: '釧路',
    areaCode: '154',
    square: '857-745',
    color: 'blue',
  },
  {
    codeMain: '51',
    codeSub: '',
    MAnum: '47',
    pref: '北海道',
    MAName: '白糠',
    areaCode: '1547',
    square: '855-733',
    color: 'blue',
  },
  {
    codeMain: '52',
    codeSub: '',
    MAnum: '37',
    pref: '北海道',
    MAName: '弟子屈',
    areaCode: '15',
    square: '885-747',
    color: 'blue',
  },
  {
    codeMain: '53',
    codeSub: '',
    MAnum: '48',
    pref: '北海道',
    MAName: '帯広',
    areaCode: '155',
    square: '854-698',
    color: 'blue',
  },
  {
    codeMain: '54',
    codeSub: '',
    MAnum: '38',
    pref: '北海道',
    MAName: '十勝池田',
    areaCode: '15',
    square: '854-707',
    color: 'blue',
  },
  {
    codeMain: '55',
    codeSub: '',
    MAnum: '49',
    pref: '北海道',
    MAName: '広尾',
    areaCode: '1558',
    square: '819-703',
    color: 'blue',
  },
  {
    codeMain: '56',
    codeSub: '',
    MAnum: '50',
    pref: '北海道',
    MAName: '本別',
    areaCode: '156',
    square: '865-714',
    color: 'blue',
  },
  {
    codeMain: '57',
    codeSub: '',
    MAnum: '52',
    pref: '北海道',
    MAName: '上士幌',
    areaCode: '1564',
    square: '871-700',
    color: 'blue',
  },
  {
    codeMain: '58',
    codeSub: '',
    MAnum: '51',
    pref: '北海道',
    MAName: '十勝清水',
    areaCode: '156',
    square: '859-684',
    color: 'blue',
  },
  {
    codeMain: '59',
    codeSub: '',
    MAnum: '53',
    pref: '北海道',
    MAName: '北見',
    areaCode: '157',
    square: '903-723',
    color: 'blue',
  },
  {
    codeMain: '60',
    codeSub: '2',
    MAnum: '54',
    pref: '北海道',
    MAName: '紋別',
    areaCode: '158',
    square: '933-700',
    color: 'blue',
  },
  {
    codeMain: '62',
    codeSub: '',
    MAnum: '55',
    pref: '北海道',
    MAName: '遠軽',
    areaCode: '158',
    square: '917-708',
    color: 'blue',
  },
  {
    codeMain: '63',
    codeSub: '',
    MAnum: '57',
    pref: '北海道',
    MAName: '中湧別',
    areaCode: '1586',
    square: '924-710',
    color: 'blue',
  },
  {
    codeMain: '64',
    codeSub: '',
    MAnum: '58',
    pref: '北海道',
    MAName: '中湧別',
    areaCode: '1587',
    square: '924-710',
    color: 'blue',
  },
  {
    codeMain: '65',
    codeSub: '',
    MAnum: '56',
    pref: '北海道',
    MAName: '興部',
    areaCode: '158',
    square: '940-691',
    color: 'blue',
  },
  {
    codeMain: '66',
    codeSub: '',
    MAnum: '59',
    pref: '北海道',
    MAName: '稚内',
    areaCode: '162',
    square: '992-632',
    color: 'red',
  },
  {
    codeMain: '67',
    codeSub: '',
    MAnum: '62',
    pref: '北海道',
    MAName: '天塩',
    areaCode: '1632',
    square: '963-635',
    color: 'red',
  },
  {
    codeMain: '68',
    codeSub: '',
    MAnum: '63',
    pref: '北海道',
    MAName: '浜頓別',
    areaCode: '1634',
    square: '976-659',
    color: 'red',
  },
  {
    codeMain: '69',
    codeSub: '',
    MAnum: '64',
    pref: '北海道',
    MAName: '浜頓別',
    areaCode: '1635',
    square: '976-659',
    color: 'red',
  },
  {
    codeMain: '70',
    codeSub: '',
    MAnum: '60',
    pref: '北海道',
    MAName: '北見枝幸',
    areaCode: '163',
    square: '966-668',
    color: 'red',
  },
  {
    codeMain: '71',
    codeSub: '',
    MAnum: '61',
    pref: '北海道',
    MAName: '利尻礼文',
    areaCode: '163',
    square: '983-614',
    color: 'red',
  },
  {
    codeMain: '72',
    codeSub: '',
    MAnum: '66',
    pref: '北海道',
    MAName: '留萌',
    areaCode: '164',
    square: '910-632',
    color: 'red',
  },
  {
    codeMain: '73',
    codeSub: '',
    MAnum: '65',
    pref: '北海道',
    MAName: '石狩深川',
    areaCode: '164',
    square: '898-649',
    color: 'red',
  },
  {
    codeMain: '74',
    codeSub: '',
    MAnum: '67',
    pref: '北海道',
    MAName: '羽幌',
    areaCode: '164',
    square: '934-634',
    color: 'red',
  },
  {
    codeMain: '75',
    codeSub: '',
    MAnum: '68',
    pref: '北海道',
    MAName: '焼尻',
    areaCode: '1648',
    square: '938-623',
    color: 'red',
  },
  {
    codeMain: '76',
    codeSub: '2',
    MAnum: '69',
    pref: '北海道',
    MAName: '士別',
    areaCode: '165',
    square: '923-662',
    color: 'red',
  },
  {
    codeMain: '81',
    codeSub: '',
    MAnum: '70',
    pref: '北海道',
    MAName: '名寄',
    areaCode: '1654',
    square: '933-664',
    color: 'red',
  },
  {
    codeMain: '82',
    codeSub: '',
    MAnum: '71',
    pref: '北海道',
    MAName: '名寄',
    areaCode: '1655',
    square: '933-664',
    color: 'red',
  },
  {
    codeMain: '83',
    codeSub: '',
    MAnum: '72',
    pref: '北海道',
    MAName: '美深',
    areaCode: '1656',
    square: '940-660',
    color: 'red',
  },
  {
    codeMain: '84',
    codeSub: '',
    MAnum: '73',
    pref: '北海道',
    MAName: '上川',
    areaCode: '1658',
    square: '905-678',
    color: 'red',
  },
  {
    codeMain: '85',
    codeSub: '',
    MAnum: '74',
    pref: '北海道',
    MAName: '旭川',
    areaCode: '166',
    square: '901-662',
    color: 'red',
  },
  {
    codeMain: '86',
    codeSub: '',
    MAnum: '75',
    pref: '北海道',
    MAName: '富良野',
    areaCode: '167',
    square: '877-663',
    color: 'red',
  },
  {
    codeMain: '87',
    codeSub: '',
    MAnum: '77',
    pref: '青森県',
    MAName: '弘前',
    areaCode: '172',
    square: '725-587',
    color: 'red',
  },
  {
    codeMain: '88',
    codeSub: '',
    MAnum: '79',
    pref: '青森県',
    MAName: '五所川原',
    areaCode: '173',
    square: '736-586',
    color: 'red',
  },
  {
    codeMain: '89',
    codeSub: '',
    MAnum: '78',
    pref: '青森県',
    MAName: '鰺ケ沢',
    areaCode: '173',
    square: '735-576',
    color: 'red',
  },
  {
    codeMain: '90',
    codeSub: '',
    MAnum: '80',
    pref: '青森県',
    MAName: '蟹田',
    areaCode: '174',
    square: '749-594',
    color: 'red',
  },
  {
    codeMain: '91',
    codeSub: '',
    MAnum: '82',
    pref: '青森県',
    MAName: 'むつ',
    areaCode: '175',
    square: '763-618',
    color: 'red',
  },
  {
    codeMain: '92',
    codeSub: '',
    MAnum: '81',
    pref: '青森県',
    MAName: '野辺地',
    areaCode: '175',
    square: '740-614',
    color: 'red',
  },
  {
    codeMain: '93',
    codeSub: '',
    MAnum: '83',
    pref: '青森県',
    MAName: '十和田',
    areaCode: '176',
    square: '725-618',
    color: 'red',
  },
  {
    codeMain: '94',
    codeSub: '2',
    MAnum: '76',
    pref: '青森県',
    MAName: '青森',
    areaCode: '17',
    square: '737-598',
    color: 'red',
  },
  {
    codeMain: '95',
    codeSub: '',
    MAnum: '84',
    pref: '青森県',
    MAName: '八戸',
    areaCode: '178',
    square: '720-630',
    color: 'red',
  },
  {
    codeMain: '96',
    codeSub: '',
    MAnum: '85',
    pref: '青森県',
    MAName: '三戸',
    areaCode: '179',
    square: '712-621',
    color: 'red',
  },
  {
    codeMain: '97',
    codeSub: '',
    MAnum: '87',
    pref: '秋田県',
    MAName: '横手',
    areaCode: '182',
    square: '654-592',
    color: 'yellow',
  },
  {
    codeMain: '98',
    codeSub: '',
    MAnum: '88',
    pref: '秋田県',
    MAName: '湯沢',
    areaCode: '183',
    square: '645-588',
    color: 'yellow',
  },
  {
    codeMain: '99',
    codeSub: '',
    MAnum: '89',
    pref: '秋田県',
    MAName: '本荘',
    areaCode: '184',
    square: '658-569',
    color: 'yellow',
  },
  {
    codeMain: '100',
    codeSub: '',
    MAnum: '91',
    pref: '秋田県',
    MAName: '男鹿',
    areaCode: '185',
    square: '685-561',
    color: 'yellow',
  },
  {
    codeMain: '101',
    codeSub: '',
    MAnum: '90',
    pref: '秋田県',
    MAName: '能代',
    areaCode: '185',
    square: '703-568',
    color: 'yellow',
  },
  {
    codeMain: '102',
    codeSub: '',
    MAnum: '92',
    pref: '秋田県',
    MAName: '鹿角',
    areaCode: '186',
    square: '702-601',
    color: 'yellow',
  },
  {
    codeMain: '103',
    codeSub: '',
    MAnum: '93',
    pref: '秋田県',
    MAName: '大館',
    areaCode: '186',
    square: '707-591',
    color: 'yellow',
  },
  {
    codeMain: '104',
    codeSub: '',
    MAnum: '94',
    pref: '秋田県',
    MAName: '鷹巣',
    areaCode: '186',
    square: '704-583',
    color: 'yellow',
  },
  {
    codeMain: '105',
    codeSub: '',
    MAnum: '96',
    pref: '秋田県',
    MAName: '大曲',
    areaCode: '187',
    square: '661-588',
    color: 'yellow',
  },
  {
    codeMain: '106',
    codeSub: '',
    MAnum: '95',
    pref: '秋田県',
    MAName: '角館',
    areaCode: '187',
    square: '669-591',
    color: 'yellow',
  },
  {
    codeMain: '107',
    codeSub: '',
    MAnum: '86',
    pref: '秋田県',
    MAName: '秋田',
    areaCode: '18',
    square: '676-572',
    color: 'yellow',
  },
  {
    codeMain: '108',
    codeSub: '',
    MAnum: '98',
    pref: '岩手県',
    MAName: '一関',
    areaCode: '191',
    square: '632-616',
    color: 'green',
  },
  {
    codeMain: '109',
    codeSub: '',
    MAnum: '99',
    pref: '岩手県',
    MAName: '大船渡',
    areaCode: '192',
    square: '640-641',
    color: 'green',
  },
  {
    codeMain: '110',
    codeSub: '',
    MAnum: '101',
    pref: '岩手県',
    MAName: '宮古',
    areaCode: '193',
    square: '672-651',
    color: 'green',
  },
  {
    codeMain: '111',
    codeSub: '',
    MAnum: '100',
    pref: '岩手県',
    MAName: '釜石',
    areaCode: '193',
    square: '651-648',
    color: 'green',
  },
  {
    codeMain: '112',
    codeSub: '',
    MAnum: '102',
    pref: '岩手県',
    MAName: '久慈',
    areaCode: '194',
    square: '702-642',
    color: 'green',
  },
  {
    codeMain: '113',
    codeSub: '',
    MAnum: '103',
    pref: '岩手県',
    MAName: '岩泉',
    areaCode: '194',
    square: '683-644',
    color: 'green',
  },
  {
    codeMain: '114',
    codeSub: '',
    MAnum: '105',
    pref: '岩手県',
    MAName: '岩手',
    areaCode: '195',
    square: '690-619',
    color: 'green',
  },
  {
    codeMain: '115',
    codeSub: '',
    MAnum: '104',
    pref: '岩手県',
    MAName: '二戸',
    areaCode: '195',
    square: '706-622',
    color: 'green',
  },
  {
    codeMain: '116',
    codeSub: '',
    MAnum: '97',
    pref: '岩手県',
    MAName: '盛岡',
    areaCode: '19',
    square: '675-617',
    color: 'green',
  },
  {
    codeMain: '117',
    codeSub: '',
    MAnum: '106',
    pref: '岩手県',
    MAName: '水沢',
    areaCode: '197',
    square: '644-617',
    color: 'green',
  },
  {
    codeMain: '118',
    codeSub: '',
    MAnum: '107',
    pref: '岩手県',
    MAName: '北上',
    areaCode: '197',
    square: '652-615',
    color: 'green',
  },
  {
    codeMain: '119',
    codeSub: '',
    MAnum: '108',
    pref: '岩手県',
    MAName: '花巻',
    areaCode: '198',
    square: '657-615',
    color: 'green',
  },
  {
    codeMain: '120',
    codeSub: '',
    MAnum: '109',
    pref: '岩手県',
    MAName: '遠野',
    areaCode: '198',
    square: '654-633',
    color: 'green',
  },
  {
    codeMain: '121',
    codeSub: '',
    MAnum: '110',
    pref: '宮城県',
    MAName: '仙台',
    areaCode: '22',
    square: '595-605',
    color: 'blue',
  },
  {
    codeMain: '122',
    codeSub: '',
    MAnum: '111',
    pref: '宮城県',
    MAName: '迫',
    areaCode: '220',
    square: '619-619',
    color: 'blue',
  },
  {
    codeMain: '123',
    codeSub: '',
    MAnum: '112',
    pref: '宮城県',
    MAName: '岩沼',
    areaCode: '223',
    square: '586-605',
    color: 'blue',
  },
  {
    codeMain: '124',
    codeSub: '',
    MAnum: '113',
    pref: '宮城県',
    MAName: '大河原',
    areaCode: '224',
    square: '583-600',
    color: 'blue',
  },
  {
    codeMain: '125',
    codeSub: '',
    MAnum: '114',
    pref: '宮城県',
    MAName: '白石',
    areaCode: '224',
    square: '581-595',
    color: 'blue',
  },
  {
    codeMain: '126',
    codeSub: '',
    MAnum: '115',
    pref: '宮城県',
    MAName: '石巻',
    areaCode: '225',
    square: '604-624',
    color: 'blue',
  },
  {
    codeMain: '127',
    codeSub: '',
    MAnum: '116',
    pref: '宮城県',
    MAName: '気仙沼',
    areaCode: '226',
    square: '631-635',
    color: 'blue',
  },
  {
    codeMain: '128',
    codeSub: '',
    MAnum: '117',
    pref: '宮城県',
    MAName: '築館',
    areaCode: '228',
    square: '621-611',
    color: 'blue',
  },
  {
    codeMain: '129',
    codeSub: '',
    MAnum: '118',
    pref: '宮城県',
    MAName: '古川',
    areaCode: '229',
    square: '612-609',
    color: 'blue',
  },
  {
    codeMain: '130',
    codeSub: '',
    MAnum: '120',
    pref: '山形県',
    MAName: '新庄',
    areaCode: '233',
    square: '623-580',
    color: 'red',
  },
  {
    codeMain: '131',
    codeSub: '',
    MAnum: '121',
    pref: '山形県',
    MAName: '酒田',
    areaCode: '234',
    square: '631-560',
    color: 'red',
  },
  {
    codeMain: '132',
    codeSub: '',
    MAnum: '122',
    pref: '山形県',
    MAName: '鶴岡',
    areaCode: '235',
    square: '621-560',
    color: 'red',
  },
  {
    codeMain: '133',
    codeSub: '',
    MAnum: '119',
    pref: '山形県',
    MAName: '山形',
    areaCode: '23',
    square: '595-582',
    color: 'red',
  },
  {
    codeMain: '134',
    codeSub: '',
    MAnum: '124',
    pref: '山形県',
    MAName: '寒河江',
    areaCode: '237',
    square: '601-579',
    color: 'red',
  },
  {
    codeMain: '135',
    codeSub: '',
    MAnum: '123',
    pref: '山形県',
    MAName: '村山',
    areaCode: '237',
    square: '607-584',
    color: 'red',
  },
  {
    codeMain: '136',
    codeSub: '',
    MAnum: '126',
    pref: '山形県',
    MAName: '米沢',
    areaCode: '238',
    square: '576-572',
    color: 'red',
  },
  {
    codeMain: '137',
    codeSub: '',
    MAnum: '125',
    pref: '山形県',
    MAName: '長井',
    areaCode: '238',
    square: '586-569',
    color: 'red',
  },
  {
    codeMain: '138',
    codeSub: '',
    MAnum: '129',
    pref: '福島県',
    MAName: '磐城富岡',
    areaCode: '240',
    square: '544-612',
    color: 'yellow',
  },
  {
    codeMain: '139',
    codeSub: '',
    MAnum: '130',
    pref: '福島県',
    MAName: '喜多方',
    areaCode: '241',
    square: '561-562',
    color: 'yellow',
  },
  {
    codeMain: '140',
    codeSub: '',
    MAnum: '131',
    pref: '福島県',
    MAName: '田島',
    areaCode: '241',
    square: '536-557',
    color: 'yellow',
  },
  {
    codeMain: '141',
    codeSub: '',
    MAnum: '133',
    pref: '福島県',
    MAName: '会津山口',
    areaCode: '241',
    square: '538-547',
    color: 'yellow',
  },
  {
    codeMain: '142',
    codeSub: '',
    MAnum: '132',
    pref: '福島県',
    MAName: '柳津',
    areaCode: '241',
    square: '554-555',
    color: 'yellow',
  },
  {
    codeMain: '143',
    codeSub: '',
    MAnum: '134',
    pref: '福島県',
    MAName: '会津若松',
    areaCode: '242',
    square: '552-564',
    color: 'yellow',
  },
  {
    codeMain: '144',
    codeSub: '',
    MAnum: '135',
    pref: '福島県',
    MAName: '二本松',
    areaCode: '243',
    square: '558-587',
    color: 'yellow',
  },
  {
    codeMain: '145',
    codeSub: '',
    MAnum: '136',
    pref: '福島県',
    MAName: '原町',
    areaCode: '244',
    square: '560-610',
    color: 'yellow',
  },
  {
    codeMain: '146',
    codeSub: '',
    MAnum: '128',
    pref: '福島県',
    MAName: '福島',
    areaCode: '24',
    square: '567-588',
    color: 'yellow',
  },
  {
    codeMain: '147',
    codeSub: '',
    MAnum: '137',
    pref: '福島県',
    MAName: 'いわき',
    areaCode: '246',
    square: '528-607',
    color: 'yellow',
  },
  {
    codeMain: '148',
    codeSub: '',
    MAnum: '139',
    pref: '福島県',
    MAName: '石川',
    areaCode: '247',
    square: '533-588',
    color: 'yellow',
  },
  {
    codeMain: '149',
    codeSub: '',
    MAnum: '138',
    pref: '福島県',
    MAName: '三春',
    areaCode: '247',
    square: '549-589',
    color: 'yellow',
  },
  {
    codeMain: '150',
    codeSub: '',
    MAnum: '141',
    pref: '福島県',
    MAName: '須賀川',
    areaCode: '248',
    square: '541-584',
    color: 'yellow',
  },
  {
    codeMain: '151',
    codeSub: '',
    MAnum: '140',
    pref: '福島県',
    MAName: '白河',
    areaCode: '248',
    square: '532-577',
    color: 'yellow',
  },
  {
    codeMain: '152',
    codeSub: '',
    MAnum: '127',
    pref: '福島県',
    MAName: '郡山',
    areaCode: '24',
    square: '547-584',
    color: 'yellow',
  },
  {
    codeMain: '153',
    codeSub: '',
    MAnum: '142',
    pref: '新潟県',
    MAName: '新潟',
    areaCode: '25',
    square: '576-526',
    color: 'blue',
  },
  {
    codeMain: '154',
    codeSub: '',
    MAnum: '149',
    pref: '新潟県',
    MAName: '新津',
    areaCode: '250',
    square: '569-529',
    color: 'blue',
  },
  {
    codeMain: '155',
    codeSub: '',
    MAnum: '150',
    pref: '新潟県',
    MAName: '新発田',
    areaCode: '254',
    square: '578-538',
    color: 'blue',
  },
  {
    codeMain: '156',
    codeSub: '',
    MAnum: '151',
    pref: '新潟県',
    MAName: '村上',
    areaCode: '254',
    square: '593-545',
    color: 'blue',
  },
  {
    codeMain: '157',
    codeSub: '',
    MAnum: '152',
    pref: '新潟県',
    MAName: '津川',
    areaCode: '254',
    square: '563-544',
    color: 'blue',
  },
  {
    codeMain: '158',
    codeSub: '',
    MAnum: '144',
    pref: '新潟県',
    MAName: '上越',
    areaCode: '25',
    square: '535-489',
    color: 'blue',
  },
  {
    codeMain: '159',
    codeSub: '',
    MAnum: '153',
    pref: '新潟県',
    MAName: '新井',
    areaCode: '255',
    square: '526-490',
    color: 'blue',
  },
  {
    codeMain: '160',
    codeSub: '',
    MAnum: '143',
    pref: '新潟県',
    MAName: '糸魚川',
    areaCode: '25',
    square: '527-473',
    color: 'blue',
  },
  {
    codeMain: '161',
    codeSub: '',
    MAnum: '145',
    pref: '新潟県',
    MAName: '安塚',
    areaCode: '25',
    square: '532-498',
    color: 'blue',
  },
  {
    codeMain: '162',
    codeSub: '',
    MAnum: '155',
    pref: '新潟県',
    MAName: '巻',
    areaCode: '256',
    square: '567-518',
    color: 'blue',
  },
  {
    codeMain: '163',
    codeSub: '',
    MAnum: '154',
    pref: '新潟県',
    MAName: '三条',
    areaCode: '256',
    square: '560-521',
    color: 'blue',
  },
  {
    codeMain: '164',
    codeSub: '',
    MAnum: '146',
    pref: '新潟県',
    MAName: '十日町',
    areaCode: '25',
    square: '532-512',
    color: 'blue',
  },
  {
    codeMain: '165',
    codeSub: '',
    MAnum: '156',
    pref: '新潟県',
    MAName: '柏崎',
    areaCode: '257',
    square: '545-503',
    color: 'blue',
  },
  {
    codeMain: '166',
    codeSub: '',
    MAnum: '147',
    pref: '新潟県',
    MAName: '六日町',
    areaCode: '25',
    square: '528-518',
    color: 'blue',
  },
  {
    codeMain: '167',
    codeSub: '',
    MAnum: '148',
    pref: '新潟県',
    MAName: '小出',
    areaCode: '25',
    square: '538-521',
    color: 'blue',
  },
  {
    codeMain: '168',
    codeSub: '',
    MAnum: '157',
    pref: '新潟県',
    MAName: '長岡',
    areaCode: '258',
    square: '550-517',
    color: 'blue',
  },
  {
    codeMain: '169',
    codeSub: '2',
    MAnum: '158',
    pref: '新潟県',
    MAName: '佐渡',
    areaCode: '259',
    square: '585-498',
    color: 'blue',
  },
  {
    codeMain: '171',
    codeSub: '',
    MAnum: '160',
    pref: '長野県',
    MAName: '阿南町',
    areaCode: '260',
    square: '432-469',
    color: 'green',
  },
  {
    codeMain: '172',
    codeSub: '',
    MAnum: '161',
    pref: '長野県',
    MAName: '大町',
    areaCode: '261',
    square: '498-472',
    color: 'green',
  },
  {
    codeMain: '173',
    codeSub: '',
    MAnum: '159',
    pref: '長野県',
    MAName: '長野',
    areaCode: '26',
    square: '506-486',
    color: 'green',
  },
  {
    codeMain: '174',
    codeSub: '',
    MAnum: '162',
    pref: '長野県',
    MAName: '松本',
    areaCode: '263',
    square: '482-477',
    color: 'green',
  },
  {
    codeMain: '175',
    codeSub: '',
    MAnum: '163',
    pref: '長野県',
    MAName: '木曾福島',
    areaCode: '264',
    square: '461-464',
    color: 'green',
  },
  {
    codeMain: '176',
    codeSub: '',
    MAnum: '164',
    pref: '長野県',
    MAName: '伊那',
    areaCode: '265',
    square: '460-476',
    color: 'green',
  },
  {
    codeMain: '177',
    codeSub: '',
    MAnum: '165',
    pref: '長野県',
    MAName: '飯田',
    areaCode: '265',
    square: '443-469',
    color: 'green',
  },
  {
    codeMain: '178',
    codeSub: '',
    MAnum: '166',
    pref: '長野県',
    MAName: '諏訪',
    areaCode: '266',
    square: '472-483',
    color: 'green',
  },
  {
    codeMain: '179',
    codeSub: '',
    MAnum: '168',
    pref: '長野県',
    MAName: '小諸',
    areaCode: '267',
    square: '488-497',
    color: 'green',
  },
  {
    codeMain: '180',
    codeSub: '',
    MAnum: '167',
    pref: '長野県',
    MAName: '佐久',
    areaCode: '267',
    square: '482-499',
    color: 'green',
  },
  {
    codeMain: '181',
    codeSub: '',
    MAnum: '169',
    pref: '長野県',
    MAName: '上田',
    areaCode: '268',
    square: '492-489',
    color: 'green',
  },
  {
    codeMain: '182',
    codeSub: '',
    MAnum: '170',
    pref: '長野県',
    MAName: '中野',
    areaCode: '269',
    square: '511-495',
    color: 'green',
  },
  {
    codeMain: '183',
    codeSub: '',
    MAnum: '171',
    pref: '長野県',
    MAName: '飯山',
    areaCode: '269',
    square: '517-495',
    color: 'green',
  },
  {
    codeMain: '184',
    codeSub: '',
    MAnum: '174',
    pref: '群馬県',
    MAName: '伊勢崎',
    areaCode: '270',
    square: '487-531',
    color: 'red',
  },
  {
    codeMain: '185',
    codeSub: '',
    MAnum: '172',
    pref: '群馬県',
    MAName: '前橋',
    areaCode: '27',
    square: '491-526',
    color: 'red',
  },
  {
    codeMain: '186',
    codeSub: '',
    MAnum: '173',
    pref: '群馬県',
    MAName: '高崎',
    areaCode: '27',
    square: '487-523',
    color: 'red',
  },
  {
    codeMain: '187',
    codeSub: '',
    MAnum: '176',
    pref: '群馬県',
    MAName: '富岡',
    areaCode: '274',
    square: '484-518',
    color: 'red',
  },
  {
    codeMain: '188',
    codeSub: '',
    MAnum: '175',
    pref: '群馬県',
    MAName: '藤岡',
    areaCode: '274',
    square: '483-526',
    color: 'red',
  },
  {
    codeMain: '189',
    codeSub: '',
    MAnum: '177',
    pref: '群馬県',
    MAName: '太田',
    areaCode: '276',
    square: '486-540',
    color: 'red',
  },
  {
    codeMain: '190',
    codeSub: '',
    MAnum: '178',
    pref: '群馬県',
    MAName: '桐生',
    areaCode: '277',
    square: '492-538',
    color: 'red',
  },
  {
    codeMain: '191',
    codeSub: '',
    MAnum: '179',
    pref: '群馬県',
    MAName: '沼田',
    areaCode: '278',
    square: '505-525',
    color: 'red',
  },
  {
    codeMain: '192',
    codeSub: '',
    MAnum: '181',
    pref: '群馬県',
    MAName: '渋川',
    areaCode: '279',
    square: '497-523',
    color: 'red',
  },
  {
    codeMain: '193',
    codeSub: '',
    MAnum: '180',
    pref: '群馬県',
    MAName: '長野原',
    areaCode: '279',
    square: '500-507',
    color: 'red',
  },
  {
    codeMain: '194',
    codeSub: '',
    MAnum: '183',
    pref: '茨城県',
    MAName: '古河',
    areaCode: '280',
    square: '480-554',
    color: 'green',
  },
  {
    codeMain: '195',
    codeSub: '',
    MAnum: '184',
    pref: '栃木県',
    MAName: '栃木',
    areaCode: '282',
    square: '490-556',
    color: 'green',
  },
  {
    codeMain: '196',
    codeSub: '',
    MAnum: '185',
    pref: '栃木県',
    MAName: '佐野',
    areaCode: '283',
    square: '487-549',
    color: 'green',
  },
  {
    codeMain: '197',
    codeSub: '',
    MAnum: '186',
    pref: '栃木県',
    MAName: '足利',
    areaCode: '284',
    square: '488-543',
    color: 'green',
  },
  {
    codeMain: '198',
    codeSub: '',
    MAnum: '187',
    pref: '栃木県',
    MAName: '真岡',
    areaCode: '285',
    square: '494-568',
    color: 'green',
  },
  {
    codeMain: '199',
    codeSub: '',
    MAnum: '188',
    pref: '栃木県',
    MAName: '小山',
    areaCode: '285',
    square: '487-559',
    color: 'green',
  },
  {
    codeMain: '200',
    codeSub: '',
    MAnum: '182',
    pref: '栃木県',
    MAName: '宇都宮',
    areaCode: '28',
    square: '501-562',
    color: 'green',
  },
  {
    codeMain: '201',
    codeSub: '',
    MAnum: '189',
    pref: '栃木県',
    MAName: '黒磯',
    areaCode: '287',
    square: '523-570',
    color: 'green',
  },
  {
    codeMain: '202',
    codeSub: '',
    MAnum: '190',
    pref: '栃木県',
    MAName: '大田原',
    areaCode: '287',
    square: '518-569',
    color: 'green',
  },
  {
    codeMain: '203',
    codeSub: '',
    MAnum: '191',
    pref: '栃木県',
    MAName: '烏山',
    areaCode: '287',
    square: '506-574',
    color: 'green',
  },
  {
    codeMain: '204',
    codeSub: '',
    MAnum: '192',
    pref: '栃木県',
    MAName: '今市',
    areaCode: '288',
    square: '510-554',
    color: 'green',
  },
  {
    codeMain: '205',
    codeSub: '',
    MAnum: '193',
    pref: '栃木県',
    MAName: '鹿沼',
    areaCode: '289',
    square: '501-556',
    color: 'green',
  },
  {
    codeMain: '206',
    codeSub: '',
    MAnum: '196',
    pref: '茨城県',
    MAName: '鉾田',
    areaCode: '291',
    square: '478-590',
    color: 'red',
  },
  {
    codeMain: '207',
    codeSub: '',
    MAnum: '194',
    pref: '茨城県',
    MAName: '水戸',
    areaCode: '29',
    square: '490-589',
    color: 'red',
  },
  {
    codeMain: '208',
    codeSub: '',
    MAnum: '197',
    pref: '茨城県',
    MAName: '高萩',
    areaCode: '293',
    square: '509-599',
    color: 'red',
  },
  {
    codeMain: '209',
    codeSub: '',
    MAnum: '198',
    pref: '茨城県',
    MAName: '常陸太田',
    areaCode: '294',
    square: '500-591',
    color: 'red',
  },
  {
    codeMain: '210',
    codeSub: '2',
    MAnum: '199',
    pref: '茨城県',
    MAName: '常陸大宮',
    areaCode: '295',
    square: '500-586',
    color: 'red',
  },
  {
    codeMain: '211',
    codeSub: '',
    MAnum: '200',
    pref: '茨城県',
    MAName: '大子',
    areaCode: '295',
    square: '512-583',
    color: 'red',
  },
  {
    codeMain: '212',
    codeSub: '',
    MAnum: '201',
    pref: '茨城県',
    MAName: '笠間',
    areaCode: '296',
    square: '491-579',
    color: 'red',
  },
  {
    codeMain: '213',
    codeSub: '',
    MAnum: '202',
    pref: '茨城県',
    MAName: '下館',
    areaCode: '296',
    square: '487-567',
    color: 'red',
  },
  {
    codeMain: '214',
    codeSub: '',
    MAnum: '204',
    pref: '茨城県',
    MAName: '竜ケ崎',
    areaCode: '297',
    square: '464-576',
    color: 'red',
  },
  {
    codeMain: '215',
    codeSub: '',
    MAnum: '203',
    pref: '茨城県',
    MAName: '水海道',
    areaCode: '297',
    square: '471-567',
    color: 'red',
  },
  {
    codeMain: '216',
    codeSub: '',
    MAnum: '195',
    pref: '茨城県',
    MAName: '土浦',
    areaCode: '29',
    square: '474-577',
    color: 'red',
  },
  {
    codeMain: '217',
    codeSub: '',
    MAnum: '205',
    pref: '茨城県',
    MAName: '石岡',
    areaCode: '299',
    square: '480-580',
    color: 'red',
  },
  {
    codeMain: '218',
    codeSub: '',
    MAnum: '206',
    pref: '茨城県',
    MAName: '潮来',
    areaCode: '299',
    square: '466-592',
    color: 'red',
  },
  {
    codeMain: '219',
    codeSub: '',
    MAnum: '207',
    pref: '東京都',
    MAName: '東京',
    areaCode: '3',
    square: '452-557',
    color: 'red',
  },
  {
    codeMain: '220',
    codeSub: '',
    MAnum: '217',
    pref: '東京都',
    MAName: '武蔵野三鷹',
    areaCode: '422',
    square: '453-548',
    color: 'green',
  },
  {
    codeMain: '221',
    codeSub: '',
    MAnum: '211',
    pref: '東京都',
    MAName: '国分寺',
    areaCode: '42',
    square: '453-544',
    color: 'green',
  },
  {
    codeMain: '222',
    codeSub: '',
    MAnum: '216',
    pref: '東京都',
    MAName: '武蔵野三鷹',
    areaCode: '42',
    square: '453-548',
    color: 'green',
  },
  {
    codeMain: '223',
    codeSub: '',
    MAnum: '212',
    pref: '東京都',
    MAName: '立川',
    areaCode: '42',
    square: '453-541',
    color: 'green',
  },
  {
    codeMain: '224',
    codeSub: '',
    MAnum: '213',
    pref: '東京都',
    MAName: '八王子',
    areaCode: '42',
    square: '451-538',
    color: 'green',
  },
  {
    codeMain: '225',
    codeSub: '',
    MAnum: '214',
    pref: '神奈川県',
    MAName: '相模原',
    areaCode: '42',
    square: '446-539',
    color: 'green',
  },
  {
    codeMain: '226',
    codeSub: '',
    MAnum: '218',
    pref: '東京都',
    MAName: '青梅',
    areaCode: '428',
    square: '458-535',
    color: 'green',
  },
  {
    codeMain: '227',
    codeSub: '',
    MAnum: '208',
    pref: '埼玉県',
    MAName: '所沢',
    areaCode: '4',
    square: '458-543',
    color: 'green',
  },
  {
    codeMain: '228',
    codeSub: '',
    MAnum: '215',
    pref: '埼玉県',
    MAName: '飯能',
    areaCode: '42',
    square: '461-537',
    color: 'green',
  },
  {
    codeMain: '229',
    codeSub: '',
    MAnum: '219',
    pref: '千葉県',
    MAName: '千葉',
    areaCode: '43',
    square: '448-573',
    color: 'green',
  },
  {
    codeMain: '230',
    codeSub: '',
    MAnum: '220',
    pref: '千葉県',
    MAName: '市原',
    areaCode: '436',
    square: '443-572',
    color: 'green',
  },
  {
    codeMain: '231',
    codeSub: '',
    MAnum: '221',
    pref: '千葉県',
    MAName: '木更津',
    areaCode: '438',
    square: '435-564',
    color: 'green',
  },
  {
    codeMain: '232',
    codeSub: '',
    MAnum: '222',
    pref: '千葉県',
    MAName: '木更津',
    areaCode: '439',
    square: '435-564',
    color: 'green',
  },
  {
    codeMain: '233',
    codeSub: '',
    MAnum: '223',
    pref: '神奈川県',
    MAName: '川崎',
    areaCode: '44',
    square: '443-554',
    color: 'green',
  },
  {
    codeMain: '234',
    codeSub: '',
    MAnum: '224',
    pref: '神奈川県',
    MAName: '横浜',
    areaCode: '45',
    square: '439-551',
    color: 'green',
  },
  {
    codeMain: '235',
    codeSub: '',
    MAnum: '227',
    pref: '神奈川県',
    MAName: '小田原',
    areaCode: '460',
    square: '428-529',
    color: 'green',
  },
  {
    codeMain: '236',
    codeSub: '2',
    MAnum: '225',
    pref: '神奈川県',
    MAName: '厚木',
    areaCode: '46',
    square: '439-539',
    color: 'green',
  },
  {
    codeMain: '237',
    codeSub: '',
    MAnum: '229',
    pref: '神奈川県',
    MAName: '平塚',
    areaCode: '463',
    square: '432-538',
    color: 'green',
  },
  {
    codeMain: '238',
    codeSub: '',
    MAnum: '228',
    pref: '神奈川県',
    MAName: '小田原',
    areaCode: '465',
    square: '428-529',
    color: 'green',
  },
  {
    codeMain: '239',
    codeSub: '',
    MAnum: '230',
    pref: '神奈川県',
    MAName: '藤沢',
    areaCode: '466',
    square: '433-545',
    color: 'green',
  },
  {
    codeMain: '240',
    codeSub: '',
    MAnum: '231',
    pref: '神奈川県',
    MAName: '藤沢',
    areaCode: '467',
    square: '433-545',
    color: 'green',
  },
  {
    codeMain: '241',
    codeSub: '',
    MAnum: '226',
    pref: '神奈川県',
    MAName: '横須賀',
    areaCode: '46',
    square: '430-553',
    color: 'green',
  },
  {
    codeMain: '242',
    codeSub: '',
    MAnum: '234',
    pref: '千葉県',
    MAName: '館山',
    areaCode: '470',
    square: '413-562',
    color: 'blue',
  },
  {
    codeMain: '243',
    codeSub: '',
    MAnum: '235',
    pref: '千葉県',
    MAName: '大原',
    areaCode: '470',
    square: '428-585',
    color: 'blue',
  },
  {
    codeMain: '244',
    codeSub: '',
    MAnum: '209',
    pref: '千葉県',
    MAName: '鴨川',
    areaCode: '4',
    square: '420-573',
    color: 'green',
  },
  {
    codeMain: '245',
    codeSub: '',
    MAnum: '210',
    pref: '千葉県',
    MAName: '柏',
    areaCode: '4',
    square: '461-566',
    color: 'green',
  },
  {
    codeMain: '246',
    codeSub: '',
    MAnum: '232',
    pref: '千葉県',
    MAName: '市川',
    areaCode: '47',
    square: '454-564',
    color: 'blue',
  },
  {
    codeMain: '247',
    codeSub: '',
    MAnum: '233',
    pref: '千葉県',
    MAName: '船橋',
    areaCode: '47',
    square: '453-567',
    color: 'blue',
  },
  {
    codeMain: '248',
    codeSub: '',
    MAnum: '237',
    pref: '千葉県',
    MAName: '東金',
    areaCode: '475',
    square: '445-584',
    color: 'blue',
  },
  {
    codeMain: '249',
    codeSub: '',
    MAnum: '236',
    pref: '千葉県',
    MAName: '茂原',
    areaCode: '475',
    square: '438-581',
    color: 'blue',
  },
  {
    codeMain: '250',
    codeSub: '',
    MAnum: '238',
    pref: '千葉県',
    MAName: '成田',
    areaCode: '476',
    square: '458-582',
    color: 'blue',
  },
  {
    codeMain: '251',
    codeSub: '',
    MAnum: '239',
    pref: '千葉県',
    MAName: '佐原',
    areaCode: '478',
    square: '463-590',
    color: 'blue',
  },
  {
    codeMain: '252',
    codeSub: '',
    MAnum: '240',
    pref: '千葉県',
    MAName: '銚子',
    areaCode: '479',
    square: '455-605',
    color: 'blue',
  },
  {
    codeMain: '253',
    codeSub: '',
    MAnum: '241',
    pref: '千葉県',
    MAName: '八日市場',
    areaCode: '479',
    square: '453-592',
    color: 'blue',
  },
  {
    codeMain: '254',
    codeSub: '',
    MAnum: '243',
    pref: '埼玉県',
    MAName: '浦和',
    areaCode: '48',
    square: '462-552',
    color: 'yellow',
  },
  {
    codeMain: '255',
    codeSub: '',
    MAnum: '242',
    pref: '埼玉県',
    MAName: '川口',
    areaCode: '48',
    square: '458-555',
    color: 'yellow',
  },
  {
    codeMain: '256',
    codeSub: '',
    MAnum: '246',
    pref: '埼玉県',
    MAName: '久喜',
    areaCode: '480',
    square: '473-553',
    color: 'yellow',
  },
  {
    codeMain: '257',
    codeSub: '2',
    MAnum: '244',
    pref: '埼玉県',
    MAName: '熊谷',
    areaCode: '48',
    square: '477-540',
    color: 'yellow',
  },
  {
    codeMain: '258',
    codeSub: '',
    MAnum: '245',
    pref: '埼玉県',
    MAName: '草加',
    areaCode: '48',
    square: '460-559',
    color: 'yellow',
  },
  {
    codeMain: '259',
    codeSub: '',
    MAnum: '247',
    pref: '埼玉県',
    MAName: '川越',
    areaCode: '49',
    square: '465-544',
    color: 'blue',
  },
  {
    codeMain: '260',
    codeSub: '',
    MAnum: '248',
    pref: '埼玉県',
    MAName: '東松山',
    areaCode: '493',
    square: '472-541',
    color: 'blue',
  },
  {
    codeMain: '261',
    codeSub: '',
    MAnum: '249',
    pref: '埼玉県',
    MAName: '秩父',
    areaCode: '494',
    square: '469-526',
    color: 'blue',
  },
  {
    codeMain: '262',
    codeSub: '',
    MAnum: '250',
    pref: '埼玉県',
    MAName: '本庄',
    areaCode: '495',
    square: '483-531',
    color: 'blue',
  },
  {
    codeMain: '263',
    codeSub: '',
    MAnum: '251',
    pref: '東京都',
    MAName: '伊豆大島',
    areaCode: '4992',
    square: '400-538',
    color: 'blue',
  },
  {
    codeMain: '264',
    codeSub: '',
    MAnum: '252',
    pref: '東京都',
    MAName: '三宅',
    areaCode: '4994',
    square: '365-546',
    color: 'blue',
  },
  {
    codeMain: '265',
    codeSub: '',
    MAnum: '253',
    pref: '東京都',
    MAName: '八丈島',
    areaCode: '4996',
    square: '309-558',
    color: 'blue',
  },
  {
    codeMain: '266',
    codeSub: '',
    MAnum: '254',
    pref: '東京都',
    MAName: '小笠原',
    areaCode: '4998',
    square: '(-024)-677',
    color: 'blue',
  },
  {
    codeMain: '267',
    codeSub: '',
    MAnum: '262',
    pref: '愛知県',
    MAName: '名古屋',
    areaCode: '52',
    square: '423-426',
    color: 'yellow',
  },
  {
    codeMain: '268',
    codeSub: '',
    MAnum: '263',
    pref: '静岡県',
    MAName: '浜松',
    areaCode: '53',
    square: '398-464',
    color: 'red',
  },
  {
    codeMain: '269',
    codeSub: '2',
    MAnum: '264',
    pref: '愛知県',
    MAName: '田原',
    areaCode: '531',
    square: '396-443',
    color: 'red',
  },
  {
    codeMain: '272',
    codeSub: '',
    MAnum: '265',
    pref: '愛知県',
    MAName: '豊橋',
    areaCode: '532',
    square: '401-448',
    color: 'red',
  },
  {
    codeMain: '273',
    codeSub: '',
    MAnum: '266',
    pref: '愛知県',
    MAName: '豊橋',
    areaCode: '533',
    square: '401-448',
    color: 'red',
  },
  {
    codeMain: '274',
    codeSub: '2',
    MAnum: '267',
    pref: '愛知県',
    MAName: '新城',
    areaCode: '536',
    square: '409-453',
    color: 'red',
  },
  {
    codeMain: '276',
    codeSub: '2',
    MAnum: '268',
    pref: '愛知県',
    MAName: '設楽',
    areaCode: '536',
    square: '419-457',
    color: 'red',
  },
  {
    codeMain: '280',
    codeSub: '',
    MAnum: '269',
    pref: '静岡県',
    MAName: '掛川',
    areaCode: '537',
    square: '401-477',
    color: 'red',
  },
  {
    codeMain: '281',
    codeSub: '',
    MAnum: '270',
    pref: '静岡県',
    MAName: '磐田',
    areaCode: '538',
    square: '398-469',
    color: 'red',
  },
  {
    codeMain: '282',
    codeSub: '',
    MAnum: '271',
    pref: '静岡県',
    MAName: '天竜',
    areaCode: '539',
    square: '404-469',
    color: 'red',
  },
  {
    codeMain: '283',
    codeSub: '2',
    MAnum: '272',
    pref: '静岡県',
    MAName: '静岡',
    areaCode: '54',
    square: '412-494',
    color: 'blue',
  },
  {
    codeMain: '285',
    codeSub: '',
    MAnum: '273',
    pref: '静岡県',
    MAName: '富士宮',
    areaCode: '544',
    square: '426-505',
    color: 'blue',
  },
  {
    codeMain: '286',
    codeSub: '',
    MAnum: '274',
    pref: '静岡県',
    MAName: '富士',
    areaCode: '545',
    square: '423-508',
    color: 'blue',
  },
  {
    codeMain: '287',
    codeSub: '',
    MAnum: '275',
    pref: '静岡県',
    MAName: '島田',
    areaCode: '547',
    square: '405-484',
    color: 'blue',
  },
  {
    codeMain: '288',
    codeSub: '',
    MAnum: '276',
    pref: '静岡県',
    MAName: '榛原',
    areaCode: '548',
    square: '400-487',
    color: 'blue',
  },
  {
    codeMain: '289',
    codeSub: '',
    MAnum: '277',
    pref: '静岡県',
    MAName: '御殿場',
    areaCode: '550',
    square: '431-519',
    color: 'yellow',
  },
  {
    codeMain: '290',
    codeSub: '',
    MAnum: '256',
    pref: '山梨県',
    MAName: '韮崎',
    areaCode: '551',
    square: '453-498',
    color: 'yellow',
  },
  {
    codeMain: '291',
    codeSub: '',
    MAnum: '255',
    pref: '山梨県',
    MAName: '甲府',
    areaCode: '55',
    square: '451-503',
    color: 'yellow',
  },
  {
    codeMain: '292',
    codeSub: '',
    MAnum: '257',
    pref: '山梨県',
    MAName: '山梨',
    areaCode: '553',
    square: '452-508',
    color: 'yellow',
  },
  {
    codeMain: '293',
    codeSub: '',
    MAnum: '258',
    pref: '山梨県',
    MAName: '大月',
    areaCode: '554',
    square: '448-520',
    color: 'yellow',
  },
  {
    codeMain: '294',
    codeSub: '',
    MAnum: '259',
    pref: '山梨県',
    MAName: '吉田',
    areaCode: '555',
    square: '441-514',
    color: 'yellow',
  },
  {
    codeMain: '295',
    codeSub: '',
    MAnum: '260',
    pref: '山梨県',
    MAName: '鰍沢青柳',
    areaCode: '556',
    square: '444-498',
    color: 'yellow',
  },
  {
    codeMain: '296',
    codeSub: '',
    MAnum: '261',
    pref: '山梨県',
    MAName: '身延',
    areaCode: '556',
    square: '435-496',
    color: 'yellow',
  },
  {
    codeMain: '297',
    codeSub: '',
    MAnum: '278',
    pref: '静岡県',
    MAName: '伊東',
    areaCode: '557',
    square: '412-527',
    color: 'yellow',
  },
  {
    codeMain: '298',
    codeSub: '',
    MAnum: '279',
    pref: '静岡県',
    MAName: '修善寺大仁',
    areaCode: '558',
    square: '413-520',
    color: 'yellow',
  },
  {
    codeMain: '299',
    codeSub: '',
    MAnum: '280',
    pref: '静岡県',
    MAName: '下田',
    areaCode: '558',
    square: '396-519',
    color: 'yellow',
  },
  {
    codeMain: '300',
    codeSub: '',
    MAnum: '281',
    pref: '静岡県',
    MAName: '沼津',
    areaCode: '55',
    square: '420-516',
    color: 'yellow',
  },
  {
    codeMain: '301',
    codeSub: '3',
    MAnum: '282',
    pref: '愛知県',
    MAName: '瀬戸',
    areaCode: '561',
    square: '426-436',
    color: 'blue',
  },
  {
    codeMain: '304',
    codeSub: '',
    MAnum: '283',
    pref: '愛知県',
    MAName: '尾張横須賀',
    areaCode: '562',
    square: '415-425',
    color: 'blue',
  },
  {
    codeMain: '305',
    codeSub: '',
    MAnum: '284',
    pref: '愛知県',
    MAName: '西尾',
    areaCode: '563',
    square: '407-433',
    color: 'blue',
  },
  {
    codeMain: '306',
    codeSub: '',
    MAnum: '285',
    pref: '愛知県',
    MAName: '岡崎',
    areaCode: '564',
    square: '411-439',
    color: 'blue',
  },
  {
    codeMain: '307',
    codeSub: '',
    MAnum: '286',
    pref: '愛知県',
    MAName: '豊田',
    areaCode: '565',
    square: '419-438',
    color: 'blue',
  },
  {
    codeMain: '308',
    codeSub: '',
    MAnum: '287',
    pref: '愛知県',
    MAName: '刈谷',
    areaCode: '566',
    square: '413-431',
    color: 'blue',
  },
  {
    codeMain: '309',
    codeSub: '2',
    MAnum: '288',
    pref: '愛知県',
    MAName: '津島',
    areaCode: '567',
    square: '424-419',
    color: 'blue',
  },
  {
    codeMain: '312',
    codeSub: '',
    MAnum: '289',
    pref: '愛知県',
    MAName: '春日井',
    areaCode: '568',
    square: '428-430',
    color: 'blue',
  },
  {
    codeMain: '313',
    codeSub: '',
    MAnum: '290',
    pref: '愛知県',
    MAName: '半田',
    areaCode: '569',
    square: '408-428',
    color: 'blue',
  },
  {
    codeMain: '314',
    codeSub: '',
    MAnum: '291',
    pref: '岐阜県',
    MAName: '多治見',
    areaCode: '572',
    square: '432-437',
    color: 'yellow',
  },
  {
    codeMain: '315',
    codeSub: '',
    MAnum: '292',
    pref: '岐阜県',
    MAName: '恵那',
    areaCode: '573',
    square: '439-450',
    color: 'yellow',
  },
  {
    codeMain: '316',
    codeSub: '',
    MAnum: '293',
    pref: '岐阜県',
    MAName: '中津川',
    areaCode: '573',
    square: '441-454',
    color: 'yellow',
  },
  {
    codeMain: '317',
    codeSub: '',
    MAnum: '294',
    pref: '岐阜県',
    MAName: '美濃加茂',
    areaCode: '574',
    square: '438-432',
    color: 'yellow',
  },
  {
    codeMain: '318',
    codeSub: '',
    MAnum: '295',
    pref: '岐阜県',
    MAName: '美濃白川',
    areaCode: '574',
    square: '446-440',
    color: 'yellow',
  },
  {
    codeMain: '319',
    codeSub: '',
    MAnum: '296',
    pref: '岐阜県',
    MAName: '関',
    areaCode: '575',
    square: '441-428',
    color: 'yellow',
  },
  {
    codeMain: '320',
    codeSub: '',
    MAnum: '297',
    pref: '岐阜県',
    MAName: '郡上八幡',
    areaCode: '575',
    square: '456-430',
    color: 'yellow',
  },
  {
    codeMain: '321',
    codeSub: '',
    MAnum: '298',
    pref: '岐阜県',
    MAName: '下呂',
    areaCode: '576',
    square: '459-443',
    color: 'yellow',
  },
  {
    codeMain: '322',
    codeSub: '',
    MAnum: '299',
    pref: '岐阜県',
    MAName: '荘川',
    areaCode: '5769',
    square: '471-431',
    color: 'yellow',
  },
  {
    codeMain: '323',
    codeSub: '',
    MAnum: '300',
    pref: '岐阜県',
    MAName: '高山',
    areaCode: '577',
    square: '477-444',
    color: 'yellow',
  },
  {
    codeMain: '324',
    codeSub: '',
    MAnum: '301',
    pref: '岐阜県',
    MAName: '神岡',
    areaCode: '578',
    square: '488-446',
    color: 'yellow',
  },
  {
    codeMain: '325',
    codeSub: '2',
    MAnum: '302',
    pref: '岐阜県',
    MAName: '岐阜',
    areaCode: '58',
    square: '437-421',
    color: 'red',
  },
  {
    codeMain: '326',
    codeSub: '3',
    MAnum: '303',
    pref: '岐阜県',
    MAName: '高富',
    areaCode: '581',
    square: '442-422',
    color: 'red',
  },
  {
    codeMain: '332',
    codeSub: '',
    MAnum: '304',
    pref: '岐阜県',
    MAName: '大垣',
    areaCode: '584',
    square: '434-414',
    color: 'red',
  },
  {
    codeMain: '333',
    codeSub: '',
    MAnum: '305',
    pref: '岐阜県',
    MAName: '揖斐川',
    areaCode: '585',
    square: '441-412',
    color: 'red',
  },
  {
    codeMain: '334',
    codeSub: '2',
    MAnum: '306',
    pref: '愛知県',
    MAName: '一宮',
    areaCode: '586',
    square: '431-422',
    color: 'red',
  },
  {
    codeMain: '336',
    codeSub: '',
    MAnum: '307',
    pref: '愛知県',
    MAName: '一宮',
    areaCode: '587',
    square: '431-422',
    color: 'red',
  },
  {
    codeMain: '337',
    codeSub: '',
    MAnum: '308',
    pref: '三重県',
    MAName: '津',
    areaCode: '59',
    square: '398-408',
    color: 'green',
  },
  {
    codeMain: '338',
    codeSub: '',
    MAnum: '309',
    pref: '三重県',
    MAName: '四日市',
    areaCode: '59',
    square: '412-414',
    color: 'green',
  },
  {
    codeMain: '339',
    codeSub: '',
    MAnum: '310',
    pref: '三重県',
    MAName: '桑名',
    areaCode: '594',
    square: '418-417',
    color: 'green',
  },
  {
    codeMain: '340',
    codeSub: '',
    MAnum: '311',
    pref: '三重県',
    MAName: '上野',
    areaCode: '595',
    square: '401-391',
    color: 'green',
  },
  {
    codeMain: '341',
    codeSub: '2',
    MAnum: '312',
    pref: '三重県',
    MAName: '亀山',
    areaCode: '595',
    square: '406-406',
    color: 'green',
  },
  {
    codeMain: '343',
    codeSub: '',
    MAnum: '313',
    pref: '三重県',
    MAName: '伊勢',
    areaCode: '596',
    square: '386-417',
    color: 'green',
  },
  {
    codeMain: '344',
    codeSub: '3',
    MAnum: '314',
    pref: '三重県',
    MAName: '尾鷲',
    areaCode: '597',
    square: '362-392',
    color: 'green',
  },
  {
    codeMain: '347',
    codeSub: '',
    MAnum: '315',
    pref: '三重県',
    MAName: '熊野',
    areaCode: '597',
    square: '352-388',
    color: 'green',
  },
  {
    codeMain: '348',
    codeSub: '',
    MAnum: '316',
    pref: '三重県',
    MAName: '熊野',
    areaCode: '5979',
    square: '352-388',
    color: 'green',
  },
  {
    codeMain: '349',
    codeSub: '2',
    MAnum: '317',
    pref: '三重県',
    MAName: '松阪',
    areaCode: '598',
    square: '390-409',
    color: 'green',
  },
  {
    codeMain: '355',
    codeSub: '2',
    MAnum: '318',
    pref: '三重県',
    MAName: '三瀬谷',
    areaCode: '598',
    square: '380-403',
    color: 'green',
  },
  {
    codeMain: '357',
    codeSub: '',
    MAnum: '319',
    pref: '三重県',
    MAName: '鳥羽',
    areaCode: '599',
    square: '385-423',
    color: 'green',
  },
  {
    codeMain: '358',
    codeSub: '2',
    MAnum: '320',
    pref: '三重県',
    MAName: '阿児',
    areaCode: '599',
    square: '377-422',
    color: 'green',
  },
  {
    codeMain: '363',
    codeSub: '',
    MAnum: '321',
    pref: '大阪府',
    MAName: '大阪',
    areaCode: '6',
    square: '397-361',
    color: 'blue',
  },
  {
    codeMain: '364',
    codeSub: '',
    MAnum: '322',
    pref: '大阪府',
    MAName: '寝屋川',
    areaCode: '72',
    square: '401-368',
    color: 'red',
  },
  {
    codeMain: '365',
    codeSub: '',
    MAnum: '323',
    pref: '大阪府',
    MAName: '富田林',
    areaCode: '721',
    square: '386-366',
    color: 'red',
  },
  {
    codeMain: '366',
    codeSub: '2',
    MAnum: '324',
    pref: '大阪府',
    MAName: '堺',
    areaCode: '72',
    square: '391-360',
    color: 'red',
  },
  {
    codeMain: '368',
    codeSub: '',
    MAnum: '325',
    pref: '大阪府',
    MAName: '岸和田貝塚',
    areaCode: '72',
    square: '384-355',
    color: 'red',
  },
  {
    codeMain: '369',
    codeSub: '',
    MAnum: '326',
    pref: '大阪府',
    MAName: '和泉',
    areaCode: '725',
    square: '386-358',
    color: 'red',
  },
  {
    codeMain: '370',
    codeSub: '',
    MAnum: '327',
    pref: '大阪府',
    MAName: '茨木',
    areaCode: '72',
    square: '404-365',
    color: 'red',
  },
  {
    codeMain: '371',
    codeSub: '',
    MAnum: '328',
    pref: '大阪府',
    MAName: '池田',
    areaCode: '72',
    square: '404-359',
    color: 'red',
  },
  {
    codeMain: '372',
    codeSub: '',
    MAnum: '329',
    pref: '大阪府',
    MAName: '八尾',
    areaCode: '72',
    square: '393-366',
    color: 'red',
  },
  {
    codeMain: '373',
    codeSub: '2',
    MAnum: '330',
    pref: '和歌山県',
    MAName: '和歌山',
    areaCode: '73',
    square: '371-346',
    color: 'blue',
  },
  {
    codeMain: '374',
    codeSub: '',
    MAnum: '331',
    pref: '和歌山県',
    MAName: '新宮',
    areaCode: '735',
    square: '343-382',
    color: 'blue',
  },
  {
    codeMain: '375',
    codeSub: '',
    MAnum: '332',
    pref: '和歌山県',
    MAName: '串本',
    areaCode: '735',
    square: '329-372',
    color: 'blue',
  },
  {
    codeMain: '376',
    codeSub: '',
    MAnum: '333',
    pref: '和歌山県',
    MAName: '岩出',
    areaCode: '736',
    square: '373-352',
    color: 'blue',
  },
  {
    codeMain: '377',
    codeSub: '',
    MAnum: '334',
    pref: '和歌山県',
    MAName: '和歌山橋本',
    areaCode: '736',
    square: '376-366',
    color: 'blue',
  },
  {
    codeMain: '378',
    codeSub: '',
    MAnum: '335',
    pref: '和歌山県',
    MAName: '湯浅',
    areaCode: '737',
    square: '360-345',
    color: 'blue',
  },
  {
    codeMain: '379',
    codeSub: '',
    MAnum: '336',
    pref: '和歌山県',
    MAName: '御坊',
    areaCode: '738',
    square: '352-344',
    color: 'blue',
  },
  {
    codeMain: '380',
    codeSub: '',
    MAnum: '337',
    pref: '和歌山県',
    MAName: '田辺',
    areaCode: '739',
    square: '344-354',
    color: 'blue',
  },
  {
    codeMain: '381',
    codeSub: '',
    MAnum: '338',
    pref: '滋賀県',
    MAName: '今津',
    areaCode: '740',
    square: '436-388',
    color: 'yellow',
  },
  {
    codeMain: '382',
    codeSub: '',
    MAnum: '339',
    pref: '奈良県',
    MAName: '奈良',
    areaCode: '742',
    square: '396-377',
    color: 'yellow',
  },
  {
    codeMain: '383',
    codeSub: '2',
    MAnum: '340',
    pref: '奈良県',
    MAName: '奈良',
    areaCode: '743',
    square: '396-377',
    color: 'yellow',
  },
  {
    codeMain: '385',
    codeSub: '2',
    MAnum: '341',
    pref: '奈良県',
    MAName: '大和高田',
    areaCode: '744',
    square: '387-372',
    color: 'yellow',
  },
  {
    codeMain: '387',
    codeSub: '',
    MAnum: '342',
    pref: '奈良県',
    MAName: '大和高田',
    areaCode: '745',
    square: '387-372',
    color: 'yellow',
  },
  {
    codeMain: '388',
    codeSub: '',
    MAnum: '343',
    pref: '奈良県',
    MAName: '大和榛原',
    areaCode: '745',
    square: '388-382',
    color: 'yellow',
  },
  {
    codeMain: '389',
    codeSub: '2',
    MAnum: '344',
    pref: '奈良県',
    MAName: '吉野',
    areaCode: '746',
    square: '380-377',
    color: 'yellow',
  },
  {
    codeMain: '392',
    codeSub: '',
    MAnum: '345',
    pref: '奈良県',
    MAName: '十津川',
    areaCode: '746',
    square: '358-374',
    color: 'yellow',
  },
  {
    codeMain: '393',
    codeSub: '',
    MAnum: '346',
    pref: '奈良県',
    MAName: '上北山',
    areaCode: '7468',
    square: '366-384',
    color: 'yellow',
  },
  {
    codeMain: '394',
    codeSub: '2',
    MAnum: '347',
    pref: '奈良県',
    MAName: '五条',
    areaCode: '747',
    square: '378-370',
    color: 'yellow',
  },
  {
    codeMain: '396',
    codeSub: '',
    MAnum: '348',
    pref: '奈良県',
    MAName: '下市',
    areaCode: '747',
    square: '379-374',
    color: 'yellow',
  },
  {
    codeMain: '397',
    codeSub: '',
    MAnum: '349',
    pref: '滋賀県',
    MAName: '八日市',
    areaCode: '748',
    square: '420-395',
    color: 'yellow',
  },
  {
    codeMain: '398',
    codeSub: '',
    MAnum: '350',
    pref: '滋賀県',
    MAName: '水口',
    areaCode: '748',
    square: '412-393',
    color: 'yellow',
  },
  {
    codeMain: '399',
    codeSub: '',
    MAnum: '351',
    pref: '滋賀県',
    MAName: '彦根',
    areaCode: '749',
    square: '429-397',
    color: 'yellow',
  },
  {
    codeMain: '400',
    codeSub: '',
    MAnum: '352',
    pref: '滋賀県',
    MAName: '長浜',
    areaCode: '749',
    square: '435-398',
    color: 'yellow',
  },
  {
    codeMain: '401',
    codeSub: '',
    MAnum: '353',
    pref: '京都府',
    MAName: '京都',
    areaCode: '75',
    square: '414-374',
    color: 'green',
  },
  {
    codeMain: '402',
    codeSub: '',
    MAnum: '354',
    pref: '石川県',
    MAName: '小松',
    areaCode: '761',
    square: '492-408',
    color: 'red',
  },
  {
    codeMain: '403',
    codeSub: '2',
    MAnum: '355',
    pref: '石川県',
    MAName: '加賀',
    areaCode: '761',
    square: '486-402',
    color: 'red',
  },
  {
    codeMain: '405',
    codeSub: '2',
    MAnum: '356',
    pref: '石川県',
    MAName: '金沢',
    areaCode: '76',
    square: '501-418',
    color: 'red',
  },
  {
    codeMain: '406',
    codeSub: '',
    MAnum: '357',
    pref: '富山県',
    MAName: '福野',
    areaCode: '763',
    square: '502-430',
    color: 'red',
  },
  {
    codeMain: '407',
    codeSub: '2',
    MAnum: '358',
    pref: '富山県',
    MAName: '富山',
    areaCode: '76',
    square: '508-443',
    color: 'red',
  },
  {
    codeMain: '408',
    codeSub: '',
    MAnum: '359',
    pref: '富山県',
    MAName: '魚津',
    areaCode: '765',
    square: '515-451',
    color: 'red',
  },
  {
    codeMain: '409',
    codeSub: '',
    MAnum: '360',
    pref: '富山県',
    MAName: '高岡',
    areaCode: '766',
    square: '511-434',
    color: 'red',
  },
  {
    codeMain: '410',
    codeSub: '',
    MAnum: '361',
    pref: '石川県',
    MAName: '七尾',
    areaCode: '767',
    square: '527-432',
    color: 'red',
  },
  {
    codeMain: '411',
    codeSub: '',
    MAnum: '362',
    pref: '石川県',
    MAName: '羽咋',
    areaCode: '767',
    square: '519-424',
    color: 'red',
  },
  {
    codeMain: '412',
    codeSub: '',
    MAnum: '363',
    pref: '石川県',
    MAName: '輪島',
    areaCode: '768',
    square: '547-431',
    color: 'red',
  },
  {
    codeMain: '413',
    codeSub: '',
    MAnum: '364',
    pref: '石川県',
    MAName: '能都',
    areaCode: '768',
    square: '542-441',
    color: 'red',
  },
  {
    codeMain: '414',
    codeSub: '',
    MAnum: '365',
    pref: '福井県',
    MAName: '敦賀',
    areaCode: '770',
    square: '450-390',
    color: 'blue',
  },
  {
    codeMain: '415',
    codeSub: '',
    MAnum: '366',
    pref: '福井県',
    MAName: '小浜',
    areaCode: '770',
    square: '442-375',
    color: 'blue',
  },
  {
    codeMain: '416',
    codeSub: '',
    MAnum: '367',
    pref: '京都府',
    MAName: '亀岡',
    areaCode: '771',
    square: '415-366',
    color: 'blue',
  },
  {
    codeMain: '417',
    codeSub: '',
    MAnum: '368',
    pref: '京都府',
    MAName: '園部',
    areaCode: '771',
    square: '420-361',
    color: 'blue',
  },
  {
    codeMain: '418',
    codeSub: '',
    MAnum: '369',
    pref: '京都府',
    MAName: '宮津',
    areaCode: '772',
    square: '444-350',
    color: 'blue',
  },
  {
    codeMain: '419',
    codeSub: '',
    MAnum: '370',
    pref: '京都府',
    MAName: '峰山',
    areaCode: '772',
    square: '448-344',
    color: 'blue',
  },
  {
    codeMain: '420',
    codeSub: '',
    MAnum: '371',
    pref: '京都府',
    MAName: '福知山',
    areaCode: '773',
    square: '431-346',
    color: 'blue',
  },
  {
    codeMain: '421',
    codeSub: '',
    MAnum: '372',
    pref: '京都府',
    MAName: '舞鶴',
    areaCode: '773',
    square: '440-358',
    color: 'blue',
  },
  {
    codeMain: '422',
    codeSub: '',
    MAnum: '373',
    pref: '京都府',
    MAName: '宇治',
    areaCode: '774',
    square: '408-376',
    color: 'blue',
  },
  {
    codeMain: '423',
    codeSub: '',
    MAnum: '374',
    pref: '滋賀県',
    MAName: '大津',
    areaCode: '77',
    square: '414-379',
    color: 'blue',
  },
  {
    codeMain: '424',
    codeSub: '2',
    MAnum: '375',
    pref: '福井県',
    MAName: '福井',
    areaCode: '776',
    square: '473-397',
    color: 'blue',
  },
  {
    codeMain: '425',
    codeSub: '',
    MAnum: '376',
    pref: '福井県',
    MAName: '武生',
    areaCode: '778',
    square: '464-395',
    color: 'blue',
  },
  {
    codeMain: '426',
    codeSub: '',
    MAnum: '377',
    pref: '福井県',
    MAName: '大野',
    areaCode: '779',
    square: '469-409',
    color: 'blue',
  },
  {
    codeMain: '428',
    codeSub: '',
    MAnum: '378',
    pref: '兵庫県',
    MAName: '神戸',
    areaCode: '78',
    square: '396-347',
    color: 'yellow',
  },
  {
    codeMain: '429',
    codeSub: '',
    MAnum: '379',
    pref: '兵庫県',
    MAName: '播磨山崎',
    areaCode: '790',
    square: '414-318',
    color: 'green',
  },
  {
    codeMain: '430',
    codeSub: '',
    MAnum: '380',
    pref: '兵庫県',
    MAName: '福崎',
    areaCode: '790',
    square: '412-329',
    color: 'green',
  },
  {
    codeMain: '431',
    codeSub: '',
    MAnum: '381',
    pref: '兵庫県',
    MAName: '竜野',
    areaCode: '791',
    square: '406-318',
    color: 'green',
  },
  {
    codeMain: '432',
    codeSub: '2',
    MAnum: '382',
    pref: '兵庫県',
    MAName: '相生',
    areaCode: '791',
    square: '403-315',
    color: 'green',
  },
  {
    codeMain: '435',
    codeSub: '2',
    MAnum: '383',
    pref: '兵庫県',
    MAName: '姫路',
    areaCode: '79',
    square: '405-325',
    color: 'green',
  },
  {
    codeMain: '438',
    codeSub: '',
    MAnum: '384',
    pref: '兵庫県',
    MAName: '加古川',
    areaCode: '79',
    square: '401-332',
    color: 'green',
  },
  {
    codeMain: '439',
    codeSub: '',
    MAnum: '385',
    pref: '兵庫県',
    MAName: '三木',
    areaCode: '794',
    square: '403-338',
    color: 'green',
  },
  {
    codeMain: '440',
    codeSub: '',
    MAnum: '386',
    pref: '兵庫県',
    MAName: '西脇',
    areaCode: '795',
    square: '413-338',
    color: 'green',
  },
  {
    codeMain: '441',
    codeSub: '',
    MAnum: '387',
    pref: '兵庫県',
    MAName: '三田',
    areaCode: '79',
    square: '408-350',
    color: 'green',
  },
  {
    codeMain: '442',
    codeSub: '',
    MAnum: '388',
    pref: '兵庫県',
    MAName: '丹波柏原',
    areaCode: '795',
    square: '421-343',
    color: 'green',
  },
  {
    codeMain: '443',
    codeSub: '',
    MAnum: '389',
    pref: '兵庫県',
    MAName: '豊岡',
    areaCode: '796',
    square: '444-333',
    color: 'green',
  },
  {
    codeMain: '444',
    codeSub: '',
    MAnum: '390',
    pref: '兵庫県',
    MAName: '浜坂',
    areaCode: '796',
    square: '449-316',
    color: 'green',
  },
  {
    codeMain: '445',
    codeSub: '',
    MAnum: '391',
    pref: '兵庫県',
    MAName: '八鹿',
    areaCode: '79',
    square: '437-330',
    color: 'green',
  },
  {
    codeMain: '446',
    codeSub: '',
    MAnum: '392',
    pref: '兵庫県',
    MAName: '西宮',
    areaCode: '797',
    square: '399-354',
    color: 'green',
  },
  {
    codeMain: '447',
    codeSub: '',
    MAnum: '393',
    pref: '兵庫県',
    MAName: '西宮',
    areaCode: '798',
    square: '399-354',
    color: 'green',
  },
  {
    codeMain: '448',
    codeSub: '',
    MAnum: '394',
    pref: '兵庫県',
    MAName: '洲本',
    areaCode: '799',
    square: '377-333',
    color: 'green',
  },
  {
    codeMain: '449',
    codeSub: '',
    MAnum: '395',
    pref: '兵庫県',
    MAName: '津名',
    areaCode: '799',
    square: '383-334',
    color: 'green',
  },
  {
    codeMain: '450',
    codeSub: '',
    MAnum: '396',
    pref: '広島県',
    MAName: '広島',
    areaCode: '82',
    square: '381-221',
    color: 'blue',
  },
  {
    codeMain: '451',
    codeSub: '',
    MAnum: '397',
    pref: '山口県',
    MAName: '柳井',
    areaCode: '820',
    square: '357-203',
    color: 'blue',
  },
  {
    codeMain: '452',
    codeSub: '',
    MAnum: '398',
    pref: '山口県',
    MAName: '久賀',
    areaCode: '820',
    square: '356-210',
    color: 'blue',
  },
  {
    codeMain: '453',
    codeSub: '',
    MAnum: '399',
    pref: '広島県',
    MAName: '呉',
    areaCode: '823',
    square: '372-225',
    color: 'blue',
  },
  {
    codeMain: '454',
    codeSub: '',
    MAnum: '400',
    pref: '広島県',
    MAName: '三次',
    areaCode: '824',
    square: '403-241',
    color: 'blue',
  },
  {
    codeMain: '455',
    codeSub: '',
    MAnum: '401',
    pref: '広島県',
    MAName: '東広島',
    areaCode: '82',
    square: '382-234',
    color: 'blue',
  },
  {
    codeMain: '456',
    codeSub: '2',
    MAnum: '402',
    pref: '広島県',
    MAName: '庄原',
    areaCode: '824',
    square: '406-248',
    color: 'blue',
  },
  {
    codeMain: '461',
    codeSub: '',
    MAnum: '403',
    pref: '広島県',
    MAName: '千代田',
    areaCode: '826',
    square: '396-226',
    color: 'blue',
  },
  {
    codeMain: '462',
    codeSub: '',
    MAnum: '404',
    pref: '広島県',
    MAName: '安芸吉田',
    areaCode: '826',
    square: '395-234',
    color: 'blue',
  },
  {
    codeMain: '463',
    codeSub: '2',
    MAnum: '405',
    pref: '広島県',
    MAName: '加計',
    areaCode: '826',
    square: '392-216',
    color: 'blue',
  },
  {
    codeMain: '465',
    codeSub: '2',
    MAnum: '406',
    pref: '山口県',
    MAName: '岩国',
    areaCode: '827',
    square: '368-209',
    color: 'blue',
  },
  {
    codeMain: '467',
    codeSub: '',
    MAnum: '407',
    pref: '広島県',
    MAName: '廿日市',
    areaCode: '829',
    square: '378-216',
    color: 'blue',
  },
  {
    codeMain: '468',
    codeSub: '2',
    MAnum: '408',
    pref: '山口県',
    MAName: '下関',
    areaCode: '83',
    square: '356-149',
    color: 'red',
  },
  {
    codeMain: '469',
    codeSub: '',
    MAnum: '409',
    pref: '山口県',
    MAName: '下松',
    areaCode: '833',
    square: '359-192',
    color: 'red',
  },
  {
    codeMain: '470',
    codeSub: '',
    MAnum: '410',
    pref: '山口県',
    MAName: '徳山',
    areaCode: '834',
    square: '361-190',
    color: 'red',
  },
  {
    codeMain: '471',
    codeSub: '',
    MAnum: '411',
    pref: '山口県',
    MAName: '防府',
    areaCode: '835',
    square: '361-179',
    color: 'red',
  },
  {
    codeMain: '472',
    codeSub: '',
    MAnum: '412',
    pref: '山口県',
    MAName: '宇部',
    areaCode: '836',
    square: '356-163',
    color: 'red',
  },
  {
    codeMain: '474',
    codeSub: '',
    MAnum: '413',
    pref: '山口県',
    MAName: '長門',
    areaCode: '837',
    square: '379-162',
    color: 'red',
  },
  {
    codeMain: '475',
    codeSub: '2',
    MAnum: '414',
    pref: '山口県',
    MAName: '美祢',
    areaCode: '837',
    square: '368-162',
    color: 'red',
  },
  {
    codeMain: '477',
    codeSub: '',
    MAnum: '415',
    pref: '山口県',
    MAName: '萩',
    areaCode: '838',
    square: '381-173',
    color: 'red',
  },
  {
    codeMain: '478',
    codeSub: '',
    MAnum: '416',
    pref: '山口県',
    MAName: '田万川',
    areaCode: '8387',
    square: '394-185',
    color: 'red',
  },
  {
    codeMain: '479',
    codeSub: '',
    MAnum: '417',
    pref: '山口県',
    MAName: '田万川',
    areaCode: '8388',
    square: '394-185',
    color: 'red',
  },
  {
    codeMain: '481',
    codeSub: '3',
    MAnum: '418',
    pref: '山口県',
    MAName: '山口',
    areaCode: '83',
    square: '369-175',
    color: 'red',
  },
  {
    codeMain: '483',
    codeSub: '',
    MAnum: '419',
    pref: '山口県',
    MAName: '山口',
    areaCode: '8396',
    square: '369-175',
    color: 'red',
  },
  {
    codeMain: '485',
    codeSub: '',
    MAnum: '420',
    pref: '広島県',
    MAName: '因島',
    areaCode: '845',
    square: '374-254',
    color: 'green',
  },
  {
    codeMain: '486',
    codeSub: '',
    MAnum: '421',
    pref: '広島県',
    MAName: '竹原',
    areaCode: '846',
    square: '378-242',
    color: 'green',
  },
  {
    codeMain: '487',
    codeSub: '',
    MAnum: '422',
    pref: '広島県',
    MAName: '木江',
    areaCode: '846',
    square: '372-241',
    color: 'green',
  },
  {
    codeMain: '488',
    codeSub: '2',
    MAnum: '423',
    pref: '広島県',
    MAName: '府中',
    areaCode: '847',
    square: '390-257',
    color: 'green',
  },
  {
    codeMain: '489',
    codeSub: '3',
    MAnum: '424',
    pref: '広島県',
    MAName: '甲山',
    areaCode: '847',
    square: '391-249',
    color: 'green',
  },
  {
    codeMain: '494',
    codeSub: '',
    MAnum: '425',
    pref: '広島県',
    MAName: '東城',
    areaCode: '8477',
    square: '408-260',
    color: 'green',
  },
  {
    codeMain: '495',
    codeSub: '',
    MAnum: '426',
    pref: '広島県',
    MAName: '東城',
    areaCode: '847',
    square: '408-260',
    color: 'green',
  },
  {
    codeMain: '496',
    codeSub: '2',
    MAnum: '427',
    pref: '広島県',
    MAName: '尾道☆',
    areaCode: '848',
    square: '381-254',
    color: 'green',
  },
  {
    codeMain: '498',
    codeSub: '',
    MAnum: '428',
    pref: '広島県',
    MAName: '尾道☆',
    areaCode: '84',
    square: '381-254',
    color: 'green',
  },
  {
    codeMain: '499',
    codeSub: '',
    MAnum: '431',
    pref: '広島県',
    MAName: '福山☆',
    areaCode: '84',
    square: '385-263',
    color: 'green',
  },
  {
    codeMain: '500',
    codeSub: '',
    MAnum: '432',
    pref: '島根県',
    MAName: '西郷',
    areaCode: '8512',
    square: '481-268',
    color: 'yellow',
  },
  {
    codeMain: '501',
    codeSub: '',
    MAnum: '433',
    pref: '島根県',
    MAName: '海士',
    areaCode: '8514',
    square: '475-256',
    color: 'yellow',
  },
  {
    codeMain: '502',
    codeSub: '',
    MAnum: '434',
    pref: '島根県',
    MAName: '松江',
    areaCode: '852',
    square: '440-253',
    color: 'yellow',
  },
  {
    codeMain: '503',
    codeSub: '',
    MAnum: '435',
    pref: '島根県',
    MAName: '出雲',
    areaCode: '853',
    square: '434-239',
    color: 'yellow',
  },
  {
    codeMain: '504',
    codeSub: '',
    MAnum: '436',
    pref: '島根県',
    MAName: '安来',
    areaCode: '854',
    square: '438-262',
    color: 'yellow',
  },
  {
    codeMain: '505',
    codeSub: '',
    MAnum: '437',
    pref: '島根県',
    MAName: '木次',
    areaCode: '854',
    square: '430-245',
    color: 'yellow',
  },
  {
    codeMain: '506',
    codeSub: '',
    MAnum: '438',
    pref: '島根県',
    MAName: '掛合',
    areaCode: '854',
    square: '425-241',
    color: 'yellow',
  },
  {
    codeMain: '507',
    codeSub: '',
    MAnum: '439',
    pref: '島根県',
    MAName: '石見大田',
    areaCode: '854',
    square: '425-226',
    color: 'yellow',
  },
  {
    codeMain: '508',
    codeSub: '',
    MAnum: '440',
    pref: '島根県',
    MAName: '浜田',
    areaCode: '855',
    square: '408-206',
    color: 'yellow',
  },
  {
    codeMain: '509',
    codeSub: '',
    MAnum: '441',
    pref: '島根県',
    MAName: '江津',
    areaCode: '855',
    square: '414-213',
    color: 'yellow',
  },
  {
    codeMain: '510',
    codeSub: '',
    MAnum: '442',
    pref: '島根県',
    MAName: '川本',
    areaCode: '855',
    square: '413-225',
    color: 'yellow',
  },
  {
    codeMain: '511',
    codeSub: '',
    MAnum: '443',
    pref: '島根県',
    MAName: '益田',
    areaCode: '856',
    square: '396-194',
    color: 'yellow',
  },
  {
    codeMain: '512',
    codeSub: '',
    MAnum: '444',
    pref: '島根県',
    MAName: '津和野',
    areaCode: '856',
    square: '384-190',
    color: 'yellow',
  },
  {
    codeMain: '513',
    codeSub: '',
    MAnum: '445',
    pref: '鳥取県',
    MAName: '鳥取',
    areaCode: '857',
    square: '442-306',
    color: 'yellow',
  },
  {
    codeMain: '514',
    codeSub: '',
    MAnum: '446',
    pref: '鳥取県',
    MAName: '郡家',
    areaCode: '858',
    square: '437-307',
    color: 'yellow',
  },
  {
    codeMain: '515',
    codeSub: '',
    MAnum: '447',
    pref: '鳥取県',
    MAName: '倉吉',
    areaCode: '858',
    square: '438-287',
    color: 'yellow',
  },
  {
    codeMain: '516',
    codeSub: '',
    MAnum: '448',
    pref: '鳥取県',
    MAName: '米子',
    areaCode: '859',
    square: '438-265',
    color: 'yellow',
  },
  {
    codeMain: '517',
    codeSub: '',
    MAnum: '449',
    pref: '鳥取県',
    MAName: '根雨',
    areaCode: '859',
    square: '427-269',
    color: 'yellow',
  },
  {
    codeMain: '518',
    codeSub: '',
    MAnum: '450',
    pref: '岡山県',
    MAName: '倉敷',
    areaCode: '86',
    square: '392-282',
    color: 'red',
  },
  {
    codeMain: '519',
    codeSub: '',
    MAnum: '451',
    pref: '岡山県',
    MAName: '岡山',
    areaCode: '86',
    square: '395-290',
    color: 'red',
  },
  {
    codeMain: '520',
    codeSub: '',
    MAnum: '452',
    pref: '岡山県',
    MAName: '玉野',
    areaCode: '863',
    square: '386-289',
    color: 'red',
  },
  {
    codeMain: '522',
    codeSub: '2',
    MAnum: '453',
    pref: '岡山県',
    MAName: '鴨方',
    areaCode: '865',
    square: '388-273',
    color: 'red',
  },
  {
    codeMain: '525',
    codeSub: '',
    MAnum: '454',
    pref: '岡山県',
    MAName: '笠岡',
    areaCode: '865',
    square: '387-270',
    color: 'red',
  },
  {
    codeMain: '526',
    codeSub: '',
    MAnum: '455',
    pref: '岡山県',
    MAName: '高梁',
    areaCode: '866',
    square: '403-276',
    color: 'red',
  },
  {
    codeMain: '527',
    codeSub: '',
    MAnum: '456',
    pref: '岡山県',
    MAName: '井原',
    areaCode: '866',
    square: '392-268',
    color: 'red',
  },
  {
    codeMain: '528',
    codeSub: '',
    MAnum: '457',
    pref: '岡山県',
    MAName: '総社',
    areaCode: '866',
    square: '396-281',
    color: 'red',
  },
  {
    codeMain: '529',
    codeSub: '',
    MAnum: '459',
    pref: '岡山県',
    MAName: '新見',
    areaCode: '867',
    square: '413-269',
    color: 'red',
  },
  {
    codeMain: '530',
    codeSub: '',
    MAnum: '460',
    pref: '岡山県',
    MAName: '久世',
    areaCode: '867',
    square: '418-283',
    color: 'red',
  },
  {
    codeMain: '531',
    codeSub: '',
    MAnum: '458',
    pref: '岡山県',
    MAName: '加茂川',
    areaCode: '867',
    square: '408-285',
    color: 'red',
  },
  {
    codeMain: '532',
    codeSub: '',
    MAnum: '461',
    pref: '岡山県',
    MAName: '津山',
    areaCode: '868',
    square: '417-294',
    color: 'red',
  },
  {
    codeMain: '533',
    codeSub: '',
    MAnum: '462',
    pref: '岡山県',
    MAName: '美作',
    areaCode: '868',
    square: '414-301',
    color: 'red',
  },
  {
    codeMain: '534',
    codeSub: '',
    MAnum: '463',
    pref: '岡山県',
    MAName: '備前',
    areaCode: '869',
    square: '400-301',
    color: 'red',
  },
  {
    codeMain: '535',
    codeSub: '2',
    MAnum: '464',
    pref: '岡山県',
    MAName: '邑久',
    areaCode: '869',
    square: '395-297',
    color: 'red',
  },
  {
    codeMain: '538',
    codeSub: '2',
    MAnum: '465',
    pref: '岡山県',
    MAName: '赤磐',
    areaCode: '86',
    square: '401-294',
    color: 'red',
  },
  {
    codeMain: '540',
    codeSub: '',
    MAnum: '466',
    pref: '香川県',
    MAName: '観音寺',
    areaCode: '875',
    square: '365-275',
    color: 'blue',
  },
  {
    codeMain: '541',
    codeSub: '',
    MAnum: '467',
    pref: '香川県',
    MAName: '丸亀',
    areaCode: '877',
    square: '374-282',
    color: 'blue',
  },
  {
    codeMain: '542',
    codeSub: '',
    MAnum: '468',
    pref: '香川県',
    MAName: '高松',
    areaCode: '87',
    square: '377-294',
    color: 'blue',
  },
  {
    codeMain: '543',
    codeSub: '',
    MAnum: '469',
    pref: '香川県',
    MAName: '三本松',
    areaCode: '879',
    square: '372-307',
    color: 'blue',
  },
  {
    codeMain: '544',
    codeSub: '',
    MAnum: '470',
    pref: '香川県',
    MAName: '土庄',
    areaCode: '879',
    square: '385-301',
    color: 'blue',
  },
  {
    codeMain: '545',
    codeSub: '',
    MAnum: '471',
    pref: '高知県',
    MAName: '土佐中村',
    areaCode: '880',
    square: '303-238',
    color: 'yellow',
  },
  {
    codeMain: '546',
    codeSub: '',
    MAnum: '472',
    pref: '高知県',
    MAName: '宿毛',
    areaCode: '880',
    square: '300-228',
    color: 'yellow',
  },
  {
    codeMain: '547',
    codeSub: '2',
    MAnum: '473',
    pref: '高知県',
    MAName: '窪川',
    areaCode: '880',
    square: '315-248',
    color: 'yellow',
  },
  {
    codeMain: '548',
    codeSub: '',
    MAnum: '474',
    pref: '高知県',
    MAName: '土佐清水',
    areaCode: '880',
    square: '291-238',
    color: 'yellow',
  },
  {
    codeMain: '549',
    codeSub: '',
    MAnum: '475',
    pref: '徳島県',
    MAName: '鴨島',
    areaCode: '883',
    square: '362-308',
    color: 'yellow',
  },
  {
    codeMain: '550',
    codeSub: '',
    MAnum: '476',
    pref: '徳島県',
    MAName: '脇町',
    areaCode: '883',
    square: '362-298',
    color: 'yellow',
  },
  {
    codeMain: '551',
    codeSub: '',
    MAnum: '477',
    pref: '徳島県',
    MAName: '阿波池田',
    areaCode: '883',
    square: '360-282',
    color: 'yellow',
  },
  {
    codeMain: '552',
    codeSub: '',
    MAnum: '478',
    pref: '徳島県',
    MAName: '阿南',
    areaCode: '884',
    square: '354-321',
    color: 'yellow',
  },
  {
    codeMain: '553',
    codeSub: '',
    MAnum: '479',
    pref: '徳島県',
    MAName: '丹生谷',
    areaCode: '884',
    square: '351-313',
    color: 'yellow',
  },
  {
    codeMain: '554',
    codeSub: '2',
    MAnum: '480',
    pref: '徳島県',
    MAName: '牟岐',
    areaCode: '884',
    square: '340-309',
    color: 'yellow',
  },
  {
    codeMain: '555',
    codeSub: '2',
    MAnum: '481',
    pref: '徳島県',
    MAName: '小松島',
    areaCode: '885',
    square: '359-318',
    color: 'yellow',
  },
  {
    codeMain: '557',
    codeSub: '2',
    MAnum: '482',
    pref: '徳島県',
    MAName: '徳島',
    areaCode: '88',
    square: '362-316',
    color: 'yellow',
  },
  {
    codeMain: '558',
    codeSub: '',
    MAnum: '483',
    pref: '高知県',
    MAName: '嶺北',
    areaCode: '887',
    square: '346-274',
    color: 'yellow',
  },
  {
    codeMain: '559',
    codeSub: '2',
    MAnum: '484',
    pref: '高知県',
    MAName: '室戸',
    areaCode: '887',
    square: '319-296',
    color: 'yellow',
  },
  {
    codeMain: '560',
    codeSub: '3',
    MAnum: '485',
    pref: '高知県',
    MAName: '安芸',
    areaCode: '887',
    square: '331-285',
    color: 'yellow',
  },
  {
    codeMain: '562',
    codeSub: '2',
    MAnum: '486',
    pref: '高知県',
    MAName: '土佐山田',
    areaCode: '887',
    square: '337-275',
    color: 'yellow',
  },
  {
    codeMain: '563',
    codeSub: '2',
    MAnum: '487',
    pref: '高知県',
    MAName: '高知',
    areaCode: '88',
    square: '334-268',
    color: 'yellow',
  },
  {
    codeMain: '564',
    codeSub: '',
    MAnum: '488',
    pref: '高知県',
    MAName: '佐川',
    areaCode: '889',
    square: '331-256',
    color: 'yellow',
  },
  {
    codeMain: '565',
    codeSub: '',
    MAnum: '489',
    pref: '高知県',
    MAName: '須崎',
    areaCode: '889',
    square: '325-256',
    color: 'yellow',
  },
  {
    codeMain: '566',
    codeSub: '',
    MAnum: '490',
    pref: '愛媛県',
    MAName: '久万',
    areaCode: '892',
    square: '339-239',
    color: 'red',
  },
  {
    codeMain: '567',
    codeSub: '',
    MAnum: '491',
    pref: '愛媛県',
    MAName: '大洲',
    areaCode: '893',
    square: '331-222',
    color: 'red',
  },
  {
    codeMain: '568',
    codeSub: '',
    MAnum: '492',
    pref: '愛媛県',
    MAName: '八幡浜',
    areaCode: '894',
    square: '329-216',
    color: 'red',
  },
  {
    codeMain: '569',
    codeSub: '',
    MAnum: '493',
    pref: '愛媛県',
    MAName: '宇和',
    areaCode: '894',
    square: '323-220',
    color: 'red',
  },
  {
    codeMain: '570',
    codeSub: '',
    MAnum: '494',
    pref: '愛媛県',
    MAName: '宇和島',
    areaCode: '895',
    square: '316-222',
    color: 'red',
  },
  {
    codeMain: '571',
    codeSub: '',
    MAnum: '495',
    pref: '愛媛県',
    MAName: '御荘',
    areaCode: '895',
    square: '301-221',
    color: 'red',
  },
  {
    codeMain: '572',
    codeSub: '',
    MAnum: '496',
    pref: '愛媛県',
    MAName: '伊予三島',
    areaCode: '896',
    square: '357-270',
    color: 'red',
  },
  {
    codeMain: '573',
    codeSub: '',
    MAnum: '497',
    pref: '愛媛県',
    MAName: '伯方',
    areaCode: '897',
    square: '370-251',
    color: 'red',
  },
  {
    codeMain: '574',
    codeSub: '',
    MAnum: '498',
    pref: '愛媛県',
    MAName: '新居浜',
    areaCode: '897',
    square: '356-257',
    color: 'red',
  },
  {
    codeMain: '575',
    codeSub: '',
    MAnum: '499',
    pref: '愛媛県',
    MAName: '今治',
    areaCode: '898',
    square: '362-245',
    color: 'red',
  },
  {
    codeMain: '576',
    codeSub: '',
    MAnum: '500',
    pref: '愛媛県',
    MAName: '松山',
    areaCode: '89',
    square: '350-233',
    color: 'red',
  },
  {
    codeMain: '577',
    codeSub: '',
    MAnum: '501',
    pref: '福岡県',
    MAName: '前原',
    areaCode: '92',
    square: '334-113',
    color: 'red',
  },
  {
    codeMain: '578',
    codeSub: '',
    MAnum: '502',
    pref: '福岡県',
    MAName: '福岡',
    areaCode: '92',
    square: '336-123',
    color: 'red',
  },
  {
    codeMain: '579',
    codeSub: '',
    MAnum: '503',
    pref: '長崎県',
    MAName: '郷ノ浦',
    areaCode: '920',
    square: '345-90',
    color: 'red',
  },
  {
    codeMain: '580',
    codeSub: '',
    MAnum: '504',
    pref: '長崎県',
    MAName: '厳原',
    areaCode: '920',
    square: '370-74',
    color: 'red',
  },
  {
    codeMain: '581',
    codeSub: '',
    MAnum: '505',
    pref: '長崎県',
    MAName: '対馬佐賀',
    areaCode: '920',
    square: '384-80',
    color: 'red',
  },
  {
    codeMain: '582',
    codeSub: '',
    MAnum: '506',
    pref: '福岡県',
    MAName: '北九州',
    areaCode: '93',
    square: '352-146',
    color: 'yellow',
  },
  {
    codeMain: '583',
    codeSub: '',
    MAnum: '507',
    pref: '福岡県',
    MAName: '行橋',
    areaCode: '930',
    square: '344-150',
    color: 'yellow',
  },
  {
    codeMain: '584',
    codeSub: '',
    MAnum: '508',
    pref: '福岡県',
    MAName: '宗像',
    areaCode: '940',
    square: '348-130',
    color: 'blue',
  },
  {
    codeMain: '585',
    codeSub: '',
    MAnum: '509',
    pref: '福岡県',
    MAName: '久留米',
    areaCode: '942',
    square: '321-126',
    color: 'blue',
  },
  {
    codeMain: '586',
    codeSub: '',
    MAnum: '510',
    pref: '福岡県',
    MAName: '八女',
    areaCode: '943',
    square: '315-128',
    color: 'blue',
  },
  {
    codeMain: '587',
    codeSub: '',
    MAnum: '511',
    pref: '福岡県',
    MAName: '田主丸',
    areaCode: '943',
    square: '322-135',
    color: 'blue',
  },
  {
    codeMain: '588',
    codeSub: '',
    MAnum: '512',
    pref: '福岡県',
    MAName: '瀬高',
    areaCode: '944',
    square: '311-124',
    color: 'blue',
  },
  {
    codeMain: '589',
    codeSub: '',
    MAnum: '513',
    pref: '福岡県',
    MAName: '甘木',
    areaCode: '946',
    square: '327-134',
    color: 'blue',
  },
  {
    codeMain: '590',
    codeSub: '',
    MAnum: '514',
    pref: '福岡県',
    MAName: '田川',
    areaCode: '947',
    square: '339-141',
    color: 'blue',
  },
  {
    codeMain: '591',
    codeSub: '',
    MAnum: '515',
    pref: '福岡県',
    MAName: '飯塚',
    areaCode: '948',
    square: '338-136',
    color: 'blue',
  },
  {
    codeMain: '592',
    codeSub: '',
    MAnum: '516',
    pref: '福岡県',
    MAName: '直方',
    areaCode: '949',
    square: '344-138',
    color: 'blue',
  },
  {
    codeMain: '593',
    codeSub: '',
    MAnum: '516',
    pref: '福岡県',
    MAName: '直方',
    areaCode: '949',
    square: '344-138',
    color: 'blue',
  },
  {
    codeMain: '594',
    codeSub: '',
    MAnum: '517',
    pref: '長崎県',
    MAName: '平戸',
    areaCode: '950',
    square: '324-082',
    color: 'yellow',
  },
  {
    codeMain: '595',
    codeSub: '',
    MAnum: '518',
    pref: '佐賀県',
    MAName: '佐賀',
    areaCode: '952',
    square: '317-116',
    color: 'yellow',
  },
  {
    codeMain: '596',
    codeSub: '',
    MAnum: '519',
    pref: '佐賀県',
    MAName: '武雄',
    areaCode: '954',
    square: '314-103',
    color: 'yellow',
  },
  {
    codeMain: '597',
    codeSub: '',
    MAnum: '520',
    pref: '佐賀県',
    MAName: '鹿島',
    areaCode: '954',
    square: '309-106',
    color: 'yellow',
  },
  {
    codeMain: '598',
    codeSub: '',
    MAnum: '521',
    pref: '佐賀県',
    MAName: '唐津',
    areaCode: '955',
    square: '328-102',
    color: 'yellow',
  },
  {
    codeMain: '599',
    codeSub: '',
    MAnum: '522',
    pref: '佐賀県',
    MAName: '伊万里',
    areaCode: '955',
    square: '318-097',
    color: 'yellow',
  },
  {
    codeMain: '600',
    codeSub: '',
    MAnum: '523',
    pref: '長崎県',
    MAName: '佐世保',
    areaCode: '956',
    square: '313-088',
    color: 'yellow',
  },
  {
    codeMain: '601',
    codeSub: '',
    MAnum: '524',
    pref: '長崎県',
    MAName: '諫早',
    areaCode: '957',
    square: '295-102',
    color: 'yellow',
  },
  {
    codeMain: '602',
    codeSub: '',
    MAnum: '525',
    pref: '長崎県',
    MAName: '島原',
    areaCode: '957',
    square: '291-117',
    color: 'yellow',
  },
  {
    codeMain: '603',
    codeSub: '',
    MAnum: '526',
    pref: '長崎県',
    MAName: '長崎',
    areaCode: '95',
    square: '289-094',
    color: 'yellow',
  },
  {
    codeMain: '604',
    codeSub: '',
    MAnum: '527',
    pref: '長崎県',
    MAName: '大瀬戸',
    areaCode: '959',
    square: '299-084',
    color: 'yellow',
  },
  {
    codeMain: '605',
    codeSub: '',
    MAnum: '528',
    pref: '長崎県',
    MAName: '福江',
    areaCode: '959',
    square: '286-045',
    color: 'yellow',
  },
  {
    codeMain: '606',
    codeSub: '',
    MAnum: '529',
    pref: '長崎県',
    MAName: '有川',
    areaCode: '959',
    square: '302-060',
    color: 'yellow',
  },
  {
    codeMain: '607',
    codeSub: '',
    MAnum: '530',
    pref: '熊本県',
    MAName: '熊本',
    areaCode: '96',
    square: '292-133',
    color: 'red',
  },
  {
    codeMain: '608',
    codeSub: '',
    MAnum: '531',
    pref: '熊本県',
    MAName: '松橋',
    areaCode: '964',
    square: '284-131',
    color: 'red',
  },
  {
    codeMain: '609',
    codeSub: '',
    MAnum: '532',
    pref: '熊本県',
    MAName: '八代',
    areaCode: '965',
    square: '276-127',
    color: 'red',
  },
  {
    codeMain: '610',
    codeSub: '',
    MAnum: '533',
    pref: '熊本県',
    MAName: '人吉',
    areaCode: '966',
    square: '260-133',
    color: 'red',
  },
  {
    codeMain: '611',
    codeSub: '',
    MAnum: '534',
    pref: '熊本県',
    MAName: '水俣',
    areaCode: '966',
    square: '259-116',
    color: 'red',
  },
  {
    codeMain: '612',
    codeSub: '',
    MAnum: '535',
    pref: '熊本県',
    MAName: '熊本一の宮',
    areaCode: '967',
    square: '300-152',
    color: 'red',
  },
  {
    codeMain: '613',
    codeSub: '',
    MAnum: '536',
    pref: '熊本県',
    MAName: '矢部',
    areaCode: '967',
    square: '286-145',
    color: 'red',
  },
  {
    codeMain: '614',
    codeSub: '',
    MAnum: '537',
    pref: '熊本県',
    MAName: '高森',
    areaCode: '967',
    square: '293-153',
    color: 'red',
  },
  {
    codeMain: '615',
    codeSub: '',
    MAnum: '538',
    pref: '熊本県',
    MAName: '山鹿',
    areaCode: '968',
    square: '304-133',
    color: 'red',
  },
  {
    codeMain: '616',
    codeSub: '',
    MAnum: '539',
    pref: '熊本県',
    MAName: '玉名',
    areaCode: '968',
    square: '299-127',
    color: 'red',
  },
  {
    codeMain: '617',
    codeSub: '2',
    MAnum: '540',
    pref: '熊本県',
    MAName: '天草',
    areaCode: '969',
    square: '273-107',
    color: 'red',
  },
  {
    codeMain: '619',
    codeSub: '',
    MAnum: '541',
    pref: '大分県',
    MAName: '佐伯',
    areaCode: '972',
    square: '301-189',
    color: 'green',
  },
  {
    codeMain: '620',
    codeSub: '',
    MAnum: '542',
    pref: '大分県',
    MAName: '臼杵',
    areaCode: '972',
    square: '310-185',
    color: 'green',
  },
  {
    codeMain: '621',
    codeSub: '',
    MAnum: '543',
    pref: '大分県',
    MAName: '日田',
    areaCode: '973',
    square: '321-146',
    color: 'green',
  },
  {
    codeMain: '622',
    codeSub: '',
    MAnum: '544',
    pref: '大分県',
    MAName: '玖珠',
    areaCode: '973',
    square: '319-156',
    color: 'green',
  },
  {
    codeMain: '623',
    codeSub: '',
    MAnum: '545',
    pref: '大分県',
    MAName: '三重',
    areaCode: '974',
    square: '302-174',
    color: 'green',
  },
  {
    codeMain: '624',
    codeSub: '',
    MAnum: '546',
    pref: '大分県',
    MAName: '竹田',
    areaCode: '974',
    square: '301-166',
    color: 'green',
  },
  {
    codeMain: '625',
    codeSub: '',
    MAnum: '547',
    pref: '大分県',
    MAName: '大分',
    areaCode: '97',
    square: '316-177',
    color: 'green',
  },
  {
    codeMain: '626',
    codeSub: '',
    MAnum: '548',
    pref: '大分県',
    MAName: '別府',
    areaCode: '977',
    square: '318-172',
    color: 'green',
  },
  {
    codeMain: '627',
    codeSub: '',
    MAnum: '549',
    pref: '大分県',
    MAName: '国東',
    areaCode: '978',
    square: '334-184',
    color: 'green',
  },
  {
    codeMain: '628',
    codeSub: '',
    MAnum: '550',
    pref: '大分県',
    MAName: '豊後高田',
    areaCode: '978',
    square: '334-170',
    color: 'green',
  },
  {
    codeMain: '629',
    codeSub: '',
    MAnum: '551',
    pref: '大分県',
    MAName: '杵築',
    areaCode: '978',
    square: '326-178',
    color: 'green',
  },
  {
    codeMain: '630',
    codeSub: '',
    MAnum: '552',
    pref: '大分県',
    MAName: '中津',
    areaCode: '979',
    square: '337-159',
    color: 'green',
  },
  {
    codeMain: '631',
    codeSub: '',
    MAnum: '553',
    pref: '沖縄県',
    MAName: '那覇',
    areaCode: '98',
    square: '(-073)-(-046)',
    color: 'blue',
  },
  {
    codeMain: '632',
    codeSub: '',
    MAnum: '554',
    pref: '沖縄県',
    MAName: '名護',
    areaCode: '980',
    square: '(-052)-(-032)',
    color: 'blue',
  },
  {
    codeMain: '633',
    codeSub: '',
    MAnum: '555',
    pref: '沖縄県',
    MAName: '南大東',
    areaCode: '9802',
    square: '(-094)-131',
    color: 'blue',
  },
  {
    codeMain: '634',
    codeSub: '',
    MAnum: '556',
    pref: '沖縄県',
    MAName: '沖縄宮古',
    areaCode: '980',
    square: '(-151)-(-168)',
    color: 'blue',
  },
  {
    codeMain: '635',
    codeSub: '',
    MAnum: '557',
    pref: '沖縄県',
    MAName: '八重山',
    areaCode: '980',
    square: '(-177)-(-227)',
    color: 'blue',
  },
  {
    codeMain: '636',
    codeSub: '',
    MAnum: '558',
    pref: '宮崎県',
    MAName: '延岡',
    areaCode: '982',
    square: '280-177',
    color: 'blue',
  },
  {
    codeMain: '637',
    codeSub: '',
    MAnum: '559',
    pref: '宮崎県',
    MAName: '日向',
    areaCode: '982',
    square: '271-175',
    color: 'blue',
  },
  {
    codeMain: '638',
    codeSub: '',
    MAnum: '560',
    pref: '宮崎県',
    MAName: '高千穂',
    areaCode: '982',
    square: '287-161',
    color: 'blue',
  },
  {
    codeMain: '639',
    codeSub: '',
    MAnum: '561',
    pref: '宮崎県',
    MAName: '高鍋',
    areaCode: '983',
    square: '255-168',
    color: 'blue',
  },
  {
    codeMain: '640',
    codeSub: '',
    MAnum: '562',
    pref: '宮崎県',
    MAName: '小林',
    areaCode: '984',
    square: '247-142',
    color: 'blue',
  },
  {
    codeMain: '641',
    codeSub: '',
    MAnum: '563',
    pref: '宮崎県',
    MAName: '宮崎',
    areaCode: '985',
    square: '243-163',
    color: 'blue',
  },
  {
    codeMain: '642',
    codeSub: '',
    MAnum: '564',
    pref: '宮崎県',
    MAName: '都城',
    areaCode: '986',
    square: '232-145',
    color: 'blue',
  },
  {
    codeMain: '643',
    codeSub: '',
    MAnum: '565',
    pref: '宮崎県',
    MAName: '日南',
    areaCode: '987',
    square: '225-160',
    color: 'blue',
  },
  {
    codeMain: '644',
    codeSub: '',
    MAnum: '566',
    pref: '鹿児島県',
    MAName: '中之島',
    areaCode: '9912',
    square: '129-079',
    color: 'green',
  },
  {
    codeMain: '645',
    codeSub: '',
    MAnum: '567',
    pref: '鹿児島県',
    MAName: '硫黄島',
    areaCode: '9913',
    square: '180-103',
    color: 'green',
  },
  {
    codeMain: '646',
    codeSub: '',
    MAnum: '568',
    pref: '鹿児島県',
    MAName: '鹿児島',
    areaCode: '99',
    square: '225-120',
    color: 'green',
  },
  {
    codeMain: '647',
    codeSub: '',
    MAnum: '569',
    pref: '鹿児島県',
    MAName: '指宿',
    areaCode: '993',
    square: '206-123',
    color: 'green',
  },
  {
    codeMain: '648',
    codeSub: '',
    MAnum: '570',
    pref: '鹿児島県',
    MAName: '加世田',
    areaCode: '993',
    square: '215-108',
    color: 'green',
  },
  {
    codeMain: '649',
    codeSub: '',
    MAnum: '571',
    pref: '鹿児島県',
    MAName: '志布志',
    areaCode: '99',
    square: '219-145',
    color: 'green',
  },
  {
    codeMain: '650',
    codeSub: '',
    MAnum: '572',
    pref: '鹿児島県',
    MAName: '鹿屋',
    areaCode: '994',
    square: '214-133',
    color: 'green',
  },
  {
    codeMain: '651',
    codeSub: '',
    MAnum: '573',
    pref: '鹿児島県',
    MAName: '大根占',
    areaCode: '994',
    square: '206-129',
    color: 'green',
  },
  {
    codeMain: '652',
    codeSub: '',
    MAnum: '574',
    pref: '鹿児島県',
    MAName: '加治木',
    areaCode: '995',
    square: '233-126',
    color: 'green',
  },
  {
    codeMain: '653',
    codeSub: '',
    MAnum: '575',
    pref: '鹿児島県',
    MAName: '大口',
    areaCode: '995',
    square: '251-125',
    color: 'green',
  },
  {
    codeMain: '654',
    codeSub: '',
    MAnum: '576',
    pref: '鹿児島県',
    MAName: '出水',
    areaCode: '996',
    square: '252-113',
    color: 'green',
  },
  {
    codeMain: '655',
    codeSub: '',
    MAnum: '577',
    pref: '鹿児島県',
    MAName: '川内',
    areaCode: '996',
    square: '237-109',
    color: 'green',
  },
  {
    codeMain: '656',
    codeSub: '',
    MAnum: '578',
    pref: '鹿児島県',
    MAName: '中甑',
    areaCode: '9969',
    square: '239-089',
    color: 'green',
  },
  {
    codeMain: '657',
    codeSub: '',
    MAnum: '579',
    pref: '鹿児島県',
    MAName: '名瀬',
    areaCode: '997',
    square: '047-054',
    color: 'green',
  },
  {
    codeMain: '658',
    codeSub: '',
    MAnum: '580',
    pref: '鹿児島県',
    MAName: '徳之島',
    areaCode: '997',
    square: '011-027',
    color: 'green',
  },
  {
    codeMain: '659',
    codeSub: '',
    MAnum: '581',
    pref: '鹿児島県',
    MAName: '種子島',
    areaCode: '997',
    square: '177-137',
    color: 'green',
  },
  {
    codeMain: '660',
    codeSub: '',
    MAnum: '582',
    pref: '鹿児島県',
    MAName: '屋久島',
    areaCode: '997',
    square: '160-116',
    color: 'green',
  },
  {
    codeMain: '661',
    codeSub: '',
    MAnum: '583',
    pref: '鹿児島県',
    MAName: '瀬戸内',
    areaCode: '997',
    square: '034-044',
    color: 'green',
  },
]

export default MACompList