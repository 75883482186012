const NumberBandList = [
  {
    id: '1',
    MA: '札幌',
    areaCode: '11',
    bandStart: '0111',
    bandEnd: '0118',
    eliminateCode: '0',
  },
  {
    id: '2',
    MA: '千歳',
    areaCode: '123',
    bandStart: '01231',
    bandEnd: '01234',
    eliminateCode: '0',
  },
  {
    id: '3',
    MA: '千歳',
    areaCode: '123',
    bandStart: '01236',
    bandEnd: '01236',
    eliminateCode: '0',
  },
  {
    id: '4',
    MA: '夕張',
    areaCode: '123',
    bandStart: '01235',
    bandEnd: '01235',
    eliminateCode: '0',
  },
  {
    id: '5',
    MA: '夕張',
    areaCode: '123',
    bandStart: '012390',
    bandEnd: '012394',
    eliminateCode: '0',
  },
  {
    id: '6',
    MA: '栗山',
    areaCode: '123',
    bandStart: '01237',
    bandEnd: '01238',
    eliminateCode: '0',
  },
  {
    id: '7',
    MA: '栗山',
    areaCode: '123',
    bandStart: '012395',
    bandEnd: '012399',
    eliminateCode: '0',
  },
  {
    id: '8',
    MA: '芦別',
    areaCode: '124',
    bandStart: '0124',
    bandEnd: '0124',
    eliminateCode: '0',
  },
  {
    id: '9',
    MA: '滝川',
    areaCode: '125',
    bandStart: '0125',
    bandEnd: '0125',
    eliminateCode: '0',
  },
  {
    id: '10',
    MA: '岩見沢',
    areaCode: '126',
    bandStart: '0126',
    bandEnd: '0126',
    eliminateCode: '0',
  },
  {
    id: '11',
    MA: '岩見沢',
    areaCode: '1267',
    bandStart: '01267',
    bandEnd: '01267',
    eliminateCode: '0',
  },
  {
    id: '12',
    MA: '石狩',
    areaCode: '133',
    bandStart: '01331',
    bandEnd: '01331',
    eliminateCode: '0',
  },
  {
    id: '13',
    MA: '石狩',
    areaCode: '133',
    bandStart: '01336',
    bandEnd: '01337',
    eliminateCode: '0',
  },
  {
    id: '14',
    MA: '当別',
    areaCode: '133',
    bandStart: '01332',
    bandEnd: '01333',
    eliminateCode: '0',
  },
  {
    id: '15',
    MA: '小樽',
    areaCode: '134',
    bandStart: '0134',
    bandEnd: '0134',
    eliminateCode: '0',
  },
  {
    id: '16',
    MA: '余市',
    areaCode: '135',
    bandStart: '01351',
    bandEnd: '01354',
    eliminateCode: '0',
  },
  {
    id: '17',
    MA: '岩内',
    areaCode: '135',
    bandStart: '01356',
    bandEnd: '01357',
    eliminateCode: '0',
  },
  {
    id: '18',
    MA: '倶知安',
    areaCode: '136',
    bandStart: '01361',
    bandEnd: '01365',
    eliminateCode: '0',
  },
  {
    id: '19',
    MA: '寿都',
    areaCode: '136',
    bandStart: '01366',
    bandEnd: '01367',
    eliminateCode: '0',
  },
  {
    id: '20',
    MA: '鹿部',
    areaCode: '1372',
    bandStart: '01372',
    bandEnd: '01372',
    eliminateCode: '0',
  },
  {
    id: '21',
    MA: '森',
    areaCode: '1374',
    bandStart: '01374',
    bandEnd: '01374',
    eliminateCode: '0',
  },
  {
    id: '22',
    MA: '八雲',
    areaCode: '137',
    bandStart: '01375',
    bandEnd: '01376',
    eliminateCode: '0',
  },
  {
    id: '23',
    MA: '八雲',
    areaCode: '1377',
    bandStart: '01377',
    bandEnd: '01377',
    eliminateCode: '0',
  },
  {
    id: '24',
    MA: '今金',
    areaCode: '137',
    bandStart: '01378',
    bandEnd: '01378',
    eliminateCode: '0',
  },
  {
    id: '25',
    MA: '函館',
    areaCode: '138',
    bandStart: '0138',
    bandEnd: '0138',
    eliminateCode: '0',
  },
  {
    id: '26',
    MA: '松前',
    areaCode: '139',
    bandStart: '01393',
    bandEnd: '01394',
    eliminateCode: '0',
  },
  {
    id: '27',
    MA: '江差',
    areaCode: '139',
    bandStart: '01395',
    bandEnd: '01396',
    eliminateCode: '0',
  },
  {
    id: '28',
    MA: '木古内',
    areaCode: '1392',
    bandStart: '01392',
    bandEnd: '01392',
    eliminateCode: '0',
  },
  {
    id: '29',
    MA: '奥尻',
    areaCode: '1397',
    bandStart: '01397',
    bandEnd: '01397',
    eliminateCode: '0',
  },
  {
    id: '30',
    MA: '熊石',
    areaCode: '1398',
    bandStart: '01398',
    bandEnd: '01398',
    eliminateCode: '0',
  },
  {
    id: '31',
    MA: '伊達',
    areaCode: '142',
    bandStart: '0142',
    bandEnd: '0142',
    eliminateCode: '0',
  },
  {
    id: '32',
    MA: '室蘭',
    areaCode: '143',
    bandStart: '0143',
    bandEnd: '0143',
    eliminateCode: '0',
  },
  {
    id: '33',
    MA: '苫小牧',
    areaCode: '144',
    bandStart: '0144',
    bandEnd: '0144',
    eliminateCode: '0',
  },
  {
    id: '34',
    MA: '早来',
    areaCode: '145',
    bandStart: '01452',
    bandEnd: '01453',
    eliminateCode: '0',
  },
  {
    id: '35',
    MA: '鵡川',
    areaCode: '145',
    bandStart: '01454',
    bandEnd: '01455',
    eliminateCode: '0',
  },
  {
    id: '36',
    MA: '門別富川',
    areaCode: '1456',
    bandStart: '01456',
    bandEnd: '01456',
    eliminateCode: '0',
  },
  {
    id: '37',
    MA: '門別富川',
    areaCode: '1457',
    bandStart: '01457',
    bandEnd: '01457',
    eliminateCode: '0',
  },
  {
    id: '38',
    MA: '浦河',
    areaCode: '146',
    bandStart: '01462',
    bandEnd: '01462',
    eliminateCode: '0',
  },
  {
    id: '39',
    MA: '浦河',
    areaCode: '146',
    bandStart: '014630',
    bandEnd: '014631',
    eliminateCode: '0',
  },
  {
    id: '40',
    MA: '浦河',
    areaCode: '146',
    bandStart: '014636',
    bandEnd: '014636',
    eliminateCode: '0',
  },
  {
    id: '41',
    MA: '浦河',
    areaCode: '146',
    bandStart: '014638',
    bandEnd: '014639',
    eliminateCode: '0',
  },
  {
    id: '42',
    MA: '静内',
    areaCode: '146',
    bandStart: '014632',
    bandEnd: '014635',
    eliminateCode: '0',
  },
  {
    id: '43',
    MA: '静内',
    areaCode: '146',
    bandStart: '014637',
    bandEnd: '014637',
    eliminateCode: '0',
  },
  {
    id: '44',
    MA: '静内',
    areaCode: '146',
    bandStart: '01464',
    bandEnd: '01465',
    eliminateCode: '0',
  },
  {
    id: '45',
    MA: 'えりも',
    areaCode: '1466',
    bandStart: '01466',
    bandEnd: '01466',
    eliminateCode: '0',
  },
  {
    id: '46',
    MA: '弟子屈',
    areaCode: '15',
    bandStart: '015410',
    bandEnd: '015416',
    eliminateCode: '0',
  },
  {
    id: '47',
    MA: '弟子屈',
    areaCode: '15',
    bandStart: '015418',
    bandEnd: '015419',
    eliminateCode: '0',
  },
  {
    id: '48',
    MA: '弟子屈',
    areaCode: '15',
    bandStart: '01548',
    bandEnd: '01548',
    eliminateCode: '0',
  },
  {
    id: '49',
    MA: '十勝池田',
    areaCode: '15',
    bandStart: '015510',
    bandEnd: '015516',
    eliminateCode: '0',
  },
  {
    id: '50',
    MA: '十勝池田',
    areaCode: '15',
    bandStart: '015518',
    bandEnd: '015519',
    eliminateCode: '0',
  },
  {
    id: '51',
    MA: '十勝池田',
    areaCode: '15',
    bandStart: '01557',
    bandEnd: '01557',
    eliminateCode: '0',
  },
  {
    id: '52',
    MA: '網走',
    areaCode: '152',
    bandStart: '01521',
    bandEnd: '01521',
    eliminateCode: '0',
  },
  {
    id: '53',
    MA: '網走',
    areaCode: '152',
    bandStart: '01524',
    bandEnd: '01526',
    eliminateCode: '0',
  },
  {
    id: '54',
    MA: '斜里',
    areaCode: '152',
    bandStart: '01522',
    bandEnd: '01523',
    eliminateCode: '0',
  },
  {
    id: '55',
    MA: '美幌',
    areaCode: '152',
    bandStart: '01527',
    bandEnd: '01528',
    eliminateCode: '0',
  },
  {
    id: '56',
    MA: '厚岸',
    areaCode: '153',
    bandStart: '01531',
    bandEnd: '01531',
    eliminateCode: '0',
  },
  {
    id: '57',
    MA: '厚岸',
    areaCode: '153',
    bandStart: '01535',
    bandEnd: '01536',
    eliminateCode: '0',
  },
  {
    id: '58',
    MA: '根室',
    areaCode: '153',
    bandStart: '01532',
    bandEnd: '01533',
    eliminateCode: '0',
  },
  {
    id: '59',
    MA: '中標津',
    areaCode: '153',
    bandStart: '01534',
    bandEnd: '01534',
    eliminateCode: '0',
  },
  {
    id: '60',
    MA: '中標津',
    areaCode: '153',
    bandStart: '01537',
    bandEnd: '01537',
    eliminateCode: '0',
  },
  {
    id: '61',
    MA: '根室標津',
    areaCode: '153',
    bandStart: '01538',
    bandEnd: '01539',
    eliminateCode: '0',
  },
  {
    id: '62',
    MA: '釧路',
    areaCode: '154',
    bandStart: '015417',
    bandEnd: '015417',
    eliminateCode: '0',
  },
  {
    id: '63',
    MA: '釧路',
    areaCode: '154',
    bandStart: '01542',
    bandEnd: '01546',
    eliminateCode: '0',
  },
  {
    id: '64',
    MA: '釧路',
    areaCode: '154',
    bandStart: '01549',
    bandEnd: '01549',
    eliminateCode: '0',
  },
  {
    id: '65',
    MA: '白糠',
    areaCode: '1547',
    bandStart: '01547',
    bandEnd: '01547',
    eliminateCode: '0',
  },
  {
    id: '66',
    MA: '帯広',
    areaCode: '155',
    bandStart: '015517',
    bandEnd: '015517',
    eliminateCode: '0',
  },
  {
    id: '67',
    MA: '帯広',
    areaCode: '155',
    bandStart: '01552',
    bandEnd: '01556',
    eliminateCode: '0',
  },
  {
    id: '68',
    MA: '帯広',
    areaCode: '155',
    bandStart: '01559',
    bandEnd: '01559',
    eliminateCode: '0',
  },
  {
    id: '69',
    MA: '広尾',
    areaCode: '1558',
    bandStart: '01558',
    bandEnd: '01558',
    eliminateCode: '0',
  },
  {
    id: '70',
    MA: '本別',
    areaCode: '156',
    bandStart: '01562',
    bandEnd: '01563',
    eliminateCode: '0',
  },
  {
    id: '71',
    MA: '十勝清水',
    areaCode: '156',
    bandStart: '01561',
    bandEnd: '01561',
    eliminateCode: '0',
  },
  {
    id: '72',
    MA: '十勝清水',
    areaCode: '156',
    bandStart: '01566',
    bandEnd: '01567',
    eliminateCode: '0',
  },
  {
    id: '73',
    MA: '上士幌',
    areaCode: '1564',
    bandStart: '01564',
    bandEnd: '01564',
    eliminateCode: '0',
  },
  {
    id: '74',
    MA: '北見',
    areaCode: '157',
    bandStart: '0157',
    bandEnd: '0157',
    eliminateCode: '0',
  },
  {
    id: '75',
    MA: '紋別',
    areaCode: '158',
    bandStart: '01581',
    bandEnd: '01583',
    eliminateCode: '0',
  },
  {
    id: '76',
    MA: '遠軽',
    areaCode: '158',
    bandStart: '01584',
    bandEnd: '01585',
    eliminateCode: '0',
  },
  {
    id: '77',
    MA: '興部',
    areaCode: '158',
    bandStart: '01588',
    bandEnd: '01589',
    eliminateCode: '0',
  },
  {
    id: '78',
    MA: '中湧別',
    areaCode: '1586',
    bandStart: '01586',
    bandEnd: '01586',
    eliminateCode: '0',
  },
  {
    id: '79',
    MA: '中湧別',
    areaCode: '1587',
    bandStart: '01587',
    bandEnd: '01587',
    eliminateCode: '0',
  },
  {
    id: '80',
    MA: '稚内',
    areaCode: '162',
    bandStart: '0162',
    bandEnd: '0162',
    eliminateCode: '0',
  },
  {
    id: '81',
    MA: '北見枝幸',
    areaCode: '163',
    bandStart: '01631',
    bandEnd: '01631',
    eliminateCode: '0',
  },
  {
    id: '82',
    MA: '北見枝幸',
    areaCode: '163',
    bandStart: '01636',
    bandEnd: '01637',
    eliminateCode: '0',
  },
  {
    id: '83',
    MA: '利尻礼文',
    areaCode: '163',
    bandStart: '01638',
    bandEnd: '01639',
    eliminateCode: '0',
  },
  {
    id: '84',
    MA: '天塩',
    areaCode: '1632',
    bandStart: '01632',
    bandEnd: '01632',
    eliminateCode: '0',
  },
  {
    id: '85',
    MA: '浜頓別',
    areaCode: '1634',
    bandStart: '01634',
    bandEnd: '01634',
    eliminateCode: '0',
  },
  {
    id: '86',
    MA: '浜頓別',
    areaCode: '1635',
    bandStart: '01635',
    bandEnd: '01635',
    eliminateCode: '0',
  },
  {
    id: '87',
    MA: '石狩深川',
    areaCode: '164',
    bandStart: '01642',
    bandEnd: '01643',
    eliminateCode: '0',
  },
  {
    id: '88',
    MA: '留萌',
    areaCode: '164',
    bandStart: '01644',
    bandEnd: '01645',
    eliminateCode: '0',
  },
  {
    id: '89',
    MA: '羽幌',
    areaCode: '164',
    bandStart: '01646',
    bandEnd: '01647',
    eliminateCode: '0',
  },
  {
    id: '90',
    MA: '焼尻',
    areaCode: '1648',
    bandStart: '01648',
    bandEnd: '01648',
    eliminateCode: '0',
  },
  {
    id: '91',
    MA: '士別',
    areaCode: '165',
    bandStart: '01651',
    bandEnd: '01653',
    eliminateCode: '0',
  },
  {
    id: '92',
    MA: '名寄',
    areaCode: '1654',
    bandStart: '01654',
    bandEnd: '01654',
    eliminateCode: '0',
  },
  {
    id: '93',
    MA: '名寄',
    areaCode: '1655',
    bandStart: '01655',
    bandEnd: '01655',
    eliminateCode: '0',
  },
  {
    id: '94',
    MA: '美深',
    areaCode: '1656',
    bandStart: '01656',
    bandEnd: '01656',
    eliminateCode: '0',
  },
  {
    id: '95',
    MA: '上川',
    areaCode: '1658',
    bandStart: '01658',
    bandEnd: '01658',
    eliminateCode: '0',
  },
  {
    id: '96',
    MA: '旭川',
    areaCode: '166',
    bandStart: '0166',
    bandEnd: '0166',
    eliminateCode: '0',
  },
  {
    id: '97',
    MA: '富良野',
    areaCode: '167',
    bandStart: '0167',
    bandEnd: '0167',
    eliminateCode: '0',
  },
  {
    id: '98',
    MA: '青森',
    areaCode: '17',
    bandStart: '0171',
    bandEnd: '0171',
    eliminateCode: '0',
  },
  {
    id: '99',
    MA: '青森',
    areaCode: '17',
    bandStart: '0177',
    bandEnd: '0177',
    eliminateCode: '0',
  },
  {
    id: '100',
    MA: '弘前',
    areaCode: '172',
    bandStart: '0172',
    bandEnd: '0172',
    eliminateCode: '0',
  },
  {
    id: '101',
    MA: '鰺ケ沢',
    areaCode: '173',
    bandStart: '01737',
    bandEnd: '01738',
    eliminateCode: '0',
  },
  {
    id: '102',
    MA: '五所川原',
    areaCode: '173',
    bandStart: '01732',
    bandEnd: '01736',
    eliminateCode: '0',
  },
  {
    id: '103',
    MA: '蟹田',
    areaCode: '174',
    bandStart: '0174',
    bandEnd: '0174',
    eliminateCode: '0',
  },
  {
    id: '104',
    MA: '野辺地',
    areaCode: '175',
    bandStart: '01756',
    bandEnd: '01757',
    eliminateCode: '0',
  },
  {
    id: '105',
    MA: 'むつ',
    areaCode: '175',
    bandStart: '01752',
    bandEnd: '01754',
    eliminateCode: '0',
  },
  {
    id: '106',
    MA: '十和田',
    areaCode: '176',
    bandStart: '0176',
    bandEnd: '0176',
    eliminateCode: '0',
  },
  {
    id: '107',
    MA: '八戸',
    areaCode: '178',
    bandStart: '0178',
    bandEnd: '0178',
    eliminateCode: '0',
  },
  {
    id: '108',
    MA: '三戸',
    areaCode: '179',
    bandStart: '0179',
    bandEnd: '0179',
    eliminateCode: '0',
  },
  {
    id: '109',
    MA: '秋田',
    areaCode: '18',
    bandStart: '0181',
    bandEnd: '0181',
    eliminateCode: '0',
  },
  {
    id: '110',
    MA: '秋田',
    areaCode: '18',
    bandStart: '0188',
    bandEnd: '0188',
    eliminateCode: '0',
  },
  {
    id: '111',
    MA: '横手',
    areaCode: '182',
    bandStart: '0182',
    bandEnd: '0182',
    eliminateCode: '0',
  },
  {
    id: '112',
    MA: '湯沢',
    areaCode: '183',
    bandStart: '0183',
    bandEnd: '0183',
    eliminateCode: '0',
  },
  {
    id: '113',
    MA: '本荘',
    areaCode: '184',
    bandStart: '0184',
    bandEnd: '0184',
    eliminateCode: '0',
  },
  {
    id: '114',
    MA: '能代',
    areaCode: '185',
    bandStart: '01855',
    bandEnd: '01858',
    eliminateCode: '0',
  },
  {
    id: '115',
    MA: '男鹿',
    areaCode: '185',
    bandStart: '01852',
    bandEnd: '01854',
    eliminateCode: '0',
  },
  {
    id: '116',
    MA: '鹿角',
    areaCode: '186',
    bandStart: '01862',
    bandEnd: '01863',
    eliminateCode: '0',
  },
  {
    id: '117',
    MA: '大館',
    areaCode: '186',
    bandStart: '01864',
    bandEnd: '01865',
    eliminateCode: '0',
  },
  {
    id: '118',
    MA: '大館',
    areaCode: '186',
    bandStart: '01869',
    bandEnd: '01869',
    eliminateCode: '0',
  },
  {
    id: '119',
    MA: '鷹巣',
    areaCode: '186',
    bandStart: '01866',
    bandEnd: '01868',
    eliminateCode: '0',
  },
  {
    id: '120',
    MA: '角館',
    areaCode: '187',
    bandStart: '01873',
    bandEnd: '01875',
    eliminateCode: '0',
  },
  {
    id: '121',
    MA: '大曲',
    areaCode: '187',
    bandStart: '01876',
    bandEnd: '01878',
    eliminateCode: '0',
  },
  {
    id: '122',
    MA: '盛岡',
    areaCode: '19',
    bandStart: '0196',
    bandEnd: '0196',
    eliminateCode: '0',
  },
  {
    id: '123',
    MA: '盛岡',
    areaCode: '19',
    bandStart: '0199',
    bandEnd: '0199',
    eliminateCode: '0',
  },
  {
    id: '124',
    MA: '一関',
    areaCode: '191',
    bandStart: '0191',
    bandEnd: '0191',
    eliminateCode: '0',
  },
  {
    id: '125',
    MA: '大船渡',
    areaCode: '192',
    bandStart: '0192',
    bandEnd: '0192',
    eliminateCode: '0',
  },
  {
    id: '126',
    MA: '釜石',
    areaCode: '193',
    bandStart: '01932',
    bandEnd: '01935',
    eliminateCode: '0',
  },
  {
    id: '127',
    MA: '宮古',
    areaCode: '193',
    bandStart: '01931',
    bandEnd: '01931',
    eliminateCode: '0',
  },
  {
    id: '128',
    MA: '宮古',
    areaCode: '193',
    bandStart: '01936',
    bandEnd: '01939',
    eliminateCode: '0',
  },
  {
    id: '129',
    MA: '久慈',
    areaCode: '194',
    bandStart: '01945',
    bandEnd: '01947',
    eliminateCode: '0',
  },
  {
    id: '130',
    MA: '岩泉',
    areaCode: '194',
    bandStart: '01942',
    bandEnd: '01944',
    eliminateCode: '0',
  },
  {
    id: '131',
    MA: '二戸',
    areaCode: '195',
    bandStart: '01952',
    bandEnd: '01955',
    eliminateCode: '0',
  },
  {
    id: '132',
    MA: '岩手',
    areaCode: '195',
    bandStart: '01956',
    bandEnd: '01958',
    eliminateCode: '0',
  },
  {
    id: '133',
    MA: '水沢',
    areaCode: '197',
    bandStart: '01971',
    bandEnd: '01975',
    eliminateCode: '0',
  },
  {
    id: '134',
    MA: '北上',
    areaCode: '197',
    bandStart: '01976',
    bandEnd: '01978',
    eliminateCode: '0',
  },
  {
    id: '135',
    MA: '花巻',
    areaCode: '198',
    bandStart: '01981',
    bandEnd: '01984',
    eliminateCode: '0',
  },
  {
    id: '136',
    MA: '遠野',
    areaCode: '198',
    bandStart: '01986',
    bandEnd: '01987',
    eliminateCode: '0',
  },
  {
    id: '137',
    MA: '仙台',
    areaCode: '22',
    bandStart: '0221',
    bandEnd: '0222',
    eliminateCode: '0',
  },
  {
    id: '138',
    MA: '仙台',
    areaCode: '22',
    bandStart: '02230',
    bandEnd: '02230',
    eliminateCode: '0',
  },
  {
    id: '139',
    MA: '仙台',
    areaCode: '22',
    bandStart: '02234',
    bandEnd: '02239',
    eliminateCode: '0',
  },
  {
    id: '140',
    MA: '仙台',
    areaCode: '22',
    bandStart: '0227',
    bandEnd: '0227',
    eliminateCode: '0',
  },
  {
    id: '141',
    MA: '迫',
    areaCode: '220',
    bandStart: '0220',
    bandEnd: '0220',
    eliminateCode: '0',
  },
  {
    id: '142',
    MA: '岩沼',
    areaCode: '223',
    bandStart: '02231',
    bandEnd: '02233',
    eliminateCode: '0',
  },
  {
    id: '143',
    MA: '大河原',
    areaCode: '224',
    bandStart: '02245',
    bandEnd: '02248',
    eliminateCode: '0',
  },
  {
    id: '144',
    MA: '白石',
    areaCode: '224',
    bandStart: '02242',
    bandEnd: '02244',
    eliminateCode: '0',
  },
  {
    id: '145',
    MA: '石巻',
    areaCode: '225',
    bandStart: '0225',
    bandEnd: '0225',
    eliminateCode: '0',
  },
  {
    id: '146',
    MA: '気仙沼',
    areaCode: '226',
    bandStart: '0226',
    bandEnd: '0226',
    eliminateCode: '0',
  },
  {
    id: '147',
    MA: '築館',
    areaCode: '228',
    bandStart: '0228',
    bandEnd: '0228',
    eliminateCode: '0',
  },
  {
    id: '148',
    MA: '古川',
    areaCode: '229',
    bandStart: '0229',
    bandEnd: '0229',
    eliminateCode: '0',
  },
  {
    id: '149',
    MA: '山形',
    areaCode: '23',
    bandStart: '0231',
    bandEnd: '0231',
    eliminateCode: '0',
  },
  {
    id: '150',
    MA: '山形',
    areaCode: '23',
    bandStart: '0236',
    bandEnd: '0236',
    eliminateCode: '0',
  },
  {
    id: '151',
    MA: '新庄',
    areaCode: '233',
    bandStart: '0233',
    bandEnd: '0233',
    eliminateCode: '0',
  },
  {
    id: '152',
    MA: '酒田',
    areaCode: '234',
    bandStart: '0234',
    bandEnd: '0234',
    eliminateCode: '0',
  },
  {
    id: '153',
    MA: '鶴岡',
    areaCode: '235',
    bandStart: '0235',
    bandEnd: '0235',
    eliminateCode: '0',
  },
  {
    id: '154',
    MA: '村山',
    areaCode: '237',
    bandStart: '02371',
    bandEnd: '02375',
    eliminateCode: '0',
  },
  {
    id: '155',
    MA: '寒河江',
    areaCode: '237',
    bandStart: '02376',
    bandEnd: '02378',
    eliminateCode: '0',
  },
  {
    id: '156',
    MA: '長井',
    areaCode: '238',
    bandStart: '02386',
    bandEnd: '02388',
    eliminateCode: '0',
  },
  {
    id: '157',
    MA: '米沢',
    areaCode: '238',
    bandStart: '02381',
    bandEnd: '02385',
    eliminateCode: '0',
  },
  {
    id: '158',
    MA: '米沢',
    areaCode: '238',
    bandStart: '02389',
    bandEnd: '02389',
    eliminateCode: '0',
  },
  {
    id: '159',
    MA: '郡山',
    areaCode: '24',
    bandStart: '0249',
    bandEnd: '0249',
    eliminateCode: '0',
  },
  {
    id: '160',
    MA: '福島',
    areaCode: '24',
    bandStart: '0245',
    bandEnd: '0245',
    eliminateCode: '0',
  },
  {
    id: '161',
    MA: '福島',
    areaCode: '24',
    bandStart: '024177',
    bandEnd: '024177',
    eliminateCode: '0',
  },
  {
    id: '162',
    MA: '磐城富岡',
    areaCode: '240',
    bandStart: '02401',
    bandEnd: '02404',
    eliminateCode: '0',
  },
  {
    id: '163',
    MA: '喜多方',
    areaCode: '241',
    bandStart: '02412',
    bandEnd: '02413',
    eliminateCode: '0',
  },
  {
    id: '164',
    MA: '田島',
    areaCode: '241',
    bandStart: '02416',
    bandEnd: '02416',
    eliminateCode: '0',
  },
  {
    id: '165',
    MA: '田島',
    areaCode: '241',
    bandStart: '024190',
    bandEnd: '024195',
    eliminateCode: '0',
  },
  {
    id: '166',
    MA: '田島',
    areaCode: '241',
    bandStart: '024198',
    bandEnd: '024199',
    eliminateCode: '0',
  },
  {
    id: '167',
    MA: '柳津',
    areaCode: '241',
    bandStart: '02414',
    bandEnd: '02415',
    eliminateCode: '0',
  },
  {
    id: '168',
    MA: '柳津',
    areaCode: '241',
    bandStart: '024196',
    bandEnd: '024197',
    eliminateCode: '0',
  },
  {
    id: '169',
    MA: '会津山口',
    areaCode: '241',
    bandStart: '024170',
    bandEnd: '024176',
    eliminateCode: '0',
  },
  {
    id: '170',
    MA: '会津山口',
    areaCode: '241',
    bandStart: '024178',
    bandEnd: '024179',
    eliminateCode: '0',
  },
  {
    id: '171',
    MA: '会津山口',
    areaCode: '241',
    bandStart: '02418',
    bandEnd: '02418',
    eliminateCode: '0',
  },
  {
    id: '172',
    MA: '会津若松',
    areaCode: '242',
    bandStart: '0242',
    bandEnd: '0242',
    eliminateCode: '0',
  },
  {
    id: '173',
    MA: '二本松',
    areaCode: '243',
    bandStart: '0243',
    bandEnd: '0243',
    eliminateCode: '0',
  },
  {
    id: '174',
    MA: '原町',
    areaCode: '244',
    bandStart: '0244',
    bandEnd: '0244',
    eliminateCode: '0',
  },
  {
    id: '175',
    MA: 'いわき',
    areaCode: '246',
    bandStart: '0246',
    bandEnd: '0246',
    eliminateCode: '0',
  },
  {
    id: '176',
    MA: '三春',
    areaCode: '247',
    bandStart: '02476',
    bandEnd: '02478',
    eliminateCode: '0',
  },
  {
    id: '177',
    MA: '石川',
    areaCode: '247',
    bandStart: '02472',
    bandEnd: '02475',
    eliminateCode: '0',
  },
  {
    id: '178',
    MA: '白河',
    areaCode: '248',
    bandStart: '02482',
    bandEnd: '02485',
    eliminateCode: '0',
  },
  {
    id: '179',
    MA: '須賀川',
    areaCode: '248',
    bandStart: '02486',
    bandEnd: '02489',
    eliminateCode: '0',
  },
  {
    id: '180',
    MA: '新潟',
    areaCode: '25',
    bandStart: '0251',
    bandEnd: '0253',
    eliminateCode: '0',
  },
  {
    id: '181',
    MA: '糸魚川',
    areaCode: '25',
    bandStart: '02555',
    bandEnd: '02556',
    eliminateCode: '0',
  },
  {
    id: '182',
    MA: '糸魚川',
    areaCode: '25',
    bandStart: '02560',
    bandEnd: '02560',
    eliminateCode: '0',
  },
  {
    id: '183',
    MA: '上越',
    areaCode: '25',
    bandStart: '02551',
    bandEnd: '02554',
    eliminateCode: '0',
  },
  {
    id: '184',
    MA: '安塚',
    areaCode: '25',
    bandStart: '02550',
    bandEnd: '02550',
    eliminateCode: '0',
  },
  {
    id: '185',
    MA: '安塚',
    areaCode: '25',
    bandStart: '02559',
    bandEnd: '02559',
    eliminateCode: '0',
  },
  {
    id: '186',
    MA: '十日町',
    areaCode: '25',
    bandStart: '02575',
    bandEnd: '02576',
    eliminateCode: '0',
  },
  {
    id: '187',
    MA: '十日町',
    areaCode: '25',
    bandStart: '02580',
    bandEnd: '02580',
    eliminateCode: '0',
  },
  {
    id: '188',
    MA: '六日町',
    areaCode: '25',
    bandStart: '025710',
    bandEnd: '025716',
    eliminateCode: '0',
  },
  {
    id: '189',
    MA: '六日町',
    areaCode: '25',
    bandStart: '025718',
    bandEnd: '025719',
    eliminateCode: '0',
  },
  {
    id: '190',
    MA: '六日町',
    areaCode: '25',
    bandStart: '02577',
    bandEnd: '02578',
    eliminateCode: '0',
  },
  {
    id: '191',
    MA: '小出',
    areaCode: '25',
    bandStart: '02570',
    bandEnd: '02570',
    eliminateCode: '0',
  },
  {
    id: '192',
    MA: '小出',
    areaCode: '25',
    bandStart: '02579',
    bandEnd: '02579',
    eliminateCode: '0',
  },
  {
    id: '193',
    MA: '新津',
    areaCode: '250',
    bandStart: '0250',
    bandEnd: '0250',
    eliminateCode: '0',
  },
  {
    id: '194',
    MA: '新発田',
    areaCode: '254',
    bandStart: '02541',
    bandEnd: '02544',
    eliminateCode: '0',
  },
  {
    id: '195',
    MA: '村上',
    areaCode: '254',
    bandStart: '02545',
    bandEnd: '02547',
    eliminateCode: '0',
  },
  {
    id: '196',
    MA: '村上',
    areaCode: '254',
    bandStart: '025480',
    bandEnd: '025484',
    eliminateCode: '0',
  },
  {
    id: '197',
    MA: '津川',
    areaCode: '254',
    bandStart: '025485',
    bandEnd: '025489',
    eliminateCode: '0',
  },
  {
    id: '198',
    MA: '津川',
    areaCode: '254',
    bandStart: '02549',
    bandEnd: '02549',
    eliminateCode: '0',
  },
  {
    id: '199',
    MA: '新井',
    areaCode: '255',
    bandStart: '02557',
    bandEnd: '02558',
    eliminateCode: '0',
  },
  {
    id: '200',
    MA: '三条',
    areaCode: '256',
    bandStart: '02562',
    bandEnd: '02566',
    eliminateCode: '0',
  },
  {
    id: '201',
    MA: '巻',
    areaCode: '256',
    bandStart: '02567',
    bandEnd: '02569',
    eliminateCode: '0',
  },
  {
    id: '202',
    MA: '柏崎',
    areaCode: '257',
    bandStart: '025717',
    bandEnd: '025717',
    eliminateCode: '0',
  },
  {
    id: '203',
    MA: '柏崎',
    areaCode: '257',
    bandStart: '02572',
    bandEnd: '02574',
    eliminateCode: '0',
  },
  {
    id: '204',
    MA: '長岡',
    areaCode: '258',
    bandStart: '02581',
    bandEnd: '02589',
    eliminateCode: '0',
  },
  {
    id: '205',
    MA: '佐渡',
    areaCode: '259',
    bandStart: '02591',
    bandEnd: '02598',
    eliminateCode: '0',
  },
  {
    id: '206',
    MA: '長野',
    areaCode: '26',
    bandStart: '0262',
    bandEnd: '0262',
    eliminateCode: '0',
  },
  {
    id: '207',
    MA: '長野',
    areaCode: '26',
    bandStart: '026177',
    bandEnd: '026177',
    eliminateCode: '0',
  },
  {
    id: '208',
    MA: '長野',
    areaCode: '26',
    bandStart: '02640',
    bandEnd: '02640',
    eliminateCode: '0',
  },
  {
    id: '209',
    MA: '長野',
    areaCode: '26',
    bandStart: '02646',
    bandEnd: '02649',
    eliminateCode: '0',
  },
  {
    id: '210',
    MA: '阿南町',
    areaCode: '260',
    bandStart: '02601',
    bandEnd: '02603',
    eliminateCode: '0',
  },
  {
    id: '211',
    MA: '大町',
    areaCode: '261',
    bandStart: '02611',
    bandEnd: '02619',
    eliminateCode: '26177',
  },
  {
    id: '212',
    MA: '松本',
    areaCode: '263',
    bandStart: '0263',
    bandEnd: '0263',
    eliminateCode: '0',
  },
  {
    id: '213',
    MA: '木曾福島',
    areaCode: '264',
    bandStart: '02641',
    bandEnd: '02645',
    eliminateCode: '0',
  },
  {
    id: '214',
    MA: '伊那',
    areaCode: '265',
    bandStart: '02656',
    bandEnd: '02659',
    eliminateCode: '0',
  },
  {
    id: '215',
    MA: '飯田',
    areaCode: '265',
    bandStart: '02651',
    bandEnd: '02655',
    eliminateCode: '0',
  },
  {
    id: '216',
    MA: '諏訪',
    areaCode: '266',
    bandStart: '0266',
    bandEnd: '0266',
    eliminateCode: '0',
  },
  {
    id: '217',
    MA: '佐久',
    areaCode: '267',
    bandStart: '02675',
    bandEnd: '02679',
    eliminateCode: '0',
  },
  {
    id: '218',
    MA: '小諸',
    areaCode: '267',
    bandStart: '02671',
    bandEnd: '02674',
    eliminateCode: '0',
  },
  {
    id: '219',
    MA: '上田',
    areaCode: '268',
    bandStart: '0268',
    bandEnd: '0268',
    eliminateCode: '0',
  },
  {
    id: '220',
    MA: '中野',
    areaCode: '269',
    bandStart: '02692',
    bandEnd: '02695',
    eliminateCode: '0',
  },
  {
    id: '221',
    MA: '飯山',
    areaCode: '269',
    bandStart: '02691',
    bandEnd: '02691',
    eliminateCode: '0',
  },
  {
    id: '222',
    MA: '飯山',
    areaCode: '269',
    bandStart: '02696',
    bandEnd: '02698',
    eliminateCode: '0',
  },
  {
    id: '223',
    MA: '前橋',
    areaCode: '27',
    bandStart: '0271',
    bandEnd: '0272',
    eliminateCode: '0',
  },
  {
    id: '224',
    MA: '前橋',
    areaCode: '27',
    bandStart: '02780',
    bandEnd: '02780',
    eliminateCode: '0',
  },
  {
    id: '225',
    MA: '前橋',
    areaCode: '27',
    bandStart: '02788',
    bandEnd: '02789',
    eliminateCode: '0',
  },
  {
    id: '226',
    MA: '高崎',
    areaCode: '27',
    bandStart: '0273',
    bandEnd: '0273',
    eliminateCode: '0',
  },
  {
    id: '227',
    MA: '伊勢崎',
    areaCode: '270',
    bandStart: '0270',
    bandEnd: '0270',
    eliminateCode: '0',
  },
  {
    id: '228',
    MA: '藤岡',
    areaCode: '274',
    bandStart: '02741',
    bandEnd: '02745',
    eliminateCode: '0',
  },
  {
    id: '229',
    MA: '富岡',
    areaCode: '274',
    bandStart: '02746',
    bandEnd: '02748',
    eliminateCode: '0',
  },
  {
    id: '230',
    MA: '太田',
    areaCode: '276',
    bandStart: '0276',
    bandEnd: '0276',
    eliminateCode: '0',
  },
  {
    id: '231',
    MA: '桐生',
    areaCode: '277',
    bandStart: '0277',
    bandEnd: '0277',
    eliminateCode: '0',
  },
  {
    id: '232',
    MA: '沼田',
    areaCode: '278',
    bandStart: '02781',
    bandEnd: '02787',
    eliminateCode: '0',
  },
  {
    id: '233',
    MA: '長野原',
    areaCode: '279',
    bandStart: '02798',
    bandEnd: '02799',
    eliminateCode: '0',
  },
  {
    id: '234',
    MA: '渋川',
    areaCode: '279',
    bandStart: '02791',
    bandEnd: '02797',
    eliminateCode: '0',
  },
  {
    id: '235',
    MA: '宇都宮',
    areaCode: '28',
    bandStart: '0281',
    bandEnd: '0281',
    eliminateCode: '0',
  },
  {
    id: '236',
    MA: '宇都宮',
    areaCode: '28',
    bandStart: '0286',
    bandEnd: '0286',
    eliminateCode: '0',
  },
  {
    id: '237',
    MA: '宇都宮',
    areaCode: '28',
    bandStart: '02830',
    bandEnd: '02830',
    eliminateCode: '0',
  },
  {
    id: '238',
    MA: '宇都宮',
    areaCode: '28',
    bandStart: '02833',
    bandEnd: '02834',
    eliminateCode: '0',
  },
  {
    id: '239',
    MA: '宇都宮',
    areaCode: '28',
    bandStart: '02890',
    bandEnd: '02890',
    eliminateCode: '0',
  },
  {
    id: '240',
    MA: '宇都宮',
    areaCode: '28',
    bandStart: '02892',
    bandEnd: '02895',
    eliminateCode: '0',
  },
  {
    id: '241',
    MA: '古河',
    areaCode: '280',
    bandStart: '0280',
    bandEnd: '0280',
    eliminateCode: '0',
  },
  {
    id: '242',
    MA: '栃木',
    areaCode: '282',
    bandStart: '0282',
    bandEnd: '0282',
    eliminateCode: '0',
  },
  {
    id: '243',
    MA: '佐野',
    areaCode: '283',
    bandStart: '02831',
    bandEnd: '02832',
    eliminateCode: '0',
  },
  {
    id: '244',
    MA: '佐野',
    areaCode: '283',
    bandStart: '02835',
    bandEnd: '02839',
    eliminateCode: '0',
  },
  {
    id: '245',
    MA: '足利',
    areaCode: '284',
    bandStart: '0284',
    bandEnd: '0284',
    eliminateCode: '0',
  },
  {
    id: '246',
    MA: '真岡',
    areaCode: '285',
    bandStart: '02856',
    bandEnd: '02858',
    eliminateCode: '0',
  },
  {
    id: '247',
    MA: '小山',
    areaCode: '285',
    bandStart: '02851',
    bandEnd: '02855',
    eliminateCode: '0',
  },
  {
    id: '248',
    MA: '小山',
    areaCode: '285',
    bandStart: '02859',
    bandEnd: '02859',
    eliminateCode: '0',
  },
  {
    id: '249',
    MA: '黒磯',
    areaCode: '287',
    bandStart: '02876',
    bandEnd: '02877',
    eliminateCode: '0',
  },
  {
    id: '250',
    MA: '大田原',
    areaCode: '287',
    bandStart: '02871',
    bandEnd: '02875',
    eliminateCode: '0',
  },
  {
    id: '251',
    MA: '大田原',
    areaCode: '287',
    bandStart: '028798',
    bandEnd: '028798',
    eliminateCode: '0',
  },
  {
    id: '252',
    MA: '烏山',
    areaCode: '287',
    bandStart: '02878',
    bandEnd: '02878',
    eliminateCode: '0',
  },
  {
    id: '253',
    MA: '烏山',
    areaCode: '287',
    bandStart: '028790',
    bandEnd: '028797',
    eliminateCode: '0',
  },
  {
    id: '254',
    MA: '烏山',
    areaCode: '287',
    bandStart: '028799',
    bandEnd: '028799',
    eliminateCode: '0',
  },
  {
    id: '255',
    MA: '今市',
    areaCode: '288',
    bandStart: '0288',
    bandEnd: '0288',
    eliminateCode: '0',
  },
  {
    id: '256',
    MA: '鹿沼',
    areaCode: '289',
    bandStart: '02891',
    bandEnd: '02891',
    eliminateCode: '0',
  },
  {
    id: '257',
    MA: '鹿沼',
    areaCode: '289',
    bandStart: '02896',
    bandEnd: '02899',
    eliminateCode: '0',
  },
  {
    id: '258',
    MA: '水戸',
    areaCode: '29',
    bandStart: '029177',
    bandEnd: '029177',
    eliminateCode: '0',
  },
  {
    id: '259',
    MA: '水戸',
    areaCode: '29',
    bandStart: '0292',
    bandEnd: '0292',
    eliminateCode: '0',
  },
  {
    id: '260',
    MA: '水戸',
    areaCode: '29',
    bandStart: '02930',
    bandEnd: '02930',
    eliminateCode: '0',
  },
  {
    id: '261',
    MA: '水戸',
    areaCode: '29',
    bandStart: '02935',
    bandEnd: '02939',
    eliminateCode: '0',
  },
  {
    id: '262',
    MA: '土浦',
    areaCode: '29',
    bandStart: '0298',
    bandEnd: '0298',
    eliminateCode: '0',
  },
  {
    id: '263',
    MA: '鉾田',
    areaCode: '291',
    bandStart: '02911',
    bandEnd: '02919',
    eliminateCode: '29177',
  },
  {
    id: '264',
    MA: '高萩',
    areaCode: '293',
    bandStart: '02931',
    bandEnd: '02934',
    eliminateCode: '0',
  },
  {
    id: '265',
    MA: '常陸太田',
    areaCode: '294',
    bandStart: '0294',
    bandEnd: '0294',
    eliminateCode: '0',
  },
  {
    id: '266',
    MA: '常陸大宮',
    areaCode: '295',
    bandStart: '02951',
    bandEnd: '02951',
    eliminateCode: '0',
  },
  {
    id: '267',
    MA: '常陸大宮',
    areaCode: '295',
    bandStart: '02955',
    bandEnd: '02955',
    eliminateCode: '0',
  },
  {
    id: '268',
    MA: '大子',
    areaCode: '295',
    bandStart: '02957',
    bandEnd: '02957',
    eliminateCode: '0',
  },
  {
    id: '269',
    MA: '笠間',
    areaCode: '296',
    bandStart: '02967',
    bandEnd: '02968',
    eliminateCode: '0',
  },
  {
    id: '270',
    MA: '下館',
    areaCode: '296',
    bandStart: '02961',
    bandEnd: '02965',
    eliminateCode: '0',
  },
  {
    id: '271',
    MA: '水海道',
    areaCode: '297',
    bandStart: '02972',
    bandEnd: '02975',
    eliminateCode: '0',
  },
  {
    id: '272',
    MA: '竜ケ崎',
    areaCode: '297',
    bandStart: '02971',
    bandEnd: '02971',
    eliminateCode: '0',
  },
  {
    id: '273',
    MA: '竜ケ崎',
    areaCode: '297',
    bandStart: '02976',
    bandEnd: '02979',
    eliminateCode: '0',
  },
  {
    id: '274',
    MA: '石岡',
    areaCode: '299',
    bandStart: '02992',
    bandEnd: '02995',
    eliminateCode: '0',
  },
  {
    id: '275',
    MA: '潮来',
    areaCode: '299',
    bandStart: '02991',
    bandEnd: '02991',
    eliminateCode: '0',
  },
  {
    id: '276',
    MA: '潮来',
    areaCode: '299',
    bandStart: '02996',
    bandEnd: '02999',
    eliminateCode: '0',
  },
  {
    id: '277',
    MA: '東京',
    areaCode: '3',
    bandStart: '031',
    bandEnd: '039',
    eliminateCode: '0',
  },
  {
    id: '278',
    MA: '所沢',
    areaCode: '4',
    bandStart: '04200',
    bandEnd: '04200',
    eliminateCode: '0',
  },
  {
    id: '279',
    MA: '所沢',
    areaCode: '4',
    bandStart: '042917',
    bandEnd: '042917',
    eliminateCode: '0',
  },
  {
    id: '280',
    MA: '所沢',
    areaCode: '4',
    bandStart: '04290',
    bandEnd: '04290',
    eliminateCode: '0',
  },
  {
    id: '281',
    MA: '所沢',
    areaCode: '4',
    bandStart: '04292',
    bandEnd: '04296',
    eliminateCode: '0',
  },
  {
    id: '282',
    MA: '所沢',
    areaCode: '4',
    bandStart: '04299',
    bandEnd: '04299',
    eliminateCode: '0',
  },
  {
    id: '283',
    MA: '鴨川',
    areaCode: '4',
    bandStart: '04700',
    bandEnd: '04700',
    eliminateCode: '0',
  },
  {
    id: '284',
    MA: '鴨川',
    areaCode: '4',
    bandStart: '04709',
    bandEnd: '04709',
    eliminateCode: '0',
  },
  {
    id: '285',
    MA: '柏',
    areaCode: '4',
    bandStart: '04710',
    bandEnd: '04719',
    eliminateCode: '47177',
  },
  {
    id: '286',
    MA: '国分寺',
    areaCode: '42',
    bandStart: '04220',
    bandEnd: '04220',
    eliminateCode: '0',
  },
  {
    id: '287',
    MA: '国分寺',
    areaCode: '42',
    bandStart: '0423',
    bandEnd: '0423',
    eliminateCode: '0',
  },
  {
    id: '288',
    MA: '国分寺',
    areaCode: '42',
    bandStart: '04240',
    bandEnd: '04240',
    eliminateCode: '0',
  },
  {
    id: '289',
    MA: '立川',
    areaCode: '42',
    bandStart: '0425',
    bandEnd: '0425',
    eliminateCode: '0',
  },
  {
    id: '290',
    MA: '立川',
    areaCode: '42',
    bandStart: '04280',
    bandEnd: '04280',
    eliminateCode: '0',
  },
  {
    id: '291',
    MA: '立川',
    areaCode: '42',
    bandStart: '04284',
    bandEnd: '04284',
    eliminateCode: '0',
  },
  {
    id: '292',
    MA: '八王子',
    areaCode: '42',
    bandStart: '04261',
    bandEnd: '04269',
    eliminateCode: '0',
  },
  {
    id: '293',
    MA: '相模原',
    areaCode: '42',
    bandStart: '0427',
    bandEnd: '0427',
    eliminateCode: '0',
  },
  {
    id: '294',
    MA: '相模原',
    areaCode: '42',
    bandStart: '04285',
    bandEnd: '04286',
    eliminateCode: '0',
  },
  {
    id: '295',
    MA: '相模原',
    areaCode: '42',
    bandStart: '042810',
    bandEnd: '042816',
    eliminateCode: '0',
  },
  {
    id: '296',
    MA: '相模原',
    areaCode: '42',
    bandStart: '042818',
    bandEnd: '042819',
    eliminateCode: '0',
  },
  {
    id: '297',
    MA: '飯能',
    areaCode: '42',
    bandStart: '042910',
    bandEnd: '042916',
    eliminateCode: '0',
  },
  {
    id: '298',
    MA: '飯能',
    areaCode: '42',
    bandStart: '042918',
    bandEnd: '042919',
    eliminateCode: '0',
  },
  {
    id: '299',
    MA: '飯能',
    areaCode: '42',
    bandStart: '04297',
    bandEnd: '04298',
    eliminateCode: '0',
  },
  {
    id: '300',
    MA: '武蔵野三鷹',
    areaCode: '42',
    bandStart: '04241',
    bandEnd: '04249',
    eliminateCode: '0',
  },
  {
    id: '301',
    MA: '武蔵野三鷹',
    areaCode: '422',
    bandStart: '04221',
    bandEnd: '04229',
    eliminateCode: '0',
  },
  {
    id: '302',
    MA: '青梅',
    areaCode: '428',
    bandStart: '042817',
    bandEnd: '042817',
    eliminateCode: '0',
  },
  {
    id: '303',
    MA: '青梅',
    areaCode: '428',
    bandStart: '04282',
    bandEnd: '04283',
    eliminateCode: '0',
  },
  {
    id: '304',
    MA: '青梅',
    areaCode: '428',
    bandStart: '04287',
    bandEnd: '04289',
    eliminateCode: '0',
  },
  {
    id: '305',
    MA: '千葉',
    areaCode: '43',
    bandStart: '0431',
    bandEnd: '0434',
    eliminateCode: '0',
  },
  {
    id: '306',
    MA: '市原',
    areaCode: '436',
    bandStart: '0436',
    bandEnd: '0436',
    eliminateCode: '0',
  },
  {
    id: '307',
    MA: '木更津',
    areaCode: '438',
    bandStart: '0438',
    bandEnd: '0438',
    eliminateCode: '0',
  },
  {
    id: '308',
    MA: '木更津',
    areaCode: '439',
    bandStart: '0439',
    bandEnd: '0439',
    eliminateCode: '0',
  },
  {
    id: '309',
    MA: '川崎',
    areaCode: '44',
    bandStart: '044',
    bandEnd: '044',
    eliminateCode: '0',
  },
  {
    id: '310',
    MA: '横浜',
    areaCode: '45',
    bandStart: '0451',
    bandEnd: '0459',
    eliminateCode: '0',
  },
  {
    id: '311',
    MA: '厚木',
    areaCode: '46',
    bandStart: '0462',
    bandEnd: '0462',
    eliminateCode: '0',
  },
  {
    id: '312',
    MA: '厚木',
    areaCode: '46',
    bandStart: '04640',
    bandEnd: '04640',
    eliminateCode: '0',
  },
  {
    id: '313',
    MA: '横須賀',
    areaCode: '46',
    bandStart: '0468',
    bandEnd: '0468',
    eliminateCode: '0',
  },
  {
    id: '314',
    MA: '小田原',
    areaCode: '460',
    bandStart: '04601',
    bandEnd: '04601',
    eliminateCode: '0',
  },
  {
    id: '315',
    MA: '小田原',
    areaCode: '460',
    bandStart: '04608',
    bandEnd: '04608',
    eliminateCode: '0',
  },
  {
    id: '316',
    MA: '小田原',
    areaCode: '465',
    bandStart: '0465',
    bandEnd: '0465',
    eliminateCode: '0',
  },
  {
    id: '317',
    MA: '平塚',
    areaCode: '463',
    bandStart: '0463',
    bandEnd: '0463',
    eliminateCode: '0',
  },
  {
    id: '318',
    MA: '藤沢',
    areaCode: '466',
    bandStart: '0466',
    bandEnd: '0466',
    eliminateCode: '0',
  },
  {
    id: '319',
    MA: '藤沢',
    areaCode: '467',
    bandStart: '0467',
    bandEnd: '0467',
    eliminateCode: '0',
  },
  {
    id: '320',
    MA: '市川',
    areaCode: '47',
    bandStart: '047177',
    bandEnd: '047177',
    eliminateCode: '0',
  },
  {
    id: '321',
    MA: '市川',
    areaCode: '47',
    bandStart: '0473',
    bandEnd: '0473',
    eliminateCode: '0',
  },
  {
    id: '322',
    MA: '市川',
    areaCode: '47',
    bandStart: '04770',
    bandEnd: '04772',
    eliminateCode: '0',
  },
  {
    id: '323',
    MA: '船橋',
    areaCode: '47',
    bandStart: '0474',
    bandEnd: '0474',
    eliminateCode: '0',
  },
  {
    id: '324',
    MA: '船橋',
    areaCode: '47',
    bandStart: '04775',
    bandEnd: '04777',
    eliminateCode: '0',
  },
  {
    id: '325',
    MA: '館山',
    areaCode: '470',
    bandStart: '04701',
    bandEnd: '04705',
    eliminateCode: '0',
  },
  {
    id: '326',
    MA: '大原',
    areaCode: '470',
    bandStart: '04706',
    bandEnd: '04708',
    eliminateCode: '0',
  },
  {
    id: '327',
    MA: '茂原',
    areaCode: '475',
    bandStart: '04751',
    bandEnd: '04754',
    eliminateCode: '0',
  },
  {
    id: '328',
    MA: '東金',
    areaCode: '475',
    bandStart: '04755',
    bandEnd: '04758',
    eliminateCode: '0',
  },
  {
    id: '329',
    MA: '成田',
    areaCode: '476',
    bandStart: '0476',
    bandEnd: '0476',
    eliminateCode: '0',
  },
  {
    id: '330',
    MA: '佐原',
    areaCode: '478',
    bandStart: '04781',
    bandEnd: '04781',
    eliminateCode: '0',
  },
  {
    id: '331',
    MA: '佐原',
    areaCode: '478',
    bandStart: '04785',
    bandEnd: '04788',
    eliminateCode: '0',
  },
  {
    id: '332',
    MA: '銚子',
    areaCode: '479',
    bandStart: '04791',
    bandEnd: '04794',
    eliminateCode: '0',
  },
  {
    id: '333',
    MA: '銚子',
    areaCode: '479',
    bandStart: '047951',
    bandEnd: '047954',
    eliminateCode: '0',
  },
  {
    id: '334',
    MA: '銚子',
    areaCode: '479',
    bandStart: '047956',
    bandEnd: '047956',
    eliminateCode: '0',
  },
  {
    id: '335',
    MA: '銚子',
    areaCode: '479',
    bandStart: '047958',
    bandEnd: '047959',
    eliminateCode: '0',
  },
  {
    id: '336',
    MA: '八日市場',
    areaCode: '479',
    bandStart: '047950',
    bandEnd: '047950',
    eliminateCode: '0',
  },
  {
    id: '337',
    MA: '八日市場',
    areaCode: '479',
    bandStart: '047955',
    bandEnd: '047955',
    eliminateCode: '0',
  },
  {
    id: '338',
    MA: '八日市場',
    areaCode: '479',
    bandStart: '047957',
    bandEnd: '047957',
    eliminateCode: '0',
  },
  {
    id: '339',
    MA: '八日市場',
    areaCode: '479',
    bandStart: '04796',
    bandEnd: '04798',
    eliminateCode: '0',
  },
  {
    id: '340',
    MA: '川口',
    areaCode: '48',
    bandStart: '0482',
    bandEnd: '0482',
    eliminateCode: '0',
  },
  {
    id: '341',
    MA: '川口',
    areaCode: '48',
    bandStart: '0484',
    bandEnd: '0484',
    eliminateCode: '0',
  },
  {
    id: '342',
    MA: '浦和',
    areaCode: '48',
    bandStart: '0481',
    bandEnd: '0481',
    eliminateCode: '0',
  },
  {
    id: '343',
    MA: '浦和',
    areaCode: '48',
    bandStart: '0486',
    bandEnd: '0488',
    eliminateCode: '0',
  },
  {
    id: '344',
    MA: '熊谷',
    areaCode: '48',
    bandStart: '0485',
    bandEnd: '0485',
    eliminateCode: '0',
  },
  {
    id: '345',
    MA: '草加',
    areaCode: '48',
    bandStart: '0489',
    bandEnd: '0489',
    eliminateCode: '0',
  },
  {
    id: '346',
    MA: '久喜',
    areaCode: '480',
    bandStart: '0480',
    bandEnd: '0480',
    eliminateCode: '0',
  },
  {
    id: '347',
    MA: '川越',
    areaCode: '49',
    bandStart: '0491',
    bandEnd: '0492',
    eliminateCode: '0',
  },
  {
    id: '348',
    MA: '東松山',
    areaCode: '493',
    bandStart: '0493',
    bandEnd: '0493',
    eliminateCode: '0',
  },
  {
    id: '349',
    MA: '秩父',
    areaCode: '494',
    bandStart: '0494',
    bandEnd: '0494',
    eliminateCode: '0',
  },
  {
    id: '350',
    MA: '本庄',
    areaCode: '495',
    bandStart: '0495',
    bandEnd: '0495',
    eliminateCode: '0',
  },
  {
    id: '351',
    MA: '伊豆大島',
    areaCode: '4992',
    bandStart: '04992',
    bandEnd: '04992',
    eliminateCode: '0',
  },
  {
    id: '352',
    MA: '三宅',
    areaCode: '4994',
    bandStart: '04994',
    bandEnd: '04994',
    eliminateCode: '0',
  },
  {
    id: '353',
    MA: '八丈島',
    areaCode: '4996',
    bandStart: '04996',
    bandEnd: '04996',
    eliminateCode: '0',
  },
  {
    id: '354',
    MA: '小笠原',
    areaCode: '4998',
    bandStart: '04998',
    bandEnd: '04998',
    eliminateCode: '0',
  },
  {
    id: '355',
    MA: '甲府',
    areaCode: '55',
    bandStart: '055177',
    bandEnd: '055177',
    eliminateCode: '0',
  },
  {
    id: '356',
    MA: '甲府',
    areaCode: '55',
    bandStart: '0552',
    bandEnd: '0552',
    eliminateCode: '0',
  },
  {
    id: '357',
    MA: '韮崎',
    areaCode: '551',
    bandStart: '05510',
    bandEnd: '05519',
    eliminateCode: '55177',
  },
  {
    id: '358',
    MA: '山梨',
    areaCode: '553',
    bandStart: '0553',
    bandEnd: '0553',
    eliminateCode: '0',
  },
  {
    id: '359',
    MA: '大月',
    areaCode: '554',
    bandStart: '0554',
    bandEnd: '0554',
    eliminateCode: '0',
  },
  {
    id: '360',
    MA: '吉田',
    areaCode: '555',
    bandStart: '0555',
    bandEnd: '0555',
    eliminateCode: '0',
  },
  {
    id: '361',
    MA: '鰍沢青柳',
    areaCode: '556',
    bandStart: '05561',
    bandEnd: '05565',
    eliminateCode: '0',
  },
  {
    id: '362',
    MA: '身延',
    areaCode: '556',
    bandStart: '05566',
    bandEnd: '05566',
    eliminateCode: '0',
  },
  {
    id: '363',
    MA: '名古屋',
    areaCode: '52',
    bandStart: '0521',
    bandEnd: '0529',
    eliminateCode: '0',
  },
  {
    id: '364',
    MA: '浜松',
    areaCode: '53',
    bandStart: '0534',
    bandEnd: '0535',
    eliminateCode: '0',
  },
  {
    id: '365',
    MA: '浜松',
    areaCode: '53',
    bandStart: '05392',
    bandEnd: '05395',
    eliminateCode: '0',
  },
  {
    id: '366',
    MA: '浜松',
    areaCode: '53',
    bandStart: '053964',
    bandEnd: '053969',
    eliminateCode: '0',
  },
  {
    id: '367',
    MA: '浜松',
    areaCode: '53',
    bandStart: '053970',
    bandEnd: '053973',
    eliminateCode: '0',
  },
  {
    id: '368',
    MA: '浜松',
    areaCode: '53',
    bandStart: '053975',
    bandEnd: '053976',
    eliminateCode: '0',
  },
  {
    id: '369',
    MA: '浜松',
    areaCode: '53',
    bandStart: '053978',
    bandEnd: '053979',
    eliminateCode: '0',
  },
  {
    id: '370',
    MA: '浜松',
    areaCode: '53',
    bandStart: '05398',
    bandEnd: '05398',
    eliminateCode: '0',
  },
  {
    id: '371',
    MA: '田原',
    areaCode: '531',
    bandStart: '0531',
    bandEnd: '0531',
    eliminateCode: '0',
  },
  {
    id: '372',
    MA: '豊橋',
    areaCode: '532',
    bandStart: '0532',
    bandEnd: '0532',
    eliminateCode: '0',
  },
  {
    id: '373',
    MA: '豊橋',
    areaCode: '533',
    bandStart: '0533',
    bandEnd: '0533',
    eliminateCode: '0',
  },
  {
    id: '374',
    MA: '新城',
    areaCode: '536',
    bandStart: '05361',
    bandEnd: '05365',
    eliminateCode: '0',
  },
  {
    id: '375',
    MA: '設楽',
    areaCode: '536',
    bandStart: '05366',
    bandEnd: '05368',
    eliminateCode: '0',
  },
  {
    id: '376',
    MA: '掛川',
    areaCode: '537',
    bandStart: '0537',
    bandEnd: '0537',
    eliminateCode: '0',
  },
  {
    id: '377',
    MA: '磐田',
    areaCode: '538',
    bandStart: '0538',
    bandEnd: '0538',
    eliminateCode: '0',
  },
  {
    id: '378',
    MA: '天竜',
    areaCode: '539',
    bandStart: '05391',
    bandEnd: '05391',
    eliminateCode: '0',
  },
  {
    id: '379',
    MA: '天竜',
    areaCode: '539',
    bandStart: '053960',
    bandEnd: '053963',
    eliminateCode: '0',
  },
  {
    id: '380',
    MA: '天竜',
    areaCode: '539',
    bandStart: '053974',
    bandEnd: '053974',
    eliminateCode: '0',
  },
  {
    id: '381',
    MA: '天竜',
    areaCode: '539',
    bandStart: '053977',
    bandEnd: '053977',
    eliminateCode: '0',
  },
  {
    id: '382',
    MA: '天竜',
    areaCode: '539',
    bandStart: '05399',
    bandEnd: '05399',
    eliminateCode: '0',
  },
  {
    id: '383',
    MA: '静岡',
    areaCode: '54',
    bandStart: '0541',
    bandEnd: '0543',
    eliminateCode: '0',
  },
  {
    id: '384',
    MA: '静岡',
    areaCode: '54',
    bandStart: '0546',
    bandEnd: '0546',
    eliminateCode: '0',
  },
  {
    id: '385',
    MA: '静岡',
    areaCode: '54',
    bandStart: '0549',
    bandEnd: '0549',
    eliminateCode: '0',
  },
  {
    id: '386',
    MA: '富士宮',
    areaCode: '544',
    bandStart: '0544',
    bandEnd: '0544',
    eliminateCode: '0',
  },
  {
    id: '387',
    MA: '富士',
    areaCode: '545',
    bandStart: '0545',
    bandEnd: '0545',
    eliminateCode: '0',
  },
  {
    id: '388',
    MA: '島田',
    areaCode: '547',
    bandStart: '0547',
    bandEnd: '0547',
    eliminateCode: '0',
  },
  {
    id: '389',
    MA: '榛原',
    areaCode: '548',
    bandStart: '0548',
    bandEnd: '0548',
    eliminateCode: '0',
  },
  {
    id: '390',
    MA: '御殿場',
    areaCode: '550',
    bandStart: '0550',
    bandEnd: '0550',
    eliminateCode: '0',
  },
  {
    id: '391',
    MA: '伊東',
    areaCode: '557',
    bandStart: '0557',
    bandEnd: '0557',
    eliminateCode: '0',
  },
  {
    id: '392',
    MA: '修善寺大仁',
    areaCode: '558',
    bandStart: '05581',
    bandEnd: '05581',
    eliminateCode: '0',
  },
  {
    id: '393',
    MA: '修善寺大仁',
    areaCode: '558',
    bandStart: '05587',
    bandEnd: '05589',
    eliminateCode: '0',
  },
  {
    id: '394',
    MA: '下田',
    areaCode: '558',
    bandStart: '05582',
    bandEnd: '05586',
    eliminateCode: '0',
  },
  {
    id: '395',
    MA: '沼津',
    areaCode: '55',
    bandStart: '0559',
    bandEnd: '0559',
    eliminateCode: '0',
  },
  {
    id: '396',
    MA: '瀬戸',
    areaCode: '561',
    bandStart: '0561',
    bandEnd: '0561',
    eliminateCode: '0',
  },
  {
    id: '397',
    MA: '尾張横須賀',
    areaCode: '562',
    bandStart: '0562',
    bandEnd: '0562',
    eliminateCode: '0',
  },
  {
    id: '398',
    MA: '西尾',
    areaCode: '563',
    bandStart: '0563',
    bandEnd: '0563',
    eliminateCode: '0',
  },
  {
    id: '399',
    MA: '岡崎',
    areaCode: '564',
    bandStart: '0564',
    bandEnd: '0564',
    eliminateCode: '0',
  },
  {
    id: '400',
    MA: '豊田',
    areaCode: '565',
    bandStart: '0565',
    bandEnd: '0565',
    eliminateCode: '0',
  },
  {
    id: '401',
    MA: '刈谷',
    areaCode: '566',
    bandStart: '0566',
    bandEnd: '0566',
    eliminateCode: '0',
  },
  {
    id: '402',
    MA: '津島',
    areaCode: '567',
    bandStart: '0567',
    bandEnd: '0567',
    eliminateCode: '0',
  },
  {
    id: '403',
    MA: '春日井',
    areaCode: '568',
    bandStart: '0568',
    bandEnd: '0568',
    eliminateCode: '0',
  },
  {
    id: '404',
    MA: '半田',
    areaCode: '569',
    bandStart: '0569',
    bandEnd: '0569',
    eliminateCode: '0',
  },
  {
    id: '405',
    MA: '多治見',
    areaCode: '572',
    bandStart: '0572',
    bandEnd: '0572',
    eliminateCode: '0',
  },
  {
    id: '406',
    MA: '恵那',
    areaCode: '573',
    bandStart: '05732',
    bandEnd: '05735',
    eliminateCode: '0',
  },
  {
    id: '407',
    MA: '中津川',
    areaCode: '573',
    bandStart: '05731',
    bandEnd: '05731',
    eliminateCode: '0',
  },
  {
    id: '408',
    MA: '中津川',
    areaCode: '573',
    bandStart: '05736',
    bandEnd: '05738',
    eliminateCode: '0',
  },
  {
    id: '409',
    MA: '美濃加茂',
    areaCode: '574',
    bandStart: '05741',
    bandEnd: '05746',
    eliminateCode: '0',
  },
  {
    id: '410',
    MA: '美濃白川',
    areaCode: '574',
    bandStart: '05747',
    bandEnd: '05748',
    eliminateCode: '0',
  },
  {
    id: '411',
    MA: '関',
    areaCode: '575',
    bandStart: '05751',
    bandEnd: '05755',
    eliminateCode: '0',
  },
  {
    id: '412',
    MA: '郡上八幡',
    areaCode: '575',
    bandStart: '05756',
    bandEnd: '05758',
    eliminateCode: '0',
  },
  {
    id: '413',
    MA: '下呂',
    areaCode: '576',
    bandStart: '05761',
    bandEnd: '05768',
    eliminateCode: '0',
  },
  {
    id: '414',
    MA: '荘川',
    areaCode: '5769',
    bandStart: '05769',
    bandEnd: '05769',
    eliminateCode: '0',
  },
  {
    id: '415',
    MA: '高山',
    areaCode: '577',
    bandStart: '0577',
    bandEnd: '0577',
    eliminateCode: '0',
  },
  {
    id: '416',
    MA: '神岡',
    areaCode: '578',
    bandStart: '0578',
    bandEnd: '0578',
    eliminateCode: '0',
  },
  {
    id: '417',
    MA: '岐阜',
    areaCode: '58',
    bandStart: '0582',
    bandEnd: '0582',
    eliminateCode: '0',
  },
  {
    id: '418',
    MA: '岐阜',
    areaCode: '58',
    bandStart: '0583',
    bandEnd: '0583',
    eliminateCode: '0',
  },
  {
    id: '419',
    MA: '高富',
    areaCode: '581',
    bandStart: '0581',
    bandEnd: '0581',
    eliminateCode: '0',
  },
  {
    id: '420',
    MA: '大垣',
    areaCode: '584',
    bandStart: '0584',
    bandEnd: '0584',
    eliminateCode: '0',
  },
  {
    id: '421',
    MA: '揖斐川',
    areaCode: '585',
    bandStart: '0585',
    bandEnd: '0585',
    eliminateCode: '0',
  },
  {
    id: '422',
    MA: '一宮',
    areaCode: '586',
    bandStart: '0586',
    bandEnd: '0586',
    eliminateCode: '0',
  },
  {
    id: '423',
    MA: '一宮',
    areaCode: '587',
    bandStart: '0587',
    bandEnd: '0587',
    eliminateCode: '0',
  },
  {
    id: '424',
    MA: '津',
    areaCode: '59',
    bandStart: '0591',
    bandEnd: '0592',
    eliminateCode: '0',
  },
  {
    id: '425',
    MA: '津',
    areaCode: '59',
    bandStart: '05999',
    bandEnd: '05999',
    eliminateCode: '0',
  },
  {
    id: '426',
    MA: '四日市',
    areaCode: '59',
    bandStart: '0593',
    bandEnd: '0593',
    eliminateCode: '0',
  },
  {
    id: '427',
    MA: '桑名',
    areaCode: '594',
    bandStart: '0594',
    bandEnd: '0594',
    eliminateCode: '0',
  },
  {
    id: '428',
    MA: '上野',
    areaCode: '595',
    bandStart: '05951',
    bandEnd: '05957',
    eliminateCode: '0',
  },
  {
    id: '429',
    MA: '亀山',
    areaCode: '595',
    bandStart: '05958',
    bandEnd: '05959',
    eliminateCode: '0',
  },
  {
    id: '430',
    MA: '伊勢',
    areaCode: '596',
    bandStart: '0596',
    bandEnd: '0596',
    eliminateCode: '0',
  },
  {
    id: '431',
    MA: '尾鷲',
    areaCode: '597',
    bandStart: '05971',
    bandEnd: '05975',
    eliminateCode: '0',
  },
  {
    id: '432',
    MA: '熊野',
    areaCode: '597',
    bandStart: '05976',
    bandEnd: '05978',
    eliminateCode: '0',
  },
  {
    id: '433',
    MA: '熊野',
    areaCode: '597',
    bandStart: '059797',
    bandEnd: '059798',
    eliminateCode: '0',
  },
  {
    id: '434',
    MA: '熊野',
    areaCode: '5979',
    bandStart: '059791',
    bandEnd: '059796',
    eliminateCode: '0',
  },
  {
    id: '435',
    MA: '熊野',
    areaCode: '5979',
    bandStart: '059799',
    bandEnd: '059799',
    eliminateCode: '0',
  },
  {
    id: '436',
    MA: '松阪',
    areaCode: '598',
    bandStart: '05981',
    bandEnd: '05986',
    eliminateCode: '0',
  },
  {
    id: '437',
    MA: '三瀬谷',
    areaCode: '598',
    bandStart: '05987',
    bandEnd: '05988',
    eliminateCode: '0',
  },
  {
    id: '438',
    MA: '鳥羽',
    areaCode: '599',
    bandStart: '05991',
    bandEnd: '05993',
    eliminateCode: '0',
  },
  {
    id: '439',
    MA: '阿児',
    areaCode: '599',
    bandStart: '05994',
    bandEnd: '05998',
    eliminateCode: '0',
  },
  {
    id: '440',
    MA: '大阪',
    areaCode: '6',
    bandStart: '061',
    bandEnd: '069',
    eliminateCode: '0',
  },
  {
    id: '441',
    MA: '寝屋川',
    areaCode: '72',
    bandStart: '072177',
    bandEnd: '072177',
    eliminateCode: '0',
  },
  {
    id: '442',
    MA: '寝屋川',
    areaCode: '72',
    bandStart: '0728',
    bandEnd: '0728',
    eliminateCode: '0',
  },
  {
    id: '443',
    MA: '寝屋川',
    areaCode: '72',
    bandStart: '07238',
    bandEnd: '07239',
    eliminateCode: '0',
  },
  {
    id: '444',
    MA: '寝屋川',
    areaCode: '72',
    bandStart: '07230',
    bandEnd: '07230',
    eliminateCode: '0',
  },
  {
    id: '445',
    MA: '富田林',
    areaCode: '721',
    bandStart: '07211',
    bandEnd: '07216',
    eliminateCode: '0',
  },
  {
    id: '446',
    MA: '富田林',
    areaCode: '721',
    bandStart: '072170',
    bandEnd: '072176',
    eliminateCode: '0',
  },
  {
    id: '447',
    MA: '富田林',
    areaCode: '721',
    bandStart: '072178',
    bandEnd: '072179',
    eliminateCode: '0',
  },
  {
    id: '448',
    MA: '富田林',
    areaCode: '721',
    bandStart: '07218',
    bandEnd: '07219',
    eliminateCode: '0',
  },
  {
    id: '449',
    MA: '堺',
    areaCode: '72',
    bandStart: '0722',
    bandEnd: '0722',
    eliminateCode: '0',
  },
  {
    id: '450',
    MA: '堺',
    areaCode: '72',
    bandStart: '07231',
    bandEnd: '07237',
    eliminateCode: '0',
  },
  {
    id: '451',
    MA: '岸和田貝塚',
    areaCode: '72',
    bandStart: '0724',
    bandEnd: '0724',
    eliminateCode: '0',
  },
  {
    id: '452',
    MA: '和泉',
    areaCode: '725',
    bandStart: '0725',
    bandEnd: '0725',
    eliminateCode: '0',
  },
  {
    id: '453',
    MA: '茨木',
    areaCode: '72',
    bandStart: '0726',
    bandEnd: '0726',
    eliminateCode: '0',
  },
  {
    id: '454',
    MA: '池田',
    areaCode: '72',
    bandStart: '0727',
    bandEnd: '0727',
    eliminateCode: '0',
  },
  {
    id: '455',
    MA: '八尾',
    areaCode: '72',
    bandStart: '0729',
    bandEnd: '0729',
    eliminateCode: '0',
  },
  {
    id: '456',
    MA: '和歌山',
    areaCode: '73',
    bandStart: '0731',
    bandEnd: '0731',
    eliminateCode: '0',
  },
  {
    id: '457',
    MA: '和歌山',
    areaCode: '73',
    bandStart: '0734',
    bandEnd: '0734',
    eliminateCode: '0',
  },
  {
    id: '458',
    MA: '新宮',
    areaCode: '735',
    bandStart: '07351',
    bandEnd: '07355',
    eliminateCode: '0',
  },
  {
    id: '459',
    MA: '串本',
    areaCode: '735',
    bandStart: '07356',
    bandEnd: '07358',
    eliminateCode: '0',
  },
  {
    id: '460',
    MA: '岩出',
    areaCode: '736',
    bandStart: '07361',
    bandEnd: '07361',
    eliminateCode: '0',
  },
  {
    id: '461',
    MA: '岩出',
    areaCode: '736',
    bandStart: '07366',
    bandEnd: '07368',
    eliminateCode: '0',
  },
  {
    id: '462',
    MA: '和歌山橋本',
    areaCode: '736',
    bandStart: '07362',
    bandEnd: '07365',
    eliminateCode: '0',
  },
  {
    id: '463',
    MA: '湯浅',
    areaCode: '737',
    bandStart: '0737',
    bandEnd: '0737',
    eliminateCode: '0',
  },
  {
    id: '464',
    MA: '御坊',
    areaCode: '738',
    bandStart: '0738',
    bandEnd: '0738',
    eliminateCode: '0',
  },
  {
    id: '465',
    MA: '田辺',
    areaCode: '739',
    bandStart: '0739',
    bandEnd: '0739',
    eliminateCode: '0',
  },
  {
    id: '466',
    MA: '今津',
    areaCode: '740',
    bandStart: '07401',
    bandEnd: '07403',
    eliminateCode: '0',
  },
  {
    id: '467',
    MA: '奈良',
    areaCode: '742',
    bandStart: '0742',
    bandEnd: '0742',
    eliminateCode: '0',
  },
  {
    id: '468',
    MA: '奈良',
    areaCode: '743',
    bandStart: '0743',
    bandEnd: '0743',
    eliminateCode: '0',
  },
  {
    id: '469',
    MA: '大和高田',
    areaCode: '744',
    bandStart: '0744',
    bandEnd: '0744',
    eliminateCode: '0',
  },
  {
    id: '470',
    MA: '大和高田',
    areaCode: '745',
    bandStart: '07451',
    bandEnd: '07457',
    eliminateCode: '0',
  },
  {
    id: '471',
    MA: '大和榛原',
    areaCode: '745',
    bandStart: '07458',
    bandEnd: '07459',
    eliminateCode: '0',
  },
  {
    id: '472',
    MA: '吉野',
    areaCode: '746',
    bandStart: '07463',
    bandEnd: '07465',
    eliminateCode: '0',
  },
  {
    id: '473',
    MA: '十津川',
    areaCode: '746',
    bandStart: '07466',
    bandEnd: '07466',
    eliminateCode: '0',
  },
  {
    id: '474',
    MA: '上北山',
    areaCode: '7468',
    bandStart: '07468',
    bandEnd: '07468',
    eliminateCode: '0',
  },
  {
    id: '475',
    MA: '五条',
    areaCode: '747',
    bandStart: '07471',
    bandEnd: '07474',
    eliminateCode: '0',
  },
  {
    id: '476',
    MA: '下市',
    areaCode: '747',
    bandStart: '07475',
    bandEnd: '07476',
    eliminateCode: '0',
  },
  {
    id: '477',
    MA: '八日市',
    areaCode: '748',
    bandStart: '07481',
    bandEnd: '07485',
    eliminateCode: '0',
  },
  {
    id: '478',
    MA: '水口',
    areaCode: '748',
    bandStart: '07486',
    bandEnd: '07488',
    eliminateCode: '0',
  },
  {
    id: '479',
    MA: '彦根',
    areaCode: '749',
    bandStart: '07491',
    bandEnd: '07494',
    eliminateCode: '0',
  },
  {
    id: '480',
    MA: '長浜',
    areaCode: '749',
    bandStart: '07495',
    bandEnd: '07498',
    eliminateCode: '0',
  },
  {
    id: '481',
    MA: '京都',
    areaCode: '75',
    bandStart: '0751',
    bandEnd: '0759',
    eliminateCode: '0',
  },
  {
    id: '482',
    MA: '小松',
    areaCode: '761',
    bandStart: '07611',
    bandEnd: '07616',
    eliminateCode: '0',
  },
  {
    id: '483',
    MA: '加賀',
    areaCode: '761',
    bandStart: '07617',
    bandEnd: '07617',
    eliminateCode: '0',
  },
  {
    id: '484',
    MA: '加賀',
    areaCode: '761',
    bandStart: '076180',
    bandEnd: '076184',
    eliminateCode: '0',
  },
  {
    id: '485',
    MA: '金沢',
    areaCode: '76',
    bandStart: '0762',
    bandEnd: '0762',
    eliminateCode: '0',
  },
  {
    id: '486',
    MA: '福野',
    areaCode: '763',
    bandStart: '0763',
    bandEnd: '0763',
    eliminateCode: '0',
  },
  {
    id: '487',
    MA: '富山',
    areaCode: '76',
    bandStart: '0764',
    bandEnd: '0764',
    eliminateCode: '0',
  },
  {
    id: '488',
    MA: '魚津',
    areaCode: '765',
    bandStart: '0765',
    bandEnd: '0765',
    eliminateCode: '0',
  },
  {
    id: '489',
    MA: '高岡',
    areaCode: '766',
    bandStart: '0766',
    bandEnd: '0766',
    eliminateCode: '0',
  },
  {
    id: '490',
    MA: '七尾',
    areaCode: '767',
    bandStart: '07671',
    bandEnd: '07671',
    eliminateCode: '0',
  },
  {
    id: '491',
    MA: '七尾',
    areaCode: '767',
    bandStart: '07675',
    bandEnd: '07678',
    eliminateCode: '0',
  },
  {
    id: '492',
    MA: '羽咋',
    areaCode: '767',
    bandStart: '07672',
    bandEnd: '07674',
    eliminateCode: '0',
  },
  {
    id: '493',
    MA: '輪島',
    areaCode: '768',
    bandStart: '07681',
    bandEnd: '07685',
    eliminateCode: '0',
  },
  {
    id: '494',
    MA: '能都',
    areaCode: '768',
    bandStart: '07686',
    bandEnd: '07688',
    eliminateCode: '0',
  },
  {
    id: '495',
    MA: '敦賀',
    areaCode: '770',
    bandStart: '07701',
    bandEnd: '07704',
    eliminateCode: '0',
  },
  {
    id: '496',
    MA: '小浜',
    areaCode: '770',
    bandStart: '07705',
    bandEnd: '07707',
    eliminateCode: '0',
  },
  {
    id: '497',
    MA: '亀岡',
    areaCode: '771',
    bandStart: '07711',
    bandEnd: '07715',
    eliminateCode: '0',
  },
  {
    id: '498',
    MA: '園部',
    areaCode: '771',
    bandStart: '07716',
    bandEnd: '07718',
    eliminateCode: '0',
  },
  {
    id: '499',
    MA: '宮津',
    areaCode: '772',
    bandStart: '07721',
    bandEnd: '07725',
    eliminateCode: '0',
  },
  {
    id: '500',
    MA: '峰山',
    areaCode: '772',
    bandStart: '07726',
    bandEnd: '07728',
    eliminateCode: '0',
  },
  {
    id: '501',
    MA: '福知山',
    areaCode: '773',
    bandStart: '07731',
    bandEnd: '07735',
    eliminateCode: '0',
  },
  {
    id: '502',
    MA: '舞鶴',
    areaCode: '773',
    bandStart: '07736',
    bandEnd: '07738',
    eliminateCode: '0',
  },
  {
    id: '503',
    MA: '宇治',
    areaCode: '774',
    bandStart: '0774',
    bandEnd: '0774',
    eliminateCode: '0',
  },
  {
    id: '504',
    MA: '大津',
    areaCode: '77',
    bandStart: '0775',
    bandEnd: '0775',
    eliminateCode: '0',
  },
  {
    id: '505',
    MA: '福井',
    areaCode: '776',
    bandStart: '0776',
    bandEnd: '0776',
    eliminateCode: '0',
  },
  {
    id: '506',
    MA: '武生',
    areaCode: '778',
    bandStart: '0778',
    bandEnd: '0778',
    eliminateCode: '0',
  },
  {
    id: '507',
    MA: '大野',
    areaCode: '779',
    bandStart: '07791',
    bandEnd: '07791',
    eliminateCode: '0',
  },
  {
    id: '508',
    MA: '大野',
    areaCode: '779',
    bandStart: '07796',
    bandEnd: '07796',
    eliminateCode: '0',
  },
  {
    id: '509',
    MA: '大野',
    areaCode: '779',
    bandStart: '077977',
    bandEnd: '077979',
    eliminateCode: '0',
  },
  {
    id: '510',
    MA: '大野',
    areaCode: '779',
    bandStart: '07798',
    bandEnd: '07798',
    eliminateCode: '0',
  },
  {
    id: '511',
    MA: '神戸',
    areaCode: '78',
    bandStart: '0781',
    bandEnd: '0789',
    eliminateCode: '0',
  },
  {
    id: '512',
    MA: '播磨山崎',
    areaCode: '790',
    bandStart: '07906',
    bandEnd: '07908',
    eliminateCode: '0',
  },
  {
    id: '513',
    MA: '福崎',
    areaCode: '790',
    bandStart: '07902',
    bandEnd: '07905',
    eliminateCode: '0',
  },
  {
    id: '514',
    MA: '竜野',
    areaCode: '791',
    bandStart: '07911',
    bandEnd: '07911',
    eliminateCode: '0',
  },
  {
    id: '515',
    MA: '竜野',
    areaCode: '791',
    bandStart: '07916',
    bandEnd: '07917',
    eliminateCode: '0',
  },
  {
    id: '516',
    MA: '相生',
    areaCode: '791',
    bandStart: '07912',
    bandEnd: '07915',
    eliminateCode: '0',
  },
  {
    id: '517',
    MA: '姫路',
    areaCode: '79',
    bandStart: '0792',
    bandEnd: '0792',
    eliminateCode: '0',
  },
  {
    id: '518',
    MA: '姫路',
    areaCode: '79',
    bandStart: '07930',
    bandEnd: '07933',
    eliminateCode: '0',
  },
  {
    id: '519',
    MA: '加古川',
    areaCode: '79',
    bandStart: '07940',
    bandEnd: '07945',
    eliminateCode: '0',
  },
  {
    id: '520',
    MA: '加古川',
    areaCode: '79',
    bandStart: '07949',
    bandEnd: '07949',
    eliminateCode: '0',
  },
  {
    id: '521',
    MA: '三木',
    areaCode: '794',
    bandStart: '07946',
    bandEnd: '07948',
    eliminateCode: '0',
  },
  {
    id: '522',
    MA: '西脇',
    areaCode: '795',
    bandStart: '07952',
    bandEnd: '07954',
    eliminateCode: '0',
  },
  {
    id: '523',
    MA: '三田',
    areaCode: '79',
    bandStart: '07950',
    bandEnd: '07950',
    eliminateCode: '0',
  },
  {
    id: '524',
    MA: '三田',
    areaCode: '79',
    bandStart: '07955',
    bandEnd: '07956',
    eliminateCode: '0',
  },
  {
    id: '525',
    MA: '三田',
    areaCode: '79',
    bandStart: '07959',
    bandEnd: '07959',
    eliminateCode: '0',
  },
  {
    id: '526',
    MA: '丹波柏原',
    areaCode: '795',
    bandStart: '07957',
    bandEnd: '07958',
    eliminateCode: '0',
  },
  {
    id: '527',
    MA: '豊岡',
    areaCode: '796',
    bandStart: '07961',
    bandEnd: '07965',
    eliminateCode: '0',
  },
  {
    id: '528',
    MA: '浜坂',
    areaCode: '796',
    bandStart: '07968',
    bandEnd: '07969',
    eliminateCode: '0',
  },
  {
    id: '529',
    MA: '八鹿',
    areaCode: '79',
    bandStart: '07960',
    bandEnd: '07960',
    eliminateCode: '0',
  },
  {
    id: '530',
    MA: '八鹿',
    areaCode: '79',
    bandStart: '07966',
    bandEnd: '07967',
    eliminateCode: '0',
  },
  {
    id: '531',
    MA: '西宮',
    areaCode: '797',
    bandStart: '0797',
    bandEnd: '0797',
    eliminateCode: '0',
  },
  {
    id: '532',
    MA: '西宮',
    areaCode: '798',
    bandStart: '0798',
    bandEnd: '0798',
    eliminateCode: '0',
  },
  {
    id: '533',
    MA: '洲本',
    areaCode: '799',
    bandStart: '07991',
    bandEnd: '07995',
    eliminateCode: '0',
  },
  {
    id: '534',
    MA: '津名',
    areaCode: '799',
    bandStart: '07996',
    bandEnd: '07998',
    eliminateCode: '0',
  },
  {
    id: '535',
    MA: '広島',
    areaCode: '82',
    bandStart: '0821',
    bandEnd: '0822',
    eliminateCode: '0',
  },
  {
    id: '536',
    MA: '広島',
    areaCode: '82',
    bandStart: '0825',
    bandEnd: '0825',
    eliminateCode: '0',
  },
  {
    id: '537',
    MA: '広島',
    areaCode: '82',
    bandStart: '0828',
    bandEnd: '0828',
    eliminateCode: '0',
  },
  {
    id: '538',
    MA: '広島',
    areaCode: '82',
    bandStart: '08290',
    bandEnd: '08290',
    eliminateCode: '0',
  },
  {
    id: '539',
    MA: '広島',
    areaCode: '82',
    bandStart: '082921',
    bandEnd: '082929',
    eliminateCode: '0',
  },
  {
    id: '540',
    MA: '広島',
    areaCode: '82',
    bandStart: '082941',
    bandEnd: '082943',
    eliminateCode: '0',
  },
  {
    id: '541',
    MA: '広島',
    areaCode: '82',
    bandStart: '08296',
    bandEnd: '08296',
    eliminateCode: '0',
  },
  {
    id: '542',
    MA: '広島',
    areaCode: '82',
    bandStart: '08299',
    bandEnd: '08299',
    eliminateCode: '0',
  },
  {
    id: '543',
    MA: '柳井',
    areaCode: '820',
    bandStart: '08201',
    bandEnd: '08206',
    eliminateCode: '0',
  },
  {
    id: '544',
    MA: '久賀',
    areaCode: '820',
    bandStart: '08207',
    bandEnd: '08208',
    eliminateCode: '0',
  },
  {
    id: '545',
    MA: '呉',
    areaCode: '823',
    bandStart: '0823',
    bandEnd: '0823',
    eliminateCode: '0',
  },
  {
    id: '546',
    MA: '三次',
    areaCode: '824',
    bandStart: '08244',
    bandEnd: '08246',
    eliminateCode: '0',
  },
  {
    id: '547',
    MA: '東広島',
    areaCode: '82',
    bandStart: '08240',
    bandEnd: '08240',
    eliminateCode: '0',
  },
  {
    id: '548',
    MA: '東広島',
    areaCode: '82',
    bandStart: '08242',
    bandEnd: '08243',
    eliminateCode: '0',
  },
  {
    id: '549',
    MA: '東広島',
    areaCode: '82',
    bandStart: '08249',
    bandEnd: '08249',
    eliminateCode: '0',
  },
  {
    id: '550',
    MA: '庄原',
    areaCode: '824',
    bandStart: '08247',
    bandEnd: '08248',
    eliminateCode: '0',
  },
  {
    id: '551',
    MA: '千代田',
    areaCode: '826',
    bandStart: '08266',
    bandEnd: '08268',
    eliminateCode: '0',
  },
  {
    id: '552',
    MA: '安芸吉田',
    areaCode: '826',
    bandStart: '08261',
    bandEnd: '08261',
    eliminateCode: '0',
  },
  {
    id: '553',
    MA: '安芸吉田',
    areaCode: '826',
    bandStart: '08264',
    bandEnd: '08265',
    eliminateCode: '0',
  },
  {
    id: '554',
    MA: '加計',
    areaCode: '826',
    bandStart: '08262',
    bandEnd: '08263',
    eliminateCode: '0',
  },
  {
    id: '555',
    MA: '岩国',
    areaCode: '827',
    bandStart: '0827',
    bandEnd: '0827',
    eliminateCode: '0',
  },
  {
    id: '556',
    MA: '廿日市',
    areaCode: '829',
    bandStart: '08291',
    bandEnd: '08291',
    eliminateCode: '0',
  },
  {
    id: '557',
    MA: '廿日市',
    areaCode: '829',
    bandStart: '082920',
    bandEnd: '082920',
    eliminateCode: '0',
  },
  {
    id: '558',
    MA: '廿日市',
    areaCode: '829',
    bandStart: '08293',
    bandEnd: '08293',
    eliminateCode: '0',
  },
  {
    id: '559',
    MA: '廿日市',
    areaCode: '829',
    bandStart: '082940',
    bandEnd: '082940',
    eliminateCode: '0',
  },
  {
    id: '560',
    MA: '廿日市',
    areaCode: '829',
    bandStart: '082944',
    bandEnd: '082949',
    eliminateCode: '0',
  },
  {
    id: '561',
    MA: '廿日市',
    areaCode: '829',
    bandStart: '08295',
    bandEnd: '08295',
    eliminateCode: '0',
  },
  {
    id: '562',
    MA: '廿日市',
    areaCode: '829',
    bandStart: '08297',
    bandEnd: '08298',
    eliminateCode: '0',
  },
  {
    id: '563',
    MA: '下関',
    areaCode: '83',
    bandStart: '08321',
    bandEnd: '08329',
    eliminateCode: '0',
  },
  {
    id: '564',
    MA: '下関',
    areaCode: '83',
    bandStart: '083766',
    bandEnd: '083768',
    eliminateCode: '0',
  },
  {
    id: '565',
    MA: '下関',
    areaCode: '83',
    bandStart: '08377',
    bandEnd: '08378',
    eliminateCode: '0',
  },
  {
    id: '566',
    MA: '下松',
    areaCode: '833',
    bandStart: '0833',
    bandEnd: '0833',
    eliminateCode: '0',
  },
  {
    id: '567',
    MA: '徳山',
    areaCode: '834',
    bandStart: '0834',
    bandEnd: '0834',
    eliminateCode: '0',
  },
  {
    id: '568',
    MA: '防府',
    areaCode: '835',
    bandStart: '0835',
    bandEnd: '0835',
    eliminateCode: '0',
  },
  {
    id: '569',
    MA: '宇部',
    areaCode: '836',
    bandStart: '08361',
    bandEnd: '08369',
    eliminateCode: '0',
  },
  {
    id: '570',
    MA: '長門',
    areaCode: '837',
    bandStart: '08372',
    bandEnd: '08374',
    eliminateCode: '0',
  },
  {
    id: '571',
    MA: '美祢',
    areaCode: '837',
    bandStart: '08375',
    bandEnd: '08375',
    eliminateCode: '0',
  },
  {
    id: '572',
    MA: '美祢',
    areaCode: '837',
    bandStart: '083760',
    bandEnd: '083765',
    eliminateCode: '0',
  },
  {
    id: '573',
    MA: '美祢',
    areaCode: '837',
    bandStart: '083769',
    bandEnd: '083769',
    eliminateCode: '0',
  },
  {
    id: '574',
    MA: '萩',
    areaCode: '838',
    bandStart: '08381',
    bandEnd: '08385',
    eliminateCode: '0',
  },
  {
    id: '575',
    MA: '田万川',
    areaCode: '8387',
    bandStart: '08387',
    bandEnd: '08387',
    eliminateCode: '0',
  },
  {
    id: '576',
    MA: '田万川',
    areaCode: '8388',
    bandStart: '08388',
    bandEnd: '08388',
    eliminateCode: '0',
  },
  {
    id: '577',
    MA: '山口',
    areaCode: '83',
    bandStart: '08360',
    bandEnd: '08360',
    eliminateCode: '0',
  },
  {
    id: '578',
    MA: '山口',
    areaCode: '83',
    bandStart: '08390',
    bandEnd: '08395',
    eliminateCode: '0',
  },
  {
    id: '579',
    MA: '山口',
    areaCode: '83',
    bandStart: '083960',
    bandEnd: '083960',
    eliminateCode: '0',
  },
  {
    id: '580',
    MA: '山口',
    areaCode: '83',
    bandStart: '083963',
    bandEnd: '083963',
    eliminateCode: '0',
  },
  {
    id: '581',
    MA: '山口',
    areaCode: '83',
    bandStart: '083966',
    bandEnd: '083966',
    eliminateCode: '0',
  },
  {
    id: '582',
    MA: '山口',
    areaCode: '83',
    bandStart: '08397',
    bandEnd: '08399',
    eliminateCode: '0',
  },
  {
    id: '583',
    MA: '山口',
    areaCode: '8396',
    bandStart: '083962',
    bandEnd: '083962',
    eliminateCode: '0',
  },
  {
    id: '584',
    MA: '山口',
    areaCode: '8396',
    bandStart: '083964',
    bandEnd: '083965',
    eliminateCode: '0',
  },
  {
    id: '585',
    MA: '山口',
    areaCode: '8396',
    bandStart: '083967',
    bandEnd: '083969',
    eliminateCode: '0',
  },
  {
    id: '586',
    MA: '因島',
    areaCode: '845',
    bandStart: '08452',
    bandEnd: '08453',
    eliminateCode: '0',
  },
  {
    id: '587',
    MA: '竹原',
    areaCode: '846',
    bandStart: '08461',
    bandEnd: '08464',
    eliminateCode: '0',
  },
  {
    id: '588',
    MA: '木江',
    areaCode: '846',
    bandStart: '08466',
    bandEnd: '08467',
    eliminateCode: '0',
  },
  {
    id: '589',
    MA: '府中',
    areaCode: '847',
    bandStart: '08471',
    bandEnd: '08471',
    eliminateCode: '0',
  },
  {
    id: '590',
    MA: '府中',
    areaCode: '847',
    bandStart: '08474',
    bandEnd: '08476',
    eliminateCode: '0',
  },
  {
    id: '591',
    MA: '甲山',
    areaCode: '847',
    bandStart: '08472',
    bandEnd: '08473',
    eliminateCode: '0',
  },
  {
    id: '592',
    MA: '東城',
    areaCode: '8477',
    bandStart: '08477',
    bandEnd: '08477',
    eliminateCode: '0',
  },
  {
    id: '593',
    MA: '東城',
    areaCode: '847',
    bandStart: '08478',
    bandEnd: '08479',
    eliminateCode: '0',
  },
  {
    id: '594',
    MA: '尾道☆',
    areaCode: '848',
    bandStart: '0848',
    bandEnd: '0848',
    eliminateCode: '0',
  },
  {
    id: '595',
    MA: '尾道☆',
    areaCode: '84',
    bandStart: '08490',
    bandEnd: '08490',
    eliminateCode: '0',
  },
  {
    id: '596',
    MA: '尾道☆',
    areaCode: '84',
    bandStart: '084930',
    bandEnd: '084930',
    eliminateCode: '0',
  },
  {
    id: '597',
    MA: '尾道☆',
    areaCode: '84',
    bandStart: '084933',
    bandEnd: '084939',
    eliminateCode: '0',
  },
  {
    id: '598',
    MA: '福山☆',
    areaCode: '84',
    bandStart: '0841',
    bandEnd: '0841',
    eliminateCode: '0',
  },
  {
    id: '599',
    MA: '福山☆',
    areaCode: '84',
    bandStart: '08491',
    bandEnd: '08492',
    eliminateCode: '0',
  },
  {
    id: '600',
    MA: '福山☆',
    areaCode: '84',
    bandStart: '084931',
    bandEnd: '084932',
    eliminateCode: '0',
  },
  {
    id: '601',
    MA: '福山☆',
    areaCode: '84',
    bandStart: '08494',
    bandEnd: '08499',
    eliminateCode: '0',
  },
  {
    id: '602',
    MA: '西郷',
    areaCode: '8512',
    bandStart: '08512',
    bandEnd: '08512',
    eliminateCode: '0',
  },
  {
    id: '603',
    MA: '海士',
    areaCode: '8514',
    bandStart: '08514',
    bandEnd: '08514',
    eliminateCode: '0',
  },
  {
    id: '604',
    MA: '松江',
    areaCode: '852',
    bandStart: '0852',
    bandEnd: '0852',
    eliminateCode: '0',
  },
  {
    id: '605',
    MA: '出雲',
    areaCode: '853',
    bandStart: '0853',
    bandEnd: '0853',
    eliminateCode: '0',
  },
  {
    id: '606',
    MA: '安来',
    areaCode: '854',
    bandStart: '08542',
    bandEnd: '08543',
    eliminateCode: '0',
  },
  {
    id: '607',
    MA: '木次',
    areaCode: '854',
    bandStart: '08544',
    bandEnd: '08545',
    eliminateCode: '0',
  },
  {
    id: '608',
    MA: '掛合',
    areaCode: '854',
    bandStart: '08546',
    bandEnd: '08547',
    eliminateCode: '0',
  },
  {
    id: '609',
    MA: '石見大田',
    areaCode: '854',
    bandStart: '08548',
    bandEnd: '08549',
    eliminateCode: '0',
  },
  {
    id: '610',
    MA: '浜田',
    areaCode: '855',
    bandStart: '08551',
    bandEnd: '08554',
    eliminateCode: '0',
  },
  {
    id: '611',
    MA: '江津',
    areaCode: '855',
    bandStart: '08555',
    bandEnd: '08556',
    eliminateCode: '0',
  },
  {
    id: '612',
    MA: '川本',
    areaCode: '855',
    bandStart: '08557',
    bandEnd: '08559',
    eliminateCode: '0',
  },
  {
    id: '613',
    MA: '益田',
    areaCode: '856',
    bandStart: '08561',
    bandEnd: '08565',
    eliminateCode: '0',
  },
  {
    id: '614',
    MA: '津和野',
    areaCode: '856',
    bandStart: '08567',
    bandEnd: '08568',
    eliminateCode: '0',
  },
  {
    id: '615',
    MA: '鳥取',
    areaCode: '857',
    bandStart: '0857',
    bandEnd: '0857',
    eliminateCode: '0',
  },
  {
    id: '616',
    MA: '郡家',
    areaCode: '858',
    bandStart: '08587',
    bandEnd: '08588',
    eliminateCode: '0',
  },
  {
    id: '617',
    MA: '倉吉',
    areaCode: '858',
    bandStart: '08582',
    bandEnd: '08586',
    eliminateCode: '0',
  },
  {
    id: '618',
    MA: '米子',
    areaCode: '859',
    bandStart: '08591',
    bandEnd: '08596',
    eliminateCode: '0',
  },
  {
    id: '619',
    MA: '根雨',
    areaCode: '859',
    bandStart: '08597',
    bandEnd: '08598',
    eliminateCode: '0',
  },
  {
    id: '620',
    MA: '倉敷',
    areaCode: '86',
    bandStart: '08642',
    bandEnd: '08648',
    eliminateCode: '0',
  },
  {
    id: '621',
    MA: '倉敷',
    areaCode: '86',
    bandStart: '08652',
    bandEnd: '08652',
    eliminateCode: '0',
  },
  {
    id: '622',
    MA: '倉敷',
    areaCode: '86',
    bandStart: '086552',
    bandEnd: '086553',
    eliminateCode: '0',
  },
  {
    id: '623',
    MA: '倉敷',
    areaCode: '86',
    bandStart: '086691',
    bandEnd: '086691',
    eliminateCode: '0',
  },
  {
    id: '624',
    MA: '倉敷',
    areaCode: '86',
    bandStart: '086697',
    bandEnd: '086698',
    eliminateCode: '0',
  },
  {
    id: '625',
    MA: '岡山',
    areaCode: '86',
    bandStart: '0861',
    bandEnd: '0862',
    eliminateCode: '0',
  },
  {
    id: '626',
    MA: '岡山',
    areaCode: '86',
    bandStart: '08680',
    bandEnd: '08680',
    eliminateCode: '0',
  },
  {
    id: '627',
    MA: '岡山',
    areaCode: '86',
    bandStart: '08689',
    bandEnd: '08689',
    eliminateCode: '0',
  },
  {
    id: '628',
    MA: '岡山',
    areaCode: '86',
    bandStart: '08690',
    bandEnd: '08690',
    eliminateCode: '0',
  },
  {
    id: '629',
    MA: '岡山',
    areaCode: '86',
    bandStart: '08694',
    bandEnd: '08694',
    eliminateCode: '0',
  },
  {
    id: '630',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086362',
    bandEnd: '086365',
    eliminateCode: '0',
  },
  {
    id: '631',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086367',
    bandEnd: '086369',
    eliminateCode: '0',
  },
  {
    id: '632',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086722',
    bandEnd: '086724',
    eliminateCode: '0',
  },
  {
    id: '633',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086726',
    bandEnd: '086726',
    eliminateCode: '0',
  },
  {
    id: '634',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086728',
    bandEnd: '086728',
    eliminateCode: '0',
  },
  {
    id: '635',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086737',
    bandEnd: '086738',
    eliminateCode: '0',
  },
  {
    id: '636',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086952',
    bandEnd: '086953',
    eliminateCode: '0',
  },
  {
    id: '637',
    MA: '岡山',
    areaCode: '86',
    bandStart: '086959',
    bandEnd: '086959',
    eliminateCode: '0',
  },
  {
    id: '638',
    MA: '玉野',
    areaCode: '863',
    bandStart: '08632',
    bandEnd: '08635',
    eliminateCode: '0',
  },
  {
    id: '639',
    MA: '玉野',
    areaCode: '863',
    bandStart: '086366',
    bandEnd: '086366',
    eliminateCode: '0',
  },
  {
    id: '640',
    MA: '玉野',
    areaCode: '863',
    bandStart: '08637',
    bandEnd: '08638',
    eliminateCode: '0',
  },
  {
    id: '641',
    MA: '鴨方',
    areaCode: '865',
    bandStart: '08654',
    bandEnd: '08654',
    eliminateCode: '0',
  },
  {
    id: '642',
    MA: '鴨方',
    areaCode: '865',
    bandStart: '086554',
    bandEnd: '086555',
    eliminateCode: '0',
  },
  {
    id: '643',
    MA: '笠岡',
    areaCode: '865',
    bandStart: '08651',
    bandEnd: '08651',
    eliminateCode: '0',
  },
  {
    id: '644',
    MA: '笠岡',
    areaCode: '865',
    bandStart: '08656',
    bandEnd: '08657',
    eliminateCode: '0',
  },
  {
    id: '645',
    MA: '高梁',
    areaCode: '866',
    bandStart: '08661',
    bandEnd: '08662',
    eliminateCode: '0',
  },
  {
    id: '646',
    MA: '高梁',
    areaCode: '866',
    bandStart: '08664',
    bandEnd: '08665',
    eliminateCode: '0',
  },
  {
    id: '647',
    MA: '井原',
    areaCode: '866',
    bandStart: '08666',
    bandEnd: '08668',
    eliminateCode: '0',
  },
  {
    id: '648',
    MA: '総社',
    areaCode: '866',
    bandStart: '08663',
    bandEnd: '08663',
    eliminateCode: '0',
  },
  {
    id: '649',
    MA: '総社',
    areaCode: '866',
    bandStart: '086690',
    bandEnd: '086690',
    eliminateCode: '0',
  },
  {
    id: '650',
    MA: '総社',
    areaCode: '866',
    bandStart: '086692',
    bandEnd: '086696',
    eliminateCode: '0',
  },
  {
    id: '651',
    MA: '総社',
    areaCode: '866',
    bandStart: '086699',
    bandEnd: '086699',
    eliminateCode: '0',
  },
  {
    id: '652',
    MA: '加茂川',
    areaCode: '867',
    bandStart: '086720',
    bandEnd: '086721',
    eliminateCode: '0',
  },
  {
    id: '653',
    MA: '加茂川',
    areaCode: '867',
    bandStart: '086725',
    bandEnd: '086725',
    eliminateCode: '0',
  },
  {
    id: '654',
    MA: '加茂川',
    areaCode: '867',
    bandStart: '086727',
    bandEnd: '086727',
    eliminateCode: '0',
  },
  {
    id: '655',
    MA: '加茂川',
    areaCode: '867',
    bandStart: '086729',
    bandEnd: '086729',
    eliminateCode: '0',
  },
  {
    id: '656',
    MA: '加茂川',
    areaCode: '867',
    bandStart: '086730',
    bandEnd: '086736',
    eliminateCode: '0',
  },
  {
    id: '657',
    MA: '加茂川',
    areaCode: '867',
    bandStart: '086739',
    bandEnd: '086739',
    eliminateCode: '0',
  },
  {
    id: '658',
    MA: '新見',
    areaCode: '867',
    bandStart: '08677',
    bandEnd: '08679',
    eliminateCode: '0',
  },
  {
    id: '659',
    MA: '久世',
    areaCode: '867',
    bandStart: '08674',
    bandEnd: '08676',
    eliminateCode: '0',
  },
  {
    id: '660',
    MA: '津山',
    areaCode: '868',
    bandStart: '08681',
    bandEnd: '08686',
    eliminateCode: '0',
  },
  {
    id: '661',
    MA: '美作',
    areaCode: '868',
    bandStart: '08687',
    bandEnd: '08688',
    eliminateCode: '0',
  },
  {
    id: '662',
    MA: '備前',
    areaCode: '869',
    bandStart: '08691',
    bandEnd: '08691',
    eliminateCode: '0',
  },
  {
    id: '663',
    MA: '備前',
    areaCode: '869',
    bandStart: '08696',
    bandEnd: '08698',
    eliminateCode: '0',
  },
  {
    id: '664',
    MA: '備前',
    areaCode: '869',
    bandStart: '086991',
    bandEnd: '086993',
    eliminateCode: '0',
  },
  {
    id: '665',
    MA: '邑久',
    areaCode: '869',
    bandStart: '08692',
    bandEnd: '08692',
    eliminateCode: '0',
  },
  {
    id: '666',
    MA: '邑久',
    areaCode: '869',
    bandStart: '086934',
    bandEnd: '086934',
    eliminateCode: '0',
  },
  {
    id: '667',
    MA: '赤磐',
    areaCode: '86',
    bandStart: '08660',
    bandEnd: '08660',
    eliminateCode: '0',
  },
  {
    id: '668',
    MA: '赤磐',
    areaCode: '86',
    bandStart: '086950',
    bandEnd: '086951',
    eliminateCode: '0',
  },
  {
    id: '669',
    MA: '赤磐',
    areaCode: '86',
    bandStart: '086954',
    bandEnd: '086958',
    eliminateCode: '0',
  },
  {
    id: '670',
    MA: '赤磐',
    areaCode: '86',
    bandStart: '086994',
    bandEnd: '086999',
    eliminateCode: '0',
  },
  {
    id: '671',
    MA: '観音寺',
    areaCode: '875',
    bandStart: '0875',
    bandEnd: '0875',
    eliminateCode: '0',
  },
  {
    id: '672',
    MA: '丸亀',
    areaCode: '877',
    bandStart: '0877',
    bandEnd: '0877',
    eliminateCode: '0',
  },
  {
    id: '673',
    MA: '高松',
    areaCode: '87',
    bandStart: '0871',
    bandEnd: '0871',
    eliminateCode: '0',
  },
  {
    id: '674',
    MA: '高松',
    areaCode: '87',
    bandStart: '0878',
    bandEnd: '0878',
    eliminateCode: '0',
  },
  {
    id: '675',
    MA: '三本松',
    areaCode: '879',
    bandStart: '08792',
    bandEnd: '08795',
    eliminateCode: '0',
  },
  {
    id: '676',
    MA: '土庄',
    areaCode: '879',
    bandStart: '08796',
    bandEnd: '08798',
    eliminateCode: '0',
  },
  {
    id: '677',
    MA: '土佐中村',
    areaCode: '880',
    bandStart: '08801',
    bandEnd: '08801',
    eliminateCode: '0',
  },
  {
    id: '678',
    MA: '土佐中村',
    areaCode: '880',
    bandStart: '08803',
    bandEnd: '08805',
    eliminateCode: '0',
  },
  {
    id: '679',
    MA: '宿毛',
    areaCode: '880',
    bandStart: '08806',
    bandEnd: '08807',
    eliminateCode: '0',
  },
  {
    id: '680',
    MA: '窪川',
    areaCode: '880',
    bandStart: '08802',
    bandEnd: '08802',
    eliminateCode: '0',
  },
  {
    id: '681',
    MA: '窪川',
    areaCode: '880',
    bandStart: '088090',
    bandEnd: '088094',
    eliminateCode: '0',
  },
  {
    id: '682',
    MA: '土佐清水',
    areaCode: '880',
    bandStart: '08808',
    bandEnd: '08808',
    eliminateCode: '0',
  },
  {
    id: '683',
    MA: '土佐清水',
    areaCode: '880',
    bandStart: '088095',
    bandEnd: '088099',
    eliminateCode: '0',
  },
  {
    id: '684',
    MA: '鴨島',
    areaCode: '883',
    bandStart: '08832',
    bandEnd: '08834',
    eliminateCode: '0',
  },
  {
    id: '685',
    MA: '脇町',
    areaCode: '883',
    bandStart: '08835',
    bandEnd: '08836',
    eliminateCode: '0',
  },
  {
    id: '686',
    MA: '阿波池田',
    areaCode: '883',
    bandStart: '08837',
    bandEnd: '08838',
    eliminateCode: '0',
  },
  {
    id: '687',
    MA: '阿南',
    areaCode: '884',
    bandStart: '08841',
    bandEnd: '08844',
    eliminateCode: '0',
  },
  {
    id: '688',
    MA: '丹生谷',
    areaCode: '884',
    bandStart: '08845',
    bandEnd: '08845',
    eliminateCode: '0',
  },
  {
    id: '689',
    MA: '丹生谷',
    areaCode: '884',
    bandStart: '08846',
    bandEnd: '08846',
    eliminateCode: '0',
  },
  {
    id: '690',
    MA: '牟岐',
    areaCode: '884',
    bandStart: '08847',
    bandEnd: '08847',
    eliminateCode: '0',
  },
  {
    id: '691',
    MA: '牟岐',
    areaCode: '884',
    bandStart: '08848',
    bandEnd: '08848',
    eliminateCode: '0',
  },
  {
    id: '692',
    MA: '小松島',
    areaCode: '885',
    bandStart: '08853',
    bandEnd: '08854',
    eliminateCode: '0',
  },
  {
    id: '693',
    MA: '徳島',
    areaCode: '88',
    bandStart: '0886',
    bandEnd: '0886',
    eliminateCode: '0',
  },
  {
    id: '694',
    MA: '嶺北',
    areaCode: '887',
    bandStart: '08877',
    bandEnd: '08878',
    eliminateCode: '0',
  },
  {
    id: '695',
    MA: '室戸',
    areaCode: '887',
    bandStart: '08872',
    bandEnd: '08872',
    eliminateCode: '0',
  },
  {
    id: '696',
    MA: '室戸',
    areaCode: '887',
    bandStart: '08879',
    bandEnd: '08879',
    eliminateCode: '0',
  },
  {
    id: '697',
    MA: '安芸',
    areaCode: '887',
    bandStart: '08873',
    bandEnd: '08874',
    eliminateCode: '0',
  },
  {
    id: '698',
    MA: '土佐山田',
    areaCode: '887',
    bandStart: '08875',
    bandEnd: '08876',
    eliminateCode: '0',
  },
  {
    id: '699',
    MA: '高知',
    areaCode: '88',
    bandStart: '0888',
    bandEnd: '0888',
    eliminateCode: '0',
  },
  {
    id: '700',
    MA: '佐川',
    areaCode: '889',
    bandStart: '08892',
    bandEnd: '08893',
    eliminateCode: '0',
  },
  {
    id: '701',
    MA: '須崎',
    areaCode: '889',
    bandStart: '08894',
    bandEnd: '08896',
    eliminateCode: '0',
  },
  {
    id: '702',
    MA: '久万',
    areaCode: '892',
    bandStart: '0892',
    bandEnd: '0892',
    eliminateCode: '0',
  },
  {
    id: '703',
    MA: '大洲',
    areaCode: '893',
    bandStart: '0893',
    bandEnd: '0893',
    eliminateCode: '0',
  },
  {
    id: '704',
    MA: '八幡浜',
    areaCode: '894',
    bandStart: '08942',
    bandEnd: '08945',
    eliminateCode: '0',
  },
  {
    id: '705',
    MA: '宇和',
    areaCode: '894',
    bandStart: '08946',
    bandEnd: '08949',
    eliminateCode: '0',
  },
  {
    id: '706',
    MA: '宇和島',
    areaCode: '895',
    bandStart: '08951',
    bandEnd: '08956',
    eliminateCode: '0',
  },
  {
    id: '707',
    MA: '御荘',
    areaCode: '895',
    bandStart: '08957',
    bandEnd: '08958',
    eliminateCode: '0',
  },
  {
    id: '708',
    MA: '伊予三島',
    areaCode: '896',
    bandStart: '0896',
    bandEnd: '0896',
    eliminateCode: '0',
  },
  {
    id: '709',
    MA: '伯方',
    areaCode: '897',
    bandStart: '08977',
    bandEnd: '08978',
    eliminateCode: '0',
  },
  {
    id: '710',
    MA: '新居浜',
    areaCode: '897',
    bandStart: '08971',
    bandEnd: '08976',
    eliminateCode: '0',
  },
  {
    id: '711',
    MA: '今治',
    areaCode: '898',
    bandStart: '0898',
    bandEnd: '0898',
    eliminateCode: '0',
  },
  {
    id: '712',
    MA: '松山',
    areaCode: '89',
    bandStart: '0891',
    bandEnd: '0891',
    eliminateCode: '0',
  },
  {
    id: '713',
    MA: '松山',
    areaCode: '89',
    bandStart: '0899',
    bandEnd: '0899',
    eliminateCode: '0',
  },
  {
    id: '714',
    MA: '前原',
    areaCode: '92',
    bandStart: '09232',
    bandEnd: '09233',
    eliminateCode: '0',
  },
  {
    id: '715',
    MA: '福岡',
    areaCode: '92',
    bandStart: '0921',
    bandEnd: '0922',
    eliminateCode: '0',
  },
  {
    id: '716',
    MA: '福岡',
    areaCode: '92',
    bandStart: '09230',
    bandEnd: '09231',
    eliminateCode: '0',
  },
  {
    id: '717',
    MA: '福岡',
    areaCode: '92',
    bandStart: '09234',
    bandEnd: '09239',
    eliminateCode: '0',
  },
  {
    id: '718',
    MA: '福岡',
    areaCode: '92',
    bandStart: '0924',
    bandEnd: '0929',
    eliminateCode: '0',
  },
  {
    id: '719',
    MA: '郷ノ浦',
    areaCode: '920',
    bandStart: '09201',
    bandEnd: '09201',
    eliminateCode: '0',
  },
  {
    id: '720',
    MA: '郷ノ浦',
    areaCode: '920',
    bandStart: '09203',
    bandEnd: '09204',
    eliminateCode: '0',
  },
  {
    id: '721',
    MA: '厳原',
    areaCode: '920',
    bandStart: '09205',
    bandEnd: '09207',
    eliminateCode: '0',
  },
  {
    id: '722',
    MA: '対馬佐賀',
    areaCode: '920',
    bandStart: '09208',
    bandEnd: '09208',
    eliminateCode: '0',
  },
  {
    id: '723',
    MA: '北九州',
    areaCode: '93',
    bandStart: '0931',
    bandEnd: '0939',
    eliminateCode: '0',
  },
  {
    id: '724',
    MA: '行橋',
    areaCode: '930',
    bandStart: '0930',
    bandEnd: '0930',
    eliminateCode: '0',
  },
  {
    id: '725',
    MA: '宗像',
    areaCode: '940',
    bandStart: '0940',
    bandEnd: '0940',
    eliminateCode: '0',
  },
  {
    id: '726',
    MA: '久留米',
    areaCode: '942',
    bandStart: '0942',
    bandEnd: '0942',
    eliminateCode: '0',
  },
  {
    id: '727',
    MA: '八女',
    areaCode: '943',
    bandStart: '09431',
    bandEnd: '09435',
    eliminateCode: '0',
  },
  {
    id: '728',
    MA: '田主丸',
    areaCode: '943',
    bandStart: '09437',
    bandEnd: '09438',
    eliminateCode: '0',
  },
  {
    id: '729',
    MA: '瀬高',
    areaCode: '944',
    bandStart: '0944',
    bandEnd: '0944',
    eliminateCode: '0',
  },
  {
    id: '730',
    MA: '甘木',
    areaCode: '946',
    bandStart: '0946',
    bandEnd: '0946',
    eliminateCode: '0',
  },
  {
    id: '731',
    MA: '田川',
    areaCode: '947',
    bandStart: '0947',
    bandEnd: '0947',
    eliminateCode: '0',
  },
  {
    id: '732',
    MA: '飯塚',
    areaCode: '948',
    bandStart: '0948',
    bandEnd: '0948',
    eliminateCode: '0',
  },
  {
    id: '733',
    MA: '直方',
    areaCode: '949',
    bandStart: '0949',
    bandEnd: '0949',
    eliminateCode: '0',
  },
  {
    id: '734',
    MA: '平戸',
    areaCode: '950',
    bandStart: '0950',
    bandEnd: '0950',
    eliminateCode: '0',
  },
  {
    id: '735',
    MA: '佐賀',
    areaCode: '952',
    bandStart: '0952',
    bandEnd: '0952',
    eliminateCode: '0',
  },
  {
    id: '736',
    MA: '武雄',
    areaCode: '954',
    bandStart: '09541',
    bandEnd: '09544',
    eliminateCode: '0',
  },
  {
    id: '737',
    MA: '鹿島',
    areaCode: '954',
    bandStart: '09546',
    bandEnd: '09547',
    eliminateCode: '0',
  },
  {
    id: '738',
    MA: '唐津',
    areaCode: '955',
    bandStart: '09555',
    bandEnd: '09558',
    eliminateCode: '0',
  },
  {
    id: '739',
    MA: '伊万里',
    areaCode: '955',
    bandStart: '09552',
    bandEnd: '09554',
    eliminateCode: '0',
  },
  {
    id: '740',
    MA: '佐世保',
    areaCode: '956',
    bandStart: '0956',
    bandEnd: '0956',
    eliminateCode: '0',
  },
  {
    id: '741',
    MA: '諫早',
    areaCode: '957',
    bandStart: '09571',
    bandEnd: '09575',
    eliminateCode: '0',
  },
  {
    id: '742',
    MA: '島原',
    areaCode: '957',
    bandStart: '09576',
    bandEnd: '09578',
    eliminateCode: '0',
  },
  {
    id: '743',
    MA: '長崎',
    areaCode: '95',
    bandStart: '0951',
    bandEnd: '0951',
    eliminateCode: '0',
  },
  {
    id: '744',
    MA: '長崎',
    areaCode: '95',
    bandStart: '0958',
    bandEnd: '0958',
    eliminateCode: '0',
  },
  {
    id: '745',
    MA: '大瀬戸',
    areaCode: '959',
    bandStart: '09592',
    bandEnd: '09593',
    eliminateCode: '0',
  },
  {
    id: '746',
    MA: '福江',
    areaCode: '959',
    bandStart: '09596',
    bandEnd: '09598',
    eliminateCode: '0',
  },
  {
    id: '747',
    MA: '有川',
    areaCode: '959',
    bandStart: '09594',
    bandEnd: '09595',
    eliminateCode: '0',
  },
  {
    id: '748',
    MA: '熊本',
    areaCode: '96',
    bandStart: '0961',
    bandEnd: '0963',
    eliminateCode: '0',
  },
  {
    id: '749',
    MA: '松橋',
    areaCode: '964',
    bandStart: '0964',
    bandEnd: '0964',
    eliminateCode: '0',
  },
  {
    id: '750',
    MA: '八代',
    areaCode: '965',
    bandStart: '0965',
    bandEnd: '0965',
    eliminateCode: '0',
  },
  {
    id: '751',
    MA: '人吉',
    areaCode: '966',
    bandStart: '09662',
    bandEnd: '09665',
    eliminateCode: '0',
  },
  {
    id: '752',
    MA: '水俣',
    areaCode: '966',
    bandStart: '09666',
    bandEnd: '09668',
    eliminateCode: '0',
  },
  {
    id: '753',
    MA: '熊本一の宮',
    areaCode: '967',
    bandStart: '09672',
    bandEnd: '09675',
    eliminateCode: '0',
  },
  {
    id: '754',
    MA: '矢部',
    areaCode: '967',
    bandStart: '09677',
    bandEnd: '09678',
    eliminateCode: '0',
  },
  {
    id: '755',
    MA: '高森',
    areaCode: '967',
    bandStart: '09676',
    bandEnd: '09676',
    eliminateCode: '0',
  },
  {
    id: '756',
    MA: '高森',
    areaCode: '967',
    bandStart: '09679',
    bandEnd: '09679',
    eliminateCode: '0',
  },
  {
    id: '757',
    MA: '山鹿',
    areaCode: '968',
    bandStart: '09681',
    bandEnd: '09684',
    eliminateCode: '0',
  },
  {
    id: '758',
    MA: '玉名',
    areaCode: '968',
    bandStart: '09685',
    bandEnd: '09688',
    eliminateCode: '0',
  },
  {
    id: '759',
    MA: '天草',
    areaCode: '969',
    bandStart: '09691',
    bandEnd: '09698',
    eliminateCode: '0',
  },
  {
    id: '760',
    MA: '佐伯',
    areaCode: '972',
    bandStart: '09721',
    bandEnd: '09725',
    eliminateCode: '0',
  },
  {
    id: '761',
    MA: '臼杵',
    areaCode: '972',
    bandStart: '09726',
    bandEnd: '09728',
    eliminateCode: '0',
  },
  {
    id: '762',
    MA: '日田',
    areaCode: '973',
    bandStart: '09731',
    bandEnd: '09735',
    eliminateCode: '0',
  },
  {
    id: '763',
    MA: '玖珠',
    areaCode: '973',
    bandStart: '09737',
    bandEnd: '09738',
    eliminateCode: '0',
  },
  {
    id: '764',
    MA: '三重',
    areaCode: '974',
    bandStart: '09742',
    bandEnd: '09744',
    eliminateCode: '0',
  },
  {
    id: '765',
    MA: '竹田',
    areaCode: '974',
    bandStart: '09746',
    bandEnd: '09747',
    eliminateCode: '0',
  },
  {
    id: '766',
    MA: '大分',
    areaCode: '97',
    bandStart: '0971',
    bandEnd: '0971',
    eliminateCode: '0',
  },
  {
    id: '767',
    MA: '大分',
    areaCode: '97',
    bandStart: '0975',
    bandEnd: '0975',
    eliminateCode: '0',
  },
  {
    id: '768',
    MA: '別府',
    areaCode: '977',
    bandStart: '0977',
    bandEnd: '0977',
    eliminateCode: '0',
  },
  {
    id: '769',
    MA: '国東',
    areaCode: '978',
    bandStart: '09787',
    bandEnd: '09788',
    eliminateCode: '0',
  },
  {
    id: '770',
    MA: '豊後高田',
    areaCode: '978',
    bandStart: '09782',
    bandEnd: '09785',
    eliminateCode: '0',
  },
  {
    id: '771',
    MA: '杵築',
    areaCode: '978',
    bandStart: '09786',
    bandEnd: '09786',
    eliminateCode: '0',
  },
  {
    id: '772',
    MA: '杵築',
    areaCode: '978',
    bandStart: '09789',
    bandEnd: '09789',
    eliminateCode: '0',
  },
  {
    id: '773',
    MA: '中津',
    areaCode: '979',
    bandStart: '0979',
    bandEnd: '0979',
    eliminateCode: '0',
  },
  {
    id: '774',
    MA: '那覇',
    areaCode: '98',
    bandStart: '0981',
    bandEnd: '0981',
    eliminateCode: '0',
  },
  {
    id: '775',
    MA: '那覇',
    areaCode: '98',
    bandStart: '0988',
    bandEnd: '0989',
    eliminateCode: '0',
  },
  {
    id: '776',
    MA: '名護',
    areaCode: '980',
    bandStart: '09801',
    bandEnd: '09801',
    eliminateCode: '0',
  },
  {
    id: '777',
    MA: '名護',
    areaCode: '980',
    bandStart: '09803',
    bandEnd: '09805',
    eliminateCode: '0',
  },
  {
    id: '778',
    MA: '南大東',
    areaCode: '9802',
    bandStart: '09802',
    bandEnd: '09802',
    eliminateCode: '0',
  },
  {
    id: '779',
    MA: '沖縄宮古',
    areaCode: '980',
    bandStart: '09806',
    bandEnd: '09807',
    eliminateCode: '0',
  },
  {
    id: '780',
    MA: '八重山',
    areaCode: '980',
    bandStart: '09808',
    bandEnd: '09809',
    eliminateCode: '0',
  },
  {
    id: '781',
    MA: '延岡',
    areaCode: '982',
    bandStart: '09821',
    bandEnd: '09824',
    eliminateCode: '0',
  },
  {
    id: '782',
    MA: '延岡',
    areaCode: '982',
    bandStart: '098290',
    bandEnd: '098294',
    eliminateCode: '0',
  },
  {
    id: '783',
    MA: '日向',
    areaCode: '982',
    bandStart: '09825',
    bandEnd: '09826',
    eliminateCode: '0',
  },
  {
    id: '784',
    MA: '日向',
    areaCode: '982',
    bandStart: '098295',
    bandEnd: '098299',
    eliminateCode: '0',
  },
  {
    id: '785',
    MA: '高千穂',
    areaCode: '982',
    bandStart: '09827',
    bandEnd: '09828',
    eliminateCode: '0',
  },
  {
    id: '786',
    MA: '高鍋',
    areaCode: '983',
    bandStart: '0983',
    bandEnd: '0983',
    eliminateCode: '0',
  },
  {
    id: '787',
    MA: '小林',
    areaCode: '984',
    bandStart: '0984',
    bandEnd: '0984',
    eliminateCode: '0',
  },
  {
    id: '788',
    MA: '宮崎',
    areaCode: '985',
    bandStart: '0985',
    bandEnd: '0985',
    eliminateCode: '0',
  },
  {
    id: '789',
    MA: '都城',
    areaCode: '986',
    bandStart: '0986',
    bandEnd: '0986',
    eliminateCode: '0',
  },
  {
    id: '790',
    MA: '日南',
    areaCode: '987',
    bandStart: '0987',
    bandEnd: '0987',
    eliminateCode: '0',
  },
  {
    id: '791',
    MA: '中之島',
    areaCode: '9912',
    bandStart: '09912',
    bandEnd: '09912',
    eliminateCode: '0',
  },
  {
    id: '792',
    MA: '硫黄島',
    areaCode: '9913',
    bandStart: '09913',
    bandEnd: '09913',
    eliminateCode: '0',
  },
  {
    id: '793',
    MA: '鹿児島',
    areaCode: '99',
    bandStart: '09917',
    bandEnd: '09917',
    eliminateCode: '0',
  },
  {
    id: '794',
    MA: '鹿児島',
    areaCode: '99',
    bandStart: '0992',
    bandEnd: '0992',
    eliminateCode: '0',
  },
  {
    id: '795',
    MA: '鹿児島',
    areaCode: '99',
    bandStart: '09980',
    bandEnd: '09983',
    eliminateCode: '0',
  },
  {
    id: '796',
    MA: '鹿児島',
    areaCode: '99',
    bandStart: '099331',
    bandEnd: '099331',
    eliminateCode: '0',
  },
  {
    id: '797',
    MA: '鹿児島',
    areaCode: '99',
    bandStart: '099343',
    bandEnd: '099343',
    eliminateCode: '0',
  },
  {
    id: '798',
    MA: '鹿児島',
    areaCode: '99',
    bandStart: '099345',
    bandEnd: '099345',
    eliminateCode: '0',
  },
  {
    id: '799',
    MA: '鹿児島',
    areaCode: '99',
    bandStart: '099347',
    bandEnd: '099347',
    eliminateCode: '0',
  },
  {
    id: '800',
    MA: '指宿',
    areaCode: '993',
    bandStart: '09932',
    bandEnd: '09932',
    eliminateCode: '0',
  },
  {
    id: '801',
    MA: '指宿',
    areaCode: '993',
    bandStart: '099330',
    bandEnd: '099330',
    eliminateCode: '0',
  },
  {
    id: '802',
    MA: '指宿',
    areaCode: '993',
    bandStart: '099332',
    bandEnd: '099339',
    eliminateCode: '0',
  },
  {
    id: '803',
    MA: '指宿',
    areaCode: '993',
    bandStart: '099340',
    bandEnd: '099342',
    eliminateCode: '0',
  },
  {
    id: '804',
    MA: '指宿',
    areaCode: '993',
    bandStart: '099344',
    bandEnd: '099344',
    eliminateCode: '0',
  },
  {
    id: '805',
    MA: '指宿',
    areaCode: '993',
    bandStart: '099346',
    bandEnd: '099346',
    eliminateCode: '0',
  },
  {
    id: '806',
    MA: '指宿',
    areaCode: '993',
    bandStart: '099348',
    bandEnd: '099349',
    eliminateCode: '0',
  },
  {
    id: '807',
    MA: '加世田',
    areaCode: '993',
    bandStart: '09931',
    bandEnd: '09931',
    eliminateCode: '0',
  },
  {
    id: '808',
    MA: '加世田',
    areaCode: '993',
    bandStart: '09935',
    bandEnd: '09938',
    eliminateCode: '0',
  },
  {
    id: '809',
    MA: '志布志',
    areaCode: '99',
    bandStart: '09940',
    bandEnd: '09940',
    eliminateCode: '0',
  },
  {
    id: '810',
    MA: '志布志',
    areaCode: '99',
    bandStart: '09947',
    bandEnd: '09948',
    eliminateCode: '0',
  },
  {
    id: '811',
    MA: '鹿屋',
    areaCode: '994',
    bandStart: '09941',
    bandEnd: '09941',
    eliminateCode: '0',
  },
  {
    id: '812',
    MA: '鹿屋',
    areaCode: '994',
    bandStart: '09943',
    bandEnd: '09946',
    eliminateCode: '0',
  },
  {
    id: '813',
    MA: '大根占',
    areaCode: '994',
    bandStart: '09942',
    bandEnd: '09942',
    eliminateCode: '0',
  },
  {
    id: '814',
    MA: '大根占',
    areaCode: '994',
    bandStart: '09949',
    bandEnd: '09949',
    eliminateCode: '0',
  },
  {
    id: '815',
    MA: '加治木',
    areaCode: '995',
    bandStart: '09951',
    bandEnd: '09951',
    eliminateCode: '0',
  },
  {
    id: '816',
    MA: '加治木',
    areaCode: '995',
    bandStart: '09954',
    bandEnd: '09957',
    eliminateCode: '0',
  },
  {
    id: '817',
    MA: '大口',
    areaCode: '995',
    bandStart: '09952',
    bandEnd: '09953',
    eliminateCode: '0',
  },
  {
    id: '818',
    MA: '出水',
    areaCode: '996',
    bandStart: '09966',
    bandEnd: '09968',
    eliminateCode: '0',
  },
  {
    id: '819',
    MA: '川内',
    areaCode: '996',
    bandStart: '09961',
    bandEnd: '09965',
    eliminateCode: '0',
  },
  {
    id: '820',
    MA: '中甑',
    areaCode: '9969',
    bandStart: '09969',
    bandEnd: '09969',
    eliminateCode: '0',
  },
  {
    id: '821',
    MA: '名瀬',
    areaCode: '997',
    bandStart: '09971',
    bandEnd: '09971',
    eliminateCode: '0',
  },
  {
    id: '822',
    MA: '名瀬',
    areaCode: '997',
    bandStart: '09975',
    bandEnd: '09976',
    eliminateCode: '0',
  },
  {
    id: '823',
    MA: '徳之島',
    areaCode: '997',
    bandStart: '09978',
    bandEnd: '09979',
    eliminateCode: '0',
  },
  {
    id: '824',
    MA: '種子島',
    areaCode: '997',
    bandStart: '09972',
    bandEnd: '09973',
    eliminateCode: '0',
  },
  {
    id: '825',
    MA: '屋久島',
    areaCode: '997',
    bandStart: '09974',
    bandEnd: '09974',
    eliminateCode: '0',
  },
  {
    id: '826',
    MA: '瀬戸内',
    areaCode: '997',
    bandStart: '09977',
    bandEnd: '09977',
    eliminateCode: '0',
  },
]

export default NumberBandList